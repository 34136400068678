<template>
    <div class="main-drone-report-missions-executed-by-hour">
        <app-single-bar-chart
            :chart-data="datacollection"
            :style="style"
            :title="'Voos por horário'"
            :showAllTooltips="false"
        ></app-single-bar-chart>
    </div>
</template>

<script>
import appSingleBarChart from "@/components/charts/SingleBarChart";

export default {
    data() {
        return {
            style: { height: "100%" },
        };
    },
    components: {
        appSingleBarChart,
    },
    methods: {},
    props: ["datacollection"],
    mounted() {},
};
</script>

<style>
.main-drone-report-missions-executed-by-hour {
    height: 100%;
    position: relative;
}
</style>