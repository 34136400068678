<template>
    <div>
        <div
            v-show="!live"
            style="height: 100%; width: 100%; background-color: black"
        >
            <div class="row h-100">
                <div class="col-12 my-auto">
                    <i style="font-size: 2rem" class="el-icon-loading"></i>
                </div>
            </div>
        </div>
        <video
            v-show="live"
            class="aws_video-element"
            playsinline
            muted
            autoplay
            controls="false"
            style="height: 100%; width: 100%; background-color: black"
            :id="id"
        ></video>
    </div>
</template>

<script>
export default {
    data() {
        return {
            count: 0,
            live: false,
        };
    },
    props: ["url", "id"],
    methods: {
        playItInLoop(player) {
            let self = this;
            if (player.getState() === "Idle") {
                if (this.count < 20) {
                    this.count++;
                    player.play();
                    player.load(this.url || "");
                    setTimeout(() => {
                        self.playItInLoop(player);
                    }, 1000);
                }
            } else {
                this.live = true;
                this.count = 0;
            }
        },
    },
    mounted() {
        if (IVSPlayer.isPlayerSupported) {
            const player = IVSPlayer.create();
            player.attachHTMLVideoElement(document.getElementById(this.id));
            this.playItInLoop(player);
        }
    },
};
</script>