import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase';
import momenttz from "moment-timezone";
import moment from 'moment';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            data: null,
            licenses: {
                solo: {
                    elements: [],
                    unlicensedElements: [],
                    counter: 0,
                },
                drone: {
                    elements: [],
                    unlicensedElements: [],
                    counter: 0,
                },
            },
            events: [],
            pilotEvents: [],
            artificialIntelligenceEvents: [],
            files: [],
            toast: {
                message: null,
                color: null
            }
        },
        nav: {
            mainNav: "drone", //drone,solo,setup,review
            topBar: 1,
            streamFocused: false,
            streamDialogDrawerVisible: false,
            forcedLoading: false
        },
        drones: {
            active: null,
            elements: [],
            markers: [],
            activeMission: null,
            activeMissionElement: null,
            activeMissionUpdateTimestamp: 0,
            activeMissionFencingsRespected: false,
            activeMissionEditWaypointIndex: 0,
            missionsEditMode: false,
            missions: [],
            report: {
                filter: {
                    date: [moment(
                        moment(Date.now() - 7 * 24 * 60 * 60 * 1000).format("DD/MM/YYYY 00:00:00"), "DD/MM/YYYY 00:00:00").valueOf(), moment(
                        moment(Date.now()).format("DD/MM/YYYY 00:00:00"), "DD/MM/YYYY 00:00:00").valueOf()],
                    device: []
                },
                status: null,
                data: {
                    graphs: {
                        missionsExecuted: {
                            datasets: [{
                                label: "Número de missões",
                                backgroundColor: "#FFE082",
                                borderColor: "#FFE082",
                                type: "bar",
                                data: [],
                            }, ],
                            labels: []
                        },
                        missionsByOperator: {
                            datasets: [{
                                data: [],
                                backgroundColor: [],
                            }, ],
                            labels: [],
                        },
                        scheduledMissions: {
                            datasets: [{
                                data: [],
                                backgroundColor: [
                                    "#A5D6A7",
                                    "#EF9A9A",
                                ],
                            }, ],
                            labels: [],

                        },
                        missionsNotExecuted: {
                            datasets: [{
                                data: [],
                                backgroundColor: [
                                    "#90CAF9",
                                    "#EF9A9A",
                                    '#FFE082'
                                ],
                            }, ],
                            labels: [],

                        },
                        missionsExecutedByHour: {
                            datasets: [{
                                label: "Número de missões",
                                backgroundColor: "#EF9A9A",
                                borderColor: "#EF9A9A",
                                type: "line",
                                fill: false,
                                data: [],
                            }, ],
                            labels: []
                        },
                        missionsExecutedByDay: {
                            datasets: [{
                                label: "Número de missões",
                                backgroundColor: "#A5D6A7",
                                borderColor: "#A5D6A7",
                                type: "bar",
                                data: [],
                            }, {
                                label: "Número de missões",
                                backgroundColor: "#EF9A9A",
                                borderColor: "#EF9A9A",
                                type: "bar",
                                data: [],
                            }, {
                                label: "Número de missões",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFFFFF",
                                type: "line",
                                fill: false,
                                id: '3',
                                data: [],
                            }, ],
                            labels: []
                        },
                        events: {
                            datasets: [{
                                label: "Eventos detectados",
                                backgroundColor: "#FFE082",
                                borderColor: "#FFE082",
                                type: "bar",
                                data: [],
                            }, ],
                            labels: []
                        },
                        artificialIntelligenceEvents: {
                            datasets: [{
                                label: "Objetos detectados com IA",
                                backgroundColor: "#A5D6A7",
                                borderColor: "#A5D6A7",
                                type: "bar",
                                data: [],
                            }, ],
                            labels: []
                        },
                        batteryStates: {
                            datasets: [{
                                label: "Número de ciclos",
                                backgroundColor: "#A5D6A7",
                                borderColor: "#A5D6A7",
                                type: "bar",
                                data: [],
                            }, {
                                label: "Número de ciclos",
                                backgroundColor: "#EBD99C",
                                borderColor: "#EBD99C",
                                type: "bar",
                                data: [],
                            }, {
                                label: "Número de ciclos",
                                backgroundColor: "#EF9A9A",
                                borderColor: "#EF9A9A",
                                type: "bar",
                                data: [],
                            }, ],
                            labels: [],
                            tooltips: [
                                []
                            ]
                        },
                    },
                    kpi: [{
                            value: 0,
                            label: "Total Previsto",
                            color: "white"
                        },
                        {
                            value: 0,
                            label: "Realizados",
                            color: "#A5D6A7"
                        },
                        {
                            value: 0,
                            label: "Não Realizados",
                            color: "#EF9A9A"
                        },
                        {
                            value: 0,
                            label: "Voos conforme",
                            color: "#A5D6A7"
                        },
                        {
                            value: 0,
                            label: "Voos não conforme",
                            color: "#EF9A9A"
                        },
                        {
                            value: "-",
                            label: "Tempo médio de voo",
                            color: "white"
                        },
                    ],
                    events: [],
                    locations: []
                }
            }
        },
        solos: {
            active: null,
            elements: [],
            markers: [],
            report: {
                filter: {
                    date: [moment(
                        moment(Date.now() - 7 * 24 * 60 * 60 * 1000).format("DD/MM/YYYY 00:00:00"), "DD/MM/YYYY 00:00:00").valueOf(), moment(
                        moment(Date.now()).format("DD/MM/YYYY 00:00:00"), "DD/MM/YYYY 00:00:00").valueOf()],
                    device: [],
                    event: []
                },
                status: null,
                data: {
                    graphs: {
                        events: {
                            datasets: [{
                                label: "Eventos detectados",
                                backgroundColor: "#FFE082",
                                borderColor: "#FFE082",
                                type: "bar",
                                data: [],
                            }, ],
                            labels: []
                        },
                        tagged_important_by_device: {
                            datasets: [{
                                label: "Envio de alertas",
                                backgroundColor: "#FFE082",
                                borderColor: "#FFE082",
                                type: "bar",
                                data: [],
                            }, ],
                            labels: []
                        }
                    },
                    kpi: [{
                            value: 0,
                            label: "Dispositivos",
                            color: "white"
                        },
                        {
                            value: 0,
                            label: "Eventos",
                            color: "#A5D6A7"
                        },
                        {
                            value: 0,
                            label: "Alertas",
                            color: "#A5D6A7"
                        },
                        {
                            value: 0,
                            label: "Sem comunicação (72h ou mais)",
                            color: "#EF9A9A"
                        },
                    ],
                    events: [],
                    eventsDetails: [],
                    additionalGraphs: [],
                    locations: []
                }
            }
        },
        logReviews: {
            active: null,
            activeElement: null,
            elements: [],
            status: null,
            filter: {
                date: [moment(
                    moment(Date.now() - 1 * 24 * 60 * 60 * 1000).format("DD/MM/YYYY 00:00:00"), "DD/MM/YYYY 00:00:00").valueOf(), moment(
                    moment(Date.now()).format("DD/MM/YYYY 00:00:00"), "DD/MM/YYYY 00:00:00").valueOf()],
                time: ["00:00", "23:59"],
                type: [],
                device: [],
                event: [],
                coordinates: {
                    lat: null,
                    lng: null,
                    radius: null
                },
                medias: true
            }
        },
        subscribers: {
            elements: []
        },
        supervisors: {
            elements: []
        },
        licensesKeys: {
            elements: []
        },
        followers: {
            elements: []
        },
        utils: {
            markers: {
                red: require("@/assets/img/markerCircleRed.svg"),
                yellow: require("@/assets/img/markerCircleYellow.svg"),
                blue: require("@/assets/img/markerCircleBlue.svg"),
                drone: require("@/assets/img/markerDrone.svg"),
                missionWaypoint: require("@/assets/img/markerMissionWaypoint.svg"),
                missionIntermediaryWaypoint: require("@/assets/img/markerMissionIntermediaryWaypoint.svg"),
                logReviewBeg: require("@/assets/img/markerLogReviewBeg.svg"),
                logReviewEnd: require("@/assets/img/markerLogReviewEnd.svg"),
                amber500: require("@/assets/img/markerCircleAmber500.svg"),
                yellow: require("@/assets/img/markerCircleYellow500.svg"),
                lime500: require("@/assets/img/markerCircleLime500.svg"),
                lightGreen500: require("@/assets/img/markerCircleLightGreen500.svg"),
                cyan500: require("@/assets/img/markerCircleCyan500.svg"),
                lightBlue500: require("@/assets/img/markerCircleLightBlue500.svg"),
                blue500: require("@/assets/img/markerCircleBlue500.svg"),
                indigo500: require("@/assets/img/markerCircleIndigo500.svg"),
                deepPurple500: require("@/assets/img/markerCircleDeepPurple500.svg"),
                purple500: require("@/assets/img/markerCirclePurple500.svg"),
                pink500: require("@/assets/img/markerCirclePink500.svg"),
                red500: require("@/assets/img/markerCircleRed500.svg"),
            },
        },
        initialized: {
            drones: false,
            solos: false,
            subscribers: false,
            supervisors: false,
            logReviews: false
        },
        map: {
            dragged: false
        },
        events: {
            showAllNotifications: true,
            checkedNotifications: [],
            notifications: [],
            focusedLocation: null,
            currentLogServiceKeys: {},
        }
    },
    getters: {
        // 
        user(state) {
            return state.user;
        },
        events(state) {
            return state.user.events;
        },
        navState(state) {
            return state.nav;
        },
        initialized(state) {
            return state.initialized;
        },
        // 
        droneActive(state) {
            return state.drones.active;
        },
        droneActiveElement(state) {
            return state.drones.elements[
                state.drones.elements
                .map(function(e) {
                    return e.vUID;
                })
                .indexOf(state.drones.active)
            ];
        },
        dronesElements(state) {
            return state.drones.elements.filter(v => v.flight && v.internals && v.telemetry && v.stream);
        },
        dronesInitialized(state) {
            return state.initialized.drones;
            // return state.drones.elements.filter(v => v.flight && v.internals && v.telemetry && v.stream).length === state.drones.elements.length;
        },
        dronesMissions(state) {
            return state.drones.missions.sort((a, b) => a.vMISSION_NAME > b.vMISSION_NAME ? 1 : -1);
        },
        dronesMissionsLength(state) {
            return state.drones.missions.length;
        },
        droneActiveMission(state) {
            return state.drones.activeMission;
        },
        droneActiveMissionElement(state) {
            return state.drones.activeMissionElement || null;
        },
        droneActiveMissionUpdateTimestamp(state) {
            return state.drones.activeMissionUpdateTimestamp;
        },
        droneActiveMissionFencingsRespected(state) {
            return state.drones.activeMissionFencingsRespected;
        },
        droneActiveMissionEditWaypointIndex(state) {
            return state.drones.activeMissionEditWaypointIndex;
        },
        dronesMissionsEditMode(state) {
            return state.drones.missionsEditMode;
        },
        dronesMarkers(state) {
            return state.drones.elements.map(v => v.marker);
        },
        dronesReport(state) {
            return state.drones.report;
        },
        dronesReportStatus(state) {
            return state.drones.report.status;
        },
        // 
        soloActive(state) {
            return state.solos.active;
        },
        soloActiveElement(state) {
            return state.solos.elements[
                state.solos.elements
                .map(function(e) {
                    return e.vUID;
                })
                .indexOf(state.solos.active)
            ];
        },
        solosElements(state) {
            return state.solos.elements.filter(v => v.internals && v.telemetry && v.stream);
        },
        solosInitialized(state) {
            return state.initialized.solos;
            // return state.solos.elements.filter(v => v.internals && v.telemetry && v.stream).length === state.solos.elements.length;
        },
        solosMarkers(state) {
            return state.solos.elements.map(v => v.marker);
        },
        solosReport(state) {
            return state.solos.report;
        },
        solosReportStatus(state) {
            return state.solos.report.status;
        },
        // 
        subscribersElements(state) {
            return state.subscribers.elements;
        },
        supervisorsElements(state) {
            return state.supervisors.elements;
        },
        // 
        licensesKeysElements(state) {
            return state.licensesKeys.elements;
        },
        // 
        followersElements(state) {
            return state.followers.elements;
        },
        // 
        logReviewsElements(state) {
            return state.logReviews.elements.sort((a, b) =>
                a.vDATETIME_START < b.vDATETIME_START ? 1 : -1
            );
        },
        logReviewActive(state) {
            return state.logReviews.active;
        },
        logReviewActiveElement(state) {
            return state.logReviews.activeElement;
            // return state.logReviews.elements[
            //     state.logReviews.elements
            //     .map(function(e) {
            //         return e.vLOG_REVIEW_KEY;
            //     })
            //     .indexOf(state.logReviews.active)
            // ];
        },
        logReviewsFilter(state) {
            return state.logReviews.filter;
        },
        logReviewsStatus(state) {
            return state.logReviews.status;
        },
        smartElements(state) {
            return state.drones.elements.concat(state.solos.elements);
        },
        droneReportMissionsExecutedCollection(state) {
            return state.drones.report.data.graphs.missionsExecuted;
        },
        droneReportMissionsByOperatorCollection(state) {
            return state.drones.report.data.graphs.missionsByOperator;
        },
        droneReportScheduledMissionsCollection(state) {
            return state.drones.report.data.graphs.scheduledMissions;
        },
        droneReportMissionsNotExecutedCollection(state) {
            return state.drones.report.data.graphs.missionsNotExecuted;
        },
        droneReportMissionsExecutedByHourCollection(state) {
            return state.drones.report.data.graphs.missionsExecutedByHour;
        },
        droneReportMissionsExecutedByDayCollection(state) {
            return state.drones.report.data.graphs.missionsExecutedByDay;
        },
        droneReportEventsCollection(state) {
            return state.drones.report.data.graphs.events;
        },
        droneReportArtificialIntelligenceEventsCollection(state) {
            return state.drones.report.data.graphs.artificialIntelligenceEvents;
        },
        droneReportBatteryStatesCollection(state) {
            return state.drones.report.data.graphs.batteryStates;
        },
        dronesReportFilter(state) {
            return state.drones.report.filter;
        },
        droneReportMissionsKpi(state) {
            return state.drones.report.data.kpi;
        },
        droneReportMissionsEvents(state) {
            return state.drones.report.data.events;
        },
        droneReportMissionsLocations(state) {
            return state.drones.report.data.locations;
        },
        dronesReportFilter(state) {
            return state.drones.report.filter;
        },
        // 
        soloReportEventsCollection(state) {
            return state.solos.report.data.graphs.events;
        },
        soloReportTaggedImportantByDeviceCollection(state) {
            return state.solos.report.data.graphs.tagged_important_by_device;
        },
        soloReportAdditionalGraphs(state) {
            return state.solos.report.data.additionalGraphs;
        },
        soloReportMissionsKpi(state) {
            return state.solos.report.data.kpi;
        },
        soloReportMissionsEvents(state) {
            return state.solos.report.data.events;
        },
        soloReportMissionsLocations(state) {
            return state.solos.report.data.locations;
        },
        soloReportMissionsEventsDetails(state) {
            return state.solos.report.data.eventsDetails
                .sort((a, b) =>
                    a.vTIMESTAMP > b.vTIMESTAMP ? -1 : 1
                )
                .slice(0, 10);
        },
        solosReportFilter(state) {
            return state.solos.report.filter;
        },
        utilsMarkers(state) {
            return state.utils.markers;
        },
        initialized(state) {
            return state.initialized;
        },
        mapDragged(state) {
            return state.map.dragged;
        },
        eventsNotifications(state) {
            // return []
            return state.drones.elements.concat(state.solos.elements).map(v => v.notifications || []).reduce(
                (a, b) => a.concat(b), []
            ).sort((a, b) =>
                a.vTIMESTAMP < b.vTIMESTAMP ? 1 : -1
            );
        },
        eventNotificationFocusedLocation(state) {
            return state.events.focusedLocation
        },
        showAllEventsNotifications(state) {
            return state.events.showAllNotifications
        },
        checkedEventsNotifications(state) {
            return state.events.checkedNotifications
        },
        pilotEvents(state) {
            return state.user.pilotEvents
        },
        artificialIntelligenceEvents(state) {
            return state.user.artificialIntelligenceEvents
        },
        files(state) {
            return state.user.files
        }
    },
    mutations: {
        //
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        SET_USER_INTERNALS(state, data) {
            state.user.data.internals = data;
        },
        SET_USER_LAST_LOGIN(state, data) {
            state.user.data.lastLogin = data;
        },
        FETCH_INTERNALS(state, data) {
            state.user.events = Object.values(data.events || {}).map(function(v) {
                return {
                    label: v.vLABEL_PT,
                    label_pt: v.vLABEL_PT || '',
                    label_en: v.vLABEL_EN || '',
                    value: v.vVALUE,
                    color: v.vMARKER_COLOR,
                    consideredBySolo: v.vCONSIDERED_BY_SOLO,
                    consideredByDrone: v.vCONSIDERED_BY_DRONE
                }
            });
            state.user.pilotEvents = data.pilotEvents;
            state.user.artificialIntelligenceEvents = data.artificialIntelligenceEvents;
            state.user.files = data.files;


            firebase
                .database()
                .ref(
                    `aegisv2/users/${state.user.data.uid}/internals/settings/solo/events`
                )
                .on('value', function(snapshot) {
                    let r = snapshot.val() || {};
                    for (let k of Object.keys(
                            r
                        ).filter(
                            (v) => state.user.events.map((v) => v.value).indexOf(v) !== -1
                        )) {
                        if (state.user.pilotEvents.solo[k]) {
                            for (let p of[
                                    "vIS_ENABLED",
                                    "vNOTIFY_BY_EMAIL",
                                    "vNOTIFY_BY_SEGWARE",
                                    "vVALUE",
                                ]) {
                                if (typeof r[k] != 'undefined') {
                                    if (typeof r[k][p] != 'undefined') {
                                        state.user.pilotEvents.solo[k][p] =
                                            r[k][p];
                                    }
                                }
                            }
                        }
                    }
                });


            firebase
                .database()
                .ref(
                    `aegisv2/users/${state.user.data.uid}/internals/settings/drone/events`
                )
                .on('value', function(snapshot) {
                    let r = snapshot.val() || {};
                    for (let k of Object.keys(
                            r
                        ).filter(
                            (v) => state.user.events.map((v) => v.value).indexOf(v) !== -1
                        )) {
                        if (state.user.pilotEvents.drone[k]) {
                            for (let p of[
                                    "vIS_ENABLED",
                                    "vNOTIFY_BY_EMAIL",
                                    "vNOTIFY_BY_SEGWARE",
                                    "vVALUE",
                                ]) {
                                if (typeof r[k] != 'undefined') {
                                    if (typeof r[k][p] != 'undefined') {
                                        state.user.pilotEvents.drone[k][p] =
                                            r[k][p];
                                    }
                                }
                            }
                        } else if (state.user.artificialIntelligenceEvents.drone[k]) {
                            for (let p of[
                                    "vIS_ENABLED",
                                    "vNOTIFY_BY_EMAIL",
                                    "vNOTIFY_BY_SEGWARE",
                                    "vVALUE",
                                ]) {
                                if (typeof r[k] != 'undefined') {
                                    if (typeof r[k][p] != 'undefined') {
                                        state.user.artificialIntelligenceEvents.drone[k][p] =
                                            r[k][p];
                                    }
                                }
                            }
                        }
                    }
                });
        },
        FETCH_USER_LICENSES(state, data) {
            state.user.licenses = data;
        },
        SET_INITIALIZED(state, value) {
            state.initialized[Object.keys(value)[0]] = Object.values(value)[0];
        },
        SET_NAV_MAIN_NAV(state, data) {
            state.nav.mainNav = data;

            if (data === 'drone') {
                for (let d of state.drones.elements.filter(v => v.flight && v.internals && v.telemetry && v.stream)) {
                    let uid = d.vUID;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vIS_DEVICE_BEING_MONITORED`
                        )
                        .set(true);
                }
            } else if (data === 'solo') {
                for (let s of state.solos.elements.filter(v => v.internals && v.telemetry && v.stream)) {
                    let uid = s.vUID;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vIS_DEVICE_BEING_MONITORED`
                        )
                        .set(true);
                }
            }
        },
        SET_NAV_TOP_BAR(state, data) {
            state.nav.topBar = data;
        },
        SET_NAV_STREAM_FOCUSED(state, data) {
            state.nav.streamFocused = data;
        },
        SET_NAV_STREAM_DIALOG_MOSAIC_VISIBLE(state, data) {
            state.nav.streamDialogDrawerVisible = data;
        },
        SET_NAV_SETUP_NAV(state, data) {
            state.nav.setupNav = data;
        },
        SET_NAV_FORCED_LOADING(state, data) {
            state.nav.forcedLoading = data;
        },
        // drone
        SET_DRONE_ACTIVE(state, value) {
            state.drones.active = value;

            if (value) {
                firebase
                    .database()
                    .ref(
                        `aegisv2/users/${value}/telemetry/vIS_DEVICE_BEING_MONITORED`
                    )
                    .set(true);
                firebase
                    .database()
                    .ref(
                        `aegisv2/users/${value}/telemetry/vSTREAMING_BEING_WATCHED_AT`
                    )
                    .set(Date.now());
            }

        },
        SET_DRONES_ELEMENTS(state, value) {
            state.drones.elements = value;
        },
        CREATE_DRONE_ELEMENT(state, value) {
            state.drones.elements.push({
                vUID: value.vUID,
                vOPERATOR_NAME: null,
                vWEBRTC_BITRATE_TIMESTAMP: null,
                flight: null,
                internals: null,
                telemetry: null,
                stream: null,
                marker: {
                    position: {
                        lat: 0,
                        lng: 0,
                    },
                    icon: {
                        strokeWeight: 2,
                        strokeColor: "#343434",
                        fillColor: "#FFE082",
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        rotation: 0,
                        scale: 8,
                        anchor: new google.maps.Point(0, 4),
                    },
                    infoOptions: {
                        pixelOffset: {
                            width: 0,
                            height: 60,
                        },
                        disableAutoPan: true,
                        content: "<strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'>" +
                            "</strong>",

                    },
                },
                lasTimeUpdated: {
                    flight: 0,
                    internals: 0,
                    telemetry: 0,
                    stream: 0,
                    actions: 0,
                    notifications: 0
                },
                vIS_CONNECTED: false,
                vIS_STANDBY: false
            });
        },
        UPDATE_DRONE_ELEMENT(state, value) {
            let pos = (pos = state.drones.elements
                .map(function(e) {
                    return e.vUID;
                })
                .indexOf(value.vUID));
            if (pos !== -1) {
                let key = Object.keys(value)[1];
                let data = value[key];

                let e = state.drones.elements[pos];
                e.lasTimeUpdated[key] = Date.now();

                if (key === 'internals') {
                    e[key] = data;
                    e.marker.infoOptions.content = "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'>" + data.vCHILD_NAME + "</strong></div>"
                    if (
                        data.vTOAST_MESSAGE !== null &&
                        data.vTOAST_MESSAGE !== "none" && ["success", "warning", "error", "info"].indexOf(data.vTOAST_COLOR) !== -1) {
                        state.user.toast = {
                            message: `${data.vCHILD_NAME}: ${data.vTOAST_MESSAGE}`,
                            color: data.vTOAST_COLOR
                        }
                        firebase
                            .database()
                            .ref(
                                `aegisv2/users/${value.vUID}/internals/`
                            )
                            .update({ 'vTOAST_COLOR': 'info', 'vTOAST_MESSAGE': 'none' });
                    }
                } else if (key === 'flight') {
                    e[key] = data;
                } else if (key === 'telemetry') {
                    e[key] = data;
                    e.marker.position.lat = data.vAIRCRAFT_LOCATION_LATITUDE || 0;
                    e.marker.position.lng = data.vAIRCRAFT_LOCATION_LONGITUDE || 0;
                    e.marker.icon = {
                        strokeWeight: 2,
                        strokeColor: "#343434",
                        fillColor: "#FFE082",
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        rotation: data.vYAW || 0,
                        scale: 8,
                        anchor: new google.maps.Point(0, 4),
                    };
                } else if (key === 'stream') {
                    e[key] = data;
                } else if (key === 'vWEBRTC_BITRATE_TIMESTAMP') {
                    e[key] = e[key] === null ? 0 : data;
                }
            }
        },
        UPDATE_DRONE_IS_CONNECTED(state, value) {
            state.drones.elements[state.drones.elements.map(v => v.vUID).indexOf(value.uid)].vIS_CONNECTED = value.isConnected;
            if (!value.isConnected) {
                state.drones.elements[state.drones.elements.map(v => v.vUID).indexOf(value.uid)].vWEBRTC_BITRATE_TIMESTAMP = 0;
            }
        },
        SET_DRONE_ACTIVE_MISSION(state, value) {
            state.drones.activeMission = value; //clone mission
        },
        SET_DRONE_ACTIVE_MISSION_ELEMENT(state, value) {
            if (value) {
                state.drones.activeMissionElement = JSON.parse(JSON.stringify(value)); //clone mission
            } else {
                state.drones.activeMissionElement = value;
            }
        },
        SET_DRONE_ACTIVE_MISSION_UPDATE_TIMESTAMP(state, value) {
            state.drones.activeMissionUpdateTimestamp = value;
        },
        SET_DRONE_ACTIVE_MISSION_FENCINGS_RESPECTED(state, value) {
            state.drones.activeMissionFencingsRespected = value
        },
        SET_DRONES_MISSIONS(state, value) {
            state.drones.missions = value;
        },
        UPDATE_DRONE_MISSION(state, values) {
            for (let v of values) {
                let pos = (pos = state.drones.missions
                    .map(function(e) {
                        return e.vMISSION_KEY;
                    })
                    .indexOf(values.vMISSION_KEY));
                if (pos !== -1) {
                    let e = state.drones.missions[pos];
                    e.vALTITUDE = v.vALTITUDE;
                    e.vCHILD_TYPE = v.vCHILD_TYPE;
                    e.vEND_ACTION = v.vEND_ACTION;
                    e.vGIMBAL_MANUAL_ROTATION = v.vGIMBAL_MANUAL_ROTATION;
                    e.vHEADING_MODE = v.vHEADING_MODE;
                    e.vALTITUDE_GO_HOME = v.vALTITUDE_GO_HOME;
                    e.vMISSION_KEY = v.vMISSION_KEY;
                    e.vMISSION_NAME = v.vMISSION_NAME;
                    e.vSPEED = v.vSPEED;
                    e.vTOTAL_TIME = v.vTOTAL_TIME;
                    e.waypoints = v.waypoints;
                    e.vCHILD_TO_EXECUTE_MISSION = v.vCHILD_TO_EXECUTE_MISSION || null;
                    let m = [];
                    if (v.waypoints) {
                        for (let w of v.waypoints) {
                            w.actions = Object.values[w.actions];
                            m.push({
                                position: {
                                    lat: w.vLATITUDE,
                                    lng: w.vLONGITUDE,
                                },
                                icon: {
                                    path: "M15.477,45.072C2.423,26.147,0,24.205,0,17.25a17.25,17.25,0,0,1,34.5,0c0,6.955-2.423,8.9-15.477,27.822a2.157,2.157,0,0,1-3.546,0Z",
                                    rotation: w.vHEADING_YAW,
                                    fillColor: "#FFE082",
                                    fillOpacity: 1,
                                    anchor: new google.maps.Point(17, 23),
                                    labelOrigin: new google.maps.Point(17, 23),
                                    strokeWeight: 0,
                                    scale: 0.8,
                                },
                            });
                        }
                    } else {
                        v.waypoints = [];
                    }

                    e.markers = m;
                } else {
                    let m = [];
                    if (v.waypoints) {
                        for (let w of v.waypoints) {
                            if (w.actions) {
                                w.actions = Object.values(w.actions);
                            } else {
                                w.actions = [];
                            }
                            m.push({
                                position: {
                                    lat: w.vLATITUDE,
                                    lng: w.vLONGITUDE,
                                },
                                icon: {
                                    path: "M15.477,45.072C2.423,26.147,0,24.205,0,17.25a17.25,17.25,0,0,1,34.5,0c0,6.955-2.423,8.9-15.477,27.822a2.157,2.157,0,0,1-3.546,0Z",
                                    rotation: w.vHEADING_YAW,
                                    fillColor: "#FFE082",
                                    fillOpacity: 1,
                                    anchor: new google.maps.Point(17, 23),
                                    labelOrigin: new google.maps.Point(17, 23),
                                    strokeWeight: 0,
                                    scale: 0.8,
                                },
                            });
                        }
                    } else {
                        v.waypoints = []
                    }
                    state.drones.missions.push({
                        vALTITUDE: v.vALTITUDE,
                        vCHILD_TYPE: v.vCHILD_TYPE,
                        vEND_ACTION: v.vEND_ACTION,
                        vGIMBAL_MANUAL_ROTATION: v.vGIMBAL_MANUAL_ROTATION,
                        vHEADING_MODE: v.vHEADING_MODE,
                        vALTITUDE_GO_HOME: v.vALTITUDE_GO_HOME,
                        vMISSION_KEY: v.vMISSION_KEY,
                        vMISSION_NAME: v.vMISSION_NAME,
                        vSPEED: v.vSPEED,
                        vTOTAL_TIME: v.vTOTAL_TIME,
                        waypoints: v.waypoints,
                        vCHILD_TO_EXECUTE_MISSION: v.vCHILD_TO_EXECUTE_MISSION || null,
                        markers: m
                    });
                }
            }
        },
        PUSH_MARKER_INTO_DRONE_ACTIVE_MISSION_ELEMENT(state, value) {

            state.drones.activeMissionElement.waypoints.push({
                actions: [],
                vALTITUDE: value.alt,
                vLATITUDE: value.lat,
                vLONGITUDE: value.lng,
                vHEADING_YAW: 0,
                vGIMBAL_PITCH: 0
            });
            state.drones.activeMissionElement.markers.push({
                position: {
                    lat: value.lat,
                    lng: value.lng,
                },
                icon: {
                    path: "M15.477,45.072C2.423,26.147,0,24.205,0,17.25a17.25,17.25,0,0,1,34.5,0c0,6.955-2.423,8.9-15.477,27.822a2.157,2.157,0,0,1-3.546,0Z",
                    rotation: 0,
                    fillColor: "#FFE082",
                    fillOpacity: 1,
                    anchor: new google.maps.Point(17, 23),
                    labelOrigin: new google.maps.Point(17, 23),
                    strokeWeight: 0,
                    scale: 0.8,
                },
            });
        },
        INSERT_MARKER_INTO_DRONE_ACTIVE_MISSION_ELEMENT(state, value) {
            state.drones.activeMissionElement.waypoints.splice(value.index, 0, {
                actions: [],
                vALTITUDE: value.alt,
                vLATITUDE: value.lat,
                vLONGITUDE: value.lng,
                vHEADING_YAW: value.yaw || 0,
                vGIMBAL_PITCH: value.pitch || 0
            });
            state.drones.activeMissionElement.markers.splice(value.index, 0, {
                position: {
                    lat: value.lat,
                    lng: value.lng,
                },
                // icon: {
                //     url: state.utils.markers.missionWaypoint,
                //     anchor: {
                //         x: 40,
                //         y: 55,
                //     },
                //     scaledSize: {
                //         width: 80,
                //         height: 80,
                //     },
                // },
                icon: {
                    path: "M15.477,45.072C2.423,26.147,0,24.205,0,17.25a17.25,17.25,0,0,1,34.5,0c0,6.955-2.423,8.9-15.477,27.822a2.157,2.157,0,0,1-3.546,0Z",
                    rotation: 0,
                    fillColor: "#FFE082",
                    fillOpacity: 1,
                    anchor: new google.maps.Point(17, 23),
                    labelOrigin: new google.maps.Point(17, 23),
                    strokeWeight: 0,
                    scale: 0.8,
                },
            });
        },
        DELETE_MARKER_FROM_DRONE_ACTIVE_MISSION_ELEMENT(state, value) {
            state.drones.activeMissionElement.waypoints.splice(value, 1);
            state.drones.activeMissionElement.markers.splice(value, 1);
            state.drones.activeMissionEditWaypointIndex = Math.max(0, state.drones.activeMissionEditWaypointIndex - 1);
        },
        UPDATE_DRONE_ACTIVE_MISSION_ELEMENT_MARKER(state, value) {
            if (value.lat !== null && value.lng !== null) {
                state.drones.activeMissionElement.waypoints[value.index].vLATITUDE = value.lat;
                state.drones.activeMissionElement.waypoints[value.index].vLONGITUDE = value.lng;
                state.drones.activeMissionElement.markers[value.index].position.lat = value.lat;
                state.drones.activeMissionElement.markers[value.index].position.lng = value.lng;
            }
            if (value.alt !== null) {
                state.drones.activeMissionElement.waypoints[value.index].vALTITUDE = value.alt;
            }
            if (value.headingYaw !== null) {
                state.drones.activeMissionElement.waypoints[value.index].vHEADING_YAW = value.headingYaw;
                state.drones.activeMissionElement.markers[value.index].icon.rotation = value.headingYaw;
            }
        },
        UPDATE_DRONE_ACTIVE_MISSION_ELEMENT(state, value) {
            state.drones.activeMissionElement = value;
        },
        SET_DRONE_ACTIVE_MISSION_EDIT_WAYPOINT_INDEX(state, value) {
            state.drones.activeMissionEditWaypointIndex = Math.max(value, 0);
        },
        SET_UPDATE_DRONES_EDIT_MODE(state, value) {
            state.drones.missionsEditMode = value;

        },
        SET_DRONES_REPORT(state, value) {
            state.drones.report = value;
        },
        // solo
        SET_SOLO_ACTIVE(state, value) {
            state.solos.active = value;
            if (value !== null) {
                firebase
                    .database()
                    .ref(
                        `aegisv2/users/${value}/actions/aSTART_STREAM`
                    )
                    .set(false);

                firebase
                    .database()
                    .ref(
                        `aegisv2/users/${value}/telemetry/vIS_DEVICE_BEING_MONITORED`
                    )
                    .set(true);
                firebase
                    .database()
                    .ref(
                        `aegisv2/users/${value}/telemetry/vSTREAMING_BEING_WATCHED_AT`
                    )
                    .set(Date.now());

            }
        },
        SET_SOLOS_ELEMENTS(state, value) {
            state.solos.elements = value;
        },
        CREATE_SOLO_ELEMENT(state, value) {
            // TODO
            state.solos.elements.push({
                vUID: value.vUID,
                vOPERATOR_NAME: null,
                vHEART_BEAT: null,
                vSTREAM_HEART_BEAT: null,
                internals: null,
                telemetry: null,
                stream: null,
                actions: null,
                notifications: [],
                marker: {
                    position: {
                        lat: 0,
                        lng: 0,
                    },
                    icon: {
                        strokeWeight: 2,
                        strokeColor: "#343434",
                        fillColor: "blue",
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.CIRCLE,
                        rotation: 0,
                        scale: 8,
                        anchor: new google.maps.Point(0, 0),
                    },
                    infoOptions: {
                        pixelOffset: {
                            width: 0,
                            height: 50,
                        },
                        disableAutoPan: true,
                        content: "<strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'>" +
                            "</strong>",

                    },
                },
                lasTimeUpdated: {
                    internals: 0,
                    telemetry: 0,
                    stream: 0,
                    actions: 0,
                    notifications: 0
                },
                vIS_CONNECTED: false,
                vIS_STANDBY: false,
                vIS_STREAMING: false
            });
        },
        UPDATE_SOLO_ELEMENT(state, value) {
            // TODO
            let pos = (pos = state.solos.elements
                .map(function(e) {
                    return e.vUID;
                })
                .indexOf(value.vUID));
            if (pos !== -1) {
                let key = Object.keys(value)[1];
                let data = value[key];

                let e = state.solos.elements[pos];
                e.lasTimeUpdated[key] = Date.now();

                if (key === 'internals') {
                    e[key] = data;
                    e.marker.infoOptions.content = "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'>" + data.vCHILD_NAME + "</strong></div>"
                    if (
                        data.vTOAST_MESSAGE !== null &&
                        data.vTOAST_MESSAGE !== "none" && ["success", "warning", "error", "info"].indexOf(data.vTOAST_COLOR) !== -1) {
                        state.user.toast = {
                            message: `${data.vCHILD_NAME}: ${data.vTOAST_MESSAGE}`,
                            color: data.vTOAST_COLOR
                        }
                        firebase
                            .database()
                            .ref(
                                `aegisv2/users/${value.vUID}/internals/`
                            )
                            .update({ 'vTOAST_COLOR': 'info', 'vTOAST_MESSAGE': 'none' });
                    }
                } else if (key === 'telemetry') {
                    e[key] = data;
                    e.marker.position.lat = data.vLOCATION_LATITUDE || 0;
                    e.marker.position.lng = data.vLOCATION_LONGITUDE || 0;
                    e.marker.icon = {
                        strokeWeight: 2,
                        strokeColor: "#343434",
                        fillColor: e.internals ? (e.internals.vMARKER_COLOR ? e.internals.vMARKER_COLOR : 'blue') : 'blue',
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.CIRCLE,
                        rotation: data.vYAW || 0,
                        scale: 8,
                        anchor: new google.maps.Point(0, 0),
                    };
                } else if (key === 'stream') {
                    e[key] = data;
                } else if (key === 'actions') {
                    e[key] = data;
                } else if (key === 'notifications') {
                    e[key] = data;
                } else if (key === 'vHEART_BEAT') {
                    e[key] = e[key] === null ? 0 : data;
                } else if (key === 'vSTREAM_HEART_BEAT') {
                    e[key] = e[key] === null ? 0 : data;
                }
            }
        },
        UPDATE_SOLO_IS_CONNECTED(state, value) {
            // TODO
            state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(value.uid)].vIS_CONNECTED = value.isConnected;
            if (!value.isConnected) {
                state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(value.uid)].vHEART_BEAT = 0;
            }
        },
        UPDATE_SOLO_IS_STANDBY(state, value) {
            // TODO
            state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(value.uid)].vIS_STANDBY = value.isStandby;
        },
        UPDATE_SOLO_IS_STREAMING(state, value) {
            // TODO
            state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(value.uid)].vIS_STREAMING = value.isStreaming;
            if (!value.isStreaming) {
                state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(value.uid)].vSTREAM_HEART_BEAT = 0;
            }
        },
        // subscriber
        SET_SUBSCRIBERS_ELEMENTS(state, value) {
            state.subscribers.elements = value;
        },
        CREATE_SUBSCRIBER_ELEMENT(state, value) {
            state.subscribers.elements.push({
                vUID: value.vUID,
                internals: null
            });
        },
        UPDATE_SUBSCRIBER_ELEMENT(state, value) {
            let pos = (pos = state.subscribers.elements
                .map(function(e) {
                    return e.vUID;
                })
                .indexOf(value.vUID));
            if (pos != -1) {
                let e = state.subscribers.elements[pos];
                e.internals = value.internals;
                e.elements = value.elements;
            } else {
                state.subscribers.elements.push({
                    vUID: value.vUID,
                    internals: value.internals,
                    elements: value.elements
                });
            }
        },
        // supervisors
        SET_SUPERVISORS_ELEMENTS(state, value) {
            state.supervisors.elements = value;
        },
        CREATE_SUPERVISOR_ELEMENT(state, value) {
            state.supervisors.elements.push({
                vUID: value.vUID,
                internals: null
            });
        },
        UPDATE_SUPERVISOR_ELEMENT(state, value) {
            let pos = (pos = state.supervisors.elements
                .map(function(e) {
                    return e.vUID;
                })
                .indexOf(value.vUID));
            if (pos != -1) {
                let e = state.supervisors.elements[pos];
                e.internals = value.internals;
                e.elements = value.elements;
            } else {
                state.supervisors.elements.push({
                    vUID: value.vUID,
                    internals: value.internals,
                    elements: value.elements
                });
            }
        },
        //  SET_LICENSES_KEYS CREATE_LICENSE_KEY_ELEMENT UPDATE_LICENSE_KEY_ELEMENT
        // licenses
        SET_LICENSES_KEYS_ELEMENTS(state, value) {
            state.licensesKeys.elements = value;
        },
        CREATE_LICENSE_KEY_ELEMENT(state, value) {
            state.licensesKeys.elements.push({
                vLICENSE_KEY: value.vLICENSE_KEY,
                data: null
            });
        },
        UPDATE_LICENSE_KEY_ELEMENT(state, value) {
            let pos = (pos = state.licensesKeys.elements
                .map(function(e) {
                    return e.vLICENSE_KEY;
                })
                .indexOf(value.vLICENSE_KEY));
            if (pos !== -1) {
                let e = state.licensesKeys.elements[pos];
                e.data = value.data;
                e.elements = value.elements;
            } else {
                state.licensesKeys.elements.push({
                    vUID: value.vUID,
                    data: value.data
                });
            }
        },
        // 
        SET_FOLLOWERS_ELEMENTS(state, value) {
            state.followers.elements = value;
        },
        PUSH_FOLLOWERS_ELEMENTS(state, value) {
            state.followers.elements.push(value);
        },
        // 
        SET_LOG_REVIEWS_ELEMENTS(state, value) {
            state.logReviews.elements = value;
        },
        SET_LOG_REVIEW_ACTIVE(state, value) {
            state.logReviews.active = null;
            state.logReviews.activeElement = null;
            if (value.vCHILD_UID && value.vSERVICE_KEY) {
                firebase
                    .database()
                    .ref("aegisv2/users/" + value.vCHILD_UID + "/logServices/" + value.vSERVICE_KEY)
                    .once('value', function(snapshot) {
                        if (snapshot.hasChildren()) {
                            let data = snapshot.val();
                            data.locations = data.locations || {};
                            data.limitBorders = data.limitBorders || {};
                            state.logReviews.activeElement = data;
                            state.logReviews.activeElement.vCHILD_UID = value.vCHILD_UID;
                            state.logReviews.activeElement.vSERVICE_KEY = value.vSERVICE_KEY;
                            state.logReviews.active = snapshot.key;
                        }
                    })
            }
        },
        SET_LOG_REVIEWS_FILTER(state, data) {
            state.logReviews.filter[data.key] = data.value;
        },
        // 
        SET_DRONES_REPORT_FILTER(state, data) {
            state.drones.report.filter[data.key] = data.value;
        },
        RESET_DRONES_REPORT(state, data) {
            state.drones.report.status = null;
            state.drones.report.data.graphs.missionsExecuted = {
                datasets: [],
                labels: []
            };
            state.drones.report.data.graphs.missionsByOperator = {
                datasets: [],
                labels: []
            };
            state.drones.report.data.graphs.missionsExecutedByDay = {
                datasets: [],
                labels: []
            }
            state.drones.report.data.graphs.missionsExecutedByHour = {
                datasets: [],
                labels: []
            };
            state.drones.report.data.graphs.scheduledMissions = {
                datasets: [],
                labels: []
            };
            state.drones.report.data.graphs.missionsNotExecuted = {
                datasets: [],
                labels: []
            };
            state.drones.report.data.graphs.artificialIntelligenceEvents = {
                datasets: [],
                labels: []
            }
            state.drones.report.data.graphs.events = {
                datasets: [],
                labels: []
            }
            state.drones.report.data.graphs.batteryStates = {
                datasets: [],
                labels: []
            }

            state.drones.report.data.kpi = [];
            state.drones.report.data.events = [];
            state.drones.report.data.locations = [];
        },
        FETCH_DRONES_REPORT(state,
            data
        ) {
            state.drones.report.status = null;
            let teams = (state.drones.elements
                .filter(v => state.drones.report.filter.device.indexOf(v.vUID) !== -1)
                .map(v => v.internals.vTEAM_UID)
                .filter((value, index, self) => self.indexOf(value) === index)) || [];


            let promises = [];
            state.drones.report.status = 'waiting';
            for (let team of teams) {
                promises.push(new Promise(function(resolve, reject) {
                        return firebase
                            .functions()
                            .httpsCallable("CLOUD_DASHBOARD_DRONES_REPORT")({
                                vTEAM_UID: team,
                                filter: {
                                    date: [state.drones.report.filter.date[0], state.drones.report.filter.date[1] + (24 * 60 * 60 * 1000 - 1)],
                                    device: ((state.drones.elements
                                                .filter(v => v.internals.vTEAM_UID === team))
                                            .map(v => v.vUID))
                                        .filter(v => state.drones.report.filter.device
                                            .indexOf(v) !== -1)
                                },
                                vTOTAL_EXPECTED_DAILY_MISSIONS: data.vTOTAL_EXPECTED_DAILY_MISSIONS,
                                timezone: momenttz.tz.guess()
                            })
                            .then(function(result) {
                                resolve({
                                    team: team,
                                    result: result.data
                                });
                            }).catch((e) => {
                                state.drones.report.status = 'error';
                            });
                    }))
                    // firebase
                    //     .functions()
                    //     .httpsCallable("CLOUD_DASHBOARD_DRONES_REPORT")({
                    //         vTEAM_UID: team,
                    //         filter: {
                    //             date: [state.drones.report.filter.date[0], state.drones.report.filter.date[1] + (24 * 60 * 60 * 1000 - 1)],
                    //             device: state.drones.report.filter.device
                    //         },
                    //         vTOTAL_EXPECTED_DAILY_MISSIONS: data.vTOTAL_EXPECTED_DAILY_MISSIONS,
                    //         timezone: momenttz.tz.guess()
                    //     })
                    //     .then(function(result) {
                    //         state.drones.report.data.graphs.missionsExecuted = result.data.data.graphs.missionsExecuted;
                    //         state.drones.report.data.graphs.missionsByOperator = result.data.data.graphs.missionsByOperator;
                    //         state.drones.report.data.graphs.missionsExecutedByDay = result.data.data.graphs.missionsExecutedByDay;
                    //         state.drones.report.data.graphs.missionsExecutedByHour = result.data.data.graphs.missionsExecutedByHour;
                    //         state.drones.report.data.graphs.scheduledMissions = result.data.data.graphs.scheduledMissions;
                    //         state.drones.report.data.graphs.missionsNotExecuted = result.data.data.graphs.missionsNotExecuted;
                    //         state.drones.report.data.kpi = Object.values(result.data.data.kpi);
                    //         state.drones.report.data.events = Object.values(result.data.data.events);
                    //         state.drones.report.data.locations = result.data.data.locations;
                    //     });
            }
            Promise.all(promises)
                .then(function(values) {
                    if (values.length === 0) {
                        state.drones.report.status = 'empty';
                    } else if (values.length === 1) {
                        state.drones.report.status = 'success';
                        let r = values[0].result.data;
                        state.drones.report.data.graphs.missionsExecuted = r.graphs.missionsExecuted;
                        state.drones.report.data.graphs.missionsByOperator = r.graphs.missionsByOperator;
                        state.drones.report.data.graphs.missionsExecutedByDay = r.graphs.missionsExecutedByDay;
                        state.drones.report.data.graphs.missionsExecutedByHour = r.graphs.missionsExecutedByHour;
                        state.drones.report.data.graphs.scheduledMissions = r.graphs.scheduledMissions;
                        state.drones.report.data.graphs.missionsNotExecuted = r.graphs.missionsNotExecuted;
                        state.drones.report.data.graphs.events = r.graphs.events;
                        state.drones.report.data.graphs.artificialIntelligenceEvents = r.graphs.artificialIntelligenceEvents;
                        state.drones.report.data.graphs.batteryStates = r.graphs.batteryStates;
                        state.drones.report.data.kpi = Object.values(r.kpi);
                        state.drones.report.data.events = Object.values(r.events);
                        state.drones.report.data.locations = r.locations;
                    } else {
                        state.drones.report.status = 'success';
                        for (let v of values) {
                            let r = v.result.data;
                            state.drones.report.data.locations = state.drones.report.data.locations.concat(r.locations);
                        }
                    }
                })
                .catch((e) => {
                    state.drones.report.status = 'error';
                })
        },
        SET_SOLOS_REPORT_FILTER(state, data) {
            state.solos.report.filter[data.key] = data.value;
        },
        RESET_SOLOS_REPORT(state, data) {
            state.solos.report.status = null;
            state.solos.report.data.kpi = [];
            state.solos.report.data.events = [];
            state.solos.report.data.locations = [];

            state.solos.report.data.graphs.events = {
                datasets: [],
                labels: []
            }
            state.solos.report.data.graphs.tagged_important_by_device = {
                datasets: [],
                labels: []
            }
        },
        FETCH_SOLOS_REPORT(state,
            data
        ) {
            state.solos.report.status = null;
            let teams = (state.solos.elements
                .filter(v => state.solos.report.filter.device.indexOf(v.vUID) !== -1)
                .map(v => v.internals.vTEAM_UID)
                .filter((value, index, self) => self.indexOf(value) === index)) || [];


            let promises = [];
            state.solos.report.status = 'waiting';
            for (let team of teams) {
                promises.push(new Promise(function(resolve, reject) {
                    return firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_SOLOS_REPORT")({
                            vTEAM_UID: team,
                            filter: {
                                date: [state.solos.report.filter.date[0], state.solos.report.filter.date[1] + (24 * 60 * 60 * 1000 - 1)],
                                device: ((state.solos.elements
                                            .filter(v => v.internals.vTEAM_UID === team))
                                        .map(v => v.vUID))
                                    .filter(v => state.solos.report.filter.device
                                        .indexOf(v) !== -1),
                                event: state.solos.report.filter.event,
                            },
                            timezone: momenttz.tz.guess()
                        })
                        .then(function(result) {
                            resolve({
                                team: team,
                                result: result.data
                            });
                        }).catch((e) => {
                            state.solos.report.status = 'error';
                        });
                }))
            }
            Promise.all(promises)
                .then(function(values) {
                    if (values.length === 0) {
                        state.solos.report.status = 'empty';
                    } else {
                        let groupedResult = {
                            events: null,
                            graphs: { events: null, tagged_important_by_device: null },
                            additionalGraphs: {},
                            kpi: null,
                            locations: null,
                            eventsDetails: null
                        }
                        state.solos.report.status = 'success';
                        for (let v of values) {
                            let r = v.result.data;
                            let team = v.team;


                            // 
                            if (groupedResult.events === null) {
                                groupedResult.events = r.events;
                            } else {
                                for (let k of Object.keys(r.events)) {
                                    if (groupedResult.events[k]) {
                                        groupedResult.events[k].count += r.events[k].count;
                                    } else {
                                        groupedResult.events[k] = r.events[k];
                                    }
                                }
                            }

                            // 
                            if (groupedResult.graphs.events === null) {
                                groupedResult.graphs.events = r.graphs.events;
                            } else {
                                for (let i = 0; i < r.graphs.events.labels.length; i++) {
                                    let label = r.graphs.events.labels[i];
                                    let data = r.graphs.events.datasets[0].data[i];
                                    let index = groupedResult.graphs.events.labels.indexOf(label);

                                    if (index !== -1) {
                                        groupedResult.graphs.events.datasets[0].data[index] += data;
                                    } else {
                                        groupedResult.graphs.events.labels.push(label);
                                        groupedResult.graphs.events.datasets[0].data.push(data);
                                    }
                                }
                            }

                            // 
                            if (groupedResult.graphs.tagged_important_by_device === null) {
                                groupedResult.graphs.tagged_important_by_device = r.graphs.tagged_important_by_device;
                            } else {
                                for (let i = 0; i < r.graphs.tagged_important_by_device.labels.length; i++) {
                                    let label = r.graphs.tagged_important_by_device.labels[i];
                                    let data = r.graphs.tagged_important_by_device.datasets[0].data[i];
                                    let index = groupedResult.graphs.tagged_important_by_device.labels.indexOf(label);

                                    if (index !== -1) {
                                        groupedResult.graphs.tagged_important_by_device.datasets[0].data[index] += data;
                                    } else {
                                        groupedResult.graphs.tagged_important_by_device.labels.push(label);
                                        groupedResult.graphs.tagged_important_by_device.datasets[0].data.push(data);
                                    }
                                }
                            }

                            // 
                            for (let g of r.additionalGraphs) {
                                if (typeof groupedResult.additionalGraphs[g.key] === 'undefined') {
                                    groupedResult.additionalGraphs[g.key] = g;
                                } else {
                                    for (let i = 0; i < g.labels.length; i++) {
                                        let label = g.labels[i];
                                        let data = g.datasets[0].data[i];
                                        let index = g.labels.indexOf(label);

                                        if (index !== -1) {
                                            groupedResult.additionalGraphs[g.key].datasets[0].data[index] += data;
                                        } else {
                                            groupedResult.additionalGraphs[g.key].labels.push(label);
                                            groupedResult.additionalGraphs[g.key].datasets[0].data.push(data);
                                        }
                                    }
                                }
                            }

                            // 
                            let kpi = Object.values(r.kpi);
                            kpi[kpi.map(v => v.key).indexOf('total_devices_without_comunications')].value = state.solos.elements
                                .filter(v => state.solos.report.filter.device.indexOf(v.vUID) !== -1 && v.internals.vTEAM_UID === team)
                                .map(v => v.telemetry.vHEART_BEAT || 0)
                                .filter(v => v < state.solos.report.filter.date[1] - 72 * 60 * 60 * 1000).length;
                            if (groupedResult.kpi === null) {
                                groupedResult.kpi = kpi;
                            } else {
                                for (let k of kpi) {
                                    groupedResult.kpi[groupedResult.kpi.map(v => v.key).indexOf(k.key)].value += k.value;
                                }
                            }

                            // 
                            if (groupedResult.locations === null) {
                                groupedResult.locations = r.locations;
                            } else {
                                groupedResult.locations = groupedResult.locations.concat(r.locations)
                            }

                            // 
                            if (groupedResult.eventsDetails === null) {
                                groupedResult.eventsDetails = r.eventsDetails;
                            } else {
                                groupedResult.eventsDetails = groupedResult.eventsDetails.concat(r.eventsDetails)
                            }
                        }

                        for (let k of Object.keys(groupedResult.additionalGraphs)) {
                            groupedResult.additionalGraphs[k] = {
                                datasets: groupedResult.additionalGraphs[k].datasets,
                                labels: groupedResult.additionalGraphs[k].labels.map(v => state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(v)].internals.vCHILD_NAME),
                                key: groupedResult.additionalGraphs[k].key
                            }
                        }

                        // 
                        state.solos.report.data.graphs.events = groupedResult.graphs.events;
                        state.solos.report.data.graphs.tagged_important_by_device = {
                            datasets: groupedResult.graphs.tagged_important_by_device.datasets,
                            labels: groupedResult.graphs.tagged_important_by_device.labels.map(v => state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(v)].internals.vCHILD_NAME)
                        }
                        state.solos.report.data.additionalGraphs = groupedResult.additionalGraphs;
                        state.solos.report.data.kpi = groupedResult.kpi;
                        state.solos.report.data.events = Object.values(groupedResult.events);
                        state.solos.report.data.locations = groupedResult.locations;
                        state.solos.report.data.eventsDetails = groupedResult.eventsDetails;
                    }
                })
                .catch((e) => {
                    state.solos.report.status = 'error';
                })
        },
        FETCH_REVIEWS(state,
            data
        ) {
            state.logReviews.status = null;

            state.logReviews.elements = [];
            state.logReviews.active = null;
            state.logReviews.activeElement = null;

            let teams = ((state.drones.elements.concat(state.solos.elements))
                .filter(v => state.logReviews.filter.device.indexOf(v.vUID) !== -1)
                .map(v => v.internals.vTEAM_UID)
                .filter((value, index, self) => self.indexOf(value) === index)) || [];

            let promises = []
            state.logReviews.status = 'waiting';
            for (let team of teams) {
                promises.push(new Promise(function(resolve, reject) {
                    return firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_REVIEWS")({
                            vTEAM_UID: team,
                            filter: {
                                date: [state.logReviews.filter.date[0], state.logReviews.filter.date[1] + (24 * 60 * 60 * 1000 - 1)],
                                time: state.logReviews.filter.time,
                                type: state.logReviews.filter.type,
                                device: (((state.drones.elements.concat(state.solos.elements))
                                            .filter(v => v.internals.vTEAM_UID === team))
                                        .map(v => v.vUID))
                                    .filter(v => state.logReviews.filter.device
                                        .indexOf(v) !== -1),
                                event: state.logReviews.filter.event,
                                coordinates: state.logReviews.filter.coordinates,
                                medias: state.logReviews.filter.medias
                            },
                            timezone: momenttz.tz.guess()
                        })
                        .then(function(result) {
                            resolve({
                                result: result.data
                            });
                        });
                }))
            }
            Promise.all(promises)
                .then(function(values) {
                    for (let v of values) {
                        state.logReviews.elements = state.logReviews.elements.concat(v.result);
                    }

                    if (state.logReviews.elements.length === 0) {
                        state.logReviews.status = 'empty';
                    } else {
                        state.logReviews.status = 'success';
                    }
                    state.logReviews.elements.sort((a, b) => a.vDATETIME_START > b.vDATETIME_START ? 1 : -1);
                }).catch((e) => {
                    state.logReviews.status = 'error';
                })
        },
        SET_MAP_DRAGGED(state, data) {
            state.map.dragged = data;
        },
        PUSH_EVENT_NOTIFICATION(state, data) {
            state.events.notifications.push(data);
        },
        REMOVE_EVENT_NOTIFICATION_BY_KEY(state, data) {
            firebase
                .database()
                .ref(`aegisv2/users/${data.vCHILD_UID}/eventsHistory/${data.vEVENT_KEY}`)
                .set(null);
        },
        SET_EVENTS_NOTIFICATIONS(state, data) {
            state.events.notifications = data;
        },
        SET_SHOW_ALL_EVENTS_NOTIFICATIONS(state, data) {
            state.events.showAllNotifications = data;
        },
        SET_CHECKED_EVENT_NOTIFICATION_BY_KEY(state, data) {
            state.events.checkedNotifications.includes(data) ? state.events.checkedNotifications.splice(state.events.checkedNotifications.indexOf(data), 1) : state.events.checkedNotifications.push(data);
        },
        CLEAR_ALL_EVENTS_NOTIFICATIONS(state, data) {
            for (let e of state.drones.elements.concat(state.solos.elements).map(v => v.vUID)) {
                firebase
                    .database()
                    .ref(`aegisv2/users/${e}/eventsHistory`)
                    .set(null);
            }
        },
        SET_CURRENT_LOG_SERVICE_KEY(state, data) {
            state.events.currentLogServiceKeys[data.vCHILD_UID] = data.vCURRENT_LOG_SERVICE_KEY;
        },
        SET_DRONE_OPERATOR_NAME(state, data) {
            state.drones.elements[state.drones.elements.map(v => v.vUID).indexOf(data.vCHILD_UID)].vOPERATOR_NAME = data.vOPERATOR_NAME
        },
        SET_EVENT_NOTIFICATION_FOCUSED_LOCATION(state, data) {
            state.events.focusedLocation = data;
        },
    },
    actions: {
        // 
        fetchUser({
            commit,
            state,
            dispatch
        }, data) {
            commit("SET_LOGGED_IN", data.user !== null);
            if (data.user) {
                dispatch('fetchInternals');
                if (data.internals.vCHILD_TYPE === 'main') {
                    commit("SET_USER", {
                        uid: data.user.uid,
                        email: data.user.email,
                        refreshToken: data.user.refreshToken,
                        internals: {},
                        realInternals: data.internals,
                        lastLogin: data.internals.vLAST_LOGIN
                    });
                    firebase
                        .database()
                        .ref(`aegisv2/users/${data.user.uid}/internals`)
                        .on("value", function(snap) {
                            commit("SET_USER_INTERNALS", snap.val());
                        })
                    firebase
                        .database()
                        .ref(`aegisv2/users/${data.user.uid}/internals/vLAST_LOGIN`)
                        .on("value", function(snap) {
                            commit("SET_USER_LAST_LOGIN", snap.val());
                        })
                    dispatch('fetchLicenses');
                } else if (data.internals.vCHILD_TYPE === 'supervisor') {
                    commit("SET_USER", {
                        uid: data.internals.vTEAM_UID,
                        email: data.user.email,
                        refreshToken: data.user.refreshToken,
                        internals: {},
                        realInternals: data.internals,
                        lastLogin: data.internals.vLAST_LOGIN
                    });
                    firebase
                        .database()
                        .ref(`aegisv2/users/${data.internals.vTEAM_UID}/internals`)
                        .on("value", function(snap) {
                            commit("SET_USER_INTERNALS", snap.val());
                        })
                    firebase
                        .database()
                        .ref(`aegisv2/users/${data.user.uid}/internals/vLAST_LOGIN`)
                        .on("value", function(snap) {
                            commit("SET_USER_LAST_LOGIN", snap.val());
                        })
                    dispatch('fetchLicenses');
                } else {

                }


            } else {
                commit("SET_USER", null);
            }
        },
        fetchInternals({
            commit,
            state,
            dispatch
        }, data) {
            firebase
                .database()
                .ref("aegisv2/internals")
                .once("value", function(snapshot) {
                    commit("FETCH_INTERNALS", snapshot.val())
                })
        },
        fetchLicenses({
            commit,
            state,
            dispatch
        }, data) {
            firebase
                .database()
                .ref("aegisv2/licenses/")
                .orderByChild("vTEAM_UID")
                .equalTo(state.user.data.uid)
                .once("value", function(snapshot) {
                    commit("FETCH_USER_LICENSES", {
                        solo: {
                            elements: [],
                            unlicensedElements: [],
                            counter: 0,
                        },
                        drone: {
                            elements: [],
                            unlicensedElements: [],
                            counter: 0,
                        },
                    });
                    snapshot.forEach(function(childSnapshot) {
                        var licenseType = childSnapshot.child("vLICENSE_TYPE").val();
                        firebase
                            .database()
                            .ref("aegisv2/licenses/" + childSnapshot.key)
                            .once("value", function(data) {
                                if (!isNaN(data.child("vEXPIRES_IN").val()) &&
                                    data.child("vIS_ENABLED").val()
                                ) {
                                    var currentLicensesObj = JSON.parse(
                                        JSON.stringify(state.user.licenses)
                                    );
                                    if (data.child("vEXPIRES_IN").val() > new Date().getTime()) {
                                        currentLicensesObj[licenseType].elements.push(data.val());
                                        currentLicensesObj[licenseType].counter++;
                                    } else {
                                        currentLicensesObj[licenseType].unlicensedElements.push(
                                            data.val()
                                        );
                                    }
                                    commit("FETCH_USER_LICENSES", currentLicensesObj);
                                }
                            });
                    });
                })
                .then(() => {
                    dispatch("initializeSolos");
                    dispatch("initializeDrones");
                    dispatch("initializeDronesMissions");
                    dispatch("initializeSubscribers");
                    dispatch("initializeSupervisors");
                    dispatch("initializeLicensesKeys");
                    dispatch("initializeFollowers");
                    dispatch('initializeLogReviews');




                    if (firebase.messaging.isSupported()) {
                        const messaging = firebase.messaging();
                        messaging
                            .requestPermission()
                            .then(function() {
                                console.log("Messaging: Permission granted");
                                return messaging.getToken();
                            })
                            .then(function(token) {
                                firebase
                                    .database()
                                    .ref(
                                        "aegisv2/users/" +
                                        state.user.data.uid +
                                        "/elements/"
                                    ).once("value", function(data) {
                                        if (data.numChildren() > 0) {
                                            data.forEach(function(childSnapshot) {
                                                if (childSnapshot.child('vCHILD_UID').val()) {
                                                    firebase
                                                        .database()
                                                        .ref(
                                                            "aegisv2/users/" +
                                                            childSnapshot.child('vCHILD_UID').val() +
                                                            "/internals/vTEAM_UID"
                                                        ).once("value", function(childData) {
                                                            firebase
                                                                .database()
                                                                .ref(
                                                                    "aegisv2/users/" +
                                                                    childData.val() +
                                                                    "/notificationTokens/" +
                                                                    token
                                                                )
                                                                .set(true);
                                                        })
                                                }
                                            });
                                        }
                                    })
                            })
                            .catch(function(error) {
                                console.log(error);
                                console.log("Messaging: Error");
                            });
                        //
                        messaging.onTokenRefresh(function() {
                            messaging
                                .getToken()
                                .then(function(newToken) {
                                    firebase
                                        .database()
                                        .ref(
                                            "aegisv2/users/" +
                                            state.user.data.uid +
                                            "/notificationTokens/" +
                                            newToken
                                        )
                                        .set(true);
                                })
                                .catch(function(error) {
                                    console.log(error);
                                });
                        });
                        // notification when on the same page
                        messaging.onMessage(function(payload) {
                            const notificationOption = {
                                body: payload.data.body,
                                icon: payload.data.icon,
                                click_action: "https://aeroguard-dashboard.web.app/"
                            }

                            if (Notification.permission === 'granted') {
                                let notification = new Notification(payload.data.title, notificationOption);
                            }
                        });
                    } else {
                        console.log('Messaging not supported.');
                    }
                });
        },
        // 
        setNavMainNav({
            commit
        }, data) {
            commit("SET_NAV_MAIN_NAV", data);
        },
        setNavTopbar({
            commit
        }, data) {
            commit("SET_NAV_TOP_BAR", data);
        },
        setNavStreamFocused({
            commit
        }, data) {
            commit("SET_NAV_STREAM_FOCUSED", data);
        },
        setNavStreamDialogDrawerVisible({
            commit
        }, data) {
            commit("SET_NAV_STREAM_DIALOG_MOSAIC_VISIBLE", data);
        },
        setNavSetupNav({
            commit
        }, data) {
            commit("SET_NAV_SETUP_NAV", data);
        },
        setNavForcedLoading({
            commit
        }, data) {
            commit("SET_NAV_FORCED_LOADING", data);
        },
        // 
        setDroneActive({
            commit
        }, data) {
            commit("SET_DRONE_ACTIVE", null);
            commit("SET_DRONE_ACTIVE", data);
        },
        initializeDrones({
            commit,
            state
        }, data) {
            commit("SET_DRONES_ELEMENTS", []);
            var itemsProcessed = 0;
            firebase
                .database()
                .ref("aegisv2/users/" + state.user.data.uid + "/elements")
                .orderByChild("vCHILD_TYPE")
                .equalTo("drone")
                .once("value", function(snapshot) {
                    if (snapshot.numChildren() === 0) {
                        commit("SET_INITIALIZED", {
                            drones: true
                        });
                    } else {
                        snapshot.forEach(function(childSnapshot) {
                            firebase
                                .database()
                                .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals')
                                .once("value", function(snap) {
                                    itemsProcessed++;
                                    if (snap.exists()) {
                                        state.logReviews.filter.device.push(childSnapshot.child("vCHILD_UID").val());
                                        if (!state.logReviews.filter.type.includes('drone')) state.logReviews.filter.type.push('drone');

                                        // create element
                                        commit("CREATE_DRONE_ELEMENT", {
                                            vUID: childSnapshot.child("vCHILD_UID").val()
                                        });

                                        // listenners
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals')
                                            .on("value", function(data) {
                                                commit("UPDATE_DRONE_ELEMENT", {
                                                    vUID: childSnapshot.child("vCHILD_UID").val(),
                                                    internals: data.val()
                                                });
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/flight')
                                            .on("value", function(data) {
                                                commit("UPDATE_DRONE_ELEMENT", {
                                                    vUID: childSnapshot.child("vCHILD_UID").val(),
                                                    flight: data.val()
                                                });
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/telemetry')
                                            .on("value", function(data) {

                                                if (Date.now() - state.drones.elements[state.drones.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['telemetry'] > 200) {
                                                    commit("UPDATE_DRONE_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        telemetry: data.val()
                                                    });
                                                }

                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/stream/vWEBRTC_BITRATE')
                                            .on("value", function(data) {
                                                if (Date.now() - state.drones.elements[state.drones.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['telemetry'] > 100) {
                                                    commit("UPDATE_DRONE_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        vWEBRTC_BITRATE_TIMESTAMP: Date.now()
                                                    });
                                                }

                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/stream')
                                            .on("value", function(data) {

                                                if (Date.now() - state.drones.elements[state.drones.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['stream'] > 200) {
                                                    commit("UPDATE_DRONE_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        stream: data.val()
                                                    });
                                                }
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals/vCURRENT_LOG_SERVICE_KEY')
                                            .on("value", function(data) {
                                                if (data.val()) {
                                                    // 
                                                    firebase
                                                        .database()
                                                        .ref(`aegisv2/users/${childSnapshot.child("vCHILD_UID").val()}/logServices/${data.val()}/vOPERATOR_NAME`)
                                                        .once("value", function(operatorName) {
                                                            commit('SET_DRONE_OPERATOR_NAME', {
                                                                vCHILD_UID: childSnapshot.child("vCHILD_UID").val(),
                                                                vOPERATOR_NAME: operatorName.val()
                                                            })
                                                        })


                                                    firebase
                                                        .database()
                                                        .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/logServices/' + data.val() + '/events').orderByChild('vTIMESTAMP').limitToLast(1)
                                                        .on("value", function(logServiceData) {
                                                            // 
                                                            if (state.events.currentLogServiceKeys[childSnapshot.child("vCHILD_UID").val()] !== data.val()) {
                                                                // 
                                                                firebase
                                                                    .database()
                                                                    .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/logServices/' + state.events.currentLogServiceKeys[childSnapshot.child("vCHILD_UID").val()] + '/events')
                                                                    .off()
                                                            }
                                                            // 
                                                            if (state.events.currentLogServiceKeys[childSnapshot.child("vCHILD_UID").val()]) {
                                                                if (['tagged_important', 'wind_strong', 'battery_low', 'gps_low_signal', 'radio_signal_low'].indexOf(Object.values(logServiceData.val())[0].vEVENT_TYPE) !== -1) {
                                                                    // 

                                                                    firebase
                                                                        .database()
                                                                        .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/logServices/' + data.val() + '/locations').orderByChild('vTIMESTAMP').limitToLast(1)
                                                                        .once("value", function(logServiceLocationsData) {
                                                                            commit("PUSH_EVENT_NOTIFICATION", {
                                                                                vCHILD_UID: childSnapshot.child("vCHILD_UID").val(),
                                                                                vTIMESTAMP: Object.values(logServiceData.val())[0].vTIMESTAMP,
                                                                                vEVENT_TYPE: Object.values(logServiceData.val())[0].vEVENT_TYPE,
                                                                                vEVENT_KEY: Object.keys(logServiceData.val())[0],
                                                                                location: logServiceLocationsData.numChildren() ? Object.values(logServiceLocationsData.val())[0] : null
                                                                            });
                                                                        })
                                                                }
                                                            }
                                                            // 
                                                            commit('SET_CURRENT_LOG_SERVICE_KEY', {
                                                                vCHILD_UID: childSnapshot.child("vCHILD_UID").val(),
                                                                vCURRENT_LOG_SERVICE_KEY: data.val()
                                                            })
                                                        });
                                                }
                                            });
                                    }
                                    if (itemsProcessed === snapshot.numChildren()) {
                                        commit("SET_INITIALIZED", {
                                            drones: true
                                        });
                                    }
                                })


                        });
                    }
                }).then(() => {});
        },
        updateDroneIsConnected({
            commit
        }, data) {
            commit("UPDATE_DRONE_IS_CONNECTED", data);
        },
        setDroneActiveMission({
            commit,
            state
        }, data) {
            commit('SET_DRONE_ACTIVE_MISSION_EDIT_WAYPOINT_INDEX', 0);
            commit("SET_DRONE_ACTIVE_MISSION", data);
            commit(
                "SET_DRONE_ACTIVE_MISSION_ELEMENT",
                state.drones.missions[
                    state.drones.missions
                    .map(function(e) {
                        return e.vMISSION_KEY;
                    })
                    .indexOf(state.drones.activeMission)
                ]
            );
        },
        setDroneActiveMissionElement({
            commit
        }, data) {
            commit("SET_DRONE_ACTIVE_MISSION_ELEMENT", data);
        },
        setDroneActiveMissionUpdateTimestamp({
            commit
        }, data) {
            commit("SET_DRONE_ACTIVE_MISSION_UPDATE_TIMESTAMP", data);
        },
        setDroneActiveMissionFencingsRespected({
            commit
        }, data) {
            commit("SET_DRONE_ACTIVE_MISSION_FENCINGS_RESPECTED", data);
        },
        setDronesMissions({
            commit
        }, data) {
            commit("SET_DRONES_MISSIONS", data);
        },
        pushMarkerIntoDroneActiveMissionElement({
            commit
        }, data) {
            commit("PUSH_MARKER_INTO_DRONE_ACTIVE_MISSION_ELEMENT", data);
        },
        insertMarkerIntoDroneActiveMissionElement({
            commit
        }, data) {
            commit("INSERT_MARKER_INTO_DRONE_ACTIVE_MISSION_ELEMENT", data);
        },
        deleteMarkerFromDroneActiveMissionElement({
            commit
        }, data) {
            commit("DELETE_MARKER_FROM_DRONE_ACTIVE_MISSION_ELEMENT", data);
        },
        updateDroneActiveMissionElementMarker({
            commit
        }, data) {
            commit("UPDATE_DRONE_ACTIVE_MISSION_ELEMENT_MARKER", data);
        },
        updateDroneActiveMissionElement({
            commit
        }, data) {
            commit("UPDATE_DRONE_ACTIVE_MISSION_ELEMENT", data);
        },
        setDroneActiveMissionEditWaypointIndex({
            commit
        }, data) {
            commit("SET_DRONE_ACTIVE_MISSION_EDIT_WAYPOINT_INDEX", data);
        },
        initializeDronesMissions({
            commit,
            state,
            dispatch
        }, data) {
            commit("SET_DRONES_MISSIONS", []);
            firebase
                .database()
                .ref("aegisv2/users/" + state.user.data.uid + "/missions")
                .orderByChild("vCHILD_TYPE")
                .equalTo("drone")
                .once("value", function(snapshot) {
                    commit("SET_DRONES_MISSIONS", []);
                    commit("UPDATE_DRONE_MISSION", Object.values(snapshot.val() || {}));
                    dispatch('setDroneActiveMission', data);
                });
        },
        setSoloActive({
            commit
        }, data) {
            commit("SET_SOLO_ACTIVE", null);
            commit("SET_SOLO_ACTIVE", data);
        },
        updateSoloIsConnected({
            commit
        }, data) {
            commit("UPDATE_SOLO_IS_CONNECTED", data);
        },
        updateSoloIsStandby({
            commit
        }, data) {
            commit("UPDATE_SOLO_IS_STANDBY", data);
        },
        updateSoloIsStreaming({
            commit
        }, data) {
            commit("UPDATE_SOLO_IS_STREAMING", data);
        },
        initializeSolos({
            commit,
            state
        }, data) {
            commit("SET_SOLOS_ELEMENTS", []);
            var itemsProcessed = 0;
            firebase
                .database()
                .ref("aegisv2/users/" + state.user.data.uid + "/elements")
                .orderByChild("vCHILD_TYPE")
                .equalTo("solo")
                .once("value", function(snapshot) {
                    if (snapshot.numChildren() === 0) {
                        commit("SET_INITIALIZED", {
                            solos: true
                        });
                    } else {
                        snapshot.forEach(function(childSnapshot) {
                            firebase
                                .database()
                                .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals')
                                .once("value", function(snap) {
                                    itemsProcessed++;
                                    if (snap.exists()) {
                                        state.logReviews.filter.device.push(childSnapshot.child("vCHILD_UID").val());
                                        if (!state.logReviews.filter.type.includes('solo')) state.logReviews.filter.type.push('solo');

                                        // create element

                                        commit("CREATE_SOLO_ELEMENT", {
                                            vUID: childSnapshot.child("vCHILD_UID").val()
                                        });

                                        // listenners
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals')
                                            .on("value", function(data) {
                                                commit("UPDATE_SOLO_ELEMENT", {
                                                    vUID: childSnapshot.child("vCHILD_UID").val(),
                                                    internals: data.val()
                                                });
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals/vMARKER_COLOR')
                                            .on("value", function(data) {
                                                // TODO
                                                firebase
                                                    .database()
                                                    .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals')
                                                    .once("value", function(data) {
                                                        commit("UPDATE_SOLO_ELEMENT", {
                                                            vUID: childSnapshot.child("vCHILD_UID").val(),
                                                            internals: data.val()
                                                        });
                                                    });
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/telemetry')
                                            .on("value", function(data) {

                                                if (Date.now() - state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['telemetry'] > 200) {
                                                    commit("UPDATE_SOLO_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        telemetry: data.val()
                                                    });
                                                }

                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/telemetry/vHEART_BEAT')
                                            .on("value", function(data) {
                                                if (Date.now() - state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['telemetry'] > 100) {
                                                    commit("UPDATE_SOLO_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        vHEART_BEAT: Date.now()
                                                    });
                                                }
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/stream/vSTREAM_HEART_BEAT')
                                            .on("value", function(data) {
                                                if (Date.now() - state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['stream'] > 100) {
                                                    commit("UPDATE_SOLO_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        vSTREAM_HEART_BEAT: Date.now()
                                                    });
                                                }
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/stream')
                                            .on("value", function(data) {

                                                if (Date.now() - state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['stream'] > 200) {
                                                    commit("UPDATE_SOLO_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        stream: data.val()
                                                    });
                                                }
                                            });
                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/actions')
                                            .on("value", function(data) {

                                                if (Date.now() - state.solos.elements[state.solos.elements.map(v => v.vUID).indexOf(childSnapshot.child("vCHILD_UID").val())].lasTimeUpdated['actions'] > 200) {
                                                    commit("UPDATE_SOLO_ELEMENT", {
                                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                                        actions: data.val()
                                                    });
                                                }
                                            });

                                        firebase
                                            .database()
                                            .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/eventsHistory/')
                                            .orderByChild('vTIMESTAMP')
                                            .startAt(Date.now() - (typeof state.user.data.internals.settings.solo !== 'undefined' ? (state.user.data.internals.settings.solo.vDURATION_CLEAR_ALERTS_IN_MINUTES || 120) : 120) * 60 * 1000)
                                            .on("value", function(data) {
                                                commit("UPDATE_SOLO_ELEMENT", {
                                                    vUID: childSnapshot.child("vCHILD_UID").val(),
                                                    notifications: Object.values(data.val() || {}).filter(v => v.vCHILD_UID && v.vTIMESTAMP && v.vEVENT_TYPE && v.vEVENT_KEY && v.vEVENT_LOCATION_LATITUDE && v.vEVENT_LOCATION_LONGITUDE)
                                                });
                                            });
                                    }
                                    if (itemsProcessed === snapshot.numChildren()) {
                                        commit("SET_INITIALIZED", {
                                            solos: true
                                        });
                                    }
                                })

                        });
                    }
                }).then(() => {});
        },
        initializeSubscribers({
            commit,
            state
        }, data) {
            commit("SET_SUBSCRIBERS_ELEMENTS", []);
            var itemsProcessed = 0;
            firebase
                .database()
                .ref("aegisv2/users/" + state.user.data.uid + "/elements")
                .orderByChild("vCHILD_TYPE")
                .equalTo("subscriber")
                .once("value", function(snapshot) {
                    if (snapshot.numChildren() === 0) {
                        commit("SET_INITIALIZED", {
                            subscribers: true
                        });
                    } else {
                        snapshot.forEach(function(childSnapshot) {
                            itemsProcessed++;
                            // create element
                            commit("CREATE_SUBSCRIBER_ELEMENT", {
                                vUID: childSnapshot.child("vCHILD_UID").val()
                            });

                            // listenners
                            firebase
                                .database()
                                .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals')
                                .on("value", function(data) {
                                    commit("UPDATE_SUBSCRIBER_ELEMENT", {
                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                        internals: data.val()
                                    });
                                });

                            if (itemsProcessed === snapshot.numChildren()) {
                                commit("SET_INITIALIZED", {
                                    subscribers: true
                                });
                            }
                        });
                    }
                })
        },
        initializeSupervisors({
            commit,
            state
        }, data) {
            commit("SET_SUPERVISORS_ELEMENTS", []);
            var itemsProcessed = 0;
            firebase
                .database()
                .ref("aegisv2/users/" + state.user.data.uid + "/elements")
                .orderByChild("vCHILD_TYPE")
                .equalTo("supervisor")
                .once("value", function(snapshot) {
                    if (snapshot.numChildren() === 0) {
                        commit("SET_INITIALIZED", {
                            supervisors: true
                        });
                    } else {
                        snapshot.forEach(function(childSnapshot) {
                            itemsProcessed++;
                            // create element
                            commit("CREATE_SUPERVISOR_ELEMENT", {
                                vUID: childSnapshot.child("vCHILD_UID").val()
                            });

                            // listenners
                            firebase
                                .database()
                                .ref("aegisv2/users/" + childSnapshot.child("vCHILD_UID").val() + '/internals')
                                .on("value", function(data) {
                                    commit("UPDATE_SUPERVISOR_ELEMENT", {
                                        vUID: childSnapshot.child("vCHILD_UID").val(),
                                        internals: data.val()
                                    });
                                });

                            if (itemsProcessed === snapshot.numChildren()) {
                                commit("SET_INITIALIZED", {
                                    supervisors: true
                                });
                            }
                        });
                    }
                })
        },
        initializeLicensesKeys({
            commit,
            state
        }, data) {
            commit("SET_LICENSES_KEYS_ELEMENTS", []);
            var itemsProcessed = 0;
            firebase
                .database()
                .ref("aegisv2/licenses/")
                .orderByChild("vTEAM_UID")
                .equalTo(state.user.data.uid)
                .once("value", function(snapshot) {
                    snapshot.forEach(function(childSnapshot) {
                        itemsProcessed++;
                        // create element
                        commit("CREATE_LICENSE_KEY_ELEMENT", {
                            vLICENSE_KEY: childSnapshot.child("vLICENSE_KEY").val()
                        });

                        // listenners
                        firebase
                            .database()
                            .ref("aegisv2/licenses/" + childSnapshot.key)
                            .on("value", function(data) {
                                commit("UPDATE_LICENSE_KEY_ELEMENT", {
                                    vLICENSE_KEY: childSnapshot.child("vLICENSE_KEY").val(),
                                    data: data.val()
                                });
                            });

                        // if (itemsProcessed === snapshot.numChildren()) {
                        //     commit("SET_INITIALIZED", {
                        //         licensesKeys: true
                        //     });
                        // }
                    });
                })
        },
        initializeFollowers({
            commit,
            state
        }, data) {
            commit("SET_FOLLOWERS_ELEMENTS", []);
            firebase
                .database()
                .ref(`aegisv2/users/${state.user.data.uid}/elements`)
                .once("value", function(snapshot) {
                    snapshot.forEach(function(childSnapshot) {
                        firebase
                            .database()
                            .ref(`aegisv2/users/${childSnapshot.child('vCHILD_UID').val()}/followers/${state.user.data.uid}/mailingList`)
                            .once("value", function(data) {
                                commit("PUSH_FOLLOWERS_ELEMENTS", { vCHILD_UID: childSnapshot.child('vCHILD_UID').val(), mailingList: data.val() || [] });
                            });
                    });
                })
        },
        setUpdateDronesEditMode({
            commit
        }, data) {
            commit('SET_UPDATE_DRONES_EDIT_MODE', data)
        },
        setDronesReport({
            commit
        }, data) {
            commit("SET_DRONES_REPORT", data);
        },
        // 
        initializeLogReviews({
            commit,
            state
        }, data) {
            commit("SET_LOG_REVIEWS_ELEMENTS", []);
            // firebase
            //     .database()
            //     .ref("aegisv2/users/" + state.user.data.uid + "/logReviews")
            //     .orderByChild("vDATETIME_START")
            //     .once("value", function(snapshot) {
            //         let logReviews = Object.values(snapshot.val() || {});
            //         let i = 0;
            //         snapshot.forEach(function(childSnapshot) {
            //             i++;
            //             let index = logReviews.map(v => v.vSERVICE_KEY).indexOf(childSnapshot.key);
            //             if (index!==-1) {
            //                 firebase
            //                     .database()
            //                     .ref(
            //                         "aegisv2/users/" +
            //                         childSnapshot.child("vCHILD_UID").val() +
            //                         "/logServices/" +
            //                         childSnapshot.child("vSERVICE_KEY").val()
            //                     )
            //                     .once("value", function(service) {
            //                         if (service.val()) {
            //                             logReviews[index].service = service.val();
            //                             logReviews[index].vLOG_REVIEW_KEY = Object.keys(snapshot.val())[index];
            //                         } else {
            //                             console.log(`Fail loading service ${service.key}`);
            //                         }
            //                     }).then(function() {
            //                         if (i === logReviews.length) {
            //                             commit("SET_LOG_REVIEWS_ELEMENTS", logReviews);
            //                         }
            //                     })
            //             }
            //         })
            //     })
        },
        setLogReviewsElements({
            commit
        }, data) {
            commit("SET_LOG_REVIEWS_ELEMENTS", data);
        },
        setLogReviewActive({
            commit
        }, data) {
            commit("SET_LOG_REVIEW_ACTIVE", data);
        },
        setLogReviewsFilter({
            commit
        }, data) {
            commit("SET_LOG_REVIEWS_FILTER", data);
        },
        // 
        setDronesReportFilter({
            commit
        }, data) {
            commit("SET_DRONES_REPORT_FILTER", data);
        },
        resetDronesReport({
            commit
        }, data) {
            commit('RESET_DRONES_REPORT', {});
        },
        fetchDronesReport({
            commit,
            getters
        }, data) {
            commit("RESET_DRONES_REPORT", {});
            commit('FETCH_DRONES_REPORT', {
                vTOTAL_EXPECTED_DAILY_MISSIONS: getters.dronesElements.filter(function(v) {
                    return getters.dronesReportFilter.device.indexOf(v.vUID) !== -1 ? true : false
                }).map(v => v.internals.vEXPECTED_DAILY_MISSIONS || 0).reduce(function(a, b) {
                    return a + b
                }, 0)
            });
        },
        setSolosReportFilter({
            commit
        }, data) {
            commit("SET_SOLOS_REPORT_FILTER", data);
        },
        resetSolosReport({
            commit
        }, data) {
            commit('RESET_SOLOS_REPORT', {});
        },
        fetchSolosReport({
            commit,
            getters
        }, data) {
            commit("RESET_SOLOS_REPORT", {});
            commit('FETCH_SOLOS_REPORT', {});
        },
        fetchReviews({
            commit,
            getters
        }, data) {
            commit('FETCH_REVIEWS', {});
        },
        setMapDragged({
            commit
        }, data) {
            commit('SET_MAP_DRAGGED', data);
        },
        removeEventNotificationByKey({
            commit
        }, data) {
            commit('REMOVE_EVENT_NOTIFICATION_BY_KEY', data)
        },
        setEventsNotifications({
            commit
        }, data) {
            commit('SET_EVENTS_NOTIFICATIONS', data)
        },
        setShowAllEventsNotifications({
            commit
        }, data) {
            commit('SET_SHOW_ALL_EVENTS_NOTIFICATIONS', data)
        },
        setCheckedEventNotificationByKey({
            commit
        }, data) {
            commit('SET_CHECKED_EVENT_NOTIFICATION_BY_KEY', data)
        },
        setEventNotificationFocusedLocation({
            commit
        }, data) {
            commit('SET_EVENT_NOTIFICATION_FOCUSED_LOCATION', data)
        },
        clearAllEventsNotifications({
            commit
        }, data) {
            commit('CLEAR_ALL_EVENTS_NOTIFICATIONS', data)
        },
    }
})