<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">Gestão de Licenças</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para criar, deletar ou editar
                            licenças de software.</span
                        >
                    </div>
                </div>
                <br />
                <br />
            </div>
            <div>
                <div class="row">
                    <div class="col-3 text-left">
                        <button
                            class="btn btn-primary"
                            @click="
                                resetForm('form-create-demo-license');
                                dialogCreateDemoLicense.visible = true;
                            "
                        >
                            <i class="fas fa-plus"></i> Liberar licença demo
                        </button>
                    </div>
                </div>
            </div>
            <hr />

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1;
                "
                @click="
                    resetForm('form-create-license-key');
                    randomizeCreateLicenseKey();
                    dialogCreateLicenseKey.visible = true;
                "
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="
                        treatedLicensesKeys.filter(
                            (data) =>
                                !search ||
                                data.vLICENSE_KEY
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vTEAM_NAME
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vTEAM_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCOMPANY_NAME
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vLICENSE_TYPE
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                    "
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column
                        sortable
                        prop="vLICENSE_KEY"
                        label="Chave"
                        width="200"
                    >
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vLICENSE_KEY,
                                        'Chave de licença copiada para a área de transferência',
                                        'Não foi possível copiar a chave de licença para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vLICENSE_KEY }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLICENSE_TYPE"
                        :label="$t('Type')"
                    >
                        <template slot-scope="scope">
                            {{
                                scope.row.vLICENSE_TYPE
                                    .charAt(0)
                                    .toUpperCase() +
                                scope.row.vLICENSE_TYPE.slice(1)
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vENABLED_IN"
                        label="Ativada em"
                    >
                    </el-table-column>
                    <el-table-column sortable prop="vIS_ENABLED" label="Ativa">
                        <template slot-scope="scope">
                            <i
                                class="fa"
                                :class="{
                                    'fa-check': scope.row.vIS_ENABLED,
                                    'fa-times': !scope.row.vIS_ENABLED,
                                }"
                            ></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vMODIFIED_IN"
                        label="Modificada em"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vEXPIRES_IN"
                        :label="$t('Expires_at')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_CHILDREN"
                        :label="$t('Elements')"
                    >
                    </el-table-column>
                    <el-table-column sortable prop="vTEAM_NAME" label="Time">
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vTEAM_UID"
                        label="UID"
                        width="300"
                    >
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vTEAM_UID,
                                        'ID copiado para a área de transferência',
                                        'Não foi possível copiar o ID para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vTEAM_UID }}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        sortable
                        prop="vCHILD_EMAIL"
                        label="Email"
                        width="300"
                    >
                    </el-table-column> -->
                    <el-table-column
                        sortable
                        prop="vCOMPANY_NAME"
                        label="Empresa"
                        width="150"
                    >
                    </el-table-column>
                    <!-- <el-table-column
                        sortable
                        prop="vCHILD_CONTACT"
                        :label="$t('Contact')"
                    >
                    </el-table-column> -->
                    <el-table-column align="right">
                        <template slot="header" slot-scope="scope">
                            <el-input
                                v-model="search"
                                size="mini"
                                placeholder="Procurar"
                                style="border: 1px solid #a1a0a0"
                                :id="scope"
                                clearable
                            >
                                > ></el-input
                            >
                        </template>
                        <template slot-scope="scope">
                            <button
                                @click="handleEdit(scope.$index, scope.row)"
                                class="btn btn-link"
                            >
                                <i
                                    class="fas fa-pen"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                :cancel-button-text="$t('Cancel')"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="
                                    confirmDeleteLicenseKey(
                                        scope.$index,
                                        scope.row
                                    )
                                "
                            >
                                <button class="btn btn-link" slot="reference">
                                    <i
                                        class="fas fa-trash"
                                        style="font-size: 1rem !important"
                                    ></i>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>
        <!-- dialog create demo license-->
        <el-dialog
            title="Liberar licença demo"
            :visible.sync="dialogCreateDemoLicense.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-create-demo-license"
                    :model="dialogCreateDemoLicense.form"
                    :rules="dialogCreateDemoLicense.rules"
                >
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Expires_at')"
                                prop="vEXPIRES_IN"
                            >
                                <el-date-picker
                                    v-model="
                                        dialogCreateDemoLicense.form.vEXPIRES_IN
                                    "
                                    type="date"
                                    placeholder="-"
                                    text-color="red"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value-format="timestamp"
                                    style="width: 100%"
                                    class="text-center"
                                    :align="'center'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Número de canais"
                                prop="vNUMBER_OF_CHANNELS"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogCreateDemoLicense.form
                                            .vNUMBER_OF_CHANNELS
                                    "
                                    controls-position="right"
                                    :min="0"
                                    :max="10"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                label="Número de Drones"
                                prop="vNUMBER_OF_DRONES"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogCreateDemoLicense.form
                                            .vNUMBER_OF_DRONES
                                    "
                                    controls-position="right"
                                    :min="0"
                                    :max="10"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Número de SmartCams"
                                prop="vNUMBER_OF_SOLOS"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogCreateDemoLicense.form
                                            .vNUMBER_OF_SOLOS
                                    "
                                    controls-position="right"
                                    :min="0"
                                    :max="10"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                label="Número de Inscritos"
                                prop="vNUMBER_OF_SUBSCRIBERS"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogCreateDemoLicense.form
                                            .vNUMBER_OF_SUBSCRIBERS
                                    "
                                    controls-position="right"
                                    :min="0"
                                    :max="10"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Número de Supervisores"
                                prop="vNUMBER_OF_SUPERVISORS"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogCreateDemoLicense.form
                                            .vNUMBER_OF_SUPERVISORS
                                    "
                                    controls-position="right"
                                    :min="0"
                                    :max="10"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogCreateDemoLicense.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="submitForm('form-create-demo-license')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!-- dialog create license key-->
        <el-dialog
            :title="$t('Add_license')"
            :visible.sync="dialogCreateLicenseKey.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-create-license-key"
                    :model="dialogCreateLicenseKey.form"
                    :rules="dialogCreateLicenseKey.rules"
                >
                    <!--  -->
                    <el-form-item
                        :label="$t('License_key')"
                        prop="vLICENSE_KEY"
                    >
                        <el-input
                            :maxlength="13"
                            v-model="dialogCreateLicenseKey.form.vLICENSE_KEY"
                            ><el-button
                                @click="copyCreateLicenseKey()"
                                style="outline: none"
                                slot="append"
                                icon="el-icon-copy-document"
                            ></el-button
                            ><el-button
                                @click="randomizeCreateLicenseKey()"
                                style="outline: none"
                                slot="append"
                                icon="el-icon-refresh"
                            ></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('Expires_at')" prop="vEXPIRES_IN">
                        <el-date-picker
                            v-model="dialogCreateLicenseKey.form.vEXPIRES_IN"
                            type="date"
                            placeholder="-"
                            text-color="red"
                            format="dd/MM/yyyy HH:mm:ss"
                            value-format="timestamp"
                            style="width: 100%"
                            class="text-center"
                            :align="'center'"
                        >
                        </el-date-picker>
                    </el-form-item>

                    <!--  -->
                    <el-form-item label="Team UID" prop="vTEAM_UID">
                        <el-input
                            :maxlength="200"
                            v-model="dialogCreateLicenseKey.form.vTEAM_UID"
                            type="text"
                        >
                            ><el-button
                                @click="resetTeamUidCreateLicenseKey()"
                                style="outline: none"
                                slot="append"
                                icon="el-icon-circle-close"
                            ></el-button
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                label="Tipo de licença"
                                prop="vLICENSE_TYPE"
                            >
                                <el-select
                                    class="w-100"
                                    v-model="
                                        dialogCreateLicenseKey.form
                                            .vLICENSE_TYPE
                                    "
                                    placeholder="-"
                                    @change="randomizeCreateLicenseKey()"
                                >
                                    <el-option
                                        v-for="item in dialogCreateLicenseKey.typeOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Número de elementos"
                                prop="vNUMBER_OF_CHILDREN"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogCreateLicenseKey.form
                                            .vNUMBER_OF_CHILDREN
                                    "
                                    controls-position="right"
                                    :min="1"
                                    :max="100"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item label="Ativada em" prop="vENABLED_IN">
                                <el-date-picker
                                    v-model="
                                        dialogCreateLicenseKey.form.vENABLED_IN
                                    "
                                    type="date"
                                    placeholder="-"
                                    text-color="red"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value-format="timestamp"
                                    readonly
                                    style="width: 100%"
                                    class="text-center"
                                    :align="'center'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Modificado em"
                                prop="vMODIFIED_IN"
                            >
                                <el-date-picker
                                    v-model="
                                        dialogCreateLicenseKey.form.vMODIFIED_IN
                                    "
                                    type="date"
                                    placeholder="-"
                                    text-color="red"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value-format="timestamp"
                                    readonly
                                    style="width: 100%"
                                    class="text-center"
                                    :align="'center'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogCreateLicenseKey.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="submitForm('form-create-license-key')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!-- dialog edit license key-->
        <el-dialog
            title="Editar licença"
            :visible.sync="dialogEditLicenseKey.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-edit-license-key"
                    :model="dialogEditLicenseKey.form"
                    :rules="dialogEditLicenseKey.rules"
                >
                    <!--  -->
                    <el-form-item
                        :label="$t('License_key')"
                        prop="vLICENSE_KEY"
                    >
                        <el-input
                            :maxlength="13"
                            v-model="dialogEditLicenseKey.form.vLICENSE_KEY"
                            disabled
                            readonly
                            ><el-button
                                @click="copyEditLicenseKey()"
                                style="outline: none"
                                slot="append"
                                icon="el-icon-copy-document"
                            ></el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('Expires_at')" prop="vEXPIRES_IN">
                        <el-date-picker
                            v-model="dialogEditLicenseKey.form.vEXPIRES_IN"
                            type="date"
                            placeholder="-"
                            text-color="red"
                            format="dd/MM/yyyy HH:mm:ss"
                            value-format="timestamp"
                            style="width: 100%"
                            class="text-center"
                            :align="'center'"
                        >
                        </el-date-picker>
                    </el-form-item>

                    <!--  -->
                    <el-form-item label="Team UID" prop="vTEAM_UID">
                        <el-input
                            :maxlength="200"
                            v-model="dialogEditLicenseKey.form.vTEAM_UID"
                            type="text"
                        >
                            ><el-button
                                @click="resetTeamUidEditLicenseKey()"
                                style="outline: none"
                                slot="append"
                                icon="el-icon-circle-close"
                            ></el-button
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                label="Tipo de licença"
                                prop="vLICENSE_TYPE"
                            >
                                <el-select
                                    class="w-100"
                                    v-model="
                                        dialogEditLicenseKey.form.vLICENSE_TYPE
                                    "
                                    placeholder="-"
                                    disabled
                                    readonlu
                                >
                                    <el-option
                                        v-for="item in dialogEditLicenseKey.typeOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Número de elementos"
                                prop="vNUMBER_OF_CHILDREN"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogEditLicenseKey.form
                                            .vNUMBER_OF_CHILDREN
                                    "
                                    controls-position="right"
                                    :min="1"
                                    :max="100"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item label="Ativada em" prop="vENABLED_IN">
                                <el-date-picker
                                    v-model="
                                        dialogEditLicenseKey.form.vENABLED_IN
                                    "
                                    type="date"
                                    placeholder="-"
                                    text-color="red"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value-format="timestamp"
                                    readonly
                                    style="width: 100%"
                                    class="text-center"
                                    :align="'center'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Modificado em"
                                prop="vMODIFIED_IN"
                            >
                                <el-date-picker
                                    v-model="
                                        dialogEditLicenseKey.form.vMODIFIED_IN
                                    "
                                    type="date"
                                    placeholder="-"
                                    text-color="red"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value-format="timestamp"
                                    readonly
                                    style="width: 100%"
                                    class="text-center"
                                    :align="'center'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogEditLicenseKey.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="submitForm('form-edit-license-key')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>

        <VueHtml2pdf
            :manual-pagination="true"
            :enable-download="true"
            ref="contentDemoReport"
            :preview-modal="false"
            style="color: #292929 !important"
            :html-to-pdf-options="htmlToPdfOptions"
        >
            <section
                slot="pdf-content"
                style="width: 100%; padding: 0px 0px 0px 0px; height: 1122px"
            >
                <img
                    style="height: 100%; width: 100%"
                    :src="require('@/assets/smartcam-cover.png')"
                    alt=""
                />
            </section>
            <section
                slot="pdf-content"
                style="
                    width: 100%;
                    padding: 200px 20px 50px 50px;
                    height: 1122px;
                    background-size: cover;
                "
                :style="{
                    backgroundImage: `url(${require('@/assets/smartcam-sub-cover.png')})`,
                }"
            >
                <h2>Folha de credenciais</h2>
                <h4 style="color: #ddc673">Pedido {{ Date.now() }}</h4>
                <br />
                <br />
                <p>
                    <strong>SmartCam</strong> é a solução
                    <strong>AeroGuard</strong> que transforma um smartphone em
                    câmera operacional móvel. Abaixo você encontrará todas as
                    informações para utilizar o sistema. Caso tenha alguma
                    dúvida, basta fazer contato em
                    <a href="mailto:suporte@aeroguard.com.br"
                        >suporte@aeroguard.com.br</a
                    >
                    e ficaremos felizes em ajudar.
                </p>

                <el-table
                    :data="dialogCreateDemoLicense.items"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="value"
                        label="Quantidade"
                        width="150"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column prop="label" label="Item" align="center">
                    </el-table-column>
                </el-table>
                <br />
                <br />
                <h5>Links de acesso</h5>
                <ul
                    v-if="
                        dialogCreateDemoLicense.items[
                            dialogCreateDemoLicense.items
                                .map((v) => v.key)
                                .indexOf('team')
                        ].elements.length > 0
                    "
                >
                    <li>
                        Central (Administrador) - Web
                        <ul>
                            <li>
                                <a href="https://aeroguard-dashboard.web.app/"
                                    >https://aeroguard-dashboard.web.app/</a
                                >
                            </li>
                            <li>
                                Login:
                                <strong>
                                    {{
                                        dialogCreateDemoLicense.items[
                                            dialogCreateDemoLicense.items
                                                .map((v) => v.key)
                                                .indexOf("team")
                                        ].elements[0].email
                                    }}</strong
                                >
                            </li>
                            <li>
                                Senha:
                                <strong>
                                    {{
                                        dialogCreateDemoLicense.items[
                                            dialogCreateDemoLicense.items
                                                .map((v) => v.key)
                                                .indexOf("team")
                                        ].elements[0].password
                                    }}</strong
                                >
                            </li>
                        </ul>
                    </li>
                </ul>
                <br />

                <h5>Manual de Uso</h5>
                <ul>
                    <li>
                        <a :href="files.web.vCURRENT_MANUAL_DOWNLOAD_URL"
                            >Manual de uso - Central Web (PT-BR)</a
                        >
                    </li>
                    <li>
                        <a :href="files.android.vCURRENT_MANUAL_DOWNLOAD_URL"
                            >Manual de uso - App Mobile (PT-BR)</a
                        >
                    </li>
                </ul>
                <br />

                <h5>Links para download</h5>
                <ul>
                    <li>
                        <a :href="files.android.vCURRENT_APK_DOWNLOAD_URL"
                            >Download APK Android 8.0+</a
                        >
                    </li>
                </ul>
                <br />
            </section>
        </VueHtml2pdf>
    </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            treatedLicensesKeys: [],
            dialogCreateDemoLicense: {
                visible: false,
                form: {
                    vTEAM_UID: null,
                    vEXPIRES_IN: Date.now() + 30 * 24 * 60 * 60 * 1000,
                    vNUMBER_OF_CHANNELS: 0,
                    vNUMBER_OF_DRONES: 0,
                    vNUMBER_OF_SOLOS: 3,
                    vNUMBER_OF_SUBSCRIBERS: 1,
                    vNUMBER_OF_SUPERVISORS: 1,
                },
                rules: {},
                items: [
                    {
                        label: "Licença de Administrador",
                        value: 0,
                        elements: [],
                        key: "team",
                    },
                    {
                        label: "Licenças de Dispositivo",
                        value: 0,
                        elements: [],
                        key: "solo",
                    },
                    {
                        label: "Licenças de Supervisor Web",
                        value: 0,
                        elements: [],
                        key: "supervisor",
                    },
                    {
                        label: "Licenças de Supervisor Mobile",
                        value: 0,
                        elements: [],
                        key: "subscriber",
                    },
                ],
            },
            dialogCreateLicenseKey: {
                visible: false,
                type: "",
                typeOptions: [
                    { label: "Drone", value: "drone" },
                    { label: "SmartCam", value: "solo" },
                ],
                form: {
                    vCHILD_UID: null,
                    vENABLED_IN: Date.now(),
                    vEXPIRES_IN: Date.now() + 365 * 24 * 60 * 60 * 1000,
                    vIS_ENABLED: true,
                    vLICENSE_KEY: null,
                    vLICENSE_TYPE: "drone",
                    vMODIFIED_IN: Date.now(),
                    vNUMBER_OF_CHILDREN: 1,
                    vTEAM_UID: null,
                },
                rules: {
                    vLICENSE_KEY: [
                        {
                            required: true,
                            message: "Defina uma chave de licença",
                            trigger: "change",
                        },
                        {
                            min: 13,
                            max: 13,
                            message: "A chave de licença deve ter 13 dígitos",
                            trigger: "change",
                        },
                    ],
                },
            },
            dialogEditLicenseKey: {
                visible: false,
                type: "",
                typeOptions: [
                    { label: "Drone", value: "drone" },
                    { label: "SmartCam", value: "solo" },
                ],
                form: {
                    vCHILD_UID: null,
                    vENABLED_IN: null,
                    vEXPIRES_IN: null,
                    vIS_ENABLED: true,
                    vLICENSE_KEY: null,
                    vLICENSE_TYPE: null,
                    vMODIFIED_IN: null,
                    vNUMBER_OF_CHILDREN: null,
                    vTEAM_UID: null,
                },
                rules: {},
            },
            search: "",
        };
    },
    computed: {
        ...mapGetters(["user", "files"]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
        htmlToPdfOptions() {
            return {
                margin: 0,
                image: {
                    type: "jpeg",
                    quality: 0.98,
                },
                enableLinks: true,
                html2canvas: {
                    scale: 2,
                    useCORS: true,
                },
                jsPDF: {
                    unit: "in",
                    format: "a4",
                    orientation: "portrait",
                },
                filename: `AeroGuard SmartCam - Folha de credenciais ${Date.now()}`,
            };
        },
    },
    methods: {
        ...mapActions(["setNavForcedLoading"]),
        handleEdit(i, r) {
            let self = this;
            firebase
                .database()
                .ref(`aegisv2/licenses/${r.vLICENSE_KEY}`)
                .once("value", function (data) {
                    self.dialogEditLicenseKey.form = {
                        vCHILD_UID: null,
                        vENABLED_IN: data.child("vENABLED_IN").val(),
                        vEXPIRES_IN: data.child("vEXPIRES_IN").val(),
                        vIS_ENABLED: data.child("vIS_ENABLED").val(),
                        vLICENSE_KEY: data.child("vLICENSE_KEY").val(),
                        vLICENSE_TYPE: data.child("vLICENSE_TYPE").val(),
                        vMODIFIED_IN: data.child("vMODIFIED_IN").val(),
                        vNUMBER_OF_CHILDREN: data
                            .child("vNUMBER_OF_CHILDREN")
                            .val(),
                        vTEAM_UID:
                            data.child("vTEAM_UID").val() === "NOT_ASSIGNED"
                                ? null
                                : data.child("vTEAM_UID").val(),
                    };
                    self.dialogEditLicenseKey.visible = true;
                });
        },
        confirmDeleteLicenseKey(i, r) {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_DELETE_LICENSE_KEY")({
                            payload: { vLICENSE_KEY: r.vLICENSE_KEY },
                            command: "delete-admin-management-license-key",
                            vTEAM_UID: r.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        resetTeamUidCreateLicenseKey() {
            this.dialogCreateLicenseKey.form.vTEAM_UID = "";
        },
        resetTeamUidEditLicenseKey() {
            this.dialogEditLicenseKey.form.vTEAM_UID = "";
        },
        copyToClipboard(text, messageSuccess, messageError) {
            let self = this;
            this.$copyText(text).then(
                () => {
                    self.$message({
                        message: messageSuccess,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                },
                () => {
                    self.$message({
                        message: messageError,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            );
        },
        copyCreateLicenseKey() {
            this.copyToClipboard(
                this.dialogCreateLicenseKey.form.vLICENSE_KEY,
                "Chave de licença copiada para a área de transferência",
                "Não foi possível copiar a chave de licença para a área de transferência"
            );
        },
        copyEditLicenseKey() {
            this.copyToClipboard(
                this.dialogEditLicenseKey.form.vLICENSE_KEY,
                "Chave de licença copiada para a área de transferência",
                "Não foi possível copiar a chave de licença para a área de transferência"
            );
        },
        randomizeCreateLicenseKey() {
            this.dialogCreateLicenseKey.form.vLICENSE_KEY = `${this.dialogCreateLicenseKey.form.vLICENSE_TYPE[0].toUpperCase()}${new Date()
                .getFullYear()
                .toString()
                .substr(2, 3)}${this.getRandomNumbersAndLetters(10)}`;
        },
        randomizeCreateLicenseKeyByType(type) {
            return `${type[0].toUpperCase()}${new Date()
                .getFullYear()
                .toString()
                .substr(2, 3)}${this.getRandomNumbersAndLetters(10)}`;
        },
        getRandomNumbersAndLetters(len) {
            const array = [
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "J",
                "K",
                "M",
                "N",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "W",
                "X",
                "Y",
                "Z",
            ];

            var str = "";
            for (var i = 0; i < len; i++) {
                str += array[Math.floor(Math.random() * array.length)];
            }
            return str;
        },
        resetForm(formName) {
            if (this.$refs[formName]) {
                this.$refs[formName].resetFields();
            }
        },
        submitForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (formName === "form-create-license-key") {
                        self.confirmCreateLicenseKey(
                            self.dialogCreateLicenseKey.form
                        );
                    } else if (formName === "form-edit-license-key") {
                        self.confirmEditLicenseKey(
                            self.dialogEditLicenseKey.form
                        );
                    } else if (formName === "form-create-demo-license") {
                        self.confirmCreateDemoLicense(
                            self.dialogCreateDemoLicense.form
                        );
                    }
                } else {
                    return false;
                }
            });
        },
        confirmCreateLicenseKey(data) {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_CREATE_LICENSE_KEY")({
                            payload: data,
                            command: "create-admin-management-license-key",
                            vTEAM_UID: data.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            self.resetForm("form-create-license-key");
                            self.dialogCreateLicenseKey.visible = false;
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmEditLicenseKey(data) {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_EDIT_LICENSE_KEY")({
                            payload: data,
                            command: "edit-admin-management-license-key",
                            vTEAM_UID: data.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            self.resetForm("form-edit-license-key");
                            self.dialogEditLicenseKey.visible = false;
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmCreateDemoLicense(data) {
            let self = this;
            this.dialogCreateDemoLicense.items = [
                {
                    label: "Licença de Administrador",
                    value: 0,
                    elements: [],
                    key: "team",
                },
                {
                    label: "Licenças de Dispositivo",
                    value: 0,
                    elements: [],
                    key: "solo",
                },
                {
                    label: "Licenças de Supervisor Web",
                    value: 0,
                    elements: [],
                    key: "supervisor",
                },
                {
                    label: "Licenças de Supervisor Mobile",
                    value: 0,
                    elements: [],
                    key: "subscriber",
                },
            ];

            this.dialogCreateDemoLicense.visible = false;
            this.setNavForcedLoading(true);
            let email =
                this.getRandomNumbersAndLetters(6).toLowerCase() +
                "@aeroguard.com.br";
            let password = email.split("@")[0];

            this.dialogCreateDemoLicense.items[
                this.dialogCreateDemoLicense.items
                    .map((v) => v.key)
                    .indexOf("team")
            ].elements.push({ email: email, password: password });
            this.dialogCreateDemoLicense.items[
                this.dialogCreateDemoLicense.items
                    .map((v) => v.key)
                    .indexOf("team")
            ].value++;

            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_CREATE_TEAM")({
                            payload: {
                                vCHILD_CONTACT: "(00)00000-0000",
                                vCHILD_EMAIL: email,
                                vCHILD_PASSWORD: password,
                                vCHILD_ID: "000.000.000-00",
                                vCHILD_NAME: email.split("@")[0],
                                vCOMPANY_ID: "00.000.000/0000-00",
                                vCOMPANY_NAME: email.split("@")[0],
                                vLAST_LOGIN: 0,
                                vTEAM_NAME: `Time ${email.split("@")[0]}`,
                                vEXPIRES_IN: data.vEXPIRES_IN,
                                vNUMBER_OF_CHANNELS: data.vNUMBER_OF_CHANNELS,
                            },
                            command: `create-account-user-management-team`,
                            idToken: idToken,
                            vCREATOR_UID: self.user.data.uid,
                        })
                        .then(function (result) {
                            // self.resetForm("form-create-team");
                            // self.dialogCreateTeam.visible = false;

                            if (!result.data.error) {
                                self.$message({
                                    message: `Time criado com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                // self.loadData();

                                let teamUid = result.data.uid;

                                let promises = [];

                                if (data.vNUMBER_OF_DRONES > 0) {
                                    promises.push(
                                        new Promise(function (resolve, reject) {
                                            firebase
                                                .functions()
                                                .httpsCallable(
                                                    "CLOUD_DASHBOARD_CREATE_LICENSE_KEY"
                                                )({
                                                    payload: {
                                                        vCHILD_UID: null,
                                                        vENABLED_IN: Date.now(),
                                                        vEXPIRES_IN:
                                                            data.vEXPIRES_IN,
                                                        vIS_ENABLED: true,
                                                        vLICENSE_KEY:
                                                            self.randomizeCreateLicenseKeyByType(
                                                                "drone"
                                                            ),
                                                        vLICENSE_TYPE: "drone",
                                                        vMODIFIED_IN:
                                                            Date.now(),
                                                        vNUMBER_OF_CHILDREN:
                                                            data.vNUMBER_OF_DRONES,
                                                        vTEAM_UID: teamUid,
                                                    },
                                                    command:
                                                        "create-admin-management-license-key",
                                                    vTEAM_UID: teamUid,
                                                    vCREATOR_UID:
                                                        self.user.data.uid,
                                                    idToken: idToken,
                                                })
                                                .then(function (result) {
                                                    if (!result.data.error) {
                                                        self.$message({
                                                            message: `Licença Drones criada com sucesso`,
                                                            type: "success",
                                                            showClose: true,
                                                            offset: 60,
                                                        });
                                                        resolve({
                                                            command:
                                                                "create-admin-management-license-key",
                                                            error: null,
                                                        });
                                                    } else {
                                                        self.$message({
                                                            message: `Não foi possível efetuar as modificações`,
                                                            type: "error",
                                                            showClose: true,
                                                            offset: 60,
                                                        });
                                                        reject({
                                                            command:
                                                                "create-admin-management-license-key",
                                                            error: result.data
                                                                .error,
                                                        });
                                                    }
                                                })
                                                .catch((e) => {
                                                    self.$message({
                                                        message: `Não foi possível efetuar as modificações`,
                                                        type: "error",
                                                        showClose: true,
                                                        offset: 60,
                                                    });
                                                    reject({
                                                        command:
                                                            "create-admin-management-license-key",
                                                        error: e,
                                                    });
                                                });
                                        })
                                    );
                                }
                                if (data.vNUMBER_OF_SOLOS > 0) {
                                    promises.push(
                                        new Promise(function (resolve, reject) {
                                            firebase
                                                .functions()
                                                .httpsCallable(
                                                    "CLOUD_DASHBOARD_CREATE_LICENSE_KEY"
                                                )({
                                                    payload: {
                                                        vCHILD_UID: null,
                                                        vENABLED_IN: Date.now(),
                                                        vEXPIRES_IN:
                                                            data.vEXPIRES_IN,
                                                        vIS_ENABLED: true,
                                                        vLICENSE_KEY:
                                                            self.randomizeCreateLicenseKeyByType(
                                                                "solo"
                                                            ),
                                                        vLICENSE_TYPE: "solo",
                                                        vMODIFIED_IN:
                                                            Date.now(),
                                                        vNUMBER_OF_CHILDREN:
                                                            data.vNUMBER_OF_SOLOS,
                                                        vTEAM_UID: teamUid,
                                                    },
                                                    command:
                                                        "create-admin-management-license-key",
                                                    vTEAM_UID: teamUid,
                                                    vCREATOR_UID:
                                                        self.user.data.uid,
                                                    idToken: idToken,
                                                })
                                                .then(function (result) {
                                                    if (!result.data.error) {
                                                        self.$message({
                                                            message: `Licença SmartCams criada com sucesso`,
                                                            type: "success",
                                                            showClose: true,
                                                            offset: 60,
                                                        });
                                                        resolve({
                                                            command:
                                                                "create-admin-management-license-key",
                                                            error: null,
                                                        });
                                                    } else {
                                                        self.$message({
                                                            message: `Não foi possível efetuar as modificações`,
                                                            type: "error",
                                                            showClose: true,
                                                            offset: 60,
                                                        });
                                                        reject({
                                                            command:
                                                                "create-admin-management-license-key",
                                                            error: result.data
                                                                .error,
                                                        });
                                                    }
                                                })
                                                .catch((e) => {
                                                    self.$message({
                                                        message: `Não foi possível efetuar as modificações`,
                                                        type: "error",
                                                        showClose: true,
                                                        offset: 60,
                                                    });
                                                    reject({
                                                        command:
                                                            "create-admin-management-license-key",
                                                        error: e,
                                                    });
                                                });
                                        })
                                    );
                                }

                                Promise.all(promises)
                                    .then(function (values) {
                                        console.log(values);

                                        let promises = [];

                                        for (
                                            let i = 0;
                                            i < data.vNUMBER_OF_DRONES;
                                            i++
                                        ) {
                                            promises.push(
                                                new Promise(function (
                                                    resolve,
                                                    reject
                                                ) {
                                                    firebase
                                                        .functions()
                                                        .httpsCallable(
                                                            `CLOUD_DASHBOARD_CREATE_DRONE`
                                                        )({
                                                            payload: {
                                                                internals: {
                                                                    vCHILD_NAME: `Drone ${
                                                                        i + 1
                                                                    }`,
                                                                    vCHILD_CONTACT:
                                                                        "(00)00000-0000",
                                                                    vCHILD_ID:
                                                                        "000.000.000-00",
                                                                    vEXPECTED_DAILY_MISSIONS: 1,
                                                                    vTEAM_UID:
                                                                        teamUid,
                                                                },
                                                            },
                                                            command: `create-account-user-management-drone`,
                                                            vTEAM_UID: teamUid,
                                                            vCREATOR_UID:
                                                                self.user.data
                                                                    .uid,
                                                            vCHILD_TYPE:
                                                                "drone",
                                                            idToken: idToken,
                                                        })
                                                        .then(function (
                                                            result
                                                        ) {
                                                            if (
                                                                !result.data
                                                                    .error
                                                            ) {
                                                                self.$message({
                                                                    message: `Drone criado com sucesso`,
                                                                    type: "success",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                resolve({
                                                                    command:
                                                                        "create-account-user-management-drone",
                                                                    error: null,
                                                                });
                                                            } else {
                                                                self.$message({
                                                                    message: `Não foi possível efetuar as modificações`,
                                                                    type: "error",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                reject({
                                                                    command:
                                                                        "create-account-user-management-drone",
                                                                    error: result
                                                                        .data
                                                                        .error,
                                                                });
                                                            }
                                                        })
                                                        .catch((e) => {
                                                            self.$message({
                                                                message: `Não foi possível efetuar as modificações`,
                                                                type: "error",
                                                                showClose: true,
                                                                offset: 60,
                                                            });
                                                            reject({
                                                                command:
                                                                    "create-account-user-management-drone",
                                                                error: e,
                                                            });
                                                        });
                                                })
                                            );
                                        }

                                        for (
                                            let i = 0;
                                            i < data.vNUMBER_OF_SOLOS;
                                            i++
                                        ) {
                                            promises.push(
                                                new Promise(function (
                                                    resolve,
                                                    reject
                                                ) {
                                                    firebase
                                                        .functions()
                                                        .httpsCallable(
                                                            `CLOUD_DASHBOARD_CREATE_SOLO`
                                                        )({
                                                            payload: {
                                                                internals: {
                                                                    vCHILD_NAME: `SmartCam ${
                                                                        i + 1
                                                                    }`,
                                                                    vCHILD_CONTACT:
                                                                        "(00)00000-0000",
                                                                    vCHILD_ID:
                                                                        "000.000.000-00",
                                                                    vTEAM_UID:
                                                                        teamUid,
                                                                },
                                                            },
                                                            command: `create-account-user-management-solo`,
                                                            vTEAM_UID: teamUid,
                                                            vCREATOR_UID:
                                                                self.user.data
                                                                    .uid,
                                                            vCHILD_TYPE: "solo",
                                                            idToken: idToken,
                                                        })
                                                        .then(function (
                                                            result
                                                        ) {
                                                            if (
                                                                !result.data
                                                                    .error
                                                            ) {
                                                                self
                                                                    .dialogCreateDemoLicense
                                                                    .items[
                                                                    self.dialogCreateDemoLicense.items
                                                                        .map(
                                                                            (
                                                                                v
                                                                            ) =>
                                                                                v.key
                                                                        )
                                                                        .indexOf(
                                                                            "solo"
                                                                        )
                                                                ].value++;
                                                                self.$message({
                                                                    message: `SmartCam criada com sucesso`,
                                                                    type: "success",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                resolve({
                                                                    command:
                                                                        "create-account-user-management-solo",
                                                                    error: null,
                                                                });
                                                            } else {
                                                                self.$message({
                                                                    message: `Não foi possível efetuar as modificações`,
                                                                    type: "error",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                reject({
                                                                    command:
                                                                        "create-account-user-management-solo",
                                                                    error: result
                                                                        .data
                                                                        .error,
                                                                });
                                                            }
                                                        })
                                                        .catch((e) => {
                                                            self.$message({
                                                                message: `Não foi possível efetuar as modificações`,
                                                                type: "error",
                                                                showClose: true,
                                                                offset: 60,
                                                            });
                                                            reject({
                                                                command:
                                                                    "create-account-user-management-solo",
                                                                error: e,
                                                            });
                                                        });
                                                })
                                            );
                                        }

                                        for (
                                            let i = 0;
                                            i < data.vNUMBER_OF_SUBSCRIBERS;
                                            i++
                                        ) {
                                            promises.push(
                                                new Promise(function (
                                                    resolve,
                                                    reject
                                                ) {
                                                    firebase
                                                        .functions()
                                                        .httpsCallable(
                                                            `CLOUD_DASHBOARD_CREATE_SUBSCRIBER`
                                                        )({
                                                            payload: {
                                                                internals: {
                                                                    vCHILD_NAME: `Inscrito ${
                                                                        i + 1
                                                                    }`,
                                                                    vCHILD_CONTACT:
                                                                        "(00)00000-0000",
                                                                    vCHILD_ID:
                                                                        "000.000.000-00",
                                                                    vDATA_FETCH_PERIOD: 7,
                                                                    elements:
                                                                        [],
                                                                },
                                                            },
                                                            command: `create-account-user-management-subscriber`,
                                                            vTEAM_UID: teamUid,
                                                            vCREATOR_UID:
                                                                self.user.data
                                                                    .uid,
                                                            vCHILD_TYPE:
                                                                "subscriber",
                                                            idToken: idToken,
                                                        })
                                                        .then(function (
                                                            result
                                                        ) {
                                                            if (
                                                                !result.data
                                                                    .error
                                                            ) {
                                                                self
                                                                    .dialogCreateDemoLicense
                                                                    .items[
                                                                    self.dialogCreateDemoLicense.items
                                                                        .map(
                                                                            (
                                                                                v
                                                                            ) =>
                                                                                v.key
                                                                        )
                                                                        .indexOf(
                                                                            "subscriber"
                                                                        )
                                                                ].value++;
                                                                self.$message({
                                                                    message: `Inscrito criado com sucesso`,
                                                                    type: "success",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                resolve({
                                                                    command:
                                                                        "create-account-user-management-subscriber",
                                                                    error: null,
                                                                });
                                                            } else {
                                                                self.$message({
                                                                    message: `Não foi possível efetuar as modificações`,
                                                                    type: "error",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                reject({
                                                                    command:
                                                                        "create-account-user-management-subscriber",
                                                                    error: result
                                                                        .data
                                                                        .error,
                                                                });
                                                            }
                                                        })
                                                        .catch((e) => {
                                                            self.$message({
                                                                message: `Não foi possível efetuar as modificações`,
                                                                type: "error",
                                                                showClose: true,
                                                                offset: 60,
                                                            });
                                                            reject({
                                                                command:
                                                                    "create-account-user-management-subscriber",
                                                                error: e,
                                                            });
                                                        });
                                                })
                                            );
                                        }

                                        for (
                                            let i = 0;
                                            i < data.vNUMBER_OF_SUPERVISORS;
                                            i++
                                        ) {
                                            promises.push(
                                                new Promise(function (
                                                    resolve,
                                                    reject
                                                ) {
                                                    let email =
                                                        self
                                                            .getRandomNumbersAndLetters(
                                                                6
                                                            )
                                                            .toLowerCase() +
                                                        "@aeroguard.com.br";
                                                    let password =
                                                        email.split("@")[0];

                                                    firebase
                                                        .functions()
                                                        .httpsCallable(
                                                            `CLOUD_DASHBOARD_CREATE_SUPERVISOR`
                                                        )({
                                                            payload: {
                                                                internals: {
                                                                    vCHILD_NAME: `Supervisor ${
                                                                        i + 1
                                                                    }`,
                                                                    vCHILD_EMAIL:
                                                                        email,
                                                                    vCHILD_PASSWORD:
                                                                        password,
                                                                    vCHILD_CONTACT:
                                                                        "(00)00000-0000",
                                                                    vCHILD_ID:
                                                                        "000.000.000-00",
                                                                },
                                                            },
                                                            command: `create-account-user-management-supervisor`,
                                                            vTEAM_UID: teamUid,
                                                            vCREATOR_UID:
                                                                self.user.data
                                                                    .uid,
                                                            vCHILD_TYPE:
                                                                "supervisor",
                                                            idToken: idToken,
                                                        })
                                                        .then(function (
                                                            result
                                                        ) {
                                                            if (
                                                                !result.data
                                                                    .error
                                                            ) {
                                                                self
                                                                    .dialogCreateDemoLicense
                                                                    .items[
                                                                    self.dialogCreateDemoLicense.items
                                                                        .map(
                                                                            (
                                                                                v
                                                                            ) =>
                                                                                v.key
                                                                        )
                                                                        .indexOf(
                                                                            "supervisor"
                                                                        )
                                                                ].value++;
                                                                self.$message({
                                                                    message: `Supervisor criado com sucesso`,
                                                                    type: "success",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                resolve({
                                                                    command:
                                                                        "create-account-user-management-supervisor",
                                                                    error: null,
                                                                });
                                                            } else {
                                                                self.$message({
                                                                    message: `Não foi possível efetuar as modificações`,
                                                                    type: "error",
                                                                    showClose: true,
                                                                    offset: 60,
                                                                });
                                                                reject({
                                                                    command:
                                                                        "create-account-user-management-supervisor",
                                                                    error: result
                                                                        .data
                                                                        .error,
                                                                });
                                                            }
                                                        })
                                                        .catch((e) => {
                                                            self.$message({
                                                                message: `Não foi possível efetuar as modificações`,
                                                                type: "error",
                                                                showClose: true,
                                                                offset: 60,
                                                            });
                                                            reject({
                                                                command:
                                                                    "create-account-user-management-supervisor",
                                                                error: e,
                                                            });
                                                        });
                                                })
                                            );
                                        }

                                        Promise.all(promises)
                                            .then(function (values) {
                                                console.log(values);
                                                self.setNavForcedLoading(false);
                                                self.dialogCreateDemoLicense.visible = false;
                                                self.$refs.contentDemoReport.generatePdf();
                                            })
                                            .catch(function (e) {
                                                console.log(e);
                                                self.setNavForcedLoading(false);
                                            });
                                    })
                                    .catch(function (e) {
                                        console.log(e);
                                        self.setNavForcedLoading(false);
                                    });
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações1`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            self.$message({
                                message: `Não foi possível efetuar as modificações2`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações3`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        loadData() {
            this.treatedLicensesKeys = [];
            let self = this;
            let array = [];

            firebase
                .database()
                .ref("aegisv2/licenses/")
                .orderByChild("vTEAM_UID")
                .once("value", function (snapshot) {
                    snapshot.forEach(function (childSnapshot) {
                        if (
                            childSnapshot.child("vTEAM_UID").val() &&
                            childSnapshot.child("vTEAM_UID").val() !==
                                "NOT_ASSIGNED"
                        ) {
                            firebase
                                .database()
                                .ref(
                                    "aegisv2/users/" +
                                        childSnapshot.child("vTEAM_UID").val() +
                                        "/internals"
                                )
                                .once("value", function (data) {
                                    let obj = {};
                                    obj.vLICENSE_KEY = childSnapshot
                                        .child("vLICENSE_KEY")
                                        .val();
                                    obj.vTEAM_UID = childSnapshot
                                        .child("vTEAM_UID")
                                        .val();

                                    let parameters = [
                                        "vEXPIRES_IN",
                                        "vENABLED_IN",
                                        "vMODIFIED_IN",
                                    ];

                                    for (let p of parameters) {
                                        obj[p] = moment(
                                            childSnapshot.child(p).val() || 0
                                        ).format("DD/MM/YYYY");
                                    }

                                    parameters = [
                                        "vIS_ENABLED",
                                        "vLICENSE_TYPE",
                                    ];

                                    for (let p of parameters) {
                                        obj[p] =
                                            childSnapshot.child(p).val() || "-";
                                    }

                                    parameters = ["vNUMBER_OF_CHILDREN"];

                                    for (let p of parameters) {
                                        obj[p] =
                                            childSnapshot.child(p).val() || 0;
                                    }

                                    parameters = [
                                        "vTEAM_NAME",
                                        "vCHILD_CONTACT",
                                        "vCHILD_EMAIL",
                                        "vCOMPANY_NAME",
                                    ];

                                    for (let p of parameters) {
                                        obj[p] = data.child(p).val() || "-";
                                    }

                                    self.treatedLicensesKeys.push(obj);
                                });
                        } else {
                            let obj = {};
                            obj.vLICENSE_KEY = childSnapshot
                                .child("vLICENSE_KEY")
                                .val();
                            obj.vTEAM_UID = "-";

                            let parameters = [
                                "vEXPIRES_IN",
                                "vENABLED_IN",
                                "vMODIFIED_IN",
                            ];

                            for (let p of parameters) {
                                obj[p] = moment(
                                    childSnapshot.child(p).val() || 0
                                ).format("DD/MM/YYYY");
                            }

                            parameters = ["vIS_ENABLED", "vLICENSE_TYPE"];

                            for (let p of parameters) {
                                obj[p] = childSnapshot.child(p).val() || "-";
                            }

                            parameters = ["vNUMBER_OF_CHILDREN"];

                            for (let p of parameters) {
                                obj[p] = childSnapshot.child(p).val() || 0;
                            }

                            parameters = [
                                "vTEAM_NAME",
                                "vCHILD_CONTACT",
                                "vCHILD_EMAIL",
                                "vCOMPANY_NAME",
                            ];

                            for (let p of parameters) {
                                obj[p] = "-";
                            }

                            self.treatedLicensesKeys.push(obj);
                        }
                    });
                });
        },
    },
    components: {
        VueHtml2pdf,
    },
    beforeMount() {
        this.loadData();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}

i {
    font-size: 1rem !important;
}
</style>