<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">{{
                            $t("Subscribers")
                        }}</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem">{{
                            $t("Subscribers_desc")
                        }}</span>
                    </div>
                </div>
                <br />
                <br />
            </div>

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1;
                "
                @click="dialogCreateFollower.visible = true"
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="treatedFollowers"
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column
                        sortable
                        prop="vFOLLOWER_EMAIL"
                        label="Email"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vOPERATIONS"
                        :label="$t('Operations')"
                    >
                    </el-table-column>
                    <el-table-column align="right">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    handleEditFollower(scope.$index, scope.row)
                                "
                                class="btn btn-link"
                            >
                                <i
                                    class="fas fa-pen"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                :cancel-button-text="$t('Cancel')"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="
                                    confirmDeleteFollower(
                                        scope.$index,
                                        scope.row
                                    )
                                "
                            >
                                <button class="btn btn-link" slot="reference">
                                    <i
                                        class="fas fa-trash"
                                        style="font-size: 1rem !important"
                                    ></i>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>

        <el-dialog
            :title="$t('Add_subscriber')"
            :visible.sync="dialogCreateFollower.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            @close="resetForm('form-add-follower')"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-add-follower"
                    :model="dialogCreateFollower.formFollower"
                    :rules="dialogCreateFollower.rulesFollower"
                >
                    <!--  -->
                    <el-form-item label="Email" prop="vFOLLOWER_EMAIL">
                        <el-input
                            :maxlength="100"
                            v-model="
                                dialogCreateFollower.formFollower
                                    .vFOLLOWER_EMAIL
                            "
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item :label="$t('Operations')" prop="vOPERATIONS">
                        <br />
                        <div class="row" v-if="smartElements.length > 0">
                            <div
                                class="col-6 text-left"
                                v-if="solosElements.length > 0"
                            >
                                <el-checkbox
                                    v-model="
                                        dialogCreateFollower.formFollower
                                            .vOPERATIONS_SOLO
                                    "
                                    >SmartCam</el-checkbox
                                >
                            </div>
                            <div
                                class="col-6 text-left"
                                v-if="dronesElements.length > 0"
                            >
                                <el-checkbox
                                    v-model="
                                        dialogCreateFollower.formFollower
                                            .vOPERATIONS_DRONE
                                    "
                                    >Drone</el-checkbox
                                >
                            </div>
                        </div>
                    </el-form-item>

                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogCreateFollower.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            :disabled="
                                !dialogCreateFollower.formFollower
                                    .vOPERATIONS_DRONE &&
                                !dialogCreateFollower.formFollower
                                    .vOPERATIONS_SOLO
                            "
                            @click="submitForm('form-add-follower')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>

        <el-dialog
            :title="$t('Edit_subscriber')"
            :visible.sync="dialogEditFollower.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            @close="resetForm('form-edit-follower')"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-edit-follower"
                    :model="dialogEditFollower.formFollower"
                    :rules="dialogEditFollower.rulesFollower"
                >
                    <!--  -->
                    <el-form-item label="Email" prop="vFOLLOWER_EMAIL">
                        <el-input
                            :maxlength="100"
                            v-model="
                                dialogEditFollower.formFollower.vFOLLOWER_EMAIL
                            "
                            disabled
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item :label="$t('Operations')" prop="vOPERATIONS">
                        <br />
                        <div class="row" v-if="smartElements.length > 0">
                            <div
                                class="col-6 text-left"
                                v-if="solosElements.length > 0"
                            >
                                <el-checkbox
                                    v-model="
                                        dialogEditFollower.formFollower
                                            .vOPERATIONS_SOLO
                                    "
                                    >SmartCam</el-checkbox
                                >
                            </div>
                            <div
                                class="col-6 text-left"
                                v-if="dronesElements.length > 0"
                            >
                                <el-checkbox
                                    v-model="
                                        dialogEditFollower.formFollower
                                            .vOPERATIONS_DRONE
                                    "
                                    >Drone</el-checkbox
                                >
                            </div>
                        </div>
                    </el-form-item>

                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogEditFollower.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="submitForm('form-edit-follower')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";

export default {
    data() {
        return {
            treatedFollowers: [],
            childTypesLabels: {
                main: "Administrador",
                drone: "Drone",
                solo: "SmartCam",
                subscriber: "Inscrito",
            },
            typeOptions: [
                { label: "Drone", value: "drone" },
                { label: "SmartCam", value: "solo" },
            ],
            dialogEditFollower: {
                visible: false,
                followerEmail: "",
                //
                formFollower: {
                    vFOLLOWER_EMAIL: "",
                    vOPERATIONS_SOLO: false,
                    vOPERATIONS_DRONE: false,
                },
                rulesFollower: {
                    vFOLLOWER_EMAIL: [
                        {
                            required: true,
                            message: "Insira um email",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 100,
                            message:
                                "O email deve ter entre 3 e 100 letras/números",
                            trigger: "change",
                        },
                    ],
                },
                //
            },
            dialogCreateFollower: {
                visible: false,
                //
                formFollower: {
                    vFOLLOWER_EMAIL: "",
                    vOPERATIONS_SOLO: false,
                    vOPERATIONS_DRONE: false,
                },
                rulesFollower: {
                    vFOLLOWER_EMAIL: [
                        {
                            required: true,
                            message: "Insira um email",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 100,
                            message:
                                "O email deve ter entre 3 e 100 letras/números",
                            trigger: "change",
                        },
                    ],
                },
                //
            },
        };
    },
    computed: {
        ...mapGetters([
            "user",
            "followersElements",
            "smartElements",
            "solosElements",
            "dronesElements",
        ]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
    },
    methods: {
        ...mapActions(["initializeFollowers", "setNavForcedLoading"]),
        submitForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (formName.includes("add")) {
                        self.confirmAddFollower();
                    } else if (formName.includes("edit")) {
                        self.confirmEditFollower();
                    }
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        getFollowersElements() {
            let self = this;
            this.treatedFollowers = [];
            let mailingList = this.followersElements
                .filter((v) =>
                    self.smartElements.map((e) => e.vUID).includes(v.vCHILD_UID)
                )
                .map(function (v) {
                    return v;
                })
                .map((v) => v.mailingList)
                .reduce((a, b) => a.concat(b), []);

            let array = this.followersElements
                .filter((v) =>
                    self.smartElements.map((e) => e.vUID).includes(v.vCHILD_UID)
                )
                .map(function (v) {
                    return v;
                });

            for (let m of mailingList) {
                let obj = {
                    vFOLLOWER_EMAIL: m,
                    vOPERATIONS: [],
                    vCHILDREN_LIST: [],
                };
                for (let a of array) {
                    if (a.mailingList.includes(m)) {
                        obj.vCHILDREN_LIST.push(a.vCHILD_UID);
                        obj.vOPERATIONS.push(
                            self.smartElements[
                                self.smartElements
                                    .map((v) => v.vUID)
                                    .indexOf(a.vCHILD_UID)
                            ].internals.vCHILD_TYPE
                        );
                    }
                }
                if (
                    this.treatedFollowers
                        .map((v) => v.vFOLLOWER_EMAIL)
                        .includes(obj.vFOLLOWER_EMAIL)
                )
                    continue;
                this.treatedFollowers.push({
                    vFOLLOWER_EMAIL: obj.vFOLLOWER_EMAIL,
                    vOPERATIONS: obj.vOPERATIONS
                        .map(
                            (v) =>
                                self.typeOptions[
                                    self.typeOptions
                                        .map((v) => v.value)
                                        .indexOf(v)
                                ].label
                        )
                        .filter(
                            (value, index, self) =>
                                self.indexOf(value) === index
                        )
                        .reduce((a, b) => a + " / " + b),
                    vCHILDREN_LIST: obj.vCHILDREN_LIST.filter(
                        (value, index, self) => self.indexOf(value) === index
                    ),
                });
            }
        },
        handleEditFollower(i, r) {
            this.dialogEditFollower.formFollower.vFOLLOWER_EMAIL =
                r.vFOLLOWER_EMAIL;
            this.dialogEditFollower.formFollower.vOPERATIONS_SOLO =
                r.vOPERATIONS.toLowerCase().includes("smartcam");
            this.dialogEditFollower.formFollower.vOPERATIONS_DRONE =
                r.vOPERATIONS.toLowerCase().includes("drone");

            this.dialogEditFollower.visible = true;
        },
        confirmEditFollower() {
            let self = this;

            self.setNavForcedLoading(true);
            self.dialogEditFollower.visible = false;

            console.log({
                vFOLLOWER_EMAIL:
                    self.dialogEditFollower.formFollower.vFOLLOWER_EMAIL,
                vOPERATIONS_SOLO:
                    self.dialogEditFollower.formFollower.vOPERATIONS_SOLO,
                vOPERATIONS_DRONE:
                    self.dialogEditFollower.formFollower.vOPERATIONS_DRONE,
            });
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_EDIT_FOLLOWER")({
                            vTEAM_UID: self.user.data.uid,
                            payload: {
                                vFOLLOWER_EMAIL:
                                    self.dialogEditFollower.formFollower
                                        .vFOLLOWER_EMAIL,
                                vOPERATIONS_SOLO:
                                    self.dialogEditFollower.formFollower
                                        .vOPERATIONS_SOLO,
                                vOPERATIONS_DRONE:
                                    self.dialogEditFollower.formFollower
                                        .vOPERATIONS_DRONE,
                            },
                            idToken: idToken,
                            command: "edit-follower",
                        })
                        .then(function (result) {
                            self.resetForm("form-edit-follower");
                            self.setNavForcedLoading(false);
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.initializeFollowers();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmAddFollower() {
            let self = this;

            self.setNavForcedLoading(true);
            self.dialogCreateFollower.visible = false;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_ADD_FOLLOWER")({
                            vTEAM_UID: self.user.data.uid,
                            payload: {
                                vFOLLOWER_EMAIL:
                                    self.dialogCreateFollower.formFollower
                                        .vFOLLOWER_EMAIL,
                                vOPERATIONS_SOLO:
                                    self.dialogCreateFollower.formFollower
                                        .vOPERATIONS_SOLO,
                                vOPERATIONS_DRONE:
                                    self.dialogCreateFollower.formFollower
                                        .vOPERATIONS_DRONE,
                            },
                            idToken: idToken,
                            command: "add-follower",
                        })
                        .then(function (result) {
                            self.resetForm("form-add-follower");
                            self.setNavForcedLoading(false);
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.initializeFollowers();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmDeleteFollower(i, r) {
            let self = this;

            self.setNavForcedLoading(true);
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_DELETE_FOLLOWER")({
                            vTEAM_UID: self.user.data.uid,
                            payload: {
                                vFOLLOWER_EMAIL: r.vFOLLOWER_EMAIL,
                            },
                            idToken: idToken,
                            command: "delete-follower",
                        })
                        .then(function (result) {
                            self.setNavForcedLoading(false);
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.initializeFollowers();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
    },
    watch: {
        followersElements(newVal) {
            this.getFollowersElements();
        },
    },
    mounted() {
        // this.initializeFollowers();
        this.getFollowersElements();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>