var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(
        !_vm.dronesInitialized ||
        !_vm.solosInitialized ||
        _vm.smartElements.map(function (v) { return v.internals; }).filter(function (v) { return !v; }).length >
            0 ||
        _vm.logReviewsStatus === 'waiting' ||
        _vm.dronesReportStatus === 'waiting' ||
        _vm.solosReportStatus === 'waiting' ||
        _vm.navState.forcedLoading ||
        !_vm.user.loggedIn
    ),expression:"\n        !dronesInitialized ||\n        !solosInitialized ||\n        smartElements.map((v) => v.internals).filter((v) => !v).length >\n            0 ||\n        logReviewsStatus === 'waiting' ||\n        dronesReportStatus === 'waiting' ||\n        solosReportStatus === 'waiting' ||\n        navState.forcedLoading ||\n        !user.loggedIn\n    "}],staticClass:"main-dashboard",staticStyle:{"height":"100vh"},attrs:{"element-loading-text":_vm.$t('Message_loading') + '...',"element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)","customClass":"loading-custom-class"}},[_c('app-navbar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navState.streamFocused),expression:"!navState.streamFocused"}]}),_c('app-drone-left-container',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.navState.streamFocused &&
            _vm.navState.mainNav === 'drone' &&
            _vm.navState.topBar !== 3
        ),expression:"\n            !navState.streamFocused &&\n            navState.mainNav === 'drone' &&\n            navState.topBar !== 3\n        "}]}),(_vm.navState.mainNav === 'drone' && _vm.navState.topBar === 3)?_c('app-drone-topbar'):_vm._e(),(_vm.navState.mainNav === 'drone' && _vm.navState.topBar === 3)?_c('app-drone-report'):_vm._e(),_c('app-solo-left-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.navState.streamFocused && _vm.navState.mainNav === 'solo'),expression:"!navState.streamFocused && navState.mainNav === 'solo'"}]}),(_vm.navState.mainNav === 'solo' && _vm.navState.topBar === 2)?_c('app-solo-topbar'):_vm._e(),(_vm.navState.mainNav === 'solo' && _vm.navState.topBar === 2)?_c('app-solo-report'):_vm._e(),(_vm.navState.mainNav === 'review')?_c('app-review-container'):_vm._e(),(_vm.navState.mainNav === 'setup')?_c('app-settings-container'):_vm._e(),(
            ((_vm.navState.mainNav === 'drone' && _vm.navState.topBar !== 3) ||
                (_vm.navState.mainNav === 'solo' && _vm.navState.topBar !== 2)) &&
            _vm.user.loggedIn
        )?_c('div',{class:{
            'main-gmaps': !_vm.navState.streamFocused,
            'main-gmaps-when-stream-focused': _vm.navState.streamFocused,
        }},[_c('app-gmaps')],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.navState.mainNav === 'drone' &&
            _vm.navState.topBar === 2 &&
            _vm.droneActiveMission
        ),expression:"\n            navState.mainNav === 'drone' &&\n            navState.topBar === 2 &&\n            droneActiveMission\n        "}],staticClass:"main-drone-mission-card-root"},[_c('app-drone-mission-card')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.navState.mainNav === 'drone' &&
            _vm.navState.topBar === 1 &&
            _vm.droneActive
        ),expression:"\n            navState.mainNav === 'drone' &&\n            navState.topBar === 1 &&\n            droneActive\n        "}],staticClass:"main-drone-mission-commands-root",staticStyle:{"z-index":"200"}},[_c('app-drone-mission-commands')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.navState.mainNav === 'solo' &&
            _vm.navState.topBar === 1 &&
            _vm.soloActive
        ),expression:"\n            navState.mainNav === 'solo' &&\n            navState.topBar === 1 &&\n            soloActive\n        "}],staticClass:"main-solo-commands-root",staticStyle:{"z-index":"200"}},[_c('app-solo-commands')],1),_vm._l((_vm.dronesElements),function(drone,index){return _c('div',{key:'drone-stream-card-' + index},[(
                _vm.navState.mainNav === 'drone' &&
                _vm.navState.topBar === 1 &&
                _vm.droneActive === drone.vUID &&
                drone.stream.vIS_STREAM_RESTARTED !== false &&
                drone.stream.vIS_RTMP_STREAM !== true
            )?_c('div',{class:{
                'main-dashboard-stream-card': !_vm.navState.streamFocused,
                'main-dashboard-stream-card-when-stream-focused':
                    _vm.navState.streamFocused,
            }},[_c('app-drone-stream-card',{attrs:{"drone":drone}})],1):_vm._e(),(
                _vm.navState.mainNav === 'drone' &&
                _vm.navState.topBar === 1 &&
                _vm.droneActive === drone.vUID &&
                drone.stream.vIS_STREAM_RESTARTED !== false &&
                drone.stream.vIS_RTMP_STREAM === true
            )?_c('div',{class:{
                'main-dashboard-stream-card': !_vm.navState.streamFocused,
                'main-dashboard-stream-card-when-stream-focused':
                    _vm.navState.streamFocused,
            }},[_c('app-solo-stream-card-rtmp',{attrs:{"drone":drone}})],1):_vm._e(),(
                _vm.navState.mainNav === 'drone' &&
                _vm.navState.topBar === 1 &&
                _vm.droneActive === drone.vUID
            )?_c('div',{class:{
                'main-drone-pilot-controls-root': !_vm.navState.streamFocused,
                'main-drone-pilot-controls-root-when-stream-focused':
                    _vm.navState.streamFocused,
            },staticStyle:{"z-index":"200"}},[_c('app-drone-pilot-controls',{attrs:{"drone":drone}})],1):_vm._e(),(
                _vm.navState.mainNav === 'drone' &&
                _vm.navState.topBar === 1 &&
                _vm.droneActive === drone.vUID
            )?_c('div',{class:{
                'main-drone-stream-telemetry-root': !_vm.navState.streamFocused,
                'main-drone-stream-telemetry-root-when-stream-focused':
                    _vm.navState.streamFocused,
            },staticStyle:{"z-index":"201"}},[_c('app-drone-stream-telemetry',{attrs:{"drone":drone}})],1):_vm._e(),(
                _vm.navState.mainNav === 'drone' &&
                _vm.navState.topBar === 1 &&
                _vm.droneActive === drone.vUID &&
                drone.vIS_CONNECTED
            )?_c('div',{class:{
                'main-drone-moisaic-button-root': !_vm.navState.streamFocused,
                'main-drone-moisaic-button-root-when-stream-focused':
                    _vm.navState.streamFocused,
            },staticStyle:{"z-index":"201"}},[_c('button',{staticClass:"btn btn-link",on:{"click":function($event){return _vm.handleDialogMosaic()}}},[_c('i',{staticClass:"fa fa-th-large"})])]):_vm._e()])}),_vm._l((_vm.solosElements),function(solo,index){return _c('div',{key:'solo-stream-card-' + index},[(
                _vm.navState.mainNav === 'solo' &&
                _vm.navState.topBar === 1 &&
                _vm.soloActive === solo.vUID &&
                solo.stream.vIS_STREAM_RESTARTED !== false
            )?_c('div',{class:{
                'main-dashboard-stream-card': !_vm.navState.streamFocused,
                'main-dashboard-stream-card-when-stream-focused':
                    _vm.navState.streamFocused,
            }},[_c('app-solo-stream-card',{attrs:{"solo":solo}})],1):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }