<template>
    <div class="main-solo-report-events">
        <app-single-bar-horizontal-chart
            :chart-data="datacollection"
            :style="style"
            :title="'Eventos detectados'"
            :show-all-tooltips="showAllTooltips"
        ></app-single-bar-horizontal-chart>
    </div>
</template>

<script>
import appSingleBarHorizontalChart from "@/components/charts/SingleBarHorizontalChart";

export default {
    data() {
        return {
            style: { height: "100%" },
        };
    },
    components: {
        appSingleBarHorizontalChart,
    },
    methods: {},
    props: ["datacollection", "showAllTooltips"],
    mounted() {},
};
</script>

<style>
.main-solo-report-events {
    height: 100%;
    position: relative;
}
</style>