<template>
    <div
        class="main-drone-mission-card bg-dark-high-gray"
        v-if="
            droneActiveMission &&
            (droneActiveMissionElement
                ? droneActiveMissionElement.waypoints
                : false)
        "
    >
        <div class="row">
            <div class="col-12">
                <h5 style="color: #a1a0a0">Dados da Missão</h5>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-12">
                <h6 style="color: white">
                    {{ droneActiveMissionElement.vMISSION_NAME }}
                </h6>
            </div>
        </div>
        <div class="row">
            <div class="col-6" style="color: #a1a0a0">Waypoints</div>
            <div class="col-6" style="color: #a1a0a0">Distância total</div>
        </div>
        <div class="row">
            <div class="col-6">
                {{ droneActiveMissionElement.waypoints.length }}
            </div>
            <div
                class="col-6"
                :class="{
                    'good-value-color': distanceTotal <= 10,
                    'bad-value-color': distanceTotal > 10,
                }"
            >
                {{ distanceTotal.toFixed(2) + " km" }}
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-6" style="color: #a1a0a0">Distância máx.</div>
            <div class="col-6" style="color: #a1a0a0">Altura máx.</div>
        </div>
        <div class="row">
            <div
                class="col-6"
                :class="{
                    'good-value-color': distanceMax <= 5,
                    'bad-value-color': distanceMax > 5,
                }"
            >
                {{ distanceMax.toFixed(2) + " km" }}
            </div>
            <div
                class="col-6"
                :class="{
                    'good-value-color': altitudeMax <= 300,
                    'bad-value-color': altitudeMax > 300,
                }"
            >
                {{ altitudeMax.toFixed(2) + " m" }}
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-12" style="color: #a1a0a0">Tempo estimado</div>
        </div>
        <div class="row">
            <div
                class="col-12"
                :class="{
                    'good-value-color': timeEstimatedInSeconds <= 30 * 60,
                    'bad-value-color': timeEstimatedInSeconds > 30 * 60,
                }"
            >
                {{ timeEstimated }}
            </div>
        </div>
        <br />
        <div class="chart-container" style="height: calc(25vh - 32px - 10px)">
            <app-drone-mission-card-elevation
                :datacollection="datacollection"
            ></app-drone-mission-card-elevation>
        </div>
        <!-- {{ droneActiveMissionElement }} -->
    </div>
</template>

<script>
import moment from "moment";
import momentformat from "moment-duration-format";
import { mapGetters } from "vuex";
import { gmapApi } from "vue2-google-maps";
import appDroneMissionCardElevation from "@/components/drone/containers/DroneMissionCardElevation";

export default {
    data() {
        return {
            elevations: [],
        };
    },
    components: { appDroneMissionCardElevation },
    computed: {
        google: gmapApi,
        ...mapGetters([
            "droneActiveMission",
            "droneActiveMissionElement",
            "droneActiveMissionUpdateTimestamp",
        ]),
        altitudeMax() {
            return this.droneActiveMissionElement.waypoints
                .map((v) => v.vALTITUDE)
                .reduce(function (a, b) {
                    return Math.max(a, b);
                }, 0);
        },
        distanceTotal() {
            return this.distanceInKm(
                this.droneActiveMissionElement.vEND_ACTION === "no_action"
                    ? this.droneActiveMissionElement.waypoints.map(function (
                          v
                      ) {
                          return {
                              lat: v.vLATITUDE,
                              lng: v.vLONGITUDE,
                          };
                      })
                    : this.droneActiveMissionElement.waypoints
                          .map(function (v) {
                              return {
                                  lat: v.vLATITUDE,
                                  lng: v.vLONGITUDE,
                              };
                          })
                          .concat(
                              this.droneActiveMissionElement.waypoints.map(
                                  function (v) {
                                      return {
                                          lat: v.vLATITUDE,
                                          lng: v.vLONGITUDE,
                                      };
                                  }
                              )[0]
                          ) || 0
            );
        },
        distanceMax() {
            if (this.droneActiveMissionElement.waypoints.length > 1) {
                let dist = -Infinity;
                let w0 = this.droneActiveMissionElement.waypoints[0];
                for (let w of this.droneActiveMissionElement.waypoints) {
                    let value = this.distanceInKm([
                        {
                            lat: w.vLATITUDE,
                            lng: w.vLONGITUDE,
                        },
                        {
                            lat: w0.vLATITUDE,
                            lng: w0.vLONGITUDE,
                        },
                    ]);
                    if (value > dist) {
                        dist = value;
                    }
                }
                return dist;
            } else {
                return 0.0;
            }
        },
        timeEstimatedInSeconds() {
            return Math.round(
                (this.distanceTotal * 1000) /
                    this.droneActiveMissionElement.vSPEED
            );
        },
        timeEstimated() {
            let duration = this.timeEstimatedInSeconds;
            let h = Math.floor(duration / 3600);
            let m = Math.floor((duration - h * 3600) / 60);
            let s = Math.floor(duration - h * 3600 - m * 60);
            return (
                (h < 10 ? ("0" + h).slice(-2) : h) +
                ":" +
                ("0" + m).slice(-2) +
                ":" +
                ("0" + s).slice(-2)
            );
        },
        droneActiveMissionElementRelativeWaypointsElevations() {
            if (
                (this.droneActiveMissionElement
                    ? this.droneActiveMissionElement.waypoints
                    : []
                ).length >= 2
            ) {
                let labels = this.elevations.map(
                    (v) =>
                        this.distanceTotal *
                        (this.elevations.indexOf(v) / this.elevations.length)
                );
                let relativeWaypointsElevations = this.elevations.map(
                    (v) => null
                );

                if (
                    this.droneActiveMissionElement.vEND_ACTION === "no_action"
                ) {
                    let w0 = this.droneActiveMissionElement.waypoints[0];
                    let positions = [
                        {
                            lat: w0.vLATITUDE,
                            lng: w0.vLONGITUDE,
                        },
                    ];
                    for (let w of this.droneActiveMissionElement.waypoints) {
                        positions.push({
                            lat: w.vLATITUDE,
                            lng: w.vLONGITUDE,
                        });
                        let value = this.distanceInKm(positions);
                        let closestValue = labels.reduce((a, b) => {
                            return Math.abs(b - value) < Math.abs(a - value)
                                ? b
                                : a;
                        }, 0);
                        let closestValueIndex = labels.indexOf(closestValue);
                        relativeWaypointsElevations[closestValueIndex] =
                            w.vALTITUDE;
                    }
                } else {
                    let w0 = this.droneActiveMissionElement.waypoints[0];
                    let positions = [
                        {
                            lat: w0.vLATITUDE,
                            lng: w0.vLONGITUDE,
                        },
                    ];
                    for (let w of this.droneActiveMissionElement.waypoints) {
                        positions.push({
                            lat: w.vLATITUDE,
                            lng: w.vLONGITUDE,
                        });
                        let value = this.distanceInKm(positions);
                        let closestValue = labels.reduce((a, b) => {
                            return Math.abs(b - value) < Math.abs(a - value)
                                ? b
                                : a;
                        }, 0);
                        let closestValueIndex = labels.indexOf(closestValue);
                        relativeWaypointsElevations[closestValueIndex] =
                            w.vALTITUDE;
                        if (
                            this.droneActiveMissionElement.waypoints.indexOf(
                                w
                            ) ===
                            this.droneActiveMissionElement.waypoints.length - 1
                        ) {
                            relativeWaypointsElevations[closestValueIndex + 1] =
                                this.droneActiveMissionElement.vALTITUDE_GO_HOME;
                            relativeWaypointsElevations[labels.length - 1] =
                                this.droneActiveMissionElement.vALTITUDE_GO_HOME;
                        }
                    }
                    for (
                        let i = 0;
                        i < relativeWaypointsElevations.length;
                        i++
                    ) {
                        relativeWaypointsElevations[i] === 0
                            ? (relativeWaypointsElevations[i] =
                                  relativeWaypointsElevations[i - 1])
                            : false;
                    }
                }
                return relativeWaypointsElevations;
            } else {
                return [];
            }
        },
        datacollection() {
            return {
                datasets: [
                    {
                        label: "Relevo",
                        backgroundColor: "#a1a0a0",
                        borderColor: "#a1a0a0",
                        pointRadius: 0,
                        type: "line",
                        // tension: 0,
                        fill: false,
                        data: this.elevations,
                    },
                    {
                        label: "Waypoints",
                        backgroundColor: "#a5d6a7",
                        borderColor: "#a5d6a7",
                        pointRadius: 2.5,
                        type: "line",
                        tension: 0,
                        // steppedLine: true,
                        fill: false,
                        data: this
                            .droneActiveMissionElementRelativeWaypointsElevations,
                    },
                ],
                labels: this.elevations.map(
                    (v) =>
                        (
                            this.distanceTotal *
                            (this.elevations.indexOf(v) /
                                this.elevations.length)
                        ).toFixed(1) + " km"
                ),
            };
        },
    },
    watch: {
        droneActiveMission: function (newVal, oldVal) {
            if (newVal) {
                this.getElevationAlongPath();
            } else {
                this.elevations = [];
            }
        },
        "droneActiveMissionElement.waypoints": function (newVal, oldVal) {
            if (this.droneActiveMission && newVal.length >= 2) {
                this.getElevationAlongPath();
            } else {
                this.elevations = [];
            }
        },
        droneActiveMissionUpdateTimestamp: function (newVal, oldVal) {
            if (this.droneActiveMission) {
                if (
                    (this.droneActiveMissionElement
                        ? this.droneActiveMissionElement.waypoints
                        : []
                    ).length >= 2
                ) {
                    this.getElevationAlongPath();
                } else {
                    this.elevations = [];
                }
            } else {
                this.elevations = [];
            }
        },
        "droneActiveMissionElement.vEND_ACTION": function (newVal, oldVal) {
            if (this.droneActiveMission) {
                if (
                    (this.droneActiveMissionElement
                        ? this.droneActiveMissionElement.waypoints
                        : []
                    ).length >= 2
                ) {
                    this.getElevationAlongPath();
                } else {
                    this.elevations = [];
                }
            } else {
                this.elevations = [];
            }
        },
    },
    methods: {
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        getElevationAlongPath() {
            let self = this;

            if (
                (this.droneActiveMissionElement
                    ? this.droneActiveMissionElement.waypoints
                    : []
                ).length >= 2
            ) {
                const elevator = new this.google.maps.ElevationService();
                // console.log("getElevationAlongPath");
                elevator.getElevationAlongPath(
                    {
                        path:
                            self.droneActiveMissionElement.vEND_ACTION ===
                            "no_action"
                                ? self.droneActiveMissionElement.waypoints.map(
                                      function (v) {
                                          return {
                                              lat: v.vLATITUDE,
                                              lng: v.vLONGITUDE,
                                          };
                                      }
                                  )
                                : self.droneActiveMissionElement.waypoints
                                      .map(function (v) {
                                          return {
                                              lat: v.vLATITUDE,
                                              lng: v.vLONGITUDE,
                                          };
                                      })
                                      .concat(
                                          self.droneActiveMissionElement.waypoints.map(
                                              function (v) {
                                                  return {
                                                      lat: v.vLATITUDE,
                                                      lng: v.vLONGITUDE,
                                                  };
                                              }
                                          )[0]
                                      ),
                        samples:
                            this.droneActiveMissionElement.waypoints.map(
                                function (v) {
                                    return {
                                        lat: v.vLATITUDE,
                                        lng: v.vLONGITUDE,
                                    };
                                }
                            ).length * 10,
                    },
                    (results, status) => {
                        if (status === "OK") {
                            if (results[0]) {
                                self.elevations = results.map(
                                    (v) => v.elevation - results[0].elevation
                                );
                            } else {
                                self.elevations = [];
                            }
                        } else {
                            self.elevations = [];
                        }
                    }
                );
            } else {
                this.elevations = [];
            }
        },
    },
};
</script>

<style scoped>
.main-drone-mission-card {
    /* width: 100%; */
    /* height: 100%; */
    border-radius: 20px;
    padding: 20px;
    /* border: 1px solid #707070; */
}

.good-value-color {
    color: #a5d6a7;
}

.bad-value-color {
    color: #ef9a9a;
}

.chart-container {
    padding: 1rem;
    flex-grow: 1;
    min-height: 0;
}
</style>