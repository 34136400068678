<template>
    <div class="main-settings bg-dark-high-gray">
        <app-settings-left-container
            @setupMenuChange="handleSetupMenuChange"
        ></app-settings-left-container>
        <app-settings-right-container :setupMenu="setupMenu">
        </app-settings-right-container>
    </div>
</template>

<script>
import appSettingsLeftContainer from "@/components/settings/containers/SettingsLeftContainer";
import appSettingsRightContainer from "@/components/settings/containers/SettingsRightContainer";

export default {
    data() {
        return {
            setupMenu: "account-registration-data",
        };
    },
    components: {
        appSettingsLeftContainer,
        appSettingsRightContainer,
    },
    methods: {
        handleSetupMenuChange(e) {
            this.setupMenu = e;
        },
    },
};
</script>


<style scoped>
.main-settings {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100vw;
    height: calc(100vh - 50px);
}
</style>