<template>
    <div>
        <!--  -->
        <div
            v-if="navState.topBar === 1"
            class="main-drone-left-container bg-dark-high-gray"
        >
            <el-input
                :placeholder="$t('Search')"
                v-model="searchDrone"
                text-color="red"
                :disabled="droneActive !== null"
                style="font-size: 1rem"
            >
                <i
                    class="fas fa-search el-input__icon"
                    style="font-size: 1rem"
                    slot="suffix"
                >
                </i>
            </el-input>
            <div>
                <app-drone
                    v-for="(drone, index) in dronesElements
                        .filter((v) => v.vIS_CONNECTED)
                        .sort((a, b) =>
                            a.internals.vCHILD_NAME.toUpperCase() >
                            b.internals.vCHILD_NAME.toUpperCase()
                                ? 1
                                : -1
                        )"
                    :key="'drone-element-connected-' + index"
                    :drone="drone"
                    v-show="
                        drone.internals.vCHILD_NAME
                            .toUpperCase()
                            .includes(searchDrone.toUpperCase())
                    "
                ></app-drone>

                <app-drone
                    v-for="(drone, index) in dronesElements
                        .filter((v) => !v.vIS_CONNECTED)
                        .sort((a, b) =>
                            a.internals.vCHILD_NAME.toUpperCase() >
                            b.internals.vCHILD_NAME.toUpperCase()
                                ? 1
                                : -1
                        )"
                    :key="'drone-element-disconnected-' + index"
                    :drone="drone"
                    v-show="
                        drone.internals.vCHILD_NAME
                            .toUpperCase()
                            .includes(searchDrone.toUpperCase())
                    "
                ></app-drone>
            </div>
        </div>
        <!--  -->
        <div
            v-if="navState.topBar === 2"
            class="main-drone-left-container bg-dark-high-gray"
        >
            <el-input
                :placeholder="$t('Search')"
                v-model="searchDroneMission"
                text-color="red"
                :disabled="droneActiveMission !== null"
                style="font-size: 1rem"
            >
                <i
                    class="fas fa-search el-input__icon"
                    style="font-size: 1rem"
                    slot="suffix"
                >
                </i>
            </el-input>
            <div class="row my-2">
                <div class="col-12 px-4">
                    <button
                        class="btn w-100 btn-bold"
                        :class="{
                            'btn-primary': !droneActiveMission,
                            'btn-secondary': droneActiveMission,
                        }"
                        :disabled="droneActiveMission"
                        @click="createDroneMission()"
                        v-if="user.data.realInternals.vCHILD_TYPE === 'main'"
                    >
                        {{ $t("Create_new_mission") }}
                    </button>
                </div>
            </div>
            <div>
                <app-drone-mission
                    v-for="(mission, index) in dronesMissions"
                    v-show="
                        mission.vMISSION_NAME
                            .toUpperCase()
                            .includes(searchDroneMission.toUpperCase())
                    "
                    :key="index"
                    :mission="mission"
                ></app-drone-mission>
            </div>
        </div>
    </div>
</template>


<script>
import appDrone from "@/components/drone/Drone";
import appDroneMission from "@/components/drone/DroneMission";
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            searchDrone: "",
            searchDroneMission: "",
            checkTimestamp: null,
        };
    },
    computed: {
        ...mapGetters([
            "user",
            "navState",
            "droneActive",
            "dronesMissions",
            "droneActiveMission",
            "droneActiveMissionElement",
            "dronesElements",
        ]),
        orderedDronesElements() {
            return this.dronesElements.filter((v) => !v.vIS_CONNECTED);
        },
    },
    methods: {
        ...mapActions([
            "initializeDronesMissions",
            "setDroneActiveMission",
            "updateDroneIsConnected",
        ]),
        createDroneMission() {
            let m = {
                vALTITUDE: 30,
                vALTITUDE_GO_HOME: 50,
                vCHILD_TYPE: "drone",
                vEND_ACTION: "no_action",
                vGIMBAL_MANUAL_ROTATION: true,
                vHEADING_MODE: "auto",
                // vMISSION_KEY: "632jm3q4k2zb4q6hjbchkzfynz4ghg",
                vMISSION_NAME: `Missão ${this.dronesMissions.length + 1}`,
                vSPEED: 12,
                vTOTAL_TIME: 0,
                waypoints: [],
            };

            let self = this;

            firebase
                .database()
                .ref("aegisv2/users/" + this.user.data.uid + "/missions/")
                .push(m)
                .then((data) => {
                    firebase
                        .database()
                        .ref(
                            "aegisv2/users/" +
                                this.user.data.uid +
                                "/missions/" +
                                data.key +
                                "/vMISSION_KEY"
                        )
                        .set(data.key)
                        .then(() => {
                            self.$message({
                                message: `Nova missão criada`,
                                type: "success",
                                showClose: true,
                                offset: 60,
                            });

                            self.initializeDronesMissions(data.key);
                        });
                });
        },
    },
    components: {
        appDrone,
        appDroneMission,
    },
    watch: {
        droneActive: function (newVal, oldVal) {
            this.searchDrone = "";
        },
        droneActiveMission: function (newVal, oldVal) {
            this.searchDroneMission = "";
        },
    },
    mounted() {
        let self = this;
        this.checkTimestamp = setInterval(function () {
            for (let d of self.dronesElements.filter(
                (v) => v.vWEBRTC_BITRATE_TIMESTAMP !== 0
            )) {
                if (Date.now() - d.vWEBRTC_BITRATE_TIMESTAMP < 10000) {
                    self.updateDroneIsConnected({
                        uid: d.vUID,
                        isConnected: true,
                    });
                } else {
                    self.updateDroneIsConnected({
                        uid: d.vUID,
                        isConnected: false,
                    });
                }
            }
        }, 2000);
    },
};
</script>


<style scoped>
.main-drone-left-container {
    padding: 5px;
    position: absolute;
    top: 50px;
    left: 0px;
    width: 350px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
}
</style>