<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">Gestão de Times</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para criar, deletar ou editar contas
                            de times.</span
                        >
                    </div>
                </div>
                <br />
                <br />
            </div>

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1;
                "
                @click="dialogCreateTeam.visible = true"
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="
                        treatedTeams.filter(
                            (data) =>
                                !search ||
                                data.vTEAM_NAME
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vTEAM_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_EMAIL
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCOMPANY_NAME
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                    "
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column sortable prop="vTEAM_NAME" label="Nome">
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLAST_LOGIN"
                        label="Último login"
                        :sort-method="sortByLastLogin"
                        ><template slot-scope="scope"
                            >{{
                                msToDDMMYYYYHHmmssAndPhrase(
                                    scope.row.vLAST_LOGIN
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vTEAM_UID" label="UID">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vTEAM_UID,
                                        'ID copiado para a área de transferência',
                                        'Não foi possível copiar o ID para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vTEAM_UID }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vCHILD_EMAIL" label="Email">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vCHILD_EMAIL,
                                        'Email copiado para a área de transferência',
                                        'Não foi possível copiar o Email para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vCHILD_EMAIL }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vCOMPANY_NAME"
                        label="Empresa"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_DRONES"
                        label="Drones"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_SOLOS"
                        label="SmartCam"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_SUBSCRIBERS"
                        label="Inscritos"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_LICENSES_KEYS"
                        label="Licenças"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLINK_EXPIRES_IN"
                        label="Expiração Link"
                        :sort-method="sortByExpirationDate"
                    >
                        <template slot-scope="scope"
                            >{{
                                scope.row.vLINK_EXPIRES_IN !== 0
                                    ? msToDDMMYYYYHHmmssAndPhrase(
                                          scope.row.vLINK_EXPIRES_IN
                                      ).split(" ")[0]
                                    : "-"
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLINK_NUMBER_OF_CHANNELS"
                        label="Canais Link"
                    >
                    </el-table-column>
                    <el-table-column align="right">
                        <template slot="header" slot-scope="scope">
                            <el-input
                                v-model="search"
                                size="mini"
                                placeholder="Procurar"
                                style="border: 1px solid #a1a0a0"
                                :id="scope"
                                clearable
                            >
                                > ></el-input
                            >
                        </template>
                        <template slot-scope="scope">
                            <button
                                @click="handleEdit(scope.$index, scope.row)"
                                class="btn btn-link"
                                disabled
                            >
                                <i
                                    class="fas fa-pen"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                :cancel-button-text="$t('Cancel')"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="
                                    confirmDeleteTeam(scope.$index, scope.row)
                                "
                            >
                                <button class="btn btn-link" slot="reference">
                                    <i
                                        class="fas fa-trash"
                                        style="font-size: 1rem !important"
                                    ></i>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>

        <!-- dialog create team-->
        <el-dialog
            title="Adicionar time"
            :visible.sync="dialogCreateTeam.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-create-team"
                    :model="dialogCreateTeam.form"
                    :rules="dialogCreateTeam.rules"
                >
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                label="Nome do administrador"
                                prop="vCHILD_NAME"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="dialogCreateTeam.form.vCHILD_NAME"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                label="Nome do time"
                                prop="vTEAM_NAME"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="dialogCreateTeam.form.vTEAM_NAME"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <el-form-item label="Email" prop="vCHILD_EMAIL">
                        <el-input
                            :maxlength="100"
                            v-model="dialogCreateTeam.form.vCHILD_EMAIL"
                            class="dark-input"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item label="Senha" prop="vCHILD_PASSWORD">
                        <el-input
                            :maxlength="20"
                            v-model="dialogCreateTeam.form.vCHILD_PASSWORD"
                            class="dark-input"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <hr />
                    <div class="row">
                        <div class="col-12">Link</div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                label="Número de canais"
                                prop="vNUMBER_OF_CHILDREN"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogCreateTeam.form
                                            .vNUMBER_OF_CHANNELS
                                    "
                                    controls-position="right"
                                    :min="0"
                                    :max="100"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Expires_at')"
                                prop="vEXPIRES_IN"
                            >
                                <el-date-picker
                                    v-model="dialogCreateTeam.form.vEXPIRES_IN"
                                    type="date"
                                    placeholder="-"
                                    text-color="red"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value-format="timestamp"
                                    style="width: 100%"
                                    class="text-center"
                                    :align="'center'"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                    <hr />
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogCreateTeam.form.vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="dialogCreateTeam.form.vCHILD_ID"
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item label="Empresa" prop="vCOMPANY_NAME">
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogCreateTeam.form.vCOMPANY_NAME
                                    "
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item label="CNPJ" prop="vCOMPANY_ID">
                                <el-input
                                    v-model="dialogCreateTeam.form.vCOMPANY_ID"
                                    v-mask="masks.vCOMPANY_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogCreateTeam.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="submitCreateUserForm('form-create-team')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!-- dialog edit team-->
        <el-dialog
            title="Editar time"
            :visible.sync="dialogEditTeam.visible"
            style=""
            width="570px"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-edit-user-drone"
                    :model="dialogEditTeam.form"
                    :rules="dialogEditTeam.rules"
                >
                    <div class="row">
                        <div class="col-6">
                            <el-form-item label="Nome" prop="vCHILD_NAME">
                                <el-input
                                    :maxlength="20"
                                    v-model="dialogEditTeam.form.vCHILD_NAME"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="dialogEditTeam.form.vCHILD_CONTACT"
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <el-form-item label="ID do Time" prop="vTEAM_UID">
                        <el-input
                            disabled
                            readonly
                            v-model="dialogEditTeam.form.vTEAM_UID"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="dialogEditTeam.form.vCHILD_ID"
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Expected_daily_missions')"
                                prop="vEXPECTED_DAILY_MISSIONS"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogEditTeam.form
                                            .vEXPECTED_DAILY_MISSIONS
                                    "
                                    controls-position="right"
                                    :min="1"
                                    :max="100"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="text-left">
                        <el-checkbox
                            :label="
                                dialogEditTeam.form
                                    .vIS_AVAILABLE_FOR_MISSION === false
                                    ? 'Indisponível para missões'
                                    : 'Disponível para missões'
                            "
                            v-model="
                                dialogEditTeam.form.vIS_AVAILABLE_FOR_MISSION
                            "
                        ></el-checkbox>
                    </div>
                    <!--  -->
                    <br />
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogEditTeam.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="submitEditUserForm('form-edit-team')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            masks: {
                vCHILD_ID: {
                    mask: "###.###.###-##",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
                vCHILD_CONTACT: {
                    mask: "(##)#####-####",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
                vCOMPANY_ID: {
                    mask: "##.###.###/####-##",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
            },
            treatedTeams: [],
            dialogCreateTeam: {
                visible: false,
                userType: "main",
                userTypeOptions: [{ label: "Administrador", value: "main" }],
                //
                form: {
                    vCHILD_CONTACT: "",
                    vCHILD_EMAIL: "",
                    vCHILD_PASSWORD: "",
                    vCHILD_ID: "",
                    vCHILD_NAME: "",
                    vCOMPANY_ID: "",
                    vCOMPANY_NAME: "",
                    vLAST_LOGIN: 0,
                    vTEAM_NAME: "",
                    vEXPIRES_IN: Date.now() + 365 * 24 * 60 * 60 * 1000,
                    vNUMBER_OF_CHANNELS: 0,
                },
                rules: {
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vCOMPANY_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vTEAM_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCOMPANY_ID: [
                        {
                            required: true,
                            message: "Defina um CNPJ",
                            trigger: "change",
                        },
                        {
                            min: 18,
                            max: 18,
                            message:
                                "O CNPJ deve respeitar o formato XX.XXX.XXX/XXXX-XX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_EMAIL: [
                        {
                            required: true,
                            message: "Defina um email",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 100,
                            message: "O email deve ter entre 3 e 100 dígitos",
                            trigger: "change",
                        },
                    ],
                    vCHILD_PASSWORD: [
                        {
                            required: true,
                            message: "Defina uma senha",
                            trigger: "change",
                        },
                        {
                            min: 6,
                            max: 20,
                            message: "A senha deve ter entre 6 e 20 dígitos",
                            trigger: "change",
                        },
                    ],
                },
            },
            dialogEditTeam: {
                visible: false,
                userUid: null,
                userType: null,
                form: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "",
                    vCHILD_ID: "",
                    vEXPECTED_DAILY_MISSIONS: 1,
                    vTEAM_UID: "",
                    vIS_AVAILABLE_FOR_MISSION: null,
                    operators: [],
                },
                rules: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vEXPECTED_DAILY_MISSIONS: [
                        {
                            required: true,
                            message:
                                "Defina a quantidade de missões diárias previstas",
                            trigger: "change",
                        },
                    ],
                    vTEAM_UID: [
                        {
                            required: true,
                            message: "Defina o ID do time",
                            trigger: "change",
                        },
                    ],
                },
            },
            search: "",
        };
    },
    computed: {
        ...mapGetters(["user"]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
    },
    methods: {
        sortByLastLogin(a, b) {
            let a_ms = isNaN(a.vLAST_LOGIN) ? 0 : a.vLAST_LOGIN;
            let b_ms = isNaN(b.vLAST_LOGIN) ? 0 : b.vLAST_LOGIN;
            return a_ms > b_ms ? -1 : 1;
        },
        sortByExpirationDate(a, b) {
            let a_ms = isNaN(a.vLINK_EXPIRES_IN) ? 0 : a.vLINK_EXPIRES_IN;
            let b_ms = isNaN(b.vLINK_EXPIRES_IN) ? 0 : b.vLINK_EXPIRES_IN;
            return a_ms > b_ms ? -1 : 1;
        },
        copyToClipboard(text, messageSuccess, messageError) {
            let self = this;
            this.$copyText(text).then(
                () => {
                    self.$message({
                        message: messageSuccess,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                },
                () => {
                    self.$message({
                        message: messageError,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            );
        },
        msToDDMMYYYYHHmmssAndPhrase(ms) {
            if (!isNaN(ms)) {
                if (ms !== 0) {
                    if (Date.now() - ms < 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / 1000
                        )} segundo${
                            Math.floor((Date.now() - ms) / 1000) > 1 ? "s" : ""
                        })`;
                    } else if (Date.now() - ms < 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / (60 * 1000)
                        )} minuto${
                            Math.floor((Date.now() - ms) / (60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else if (Date.now() - ms < 72 * 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (60 * 60 * 1000)
                        )} hora${
                            Math.floor((Date.now() - ms) / (60 * 60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (24 * 60 * 60 * 1000)
                        )} dia${
                            Math.floor(
                                (Date.now() - ms) / (24 * 60 * 60 * 1000)
                            ) > 1
                                ? "s"
                                : ""
                        })`;
                    }
                } else {
                    return "-";
                }
            } else {
                return "-";
            }
        },
        loadData() {
            this.treatedTeams = [];
            let self = this;

            firebase
                .database()
                .ref("aegisv2/teams/")
                .orderByChild("vTEAM_UID")
                .once("value", function (snapshot) {
                    snapshot.forEach(function (childSnapshot) {
                        //
                        firebase
                            .database()
                            .ref(
                                "aegisv2/users/" +
                                    childSnapshot.child("vTEAM_UID").val() +
                                    "/internals"
                            )
                            .once("value", function (data) {
                                let obj = {};
                                obj.vTEAM_UID = childSnapshot
                                    .child("vTEAM_UID")
                                    .val();

                                let parameters = [
                                    "vTEAM_NAME",
                                    "vCHILD_CONTACT",
                                    "vCHILD_EMAIL",
                                    "vCOMPANY_NAME",
                                    "vLAST_LOGIN",
                                ];
                                for (let p of parameters) {
                                    obj[p] = data.child(p).val() || "-";
                                }

                                firebase
                                    .database()
                                    .ref(
                                        "aegisv2/users/" +
                                            childSnapshot
                                                .child("vTEAM_UID")
                                                .val() +
                                            "/elements"
                                    )
                                    .once("value", function (data) {
                                        obj.elements = Object.values(
                                            data.val() || {}
                                        );

                                        obj.vNUMBER_OF_DRONES =
                                            obj.elements.filter(
                                                (v) => v.vCHILD_TYPE === "drone"
                                            ).length;
                                        obj.vNUMBER_OF_SOLOS =
                                            obj.elements.filter(
                                                (v) => v.vCHILD_TYPE === "solo"
                                            ).length;
                                        obj.vNUMBER_OF_SUBSCRIBERS =
                                            obj.elements.filter(
                                                (v) =>
                                                    v.vCHILD_TYPE ===
                                                    "subscriber"
                                            ).length;
                                    })
                                    .then(() => {
                                        firebase
                                            .database()
                                            .ref(
                                                "aegisv2/users/" +
                                                    childSnapshot
                                                        .child("vTEAM_UID")
                                                        .val() +
                                                    "/link"
                                            )
                                            .once("value", function (dataLink) {
                                                obj.vLINK_EXPIRES_IN =
                                                    dataLink
                                                        .child("vEXPIRES_IN")
                                                        .val() || 0;
                                                obj.vLINK_NUMBER_OF_CHANNELS =
                                                    dataLink
                                                        .child(
                                                            "vNUMBER_OF_CHANNELS"
                                                        )
                                                        .val() || 0;
                                            })
                                            .then(() => {
                                                firebase
                                                    .database()
                                                    .ref("aegisv2/licenses/")
                                                    .orderByChild("vTEAM_UID")
                                                    .equalTo(
                                                        childSnapshot
                                                            .child("vTEAM_UID")
                                                            .val()
                                                    )
                                                    .once(
                                                        "value",
                                                        function (
                                                            dataLicenses
                                                        ) {
                                                            obj.vNUMBER_OF_LICENSES_KEYS =
                                                                dataLicenses.numChildren();
                                                            self.treatedTeams.push(
                                                                obj
                                                            );
                                                        }
                                                    );
                                            });
                                    });
                            });
                    });
                });
        },
        submitCreateUserForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    self.confirmCreateTeam(self.dialogCreateTeam.form);
                } else {
                    return false;
                }
            });
        },
        confirmCreateTeam(data) {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_CREATE_TEAM")({
                            payload: data,
                            command: `create-account-user-management-team`,
                            idToken: idToken,
                            vCREATOR_UID: self.user.data.uid,
                        })
                        .then(function (result) {
                            self.resetForm("form-create-team");
                            self.dialogCreateTeam.visible = false;

                            console.log(result.data);

                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    console.log(e);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        handleEdit(i, r) {
            console.log(i, r);
        },
        confirmDeleteTeam(i, r) {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_DELETE_TEAM")({
                            command: "delete-account-user-management-team",
                            vTEAM_UID: r.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
    beforeMount() {
        this.loadData();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>