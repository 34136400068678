<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">Gestão de Baterias</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para realizar a gestão das baterias
                            dos dispositivos.</span
                        >
                    </div>
                </div>
                <br />
                <br />
            </div>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="
                        treatedBatteries.filter(
                            (data) =>
                                !search ||
                                data.vCHILD_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vTEAM_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_EMAIL
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vBATTERY_SERIAL_NUMBER
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                    "
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column sortable prop="vCHILD_NAME" label="Nome">
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLAST_LOGIN"
                        label="Último login"
                        ><template slot-scope="scope"
                            >{{
                                msToDDMMYYYYHHmmssAndPhrase(
                                    scope.row.vLAST_LOGIN
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vBATTERY_LAST_CONNECTED"
                        label="Última descarga"
                        ><template slot-scope="scope"
                            >{{
                                msToDDMMYYYYHHmmssAndPhrase(
                                    scope.row.vBATTERY_LAST_CONNECTED
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vBATTERY_SERIAL_NUMBER"
                        label="Número serial"
                        ><template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vBATTERY_SERIAL_NUMBER,
                                        'ID copiado para a área de transferência',
                                        'Não foi possível copiar o ID para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i></button
                            >{{ scope.row.vBATTERY_SERIAL_NUMBER }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vFULL_CHARGE_CAPACITY"
                        label="Capacidade"
                        ><template slot-scope="scope"
                            >{{ scope.row.vFULL_CHARGE_CAPACITY }} /{{
                                scope.row.vFULL_DESIGN_CAPACITY
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLIFETIME_REMAINING"
                        label="Tempo de vida restante"
                        ><template slot-scope="scope"
                            >{{ scope.row.vLIFETIME_REMAINING }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_DISCHARGES"
                        label="Descargas"
                        ><template slot-scope="scope"
                            >{{ scope.row.vNUMBER_OF_DISCHARGES }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vCHILD_UID" label="UID">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vCHILD_UID,
                                        'ID copiado para a área de transferência',
                                        'Não foi possível copiar o ID para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vCHILD_UID }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vTEAM_UID"
                        label="ID do time"
                    >
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vTEAM_UID,
                                        'ID do time copiado para a área de transferência',
                                        'Não foi possível copiar o ID do time para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vTEAM_UID }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vCHILD_EMAIL" label="Email">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vCHILD_EMAIL,
                                        'Email copiado para a área de transferência',
                                        'Não foi possível copiar o Email para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vCHILD_EMAIL }}
                        </template>
                    </el-table-column>
                    <el-table-column align="right">
                        <template slot="header" slot-scope="scope">
                            <el-input
                                v-model="search"
                                size="mini"
                                placeholder="Procurar"
                                style="border: 1px solid #a1a0a0"
                                :id="scope"
                                clearable
                            >
                            </el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            treatedBatteries: [],
            search: "",
        };
    },
    computed: {
        ...mapGetters(["user"]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
    },
    methods: {
        copyToClipboard(text, messageSuccess, messageError) {
            let self = this;
            this.$copyText(text).then(
                () => {
                    self.$message({
                        message: messageSuccess,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                },
                () => {
                    self.$message({
                        message: messageError,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            );
        },
        msToDDMMYYYYHHmmssAndPhrase(ms) {
            if (!isNaN(ms)) {
                if (ms !== 0) {
                    if (Date.now() - ms < 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / 1000
                        )} segundo${
                            Math.floor((Date.now() - ms) / 1000) > 1 ? "s" : ""
                        })`;
                    } else if (Date.now() - ms < 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / (60 * 1000)
                        )} minuto${
                            Math.floor((Date.now() - ms) / (60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else if (Date.now() - ms < 72 * 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (60 * 60 * 1000)
                        )} hora${
                            Math.floor((Date.now() - ms) / (60 * 60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (24 * 60 * 60 * 1000)
                        )} dia${
                            Math.floor(
                                (Date.now() - ms) / (24 * 60 * 60 * 1000)
                            ) > 1
                                ? "s"
                                : ""
                        })`;
                    }
                } else {
                    return "-";
                }
            } else {
                return "-";
            }
        },
        loadData() {
            this.treatedBatteries = [];
            let self = this;

            firebase
                .database()
                .ref("aegisv2/drones/")
                .orderByChild("vCHILD_UID")
                .once("value", function (snapshot) {
                    snapshot.forEach(function (childSnapshot) {
                        firebase
                            .database()
                            .ref(
                                "aegisv2/users/" +
                                    childSnapshot.child("vCHILD_UID").val() +
                                    "/internals"
                            )
                            .once("value", async function (data) {
                                let obj = {};
                                obj.vCHILD_UID = childSnapshot
                                    .child("vCHILD_UID")
                                    .val();

                                let parameters = [
                                    "vCHILD_CONTACT",
                                    "vCHILD_EMAIL",
                                    "vCHILD_NAME",
                                    "vLAST_LOGIN",
                                    "vTEAM_UID",
                                ];

                                for (let p of parameters) {
                                    obj[p] = data.child(p).val();
                                }
                                try {
                                    let batteriesList = await firebase
                                        .database()
                                        .ref(
                                            `aegisv2/users/${childSnapshot
                                                .child("vCHILD_UID")
                                                .val()}/flight/state/battery`
                                        )
                                        .orderByChild("vBATTERY_LAST_CONNECTED")
                                        .startAt(
                                            Date.now() -
                                                30 * 24 * 60 * 60 * 1000
                                        )
                                        .once("value");
                                    obj["vBATTERIES_LIST"] = Object.values(
                                        batteriesList.val() || {}
                                    );
                                } catch (error) {
                                    console.log(error);
                                }

                                if (obj.vBATTERIES_LIST.length > 0) {
                                    for (
                                        let i = 0;
                                        i < obj.vBATTERIES_LIST.length;
                                        i++
                                    ) {
                                        if (obj.vTEAM_UID && obj.vCHILD_UID) {
                                            self.treatedBatteries.push({
                                                vTEAM_UID: obj.vTEAM_UID || "-",
                                                vLAST_LOGIN: obj.vLAST_LOGIN,
                                                vCHILD_UID:
                                                    obj.vCHILD_UID || "-",
                                                vCHILD_NAME:
                                                    obj.vCHILD_NAME || "-",
                                                vCHILD_EMAIL:
                                                    obj.vCHILD_EMAIL || "-",
                                                vCHILD_CONTACT:
                                                    obj.vCHILD_CONTACT || "-",
                                                vBATTERY_LAST_CONNECTED:
                                                    obj.vBATTERIES_LIST[i]
                                                        .vBATTERY_LAST_CONNECTED,
                                                vBATTERY_SERIAL_NUMBER:
                                                    obj.vBATTERIES_LIST[i]
                                                        .vBATTERY_SERIAL_NUMBER,
                                                vFULL_CHARGE_CAPACITY:
                                                    obj.vBATTERIES_LIST[i]
                                                        .vFULL_CHARGE_CAPACITY,
                                                vFULL_DESIGN_CAPACITY:
                                                    obj.vBATTERIES_LIST[i]
                                                        .vFULL_DESIGN_CAPACITY,
                                                vLIFETIME_REMAINING:
                                                    obj.vBATTERIES_LIST[i]
                                                        .vLIFETIME_REMAINING,
                                                vNUMBER_OF_DISCHARGES:
                                                    obj.vBATTERIES_LIST[i]
                                                        .vNUMBER_OF_DISCHARGES,
                                            });
                                        }
                                    }
                                } else {
                                    // self.treatedBatteries.push(obj);
                                }
                            });
                    });
                });
        },
    },
    beforeMount() {
        this.loadData();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>