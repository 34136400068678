<template>
    <div>
        <svg
            id="Camada_2"
            data-name="Camada 2"
            xmlns="http://www.w3.org/2000/svg"
            width="29.127"
            height="28.868"
            viewBox="0 0 29.127 28.868"
            v-if="!active"
        >
            <g id="Camada_1" data-name="Camada 1" transform="translate(0)">
                <path
                    id="Caminho_477"
                    data-name="Caminho 477"
                    d="M24.161,30.45a7.588,7.588,0,0,0-1.548-4.991,3.84,3.84,0,0,0-2.322-1.571,2.619,2.619,0,0,1-1.938-2.748,2.738,2.738,0,0,1,2.452-2.5,2.566,2.566,0,0,1,2.777,1.779c.506,2.281,2.255,3.08,4.166,3.591a12.844,12.844,0,0,0,3.315.23,6.522,6.522,0,0,0,4.5-1.872,3.741,3.741,0,0,0,.935-1.586,2.813,2.813,0,0,1,2.833-2.157,2.75,2.75,0,0,1,2.525,2.506,2.662,2.662,0,0,1-2.141,2.821A3.425,3.425,0,0,0,37.9,25.108a7.85,7.85,0,0,0,.3,10.5,3.65,3.65,0,0,0,1.477.836,2.8,2.8,0,0,1,2.2,2.874,2.756,2.756,0,0,1-2.544,2.494,2.807,2.807,0,0,1-2.821-2.175,3.9,3.9,0,0,0-1-1.63,6.99,6.99,0,0,0-4.546-1.825c-2.442-.131-4.715.249-6.506,2.068a2.956,2.956,0,0,0-.713,1.278,2.717,2.717,0,1,1-3.157-3.109,3.185,3.185,0,0,0,1.445-.847A7.841,7.841,0,0,0,24.161,30.45Zm8.3-.217a2.351,2.351,0,1,0-4.7-.046,2.789,2.789,0,0,0,2.12,2.522A2.656,2.656,0,0,0,32.465,30.233Z"
                    transform="translate(-15.552 -15.786)"
                    fill="#bebebe"
                />
                <path
                    id="Caminho_478"
                    data-name="Caminho 478"
                    d="M125.234,10.535a3.445,3.445,0,0,1,1.977-1.507,3.561,3.561,0,0,0,2.669-3.833,3.682,3.682,0,0,0-3.346-3.285,3.577,3.577,0,0,0-3.775,2.747,2.89,2.89,0,0,1-1.53,1.855A5.2,5.2,0,0,1,123.683.743a5.369,5.369,0,0,1,7.265,7.476A5.2,5.2,0,0,1,125.234,10.535Z"
                    transform="translate(-102.645 -0.009)"
                    fill="#bebebe"
                />
                <path
                    id="Caminho_479"
                    data-name="Caminho 479"
                    d="M10.559,123.609A5.175,5.175,0,0,1,8.245,129.3a5.4,5.4,0,0,1-6.587-.69,5.412,5.412,0,0,1-.97-6.429,5.268,5.268,0,0,1,5.761-2.634,2.662,2.662,0,0,1-1.728,1.521,3.577,3.577,0,0,0-2.806,3.874,3.7,3.7,0,0,0,3.356,3.276,3.574,3.574,0,0,0,3.788-2.823A2.9,2.9,0,0,1,10.559,123.609Z"
                    transform="translate(-0.036 -101.245)"
                    fill="#bebebe"
                />
                <path
                    id="Caminho_480"
                    data-name="Caminho 480"
                    d="M6.42,10.637A5.154,5.154,0,0,1,.863,8.357a5.394,5.394,0,0,1,.608-6.594A5.416,5.416,0,0,1,8.088.815a5.221,5.221,0,0,1,2.474,5.756A2.734,2.734,0,0,1,9.055,4.811,3.582,3.582,0,0,0,5.486,1.985a3.644,3.644,0,0,0-.733,7.2A2.281,2.281,0,0,1,6.42,10.637Z"
                    transform="translate(-0.013 -0.081)"
                    fill="#bebebe"
                />
                <path
                    id="Caminho_481"
                    data-name="Caminho 481"
                    d="M125.326,119.455a5.125,5.125,0,0,1,5.636,2.243,5.37,5.37,0,0,1-7.148,7.589,5.233,5.233,0,0,1-2.543-5.791,2.7,2.7,0,0,1,1.51,1.755,3.581,3.581,0,0,0,3.568,2.826,3.636,3.636,0,0,0,.73-7.189A3.02,3.02,0,0,1,125.326,119.455Z"
                    transform="translate(-102.692 -101.11)"
                    fill="#bebebe"
                />
            </g>
        </svg>
        <svg
            id="Camada_2"
            data-name="Camada 2"
            xmlns="http://www.w3.org/2000/svg"
            width="29.127"
            height="28.868"
            viewBox="0 0 29.127 28.868"
            v-else
        >
            <g id="Camada_1" data-name="Camada 1" transform="translate(0)">
                <path
                    id="Caminho_477"
                    data-name="Caminho 477"
                    d="M24.161,30.45a7.588,7.588,0,0,0-1.548-4.991,3.84,3.84,0,0,0-2.322-1.571,2.619,2.619,0,0,1-1.938-2.748,2.738,2.738,0,0,1,2.452-2.5,2.566,2.566,0,0,1,2.777,1.779c.506,2.281,2.255,3.08,4.166,3.591a12.844,12.844,0,0,0,3.315.23,6.522,6.522,0,0,0,4.5-1.872,3.741,3.741,0,0,0,.935-1.586,2.813,2.813,0,0,1,2.833-2.157,2.75,2.75,0,0,1,2.525,2.506,2.662,2.662,0,0,1-2.141,2.821A3.425,3.425,0,0,0,37.9,25.108a7.85,7.85,0,0,0,.3,10.5,3.65,3.65,0,0,0,1.477.836,2.8,2.8,0,0,1,2.2,2.874,2.756,2.756,0,0,1-2.544,2.494,2.807,2.807,0,0,1-2.821-2.175,3.9,3.9,0,0,0-1-1.63,6.99,6.99,0,0,0-4.546-1.825c-2.442-.131-4.715.249-6.506,2.068a2.956,2.956,0,0,0-.713,1.278,2.717,2.717,0,1,1-3.157-3.109,3.185,3.185,0,0,0,1.445-.847A7.841,7.841,0,0,0,24.161,30.45Zm8.3-.217a2.351,2.351,0,1,0-4.7-.046,2.789,2.789,0,0,0,2.12,2.522A2.656,2.656,0,0,0,32.465,30.233Z"
                    transform="translate(-15.552 -15.786)"
                    fill="#ffe082"
                />
                <path
                    id="Caminho_478"
                    data-name="Caminho 478"
                    d="M125.234,10.535a3.445,3.445,0,0,1,1.977-1.507,3.561,3.561,0,0,0,2.669-3.833,3.682,3.682,0,0,0-3.346-3.285,3.577,3.577,0,0,0-3.775,2.747,2.89,2.89,0,0,1-1.53,1.855A5.2,5.2,0,0,1,123.683.743a5.369,5.369,0,0,1,7.265,7.476A5.2,5.2,0,0,1,125.234,10.535Z"
                    transform="translate(-102.645 -0.009)"
                    fill="#ffe082"
                />
                <path
                    id="Caminho_479"
                    data-name="Caminho 479"
                    d="M10.559,123.609A5.175,5.175,0,0,1,8.245,129.3a5.4,5.4,0,0,1-6.587-.69,5.412,5.412,0,0,1-.97-6.429,5.268,5.268,0,0,1,5.761-2.634,2.662,2.662,0,0,1-1.728,1.521,3.577,3.577,0,0,0-2.806,3.874,3.7,3.7,0,0,0,3.356,3.276,3.574,3.574,0,0,0,3.788-2.823A2.9,2.9,0,0,1,10.559,123.609Z"
                    transform="translate(-0.036 -101.245)"
                    fill="#ffe082"
                />
                <path
                    id="Caminho_480"
                    data-name="Caminho 480"
                    d="M6.42,10.637A5.154,5.154,0,0,1,.863,8.357a5.394,5.394,0,0,1,.608-6.594A5.416,5.416,0,0,1,8.088.815a5.221,5.221,0,0,1,2.474,5.756A2.734,2.734,0,0,1,9.055,4.811,3.582,3.582,0,0,0,5.486,1.985a3.644,3.644,0,0,0-.733,7.2A2.281,2.281,0,0,1,6.42,10.637Z"
                    transform="translate(-0.013 -0.081)"
                    fill="#ffe082"
                />
                <path
                    id="Caminho_481"
                    data-name="Caminho 481"
                    d="M125.326,119.455a5.125,5.125,0,0,1,5.636,2.243,5.37,5.37,0,0,1-7.148,7.589,5.233,5.233,0,0,1-2.543-5.791,2.7,2.7,0,0,1,1.51,1.755,3.581,3.581,0,0,0,3.568,2.826,3.636,3.636,0,0,0,.73-7.189A3.02,3.02,0,0,1,125.326,119.455Z"
                    transform="translate(-102.692 -101.11)"
                    fill="#ffe082"
                />
            </g>
        </svg>
    </div>
</template>


<script>
export default {
    data() {
        return {};
    },
    props: ["active"],
};
</script>