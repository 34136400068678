<script>
//Importing Line class from the vue-chartjs wrapper
import { Bar } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
    extends: Bar,
    data() {
        return {};
    },
    computed: {
        options() {
            return {
                title: {
                    display: true,
                    text: this.title,
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: "bottom",
                    display: false,
                    labels: {
                        usePointStyle: false,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            // barPercentage: 0.4,
                        },
                    ],
                    yAxes: [
                        {
                            type: "logarithmic", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            stacked: true,
                            display: true,
                            position: "left",
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                            },
                        },
                    ],
                },
            };
        },
    },
    props: ["chartData", "title"],
    watch: {
        "chartData.datasets": function (newVal, oldVal) {
            this.renderChart(this.chartData, this.options);
        },
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    },
};
</script>