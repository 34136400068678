<template>
    <div
        class="main-stream-card-element"
        v-if="drone.vIS_CONNECTED && drone.stream.vIS_STREAMING_TO_CLOUD"
    >
        <div>
            <div class="block" style="position: absolute; top: 10px; left: 0px">
                <el-slider
                    v-model="gimbalPitch"
                    @change="handleGimbalPitchSliderChange"
                    vertical
                    height="250px"
                    :min="-90"
                    :max="0"
                    :class="{
                        'main-stream-card-element-left-container':
                            !navState.streamFocused,
                        'main-stream-card-element-left-container-when-stream-focused':
                            navState.streamFocused,
                    }"
                >
                </el-slider>
            </div>
            <div
                style="
                    position: absolute;
                    top: 0px;
                    left: 30px;
                    height: 100%;
                    width: calc(100% - 60px);
                "
            >
                <div
                    @click="setNavStreamFocused(true)"
                    :id="'id-main-stream-card-element-video-' + drone.vUID"
                    :class="{
                        'main-stream-card-element-video':
                            !navState.streamFocused,
                        'main-stream-card-element-video-when-stream-focused':
                            navState.streamFocused,
                    }"
                ></div>
                <!-- <div
                    :id="'id-secondary-stream-card-element-video-' + drone.vUID"
                    class="main-stream-card-element-video"
                    style="width: 100%; height: 100%; background-color: #292929"
                ></div> -->
                <!-- <video class="main-stream-card-element-video" src=""></video> -->
            </div>
        </div>
        <div
            :class="{
                'main-stream-card-element-right-container':
                    !navState.streamFocused,
                'main-stream-card-element-right-container-when-stream-focused':
                    navState.streamFocused,
            }"
        >
            <button
                class="btn btn-secondary w-100 mx-0 px-0 btn-stream-card"
                style="border-top-right-radius: 10px !important"
                @click="handleResetCamera"
            >
                R
            </button>
            <button
                class="btn btn-secondary w-100 mx-0 px-0 btn-stream-card"
                @click="handleClickCameraZoomIn"
            >
                <i class="fas fa-plus" style="font-size: 15px"></i>
            </button>
            <button class="btn btn-secondary w-100 mx-0 px-0 btn-stream-card">
                {{ drone.flight.state.vCAMERA_ZOOM / 100 }}x
            </button>
            <button
                class="btn btn-secondary w-100 mx-0 px-0 btn-stream-card"
                @click="handleClickCameraZoomOut"
            >
                <i class="fas fa-minus" style="font-size: 15px"></i>
            </button>
            <button
                class="btn btn-secondary w-100 mx-0 px-0 btn-stream-card"
                style="position: absolute; bottom: 37.53px; right: 0px"
                @click="handleRestartStreamLink"
            >
                <i class="fas fa-sync-alt" style="font-size: 15px"></i>
            </button>
            <button
                class="btn btn-secondary w-100 mx-0 px-0 btn-stream-card"
                style="
                    border-bottom-right-radius: 10px !important;
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                "
                @click="handleShareStreamLink"
            >
                <i class="fas fa-share-alt" style="font-size: 15px"></i>
            </button>
        </div>
        <div
            :class="{
                'main-stream-card-element-top-container':
                    !navState.streamFocused,
                'main-stream-card-element-top-container-when-stream-focused':
                    navState.streamFocused,
            }"
        >
            <span
                class="btn btn-secondary my-0 py-0 btn-stream-card"
                style="
                    border-top-left-radius: 10px !important;
                    height: 30px;
                    line-height: 30px;
                "
            >
                FPS:
                {{ (fps || 0).toFixed(2) }}
            </span>
            <span
                class="btn btn-secondary my-0 py-0 btn-stream-card"
                style="height: 30px; line-height: 30px"
                ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34.492"
                    height="25.629"
                    viewBox="0 0 34.492 25.629"
                >
                    <g
                        id="Grupo_1184"
                        data-name="Grupo 1184"
                        transform="translate(-0.002)"
                    >
                        <g
                            id="computer-24px"
                            transform="translate(20.682 11.816)"
                        >
                            <path
                                id="Caminho_675"
                                data-name="Caminho 675"
                                d="M0,0H13.812V13.813H0Z"
                                fill="none"
                            />
                            <path
                                id="Caminho_676"
                                data-name="Caminho 676"
                                d="M11.51,12.057a1.15,1.15,0,0,0,1.145-1.151l.006-5.755A1.154,1.154,0,0,0,11.51,4H2.3A1.154,1.154,0,0,0,1.151,5.151v5.755A1.154,1.154,0,0,0,2.3,12.057H0v1.151H13.812V12.057ZM2.3,5.151H11.51v5.755H2.3Z"
                                transform="translate(0 -1.698)"
                                fill="#fff"
                            />
                        </g>
                        <g
                            id="signal_cellular_alt-24px"
                            transform="translate(0.002)"
                        >
                            <path
                                id="Caminho_673"
                                data-name="Caminho 673"
                                d="M0,0H23.632V23.633H0Z"
                                fill="none"
                            />
                            <path
                                id="Caminho_674"
                                data-name="Caminho 674"
                                d="M16.816,4H19.77V19.755H16.816ZM5,13.847H7.954v5.908H5Zm5.908-4.923h2.954V19.755H10.908Z"
                                transform="translate(-0.077 -0.061)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </svg>

                {{ (drone.stream.vWEBRTC_BITRATE / 1000).toFixed(2) }}
                Mbps
            </span>
            <span
                class="btn btn-secondary my-0 py-0 btn-stream-card"
                style="
                    border-top-right-radius: 10px !important;
                    height: 30px;
                    line-height: 30px;
                "
                ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="39.445"
                    height="23.633"
                    viewBox="0 0 39.445 23.633"
                >
                    <g
                        id="Grupo_1185"
                        data-name="Grupo 1185"
                        transform="translate(-1761 -108)"
                    >
                        <text
                            id="HD"
                            transform="translate(1792.445 129.09)"
                            fill="#fff"
                            font-size="10"
                            font-family="Helvetica"
                        >
                            <tspan x="-7.222" y="0">HD</tspan>
                        </text>
                        <g
                            id="signal_cellular_alt-24px"
                            transform="translate(1761 108)"
                        >
                            <path
                                id="Caminho_673"
                                data-name="Caminho 673"
                                d="M0,0H23.632V23.633H0Z"
                                fill="none"
                            />
                            <path
                                id="Caminho_674"
                                data-name="Caminho 674"
                                d="M16.816,4H19.77V19.755H16.816ZM5,13.847H7.954v5.908H5Zm5.908-4.923h2.954V19.755H10.908Z"
                                transform="translate(-0.077 -0.061)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </svg>

                <!-- {{ (drone.stream.vVIDEO_LINK_SIGNAL || 0).toFixed(0) }} % -->
                {{ (quality || 0).toFixed(0) }} %
            </span>
        </div>
        <div
            v-if="
                drone.flight.state.vIS_EXECUTING_MISSION &&
                drone.telemetry.vMISSION_NAME &&
                drone.telemetry.vMISSION_NAME !== 'none'
            "
            :class="{
                'main-stream-card-element-bottom-container':
                    !navState.streamFocused,
                'main-stream-card-element-bottom-container-when-stream-focused':
                    navState.streamFocused,
            }"
        >
            <span
                class="btn btn-secondary my-0 py-0 btn-stream-card"
                style="
                    border-bottom-left-radius: 10px !important;
                    border-bottom-right-radius: 10px !important;
                    height: 30px;
                    line-height: 30px;
                "
            >
                {{ drone.telemetry.vMISSION_NAME }}
            </span>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            session: null,
            publisher: null,
            subscriber: null,
            subscribed: false,
            apiKey: null,
            sessionId: null,
            token: null,
            zoomOptions: [100, 200, 300, 400, 500, 600],
            fps: 0,
            quality: 0,
            interval: null,
            gimbalPitch: 0,
        };
    },
    computed: {
        ...mapGetters(["user", "navState"]),
        // _gimbalPitch: {
        //     get() {
        //         if (
        //             this.drone.flight.state.vGIMBAL_PITCH !==
        //             this.drone.flight.actions.aSET_GIMBAL_PITCH
        //         ) {
        //             return this.drone.flight.actions.aSET_GIMBAL_PITCH;
        //         } else {
        //             return this.drone.flight.state.vGIMBAL_PITCH;
        //         }
        //     },
        //     set(val) {
        //         firebase
        //             .database()
        //             .ref(
        //                 "aegisv2/users/" +
        //                     this.drone.vUID +
        //                     "/flight/actions/aSET_GIMBAL_PITCH"
        //             )
        //             .set(val);
        //     },
        // },
    },
    methods: {
        ...mapActions([
            "setNavStreamFocused",
            "updateDroneIsConnected",
            "setNavForcedLoading",
        ]),
        makeDynamicLongLink(
            customToken,
            socialTitle,
            androidPackageName,
            socialDescription,
            socialImageUrl
        ) {
            return {
                dynamicLinkInfo: {
                    domainUriPrefix: "https://aeroguard.live/link",
                    link: `https://aeroguard-viewer.web.app/live?key="${customToken}`,
                    androidInfo: {
                        androidPackageName: androidPackageName,
                    },
                    socialMetaTagInfo: {
                        socialTitle: socialTitle,
                        socialDescription: socialDescription,
                        socialImageLink: socialImageUrl,
                    },
                },
                suffix: {
                    option: "UNGUESSABLE",
                },
            };
        },
        handleShareStreamLink() {
            let self = this;

            this.setNavForcedLoading(true);

            firebase
                .functions()
                .httpsCallable("createDynamicLinkFromCustomToken_v2")({
                    userId:
                        Date.now() + "-" + Math.floor(Math.random() * 1000000),
                    additionalClaims: {
                        vCHILD_TYPE: "viewer",
                        vCREATOR_CHILD_UID: self.drone.vUID,
                        vCREATOR_TEAM_UID: self.user.data.uid,
                        vCREATOR_CHILD_TYPE: self.drone.internals.vCHILD_TYPE,
                        vCREATOR_CHILD_NAME: self.drone.internals.vCHILD_NAME,
                        vEXPIRES_IN: Date.now() + 59 * 60 * 1000,
                    },
                })
                .then(function (result) {
                    if (result.data.statusCode === 200) {
                        self.$copyText(result.data.res).then(
                            () => {
                                self.$message({
                                    message: `Link copiado para a área de transferência`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                            },
                            (e) => {
                                console.log(e);
                                self.$message({
                                    message: `Não foi possível copiar o link para a área de transferência`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        );
                    } else {
                        self.$message({
                            message: `Não foi possível copiar o link para a área de transferência`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                    }
                })
                .catch(() => {
                    self.$message({
                        message: `Não foi possível copiar o link para a área de transferência`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                })
                .finally(function () {
                    self.setNavForcedLoading(false);
                });
        },
        handleClickCameraZoomIn() {
            let currentZoom = this.drone.flight.state.vCAMERA_ZOOM;
            let indexZoom = this.zoomOptions.indexOf(
                this.drone.flight.state.vCAMERA_ZOOM
            );

            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/flight/actions/aSET_CAMERA_ZOOM"
                )
                .set(
                    this.zoomOptions[
                        Math.min(indexZoom + 1, this.zoomOptions.length - 1)
                    ]
                );
        },
        handleRestartStreamLink() {
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/stream/aRESTART_STREAM"
                )
                .set(true);
        },
        handleClickCameraZoomOut() {
            let currentZoom = this.drone.flight.state.vCAMERA_ZOOM;
            let indexZoom = this.zoomOptions.indexOf(
                this.drone.flight.state.vCAMERA_ZOOM
            );

            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/flight/actions/aSET_CAMERA_ZOOM"
                )
                .set(this.zoomOptions[Math.max(indexZoom - 1, 0)]);
        },
        handleResetCamera() {
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/flight/actions/aSET_CAMERA_ZOOM"
                )
                .set(100);
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/flight/actions/aSET_GIMBAL_PITCH"
                )
                .set(0);
        },
        startFakeStream() {
            let self = this;
            setInterval(() => {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.drone.vUID +
                            "/stream/vWEBRTC_BITRATE"
                    )
                    .set(Math.round(Math.random() * 100));
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.drone.vUID +
                            "/stream/vIS_STREAMING"
                    )
                    .set(true);
            }, 2000);
            axios
                .get(
                    "https://aeroguard-opentok.herokuapp.com/room/" +
                        self.drone.vUID
                )
                .then(function (response) {
                    // console.log(response);
                    self.apiKey = response.data.apiKey;
                    self.sessionId = response.data.sessionId;
                    self.token = response.data.token;
                    // console.log("API KEY -->", self.apiKey);
                    // console.log("SESSION ID -->", self.sessionId);
                    // console.log("TOKEN -->", self.token);
                    self.session = OT.initSession(self.apiKey, self.sessionId);
                    self.publisher = OT.initPublisher(
                        //my video
                        "id-main-stream-card-element-video-" + self.drone.vUID,
                        {
                            // showControls: false,
                            insertMode: "replace",
                            width: "100%",
                            height: "100%",
                            publishAudio: false,
                            publishVideo: true,
                            style: { buttonDisplayMode: "off" },
                            name:
                                self.drone.internals.vCHILD_NAME +
                                " pusblisher",
                        },
                        self.handleError
                    );
                    self.session.on("sessionDisconnected", function (event) {
                        console.log(
                            "You were disconnected from the session.",
                            event.reason
                        );
                    });
                    // Subscribe to a newly created stream
                    self.session.on("streamCreated", function (event) {
                        var subscriberOptions = {
                            // showControls: false,
                            insertMode: "replace",
                            width: "100%",
                            height: "100%",
                        };
                        self.session.subscribe(
                            event.stream,
                            "id-secondary-stream-card-element-video-" +
                                self.drone.vUID,
                            subscriberOptions,
                            function (error) {
                                if (error) {
                                    console.log(
                                        "There was an error publishing: ",
                                        error.name,
                                        error.message
                                    );
                                }
                            }
                        );
                    });
                    self.session.connect(self.token, function (error) {
                        // If the connection is successful, initialize a publisher and publish to the session
                        if (error) {
                            self.handleError(error);
                        } else {
                            self.session.publish(
                                self.publisher,
                                self.handleError
                            );
                        }
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        startFakeHeartBeat() {
            setInterval(() => {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.drone.vUID +
                            "/stream/vWEBRTC_BITRATE"
                    )
                    .set(Math.round(Math.random() * 100));
            }, 2000);
        },
        startStream() {
            let self = this;
            axios
                .get(
                    "https://aeroguard-opentok.herokuapp.com/room/" +
                        self.drone.vUID
                )
                .then(function (response) {
                    self.apiKey = response.data.apiKey;
                    self.sessionId = response.data.sessionId;
                    self.token = response.data.token;

                    self.session = OT.initSession(self.apiKey, self.sessionId);
                    self.session ? self.session.disconnect() : false;
                    self.session = OT.initSession(self.apiKey, self.sessionId);

                    self.session.on("streamCreated", function (event) {
                        // console.log(event);
                        if (!self.subscribed) {
                            self.subscriber = self.session.subscribe(
                                //myself
                                event.stream,
                                "id-main-stream-card-element-video-" +
                                    self.drone.vUID,
                                {
                                    // showControls: false,
                                    insertMode: "replace",
                                    width: "100%",
                                    height: "100%",
                                    style: { buttonDisplayMode: "off" },
                                    subscribeToAudio: false,
                                    subscribeToVideo: true,
                                    // showControls: false,
                                },
                                self.handleError
                            );
                            self.subscribed = true;
                        }
                    });
                    self.session.connect(self.token, function (error) {
                        // If the connection is successful, initialize a publisher and publish to the session
                        if (error) {
                            self.handleError(error);
                        } else {
                            // self.session.publish(self.publisher, self.handleError);
                        }
                    });
                });
        },
        handleGimbalPitchSliderChange(e) {
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/flight/actions/aSET_GIMBAL_PITCH"
                )
                .set(e);
        },
    },
    props: ["drone"],
    watch: {
        "drone.flight.state.vGIMBAL_PITCH": function (newVal) {
            this.gimbalPitch = newVal;
        },
    },
    mounted() {
        this.gimbalPitch = this.drone.flight.state.vGIMBAL_PITCH;

        let self = this;
        if (
            this.drone.vIS_CONNECTED &&
            this.drone.stream.vIS_STREAMING_TO_CLOUD
        ) {
            setTimeout(() => {
                if (
                    self.drone.vIS_CONNECTED &&
                    self.drone.stream.vIS_STREAMING_TO_CLOUD
                ) {
                    self.startStream();
                }
            }, 200);
        }

        if (this.interval !== null) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
            if (self.subscribed && self.subscriber) {
                self.subscriber.getStats(function (error, stats) {
                    if (!error) {
                        self.fps = stats.video.frameRate;
                        self.quality =
                            (100 *
                                (stats.video.packetsReceived -
                                    stats.video.packetsLost)) /
                            stats.video.packetsReceived;
                    }
                });
            }
        }, 1000);
    },
    beforeDestroy() {
        let self = this;
        if (self.session) {
            // console.log("Session exists");
            self.session.disconnect();
        }
        if (this.interval !== null) {
            clearInterval(this.interval);
        }
    },
    beforeMount() {},
};
</script>


<style scoped>
.main-stream-card-element {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.main-stream-card-element-left-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 30px);
    height: 100%;
    /* border: 1px solid blue; */
}

.main-stream-card-element-left-container-when-stream-focused {
    position: absolute;
    top: 100px;
    left: calc(100vw - 560px);
    width: calc(100% - 30px);
    height: 100%;
    z-index: 300;
    /* border: 1px solid blue; */
}

.main-stream-card-element-right-container {
    opacity: 0.5;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 100%;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.main-stream-card-element-right-container-when-stream-focused {
    opacity: 0.5;
    position: absolute;
    top: 100px;
    right: 20px;
    width: 30px;
    height: 270px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.main-stream-card-element-top-container {
    opacity: 0.5;
    position: absolute;
    top: -30px;
    right: 30px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.main-stream-card-element-top-container-when-stream-focused {
    opacity: 0.5;
    position: absolute;
    top: 70px;
    right: 50px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.main-stream-card-element-bottom-container-when-stream-focused {
    opacity: 0.5;
    position: absolute;
    top: 370px;
    right: 50px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.main-stream-card-element-bottom-container {
    opacity: 0.5;
    position: absolute;
    bottom: -30px;
    right: 30px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.btn-stream-card {
    border-color: #868686 !important;
    background-color: #535353 !important;
}

.main-stream-card-element-video {
    width: 100% !important;
    height: 100% !important;
    background-color: #292929;
}
.main-stream-card-element-video-when-stream-focused {
    width: calc(100% - 300px) !important;
    margin-left: 0px !important;
    height: 100% !important;
    background-color: #292929;
}
</style>