<script>
//Importing Line class from the vue-chartjs wrapper
import { Bar } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
    extends: Bar,
    data() {
        return {
            options: {
                spanGaps: true,
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: "bottom",
                    display: false,
                    labels: {
                        usePointStyle: false,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            // barPercentage: 0.4,
                        },
                    ],
                    yAxes: [
                        {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            stacked: false,
                            display: true,
                            position: "left",
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            if (tooltipItem.datasetIndex === 0) {
                                return `Relevo: ${
                                    Math.round(tooltipItem.yLabel * 100) / 100
                                }m`;
                            } else if (tooltipItem.datasetIndex === 1) {
                                let waypointAltitude =
                                    data.datasets[1].data[tooltipItem.index];
                                let waypointIndex = data.datasets[1].data
                                    .slice(0, tooltipItem.index)
                                    .filter((v) => v).length;

                                return `Waypoint ${
                                    waypointIndex + 1
                                }: ${waypointAltitude}m`;
                            }
                        },
                    },
                },
            },
        };
    },
    props: ["chartData"],
    watch: {
        "chartData.datasets": function (newVal, oldVal) {
            this.renderChart(this.chartData, this.options);
        },
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    },
};
</script>