<template>
    <div
        style="
            width: 100%;
            height: 100%;
            padding: 20px;
            overflow-y: scroll;
            overflow-x: hidden;
        "
    >
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">{{
                            $t("Events_detection")
                        }}</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para configurar quais eventos serão
                            detectados por SmartCams.</span
                        >
                    </div>
                </div>
                <br />
                <br />

                <!--  -->
                <div>
                    <!--  -->
                    <div>
                        <div class="row">
                            <div class="col-12 text-left">
                                <h5>{{ $t("Events") }}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-3 my-2"
                                :key="'pilot-event-' + index"
                                v-for="(p, index) in pilotEvents.solo"
                            >
                                <div
                                    style="border-radius: 20px; height: 280px"
                                    class="bg-dark-medium-gray"
                                >
                                    <div class="row px-4 py-2">
                                        <div
                                            class="
                                                col-9
                                                text-left
                                                px-2
                                                py-2
                                                my-0 my-auto
                                            "
                                        >
                                            <el-checkbox
                                                v-model="p.vIS_ENABLED"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vIS_ENABLED',
                                                        $event
                                                    )
                                                "
                                                :disabled="
                                                    p.vIS_ENABLED_FORCED ===
                                                    true
                                                "
                                                ><span
                                                    class="
                                                        el-checkbox-span-text-large
                                                    "
                                                    >{{
                                                        p[$t("vLABEL_LANGUAGE")]
                                                    }}</span
                                                ></el-checkbox
                                            >
                                        </div>
                                        <div class="col-3 text-right">
                                            <el-tooltip
                                                :content="
                                                    p[$t('vTOOLTIP_LANGUAGE')]
                                                "
                                                placement="top"
                                                ><i
                                                    style="
                                                        font-size: 1rem;
                                                        color: #ffe082;
                                                    "
                                                    class="
                                                        fas
                                                        fa-question-circle
                                                    "
                                                ></i>
                                            </el-tooltip>
                                        </div>
                                    </div>

                                    <div
                                        v-if="p.vACCEPT_EVENT_MESSAGE === true"
                                        class="row px-4 py-2"
                                    >
                                        <div class="col-12 text-left px-2 py-2">
                                            <el-checkbox
                                                label="Permitir anexar mensagem"
                                                v-model="
                                                    p.vACCEPT_EVENT_MESSAGE
                                                "
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vIS_EVENT_MESSAGE_ENABLED',
                                                        $event
                                                    )
                                                "
                                                :disabled="
                                                    p.vACCEPT_EVENT_MESSAGE ===
                                                    true
                                                "
                                            ></el-checkbox>
                                        </div>
                                    </div>
                                    <div
                                        v-if="p.vRANGE_INPUT"
                                        :class="{ hidden: !p.vRANGE_INPUT }"
                                    >
                                        <div class="row">
                                            <div
                                                class="
                                                    col-12
                                                    text-left
                                                    mx-4
                                                    px-2
                                                    py-2
                                                "
                                            >
                                                {{
                                                    p[
                                                        $t(
                                                            "vRANGE_INPUT_LABEL_LANGUAGE"
                                                        )
                                                    ]
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-7">
                                                <el-slider
                                                    :min="p.vMIN_VALUE"
                                                    :max="p.vMAX_VALUE"
                                                    v-model="p.vVALUE"
                                                    :marks="p.vMARKS"
                                                    :show-tooltip="
                                                        p.vSHOW_TOOLTIP
                                                    "
                                                    @change="
                                                        handleSliderChange(
                                                            p.vKEY,
                                                            'vVALUE',
                                                            $event
                                                        )
                                                    "
                                                >
                                                    ></el-slider
                                                >
                                            </div>
                                            <div class="col-4 align-middle">
                                                <span
                                                    class="align-middle"
                                                    :class="{
                                                        hidden: !p.vSHOW_RANGE_INPUT_VALUE,
                                                    }"
                                                    >{{ p.vVALUE }}
                                                    {{ p.vUNIT }}</span
                                                >
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <div class="row">
                                        <div
                                            class="
                                                col-12
                                                text-left
                                                mx-4
                                                px-2
                                                py-2
                                            "
                                        >
                                            <el-checkbox
                                                label="Notificar por email"
                                                v-model="p.vNOTIFY_BY_EMAIL"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vNOTIFY_BY_EMAIL',
                                                        $event
                                                    )
                                                "
                                            ></el-checkbox>
                                        </div>
                                    </div>
                                    <!-- <br /> -->
                                    <div
                                        class="row w-100"
                                        style="
                                            padding-bottom: 10px;
                                            position: absolute;
                                            bottom: 0px;
                                        "
                                        v-if="
                                            p.vACCEPT_EVENT_GEOFENCES === true
                                        "
                                    >
                                        <div
                                            class="col-12 text-center px-2 py-2"
                                        >
                                            <button
                                                style="font-size: 0.8rem"
                                                class="btn btn-primary py-0"
                                                @click="
                                                    handleEditSelectedGeofences(
                                                        p.vKEY
                                                    )
                                                "
                                            >
                                                Editar pontos
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div
                                            class="
                                                col-12
                                                text-left
                                                mx-4
                                                px-2
                                                py-2
                                            "
                                        >
                                            <el-checkbox
                                                label="Notificar no Segware"
                                                v-model="p.vNOTIFY_BY_SEGWARE"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vNOTIFY_BY_SEGWARE',
                                                        $event
                                                    )
                                                "
                                                :disabled="!p.vIS_ENABLED"
                                            ></el-checkbox>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <!-- <br /> -->
                    </div>
                    <!--  -->
                </div>
            </div>
        </div>

        <el-dialog
            title="Selecione pontos e cercas"
            :visible.sync="dialogEditGeofences.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                Selecione os pontos e/ou cercas que serão usadosna geração de
                eventos do tipo
                <strong
                    >"{{
                        dialogEditGeofences.eventKey
                            ? events[
                                  events
                                      .map((v) => v.value)
                                      .indexOf(dialogEditGeofences.eventKey)
                              ].label
                            : ""
                    }}"</strong
                >
            </div>
            <br />
            <div class="row">
                <div class="col-6 text-center">Nome</div>
                <div class="col-6 text-center">Local</div>
            </div>
            <br />
            <el-checkbox-group
                v-model="dialogEditGeofences.selectedGeofencesKeys"
            >
                <div
                    v-for="t in treatedFencings"
                    :key="'subscriber-checked-elements-' + t.vFENCING_KEY"
                >
                    <div class="row">
                        <div class="col-6 text-left">
                            <el-checkbox :label="t.vFENCING_KEY">{{
                                t.vFENCING_NAME
                            }}</el-checkbox>
                        </div>
                        <div class="col-6 text-center">
                            <h6>
                                {{ t.vFENCING_LOCATION_NAME }}
                            </h6>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogEditGeofences.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="
                                handleConfirmEditGeofences(
                                    dialogEditGeofences.eventKey
                                )
                            "
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            automaticEventDeletion: false,
            dialogEditGeofences: {
                visible: false,
                eventKey: null,
                selectedGeofencesKeys: [],
            },
            treatedFencings: [],
        };
    },
    computed: {
        ...mapGetters(["user", "pilotEvents", "events"]),
    },
    methods: {
        refreshUserInternalsSettings() {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${self.user.data.uid}/internals/settings/solo/fencings`
                )
                .on("value", function (snapshot) {
                    self.treatedFencings = (
                        Object.values(snapshot.val() || {}) || []
                    ).filter((v) => v.vFENCING_KEY);
                });
        },
        handleCheckBoxChange(key, parameter, value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/events/${key}/${parameter}`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleSliderChange(key, parameter, value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/events/${key}/${parameter}`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleAutomaticEventDeletionChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/events/vAUTOMATIC_EVENT_DELETION`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleEditSelectedGeofences(eventKey) {
            let self = this;
            this.dialogEditGeofences.eventKey = eventKey;

            firebase
                .database()
                .ref(
                    `aegisv2/users/${self.user.data.uid}/internals/settings/solo/events/${eventKey}/geofences`
                )
                .once("value", function (snapshot) {
                    self.dialogEditGeofences.selectedGeofencesKeys =
                        snapshot.val() || [];
                })
                .finally(() => {
                    self.dialogEditGeofences.visible = true;
                });
        },
        handleConfirmEditGeofences(eventKey) {
            let self = this;

            firebase
                .database()
                .ref(
                    `aegisv2/users/${self.user.data.uid}/internals/settings/solo/events/${eventKey}/geofences`
                )
                .set(self.dialogEditGeofences.selectedGeofencesKeys)
                .then(() => {
                    self.$message({
                        message: `Modificações realizadas com sucesso`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                })
                .finally(() => {
                    self.dialogEditGeofences.visible = false;
                });
        },
    },
    mounted() {
        this.refreshUserInternalsSettings();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}

.hidden {
    visibility: hidden;
}
</style>