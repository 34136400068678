<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">{{
                            $t("Users_management")
                        }}</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem">{{
                            $t("Users_management_desc")
                        }}</span>
                    </div>
                </div>
                <br />
                <br />
            </div>
            <div>
                <div class="row">
                    <div class="col-3 text-left">
                        <button
                            class="btn btn-primary"
                            @click="handleIncorporateUserByToken()"
                        >
                            <i class="fas fa-plus"></i>
                            {{ $t("IncorporateUserByToken") }}
                        </button>
                    </div>
                </div>
            </div>
            <hr />

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1;
                "
                @click="dialogCreateUser.visible = true"
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="
                        treatedUsers.filter(
                            (data) =>
                                !search ||
                                data.vCHILD_NAME
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_EMAIL
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_CONTACT
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_TYPE
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_ID
                                    .toString()
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                    "
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column
                        sortable
                        prop="vCHILD_NAME"
                        :label="$t('Name')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vCHILD_EMAIL"
                        :label="$t('Email')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vCHILD_ID"
                        :label="$t('Child_id')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vCHILD_CONTACT"
                        :label="$t('Contact')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vCHILD_TYPE"
                        :label="$t('Type')"
                    >
                    </el-table-column>

                    <el-table-column align="right">
                        <template slot="header" slot-scope="scope">
                            <el-input
                                v-model="search"
                                size="mini"
                                :placeholder="$t('Search')"
                                style="border: 1px solid #a1a0a0"
                                :id="scope"
                                clearable
                            >
                                > ></el-input
                            >
                        </template>
                        <template slot-scope="scope">
                            <button
                                @click="handleEdit(scope.$index, scope.row)"
                                class="btn btn-link"
                                v-if="
                                    childTypesLabels.main !==
                                    scope.row.vCHILD_TYPE
                                "
                                :disabled="
                                    !(scope.row.vTEAM_UID === user.data.uid)
                                "
                            >
                                <i
                                    class="fas fa-pen"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                :cancel-button-text="$t('Cancel')"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="
                                    confirmDeleteUser(scope.$index, scope.row)
                                "
                            >
                                <button
                                    class="btn btn-link"
                                    slot="reference"
                                    v-if="
                                        childTypesLabels.main !==
                                        scope.row.vCHILD_TYPE
                                    "
                                    :disabled="
                                        !(scope.row.vTEAM_UID === user.data.uid)
                                    "
                                >
                                    <i
                                        class="fas fa-trash"
                                        style="font-size: 1rem !important"
                                    ></i>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>
        <!-- dialog incorporate user-->
        <el-dialog
            :title="$t('IncorporateUserByToken')"
            :visible.sync="dialogIncorporateUser.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-incorporate-user"
                    :model="dialogIncorporateUser.form"
                    :rules="dialogIncorporateUser.rules"
                >
                    <!--  -->
                    <el-form-item
                        :label="$t('IncorporationToken')"
                        prop="vTOKEN_KEY"
                    >
                        <el-input
                            :maxlength="100"
                            v-model="dialogIncorporateUser.form.vTOKEN_KEY"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <!--  -->
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogIncorporateUser.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="
                                submitIncorporateUserForm(
                                    'form-incorporate-user'
                                )
                            "
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!-- dialog create user-->
        <el-dialog
            :title="$t('Add_user')"
            :visible.sync="dialogCreateUser.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <div class="row">
                    <div class="col-12 text-left">{{ $t("Type") }}</div>
                </div>
                <br />
                <div class="row">
                    <div class="col-12">
                        <el-select
                            class="w-100"
                            v-model="dialogCreateUser.userType"
                            placeholder="-"
                        >
                            <el-option
                                v-for="item in dialogCreateUser.userTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <br />

                <!--  -->
                <el-form
                    v-if="dialogCreateUser.userType === 'drone'"
                    ref="form-create-user-drone"
                    :model="dialogCreateUser.formDrone"
                    :rules="dialogCreateUser.rulesDrone"
                >
                    <!--  -->
                    <el-form-item :label="$t('Name')" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="dialogCreateUser.formDrone.vCHILD_NAME"
                        ></el-input>
                    </el-form-item>
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogCreateUser.formDrone
                                            .vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="
                                        dialogCreateUser.formDrone.vCHILD_ID
                                    "
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <el-form-item
                        :label="$t('Expected_daily_missions')"
                        prop="vEXPECTED_DAILY_MISSIONS"
                    >
                        <el-input-number
                            class="w-100"
                            v-model="
                                dialogCreateUser.formDrone
                                    .vEXPECTED_DAILY_MISSIONS
                            "
                            controls-position="right"
                            :min="1"
                            :max="100"
                            :step="1"
                        ></el-input-number>
                    </el-form-item>
                    <!--  -->
                </el-form>
                <!--  -->
                <el-form
                    v-if="dialogCreateUser.userType === 'solo'"
                    ref="form-create-user-solo"
                    :model="dialogCreateUser.formSolo"
                    :rules="dialogCreateUser.rulesSolo"
                >
                    <!--  -->
                    <el-form-item :label="$t('Name')" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="dialogCreateUser.formSolo.vCHILD_NAME"
                        ></el-input>
                    </el-form-item>
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogCreateUser.formSolo.vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="
                                        dialogCreateUser.formSolo.vCHILD_ID
                                    "
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                </el-form>
                <!--  -->
                <el-form
                    v-if="dialogCreateUser.userType === 'subscriber'"
                    ref="form-create-user-subscriber"
                    :model="dialogCreateUser.formSubscriber"
                    :rules="dialogCreateUser.rulesSubscriber"
                >
                    <!--  -->
                    <el-form-item :label="$t('Name')" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="
                                dialogCreateUser.formSubscriber.vCHILD_NAME
                            "
                        ></el-input>
                    </el-form-item>
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogCreateUser.formSubscriber
                                            .vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="
                                        dialogCreateUser.formSubscriber
                                            .vCHILD_ID
                                    "
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <el-form-item
                        :label="$t('Data_fetch_period')"
                        prop="vDATA_FETCH_PERIOD"
                    >
                        <el-input-number
                            class="w-100"
                            v-model="
                                dialogCreateUser.formSubscriber
                                    .vDATA_FETCH_PERIOD
                            "
                            controls-position="right"
                            :min="1"
                            :max="100"
                            :step="1"
                        ></el-input-number>
                    </el-form-item>
                    <!--  -->
                </el-form>
                <!--  -->
                <el-form
                    v-if="dialogCreateUser.userType === 'supervisor'"
                    ref="form-create-user-supervisor"
                    :model="dialogCreateUser.formSupervisor"
                    :rules="dialogCreateUser.rulesSupervisor"
                >
                    <!--  -->
                    <el-form-item :label="$t('Name')" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="
                                dialogCreateUser.formSupervisor.vCHILD_NAME
                            "
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item :label="$t('Email')" prop="vCHILD_EMAIL">
                        <el-input
                            :maxlength="100"
                            v-model="
                                dialogCreateUser.formSupervisor.vCHILD_EMAIL
                            "
                            class="dark-input"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item
                        :label="$t('Password')"
                        prop="vCHILD_PASSWORD"
                    >
                        <el-input
                            :maxlength="20"
                            v-model="
                                dialogCreateUser.formSupervisor.vCHILD_PASSWORD
                            "
                            class="dark-input"
                        ></el-input>
                    </el-form-item>
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogCreateUser.formSupervisor
                                            .vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="
                                        dialogCreateUser.formSupervisor
                                            .vCHILD_ID
                                    "
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                </el-form>
                <br />
            </div>
            <div
                class="row"
                v-if="
                    dialogCreateUser.userType === 'drone' &&
                    licensesKeysMaxDronesElements <= dronesElements.length
                "
            >
                <div class="col-12" style="color: #ffe082">
                    {{
                        licensesKeysMaxDronesElements === 0
                            ? "Adquire ou renove a sua licença de Drones."
                            : `Você já possui ${dronesElements.length} de ${licensesKeysMaxDronesElements} drone(s).`
                    }}
                    <br />
                    Entre em contato com a equipe AeroGuard.
                </div>
            </div>
            <div
                class="row"
                v-else-if="
                    dialogCreateUser.userType === 'solo' &&
                    licensesKeysMaxSolosElements <= solosElements.length
                "
            >
                <div class="col-12" style="color: #ffe082">
                    {{
                        licensesKeysMaxSolosElements === 0
                            ? "Adquire ou renove a sua licença SmartCam."
                            : `Você já possui ${solosElements.length} de ${licensesKeysMaxSolosElements} smartcam(s).`
                    }}
                    <br />
                    Entre em contato com a equipe AeroGuard.
                </div>
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogCreateUser.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="
                                submitCreateUserForm(
                                    `form-create-user-${dialogCreateUser.userType}`
                                )
                            "
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!-- dialog edit user-->
        <el-dialog
            title="Editar usuário"
            :visible.sync="dialogEditUser.visible"
            style=""
            width="570px"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    v-if="dialogEditUser.userType === 'drone'"
                    ref="form-edit-user-drone"
                    :model="dialogEditUser.formDrone"
                    :rules="dialogEditUser.rulesDrone"
                >
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Name')"
                                prop="vCHILD_NAME"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogEditUser.formDrone.vCHILD_NAME
                                    "
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogEditUser.formDrone.vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="dialogEditUser.formDrone.vCHILD_ID"
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Expected_daily_missions')"
                                prop="vEXPECTED_DAILY_MISSIONS"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogEditUser.formDrone
                                            .vEXPECTED_DAILY_MISSIONS
                                    "
                                    controls-position="right"
                                    :min="1"
                                    :max="100"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="text-left">
                        <el-checkbox
                            :label="
                                dialogEditUser.formDrone
                                    .vIS_AVAILABLE_FOR_MISSION === false
                                    ? 'Indisponível para missões'
                                    : 'Disponível para missões'
                            "
                            v-model="
                                dialogEditUser.formDrone
                                    .vIS_AVAILABLE_FOR_MISSION
                            "
                        ></el-checkbox>
                    </div>
                    <!--  -->
                    <!--  -->
                    <br />
                    <!--  -->
                    <!--  -->
                    <!--  -->
                    <div class="row">
                        <div class="col-12 text-left">Lista de operadores</div>
                    </div>
                    <br />

                    <div class="row">
                        <div class="col-12 text-left">
                            <el-tag
                                :key="tag"
                                v-for="tag in dialogEditUser.formDrone
                                    .operators"
                                closable
                                :disable-transitions="false"
                                @close="handleCloseOperators(tag)"
                                class="mx-2 my-2"
                                type="info"
                            >
                                {{ tag }}
                            </el-tag>
                        </div>
                    </div>
                    <br />
                    <div
                        class="row"
                        v-if="dialogEditUser.formDrone.operators.length < 10"
                    >
                        <div class="col-6 text-left">
                            <el-input
                                class="input-new-tag my-2"
                                v-if="inputVisibleOperators"
                                v-model="inputValueOperators"
                                ref="saveTagInputOperators"
                                size="mini"
                                @keyup.enter.native="
                                    handleInputConfirmOperators
                                "
                                @blur="handleInputConfirmOperators"
                                :maxlength="21"
                            >
                            </el-input>

                            <el-button
                                v-else
                                class="button-new-tag"
                                size="small"
                                @click="showInputOperators"
                                >+ Novo operador</el-button
                            >
                        </div>
                    </div>
                    <br />
                    <!--  -->
                    <!--  -->
                    <div class="row">
                        <div class="col-12 text-left">Lista de assinantes</div>
                    </div>
                    <br />

                    <div class="row">
                        <div class="col-12 text-left">
                            <el-tag
                                :key="tag"
                                v-for="tag in dialogEditUser.formDrone
                                    .followers"
                                closable
                                :disable-transitions="false"
                                @close="handleCloseDroneFollowers(tag)"
                                class="mx-2 my-2"
                                type="info"
                            >
                                {{ tag }}
                            </el-tag>
                        </div>
                    </div>
                    <br />
                    <div
                        class="row"
                        v-if="dialogEditUser.formDrone.followers.length < 10"
                    >
                        <div class="col-6 text-left">
                            <el-input
                                class="input-new-tag my-2"
                                v-if="inputVisibleDroneFollowers"
                                v-model="inputValueDroneFollowers"
                                ref="saveTagInputDroneFollowers"
                                size="mini"
                                @keyup.enter.native="
                                    handleInputConfirmDroneFollowers
                                "
                                @blur="handleInputConfirmDroneFollowers"
                                :maxlength="210"
                            >
                            </el-input>

                            <el-button
                                v-else
                                class="button-new-tag"
                                size="small"
                                @click="showInputDroneFollowers"
                                >+ Novo assinante</el-button
                            >
                        </div>
                    </div>
                    <br />
                    <!--  -->
                </el-form>
                <!--  -->
                <el-form
                    v-if="dialogEditUser.userType === 'solo'"
                    ref="form-edit-user-solo"
                    :model="dialogEditUser.formSolo"
                    :rules="dialogEditUser.rulesSolo"
                >
                    <!--  -->
                    <el-form-item :label="$t('Name')" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="dialogEditUser.formSolo.vCHILD_NAME"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item :label="$t('Contact')" prop="vCHILD_CONTACT">
                        <el-input
                            :maxlength="20"
                            v-model="dialogEditUser.formSolo.vCHILD_CONTACT"
                            v-mask="masks.vCHILD_CONTACT"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item :label="$t('Child_id')" prop="vCHILD_ID">
                        <el-input
                            :maxlength="14"
                            v-model="dialogEditUser.formSolo.vCHILD_ID"
                            v-mask="masks.vCHILD_ID"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <!--  -->
                    <div class="row">
                        <div class="col-12 text-left">Lista de assinantes</div>
                    </div>
                    <br />

                    <div class="row">
                        <div class="col-12 text-left">
                            <el-tag
                                :key="tag"
                                v-for="tag in dialogEditUser.formSolo.followers"
                                closable
                                :disable-transitions="false"
                                @close="handleCloseSoloFollowers(tag)"
                                class="mx-2 my-2"
                                type="info"
                            >
                                {{ tag }}
                            </el-tag>
                        </div>
                    </div>
                    <br />
                    <div
                        class="row"
                        v-if="dialogEditUser.formSolo.followers.length < 10"
                    >
                        <div class="col-6 text-left">
                            <el-input
                                class="input-new-tag my-2"
                                v-if="inputVisibleSoloFollowers"
                                v-model="inputValueSoloFollowers"
                                ref="saveTagInputSoloFollowers"
                                size="mini"
                                @keyup.enter.native="
                                    handleInputConfirmSoloFollowers
                                "
                                @blur="handleInputConfirmSoloFollowers"
                                :maxlength="210"
                            >
                            </el-input>

                            <el-button
                                v-else
                                class="button-new-tag"
                                size="small"
                                @click="showInputSoloFollowers"
                                >+ Novo assinante</el-button
                            >
                        </div>
                    </div>
                    <br />
                    <!--  -->
                </el-form>
                <!--  -->
                <el-form
                    v-if="dialogEditUser.userType === 'subscriber'"
                    ref="form-edit-user-subscriber"
                    :model="dialogEditUser.formSubscriber"
                    :rules="dialogEditUser.rulesSubscriber"
                >
                    <!--  -->
                    <el-form-item :label="$t('Name')" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="dialogEditUser.formSubscriber.vCHILD_NAME"
                        ></el-input>
                    </el-form-item>
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogEditUser.formSubscriber
                                            .vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="
                                        dialogEditUser.formSubscriber.vCHILD_ID
                                    "
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <el-form-item
                        label="Período de revisão"
                        prop="vDATA_FETCH_PERIOD"
                    >
                        <el-input-number
                            class="w-100"
                            v-model="
                                dialogEditUser.formSubscriber.vDATA_FETCH_PERIOD
                            "
                            controls-position="right"
                            :min="1"
                            :max="100"
                            :step="1"
                        ></el-input-number>
                    </el-form-item>
                    <!--  -->

                    <el-checkbox-group
                        v-model="_checkedEditUserSubscriberCheckedElements"
                    >
                        <div class="row">
                            <div
                                v-for="e in dronesElements.concat(
                                    solosElements
                                )"
                                :key="'subscriber-checked-elements-' + e.vUID"
                                class="col-4 text-left"
                            >
                                <el-checkbox :label="e.vUID">{{
                                    e.internals.vCHILD_NAME
                                }}</el-checkbox>
                            </div>
                        </div>
                    </el-checkbox-group>
                    <!--  -->
                </el-form>
                <!--  -->
                <el-form
                    v-if="dialogEditUser.userType === 'supervisor'"
                    ref="form-edit-user-supervisor"
                    :model="dialogEditUser.formSupervisor"
                    :rules="dialogEditUser.rulesSupervisor"
                >
                    <!--  -->
                    <el-form-item :label="$t('Name')" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="dialogEditUser.formSupervisor.vCHILD_NAME"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item label="Email" prop="vCHILD_EMAIL">
                        <el-input
                            :maxlength="100"
                            v-model="dialogEditUser.formSupervisor.vCHILD_EMAIL"
                            class="dark-input"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item :label="$t('Contact')" prop="vCHILD_CONTACT">
                        <el-input
                            :maxlength="20"
                            v-model="
                                dialogEditUser.formSupervisor.vCHILD_CONTACT
                            "
                            v-mask="masks.vCHILD_CONTACT"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item :label="$t('Child_id')" prop="vCHILD_ID">
                        <el-input
                            :maxlength="14"
                            v-model="dialogEditUser.formSupervisor.vCHILD_ID"
                            v-mask="masks.vCHILD_ID"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogEditUser.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="
                                submitEditUserForm(
                                    `form-edit-user-${dialogEditUser.userType}`
                                )
                            "
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            masks: {
                vCHILD_ID: {
                    mask: "###.###.###-##",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
                vCHILD_CONTACT: {
                    mask: "(##)#####-####",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
            },
            treatedUsers: [],
            childTypesLabels: {
                main: "Administrador",
                drone: "Drone",
                solo: "SmartCam",
                subscriber: "Inscrito",
                supervisor: "Supervisor",
            },
            dialogIncorporateUser: {
                visible: false,
                form: {
                    vTOKEN_KEY: "",
                },
                rules: {
                    vTOKEN_KEY: [
                        {
                            required: true,
                            message: "Defina um token",
                            trigger: "change",
                        },
                        {
                            min: 10,
                            max: 100,
                            message: "O token deve ter entre 10 e 100 dígitos",
                            trigger: "change",
                        },
                    ],
                },
            },
            dialogCreateUser: {
                visible: false,
                userType: "drone",
                userTypeOptions: [
                    { label: "Drone", value: "drone" },
                    { label: "SmartCam", value: "solo" },
                    { label: "Inscrito", value: "subscriber" },
                    { label: "Supervisor", value: "supervisor" },
                ],
                //
                formDrone: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "(00)00000-0000",
                    vCHILD_ID: "000.000.000-00",
                    vEXPECTED_DAILY_MISSIONS: 1,
                },
                rulesDrone: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vEXPECTED_DAILY_MISSIONS: [
                        {
                            required: true,
                            message:
                                "Defina a quantidade de missões diárias previstas",
                            trigger: "change",
                        },
                    ],
                },
                //
                formSolo: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "(00)00000-0000",
                    vCHILD_ID: "000.000.000-00",
                },
                rulesSolo: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                },
                //
                formSubscriber: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "(00)00000-0000",
                    vCHILD_ID: "000.000.000-00",
                    vDATA_FETCH_PERIOD: 7,
                    elements: [],
                },
                rulesSubscriber: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vDATA_FETCH_PERIOD: [
                        {
                            required: true,
                            message: "Defina um período de revisão",
                            trigger: "change",
                        },
                    ],
                },
                //
                formSupervisor: {
                    vCHILD_NAME: "",
                    vCHILD_EMAIL: "",
                    vCHILD_PASSWORD: "",
                    vCHILD_CONTACT: "(00)00000-0000",
                    vCHILD_ID: "000.000.000-00",
                },
                rulesSupervisor: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_EMAIL: [
                        {
                            required: true,
                            message: "Defina um email",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 100,
                            message: "O email deve ter um formato válido",
                            trigger: "change",
                        },
                    ],
                    vCHILD_PASSWORD: [
                        {
                            required: true,
                            message: "Defina uma senha",
                            trigger: "change",
                        },
                        {
                            min: 6,
                            max: 20,
                            message: "A senha deve ter entre 6 e 20 dígitos",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                },
            },
            dialogEditUser: {
                visible: false,
                userUid: null,
                userType: null,
                formDrone: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "",
                    vCHILD_ID: "",
                    vEXPECTED_DAILY_MISSIONS: 1,
                    vIS_AVAILABLE_FOR_MISSION: null,
                    operators: [],
                    followers: [],
                },
                rulesDrone: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vEXPECTED_DAILY_MISSIONS: [
                        {
                            required: true,
                            message:
                                "Defina a quantidade de missões diárias previstas",
                            trigger: "change",
                        },
                    ],
                },
                //
                formSolo: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "",
                    vCHILD_ID: "",
                    followers: [],
                },
                rulesSolo: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                },
                //
                formSubscriber: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "",
                    vCHILD_ID: "",
                    vDATA_FETCH_PERIOD: 7,
                    elements: [],
                },
                //
                rulesSubscriber: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vDATA_FETCH_PERIOD: [
                        {
                            required: true,
                            message: "Defina um período de revisão",
                            trigger: "change",
                        },
                    ],
                }, //
                //
                formSupervisor: {
                    vCHILD_NAME: "",
                    vCHILD_EMAIL: "",
                    vCHILD_CONTACT: "",
                    vCHILD_ID: "",
                },
                rulesSupervisor: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_EMAIL: [
                        {
                            required: true,
                            message: "Defina um email",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 100,
                            message: "O email deve ter um formato válido",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                },
            },
            inputVisibleOperators: false,
            inputValueOperators: "",
            inputVisibleDroneFollowers: false,
            inputValueDroneFollowers: "",
            inputVisibleSoloFollowers: false,
            inputValueSoloFollowers: "",
            search: "",
        };
    },
    computed: {
        ...mapGetters([
            "user",
            "dronesElements",
            "solosElements",
            "smartElements",
            "subscribersElements",
            "supervisorsElements",
            "licensesKeysElements",
        ]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
        licensesKeysMaxDronesElements() {
            return this.licensesKeysElements
                .map((v) => v.data)
                .filter(
                    (v) =>
                        v.vEXPIRES_IN > Date.now() &&
                        v.vLICENSE_TYPE === "drone"
                )
                .map((v) => v.vNUMBER_OF_CHILDREN)
                .reduce((a, b) => a + b, 0);
        },
        licensesKeysMaxSolosElements() {
            return this.licensesKeysElements
                .map((v) => v.data)
                .filter(
                    (v) =>
                        v.vEXPIRES_IN > Date.now() && v.vLICENSE_TYPE === "solo"
                )
                .map((v) => v.vNUMBER_OF_CHILDREN)
                .reduce((a, b) => a + b, 0);
        },
        licensesKeysMaxSubscribersElements() {
            return this.dronesElements.length * 10;
        },
        licensesKeysMaxSupervisorsElements() {
            return this.dronesElements.length * 10;
        },
        _checkedEditUserSubscriberCheckedElements: {
            get() {
                return this.dialogEditUser.formSubscriber.elements.map(
                    (v) => v.vCHILD_UID
                );
            },
            set(val) {
                let self = this;
                this.dialogEditUser.formSubscriber.elements = val.map(function (
                    v
                ) {
                    return {
                        vCHILD_UID: v,
                        vCHILD_TYPE:
                            self.smartElements[
                                self.smartElements.map((v) => v.vUID).indexOf(v)
                            ].internals.vCHILD_TYPE,
                    };
                });
            },
        },
    },
    methods: {
        ...mapActions(["initializeFollowers", "setNavForcedLoading"]),
        handleIncorporateUserByToken() {
            this.dialogIncorporateUser.visible = true;
        },
        confirmDeleteUser(i, r) {
            const type = Object.keys(this.childTypesLabels)[
                Object.values(this.childTypesLabels).indexOf(r.vCHILD_TYPE)
            ];
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    self.setNavForcedLoading(true);
                    firebase
                        .functions()
                        .httpsCallable(
                            `CLOUD_DASHBOARD_DELETE_${type.toUpperCase()}`
                        )({
                            vTEAM_UID: r.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            vCHILD_UID: r.vCHILD_UID,
                            idToken: idToken,
                            command: `delete-account-user-management-${type}`,
                        })
                        .then(function (result) {
                            self.setNavForcedLoading(false);
                            console.log(result.data.error);
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                setTimeout(() => {
                                    location.reload();
                                }, 1000);
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            console.log(e);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    console.log(e);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        handleEdit(i, r) {
            this.dialogEditUser.userUid = r.vCHILD_UID;

            let self = this;
            let promises = [];

            promises.push(
                new Promise(function (resolve, reject) {
                    firebase
                        .database()
                        .ref(`aegisv2/users/${r.vCHILD_UID}/internals`)
                        .once("value", function (data) {
                            resolve({
                                child: "internals",
                                data: data.val(),
                            });
                        });
                })
            );
            promises.push(
                new Promise(function (resolve, reject) {
                    firebase
                        .database()
                        .ref(`aegisv2/users/${r.vCHILD_UID}/elements`)
                        .once("value", function (data) {
                            resolve({
                                child: "elements",
                                data: (data.val() || []).filter(
                                    (v) => v !== null
                                ),
                            });
                        });
                })
            );
            promises.push(
                new Promise(function (resolve, reject) {
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${r.vCHILD_UID}/internals/operators`
                        )
                        .once("value", function (data) {
                            resolve({
                                child: "operators",
                                data: (data.val() || []).filter(
                                    (v) => v !== null
                                ),
                            });
                        });
                })
            );
            promises.push(
                new Promise(function (resolve, reject) {
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${r.vCHILD_UID}/followers/${self.user.data.uid}/mailingList`
                        )
                        .once("value", function (data) {
                            resolve({
                                child: "followers",
                                data: (data.val() || []).filter(
                                    (v) => v !== null
                                ),
                            });
                        });
                })
            );

            Promise.all(promises).then(function (values) {
                let r = {
                    internals: null,
                    elements: null,
                    operators: null,
                    followers: null,
                };

                for (let v of values) {
                    r[v.child] = v.data;
                }

                let type = r.internals.vCHILD_TYPE;
                let f = type.charAt(0).toUpperCase() + type.slice(1);

                self.dialogEditUser[`form${f}`].vCHILD_NAME =
                    r.internals.vCHILD_NAME;
                self.dialogEditUser[`form${f}`].vCHILD_ID =
                    r.internals.vCHILD_ID;
                self.dialogEditUser[`form${f}`].vCHILD_CONTACT =
                    r.internals.vCHILD_CONTACT;
                self.dialogEditUser.userType = type;

                if (type === "drone") {
                    self.dialogEditUser[`form${f}`].vIS_AVAILABLE_FOR_MISSION =
                        !(r.internals.vIS_AVAILABLE_FOR_MISSION === false);
                    self.dialogEditUser[`form${f}`].vEXPECTED_DAILY_MISSIONS =
                        r.internals.vEXPECTED_DAILY_MISSIONS;
                    self.dialogEditUser[`form${f}`].operators = r.operators;
                    self.dialogEditUser[`form${f}`].followers = r.followers;
                } else if (type === "solo") {
                    self.dialogEditUser[`form${f}`].followers = r.followers;
                } else if (type === "subscriber") {
                    self.dialogEditUser[`form${f}`].vDATA_FETCH_PERIOD =
                        r.internals.vDATA_FETCH_PERIOD;
                    self.dialogEditUser[`form${f}`].elements =
                        r.elements.filter(
                            (v) =>
                                self.smartElements
                                    .map((v) => v.vUID)
                                    .indexOf(v.vCHILD_UID) !== -1
                        );
                } else if (type === "supervisor") {
                    self.dialogEditUser[`form${f}`].vCHILD_EMAIL =
                        r.internals.vCHILD_EMAIL || "";
                }
                self.dialogEditUser.visible = true;
            });
        },
        submitIncorporateUserForm(formName) {
            console.log(formName);
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    self.confirmIncorporateUser(self.dialogIncorporateUser);
                } else {
                    return false;
                }
            });
        },
        submitCreateUserForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let type =
                        formName.split("-")[formName.split("-").length - 1];
                    let f = type.charAt(0).toUpperCase() + type.slice(1);

                    self.confirmCreateChild(
                        type,
                        self.dialogCreateUser[`form${f}`]
                    );
                } else {
                    return false;
                }
            });
        },
        submitEditUserForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let type = self.dialogEditUser.userType;
                    let f = type.charAt(0).toUpperCase() + type.slice(1);

                    self.confirmEditChild(
                        type,
                        self.dialogEditUser[`form${f}`]
                    );
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        confirmIncorporateUser(data) {
            let self = this;
            self.setNavForcedLoading(true);
            self.dialogIncorporateUser.visible = false;

            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(`CLOUD_DASHBOARD_INCORPORATE_USER`)({
                            payload: { vTOKEN_KEY: data.form.vTOKEN_KEY },
                            command: `create-account-user-management-incorporate-user`,
                            vTEAM_UID: self.user.data.uid,
                            vCREATOR_UID: self.user.data.uid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            console.log(result);
                            self.setNavForcedLoading(false);
                            self.resetForm("form-incorporate-user");

                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                setTimeout(() => {
                                    location.reload();
                                }, 1000);
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmCreateChild(type, data) {
            let self = this;
            self.setNavForcedLoading(true);
            self.dialogCreateUser.visible = false;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(
                            `CLOUD_DASHBOARD_CREATE_${type.toUpperCase()}`
                        )({
                            payload: { internals: data },
                            command: `create-account-user-management-${type.toLowerCase()}`,
                            vTEAM_UID: self.user.data.uid,
                            vCREATOR_UID: self.user.data.uid,
                            vCHILD_TYPE: type,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            self.setNavForcedLoading(false);
                            self.resetForm(`form-create-user-${type}`);

                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                setTimeout(() => {
                                    location.reload();
                                }, 1000);
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmEditChild(type, data) {
            let self = this;

            // isolate internals data
            let internals = JSON.parse(JSON.stringify(data));
            delete internals.elements;
            delete internals.operators;
            delete internals.vCHILD_EMAIL;

            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(
                            `CLOUD_DASHBOARD_EDIT_${type.toUpperCase()}`
                        )({
                            payload: {
                                internals: internals,
                                elements: data.elements || [],
                                operators: data.operators || [],
                                followers: data.followers || [],
                            },
                            command: `edit-account-user-management-${type.toLowerCase()}`,
                            vTEAM_UID: self.user.data.uid,
                            vCREATOR_UID: self.user.data.uid,
                            vCHILD_UID: self.dialogEditUser.userUid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.dialogEditUser.visible = false;
                                self.initializeFollowers();
                                self.reloadElements();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },

        handleCloseOperators(tag) {
            this.dialogEditUser.formDrone.operators.splice(
                this.dialogEditUser.formDrone.operators.indexOf(tag),
                1
            );
        },

        handleCloseDroneFollowers(tag) {
            this.dialogEditUser.formDrone.followers.splice(
                this.dialogEditUser.formDrone.followers.indexOf(tag),
                1
            );
        },
        handleCloseSoloFollowers(tag) {
            this.dialogEditUser.formSolo.followers.splice(
                this.dialogEditUser.formSolo.followers.indexOf(tag),
                1
            );
        },

        showInputOperators() {
            this.inputVisibleOperators = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInputOperators.$refs.input.focus();
            });
        },

        showInputDroneFollowers() {
            this.inputVisibleDroneFollowers = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInputDroneFollowers.$refs.input.focus();
            });
        },

        showInputSoloFollowers() {
            this.inputVisibleSoloFollowers = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInputSoloFollowers.$refs.input.focus();
            });
        },
        handleInputConfirmOperators() {
            let inputValueOperators = this.inputValueOperators;
            if (inputValueOperators) {
                this.dialogEditUser.formDrone.operators.push(
                    inputValueOperators
                );
            }
            this.inputVisibleOperators = false;
            this.inputValueOperators = "";
        },
        handleInputConfirmDroneFollowers() {
            let inputValueDroneFollowers = this.inputValueDroneFollowers;
            if (inputValueDroneFollowers) {
                this.dialogEditUser.formDrone.followers.push(
                    inputValueDroneFollowers
                );
            }
            this.inputVisibleDroneFollowers = false;
            this.inputValueDroneFollowers = "";
        },
        handleInputConfirmSoloFollowers() {
            let inputValueSoloFollowers = this.inputValueSoloFollowers;
            if (inputValueSoloFollowers) {
                this.dialogEditUser.formSolo.followers.push(
                    inputValueSoloFollowers
                );
            }
            this.inputVisibleSoloFollowers = false;
            this.inputValueSoloFollowers = "";
        },
        reloadElements() {
            let self = this;
            let array = [];

            let elements = [];
            elements = elements.concat([this.user.data]);
            elements = elements.concat(this.dronesElements);
            elements = elements.concat(this.solosElements);
            elements = elements.concat(this.subscribersElements);
            elements = elements.concat(this.supervisorsElements);

            array = elements
                .filter((v) => v.internals)
                .map(function (v) {
                    return {
                        vCHILD_NAME: v.internals.vCHILD_NAME || "-",
                        vCHILD_EMAIL: v.internals.vCHILD_EMAIL || "-",
                        vCHILD_ID: v.internals.vCHILD_ID || "-",
                        vTEAM_UID: v.internals.vTEAM_UID || "-",
                        vCHILD_TYPE:
                            self.childTypesLabels[v.internals.vCHILD_TYPE],
                        vCHILD_UID: v.vUID || "-",
                        vCHILD_CONTACT: v.internals.vCHILD_CONTACT || "-",
                    };
                });

            this.treatedUsers = array;
        },
    },
    mounted() {
        this.reloadElements();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>

<style>
.el-tag + .el-tag {
    margin-left: 10px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 150px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>