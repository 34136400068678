<template>
    <div class="main-review-list bg-dark-medium-gray">
        <template>
            <el-table
                :data="treatedLogReviews"
                style="width: 100%"
                height="calc(60vh - 142px)"
                @row-click="handleRowClick"
                highlight-current-row
                :row-class-name="tableRowClassName"
                header-cell-class-name="header-row-class-name-custom"
                ref="tableReviewList"
                :row-key="(row) => row.serviceKey"
                :expand-row-keys="expandRowKeys"
            >
                >

                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div v-if="logReviewActiveElement">
                            <div v-if="props.row.type === 'Drone'">
                                <div>
                                    <div
                                        class="row"
                                        style="color: #bebebe !important"
                                    >
                                        <div class="col-2">
                                            {{ $t("Device_serial_number") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Battery_serial_number") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Radio_serial_number") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Battery") }}
                                        </div>
                                        <div class="col-2"></div>
                                        <div
                                            class="col-2 text-right"
                                            v-if="
                                                user.data.realInternals
                                                    .vCHILD_TYPE === 'main'
                                            "
                                        >
                                            <el-popconfirm
                                                :confirm-button-text="
                                                    $t('Confirm')
                                                "
                                                :cancel-button-text="
                                                    $t('Cancel')
                                                "
                                                icon="el-icon-info"
                                                icon-color="red"
                                                title="Você tem certeza disso?"
                                                @confirm="
                                                    confirmDeleteLogService(
                                                        props.$index,
                                                        props.row
                                                    )
                                                "
                                            >
                                                <button
                                                    class="btn btn-link"
                                                    slot="reference"
                                                >
                                                    <i
                                                        class="fas fa-trash"
                                                        style="
                                                            font-size: 1rem !important;
                                                        "
                                                    ></i>
                                                </button>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            {{
                                                logReviewActiveElement.vDEVICE_SERIAL_NUMBER ||
                                                "-"
                                            }}
                                        </div>
                                        <div class="col-2">
                                            {{
                                                logReviewActiveElement.vBATTERY_SERIAL_NUMBER ||
                                                "-"
                                            }}
                                        </div>
                                        <div class="col-2">
                                            {{
                                                logReviewActiveElement.vRADIO_SERIAL_NUMBER ||
                                                "-"
                                            }}
                                        </div>

                                        <div class="col-2">
                                            {{
                                                logReviewActiveElement.battery
                                                    ? `${
                                                          logReviewActiveElement
                                                              .battery
                                                              .vNUMBER_OF_DISCHARGES ||
                                                          0
                                                      } ciclo${
                                                          logReviewActiveElement
                                                              .battery
                                                              .vNUMBER_OF_DISCHARGES >
                                                          1
                                                              ? "s"
                                                              : ""
                                                      } | vida útil ${(
                                                          logReviewActiveElement
                                                              .battery
                                                              .vLIFETIME_REMAINING ||
                                                          0
                                                      ).toFixed(0)} %`
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                <div>
                                    <div
                                        class="row"
                                        style="color: #bebebe !important"
                                    >
                                        <div class="col-2">
                                            {{ $t("Mission") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Operator") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Conformity") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Distance") }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            {{
                                                `${
                                                    logReviewActiveElement.vMISSION_NAME ||
                                                    "Missão desconhecida"
                                                } (${props.row.serviceKey})`
                                            }}
                                        </div>
                                        <div class="col-2">
                                            {{
                                                logReviewActiveElement.vMISSION_OPERATOR_NAME ||
                                                "Desconhecido"
                                            }}
                                        </div>
                                        <div class="col-2">
                                            {{
                                                logReviewActiveElement.compliancy
                                                    ? logReviewActiveElement.vIS_MISSION_COMPLIANT ===
                                                      false
                                                        ? "Não conforme"
                                                        : "Conforme"
                                                    : "Conforme"
                                            }}
                                        </div>
                                        <div class="col-2">
                                            {{
                                                distanceInKm(
                                                    Object.values(
                                                        logReviewActiveElement.locations
                                                    )
                                                        .sort((a, b) =>
                                                            a.vTIMESTAMP >
                                                            b.vTIMESTAMP
                                                                ? 1
                                                                : -1
                                                        )
                                                        .map(function (v) {
                                                            return {
                                                                lat: v.vLOCATION_LATITUDE,
                                                                lng: v.vLOCATION_LONGITUDE,
                                                            };
                                                        })
                                                ).toFixed(2)
                                            }}
                                            km
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div v-else-if="props.row.type === 'SmartCam'">
                                <div>
                                    <div
                                        class="row"
                                        style="color: #bebebe !important"
                                    >
                                        <div class="col-2">
                                            {{ $t("Device_id") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Device_serial_number") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("IMEI") }}
                                        </div>
                                        <div class="col-2">
                                            {{ $t("Contact") }}
                                        </div>
                                        <div class="col-2"></div>
                                        <div
                                            class="col-2 text-right"
                                            v-if="
                                                user.data.realInternals
                                                    .vCHILD_TYPE === 'main'
                                            "
                                        >
                                            <el-popconfirm
                                                :confirm-button-text="
                                                    $t('Confirm')
                                                "
                                                :cancel-button-text="
                                                    $t('Cancel')
                                                "
                                                icon="el-icon-info"
                                                icon-color="red"
                                                title="Você tem certeza disso?"
                                                @confirm="
                                                    confirmDeleteLogService(
                                                        props.$index,
                                                        props.row
                                                    )
                                                "
                                            >
                                                <button
                                                    class="btn btn-link"
                                                    slot="reference"
                                                >
                                                    <i
                                                        class="fas fa-trash"
                                                        style="
                                                            font-size: 1rem !important;
                                                        "
                                                    ></i>
                                                </button>
                                            </el-popconfirm>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            {{
                                                smartElements[
                                                    smartElements
                                                        .map((v) => v.vUID)
                                                        .indexOf(
                                                            logReviewActiveElement.vCHILD_UID
                                                        )
                                                ].internals.vCHILD_EMAIL
                                                    .split("@")[0]
                                                    .toUpperCase() || "-"
                                            }}
                                        </div>
                                        <div class="col-2">
                                            {{
                                                smartElements[
                                                    smartElements
                                                        .map((v) => v.vUID)
                                                        .indexOf(
                                                            logReviewActiveElement.vCHILD_UID
                                                        )
                                                ].internals
                                                    .vDEVICE_SERIAL_NUMBER ||
                                                "-"
                                            }}
                                        </div>
                                        <div class="col-2">
                                            {{
                                                smartElements[
                                                    smartElements
                                                        .map((v) => v.vUID)
                                                        .indexOf(
                                                            logReviewActiveElement.vCHILD_UID
                                                        )
                                                ].internals.vIMEI_ID || "-"
                                            }}
                                        </div>

                                        <div class="col-2">
                                            {{
                                                smartElements[
                                                    smartElements
                                                        .map((v) => v.vUID)
                                                        .indexOf(
                                                            logReviewActiveElement.vCHILD_UID
                                                        )
                                                ].internals.vCHILD_CONTACT ||
                                                "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="serviceKey" v-if="false">
                </el-table-column>
                <el-table-column sortable prop="uid" v-if="false">
                </el-table-column>
                <el-table-column
                    sortable
                    prop="date"
                    :sort-method="handleSortDate"
                    :label="$t('Date')"
                >
                </el-table-column>
                <el-table-column sortable prop="device" :label="$t('Device')">
                </el-table-column>
                <el-table-column sortable prop="type" :label="$t('Type')">
                </el-table-column>
                <el-table-column
                    sortable
                    prop="begin"
                    :sort-method="handleSortBegin"
                    :label="$t('Begin')"
                >
                </el-table-column>
                <el-table-column
                    sortable
                    prop="end"
                    :sort-method="handleSortEnd"
                    :label="$t('End')"
                >
                </el-table-column>
                <el-table-column
                    sortable
                    prop="duration"
                    :label="$t('Duration')"
                >
                </el-table-column>
                <el-table-column sortable prop="events" :label="$t('Events')">
                </el-table-column>
                <el-table-column sortable prop="eventsTypes" v-if="false">
                </el-table-column>
                <el-table-column sortable prop="place" :label="$t('Location')">
                </el-table-column>
            </el-table>
        </template>
    </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";

export default {
    data() {
        return {
            expandRowKeys: [],
        };
    },
    methods: {
        ...mapActions([
            "setLogReviewActive",
            "fetchReviews",
            "setNavForcedLoading",
        ]),
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        handleRowClick(val, index) {
            if (
                this.logReviewActive !== null &&
                this.logReviewActive === val.serviceKey
            ) {
                this.setLogReviewActive({
                    vSERVICE_KEY: null,
                    vCHILD_UID: null,
                });
                this.expandRowKeys = [];
            } else {
                if (val) {
                    this.setLogReviewActive({
                        vSERVICE_KEY: val.serviceKey,
                        vCHILD_UID: val.uid,
                    });
                    this.expandRowKeys = [val.serviceKey];
                }
            }
        },
        confirmDeleteLogService(i, r) {
            let self = this;
            self.setNavForcedLoading(true);
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(`CLOUD_DASHBOARD_DELETE_LOG_SERVICE`)({
                            vCREATOR_UID: self.user.data.uid,
                            vCHILD_UID: r.uid,
                            vLOG_SERVICE_KEY: r.serviceKey,
                            idToken: idToken,
                            command: `delete-log-service`,
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.fetchReviews();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        })
                        .finally(() => self.setNavForcedLoading(false));
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                    self.setNavForcedLoading(false);
                });
        },

        msToHHMMSS(ms) {
            let h, m, s;
            h = Math.floor(ms / 1000 / 3600);
            m = Math.floor((ms / 1000 - h * 3600) / 60);
            s = Math.floor(ms / 1000 - m * 60 - h * 3600);
            return (
                ("00" + h).slice(-2) +
                ":" +
                ("00" + m).slice(-2) +
                ":" +
                ("00" + s).slice(-2)
            );
        },
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
            // if (rowIndex === this.currentRow) {
            //     return "success-row";
            // } else {
            //     return "row-class-name-custom";
            // }
        },
        handleSortDate(a, b) {
            if (moment(a.date, "DD/MM/YYYY") > moment(b.date, "DD/MM/YYYY")) {
                return 1;
            } else if (
                moment(a.date, "DD/MM/YYYY") < moment(b.date, "DD/MM/YYYY")
            ) {
                return -1;
            } else if (a.date === b.date) {
                if (a.begin > b.begin) {
                    return 1;
                } else {
                    return -1;
                }
            }
        },
        handleSortBegin(a, b) {
            if (a.begin > b.begin) {
                return 1;
            } else if (a.begin < b.begin) {
                return -1;
            } else if (a.begin === b.begin) {
                if (
                    moment(a.date, "DD/MM/YYYY") > moment(b.date, "DD/MM/YYYY")
                ) {
                    return 1;
                } else {
                    return -1;
                }
            }
        },
        handleSortEnd(a, b) {
            if (a.end > b.end) {
                return 1;
            } else if (a.end < b.end) {
                return -1;
            } else if (a.end === b.end) {
                if (
                    moment(a.date, "DD/MM/YYYY") > moment(b.date, "DD/MM/YYYY")
                ) {
                    return 1;
                } else {
                    return -1;
                }
            }
        },
    },
    computed: {
        ...mapGetters([
            "logReviewsElements",
            "logReviewActive",
            "logReviewActiveElement",
            "logReviewsFilter",
            "smartElements",
            "logReviewsStatus",
            "user",
        ]),
        treatedLogReviews() {
            let self = this;
            let array = this.logReviewsElements.map(function (v) {
                return {
                    serviceKey: v.vSERVICE_KEY,
                    uid: v.vCHILD_UID,
                    date: moment(v.vDATETIME_START).format("DD/MM/YYYY"),
                    type:
                        v.vCHILD_TYPE === "drone"
                            ? "Drone"
                            : v.vCHILD_TYPE === "solo"
                            ? "SmartCam"
                            : "-",
                    device:
                        self.smartElements
                            .map((v) => v.vUID)
                            .indexOf(v.vCHILD_UID) !== -1
                            ? self.smartElements[
                                  self.smartElements
                                      .map((v) => v.vUID)
                                      .indexOf(v.vCHILD_UID)
                              ].internals.vCHILD_NAME
                            : v.vCHILD_UID.toUpperCase().slice(0, 6),
                    begin: moment(v.vDATETIME_START).format("HH:mm:ss"),
                    end: moment(v.vDATETIME_END).format("HH:mm:ss"),
                    duration: self.msToHHMMSS(
                        Math.max(v.vDATETIME_END - v.vDATETIME_START, 0)
                    ),
                    events: v.events
                        ? Object.values(v.events)
                              .map((v) => v.vEVENT_TYPE)
                              .filter(
                                  (v) =>
                                      v !== "start_service" &&
                                      v !== "stop_service" &&
                                      v !== "take_off"
                              ).length
                        : 0,
                    // .service.events
                    //     ? Object.values(v.service.events).length
                    //     : 0
                    eventsTypes: v.events ? Object.values(v.events) : [],
                    place:
                        JSON.stringify(v.limitBorders) !== "{}" &&
                        !(
                            typeof v.limitBorders.vLOCATION_LATITUDE_MAX ===
                                "undefined" ||
                            typeof v.limitBorders.vLOCATION_LATITUDE_MIN ===
                                "undefined" ||
                            typeof v.limitBorders.vLOCATION_LONGITUDE_MAX ===
                                "undefined" ||
                            typeof v.limitBorders.vLOCATION_LONGITUDE_MIN ===
                                "undefined"
                        )
                            ? `Lat: ${(
                                  (v.limitBorders.vLOCATION_LATITUDE_MAX +
                                      v.limitBorders.vLOCATION_LATITUDE_MIN) /
                                  2
                              ).toFixed(4)}, Long: ${(
                                  (v.limitBorders.vLOCATION_LONGITUDE_MAX +
                                      v.limitBorders.vLOCATION_LONGITUDE_MIN) /
                                  2
                              ).toFixed(4)}`
                            : "-",
                };
            });

            return array;
        },
    },
    watch: {
        logReviewsStatus: function (newVal, oldVal) {
            if (newVal) {
                if (newVal === "empty") {
                    this.$message({
                        message: `Nenhum dado disponível nesse período`,
                        type: "info",
                        showClose: true,
                        offset: 60,
                    });
                } else if (newVal === "success") {
                    this.$message({
                        message: `${this.$t("Messsage_data_obtained")} (${
                            this.logReviewsElements.length
                        } ${
                            this.logReviewsElements.length === 1
                                ? this.$t("mission")
                                : this.$t("missions")
                        })`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                } else if (newVal === "error") {
                    this.$message({
                        message: `Não foi possível carregar os dados`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            }
        },
    },
};
</script>

<style>
.main-review-list {
    padding: 5px 0px 10px 5px;
}
</style>