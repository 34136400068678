<template>
    <div class="main-topbar bg-dark-medium-gray">
        <div class="main-topbar-content" ref="document">
            <div class="row mx-0 my-0 py-0" style="line-height: 50px">
                <div class="align-self-center text-left mx-0 px-0 py-0 my-0">
                    <el-date-picker
                        v-model="_reviewFilterDate"
                        type="daterange"
                        range-separator="a"
                        start-placeholder="-"
                        end-placeholder="-"
                        text-color="red"
                        format="dd/MM/yyyy"
                        value-format="timestamp"
                        class="mx-2"
                        @change="resetDronesReport()"
                    >
                    </el-date-picker>
                </div>
                <div class="align-self-center text-left mx-0 px-0 py-0 my-0">
                    <el-select
                        v-model="_reviewFilterDevice"
                        placeholder="-"
                        multiple
                        collapse-tags
                        class="mx-2"
                        style="width: 300px"
                        :multiple-limit="1"
                        @change="resetDronesReport()"
                    >
                        <el-option
                            v-for="item in optionsDevice"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <button
                        class="btn btn-primary mx-2"
                        @click="handleFetchDronesReport"
                        :disabled="
                            (_reviewFilterDate && _reviewFilterDevice
                                ? _reviewFilterDate.length !== 2 ||
                                  _reviewFilterDevice.length === 0
                                    ? true
                                    : false
                                : true) || dronesReportStatus === 'waiting'
                        "
                    >
                        {{ $t("SEARCH") }}
                    </button>
                    <button
                        class="btn btn-primary mx-2"
                        v-if="
                            dronesReportFilter.device.length > 0 &&
                            dronesReportFilter.date.filter((v) => !isNaN(v))
                                .length === 2 &&
                            dronesReportStatus === 'success'
                        "
                        @click="
                            setNavForcedLoading(true);
                            handleGenerateDronesReport();
                        "
                        :disabled="
                            (_reviewFilterDate && _reviewFilterDevice
                                ? _reviewFilterDate.length !== 2 ||
                                  _reviewFilterDevice.length === 0
                                    ? true
                                    : false
                                : true) || dronesReportStatus === 'waiting'
                        "
                    >
                        Gerar relatório
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="
                _reviewFilterDate &&
                _reviewFilterDevice.length > 0 &&
                _reviewFilterDate.filter((v) => !isNaN(v)).length === 2 &&
                dronesReportStatus === 'success'
            "
        >
            <VueHtml2pdf
                :manual-pagination="true"
                :enable-download="false"
                ref="contentDroneReport"
                :preview-modal="true"
                style="color: #292929 !important"
                @hasDownloaded="setNavForcedLoading(false)"
                :html-to-pdf-options="htmlToPdfOptions"
            >
                <section slot="pdf-content" style="background-color: #f9f9f9">
                    <div
                        class="row"
                        style="height: 58px; background-color: #292929"
                    >
                        <div
                            class="col-12 text-center h-100"
                            style="padding: 10px"
                        >
                            <img
                                style="max-height: 100%; max-width: 100%"
                                :src="require('@/assets/logoAeroguard.png')"
                            />
                        </div>
                    </div>
                    <!--  -->
                    <div class="row my-auto h-100">
                        <div
                            class="col-12 text-center"
                            style="padding: 10px; font-size: 36px"
                        >
                            <strong>RELATÓRIO OPERACIONAL</strong>
                        </div>
                    </div>
                    <div style="width: 100%; padding: 10px 30px 10px 30px">
                        <div class="row" style="background-color: white">
                            <div
                                class="col-12 text-center"
                                style="padding: 10px"
                            >
                                <strong>RESUMO</strong>
                            </div>
                            <div
                                class="col-12 text-left"
                                style="padding: 10px; margin-left: 20px"
                            >
                                <span>
                                    <strong>Data: </strong>
                                    {{ msToLLL(Date.now()) }}</span
                                ><br />
                                <span>
                                    <strong>Período avaliado: </strong>

                                    {{
                                        msToDDMMYYYY(dronesReportFilter.date[0])
                                    }}
                                    à
                                    {{
                                        msToDDMMYYYY(dronesReportFilter.date[1])
                                    }}</span
                                ><br />
                                <span>
                                    <strong>Dispositivo: </strong>

                                    {{
                                        dronesReportFilter.device
                                            .map(
                                                (v) =>
                                                    dronesElements[
                                                        dronesElements
                                                            .map((v) => v.vUID)
                                                            .indexOf(v)
                                                    ].internals.vCHILD_NAME
                                            )
                                            .reduce(
                                                (a, b) => a + (" | " + b),
                                                0
                                            )
                                    }}</span
                                ><br />
                                <span>
                                    <strong>Eventos inclusos: </strong
                                    >{{
                                        droneReportMissionsEvents
                                            .filter((v) => v.count > 0)
                                            .map((v) => v.label)
                                            .reduce(
                                                (a, b) => a + (" | " + b),
                                                0
                                            )
                                    }}</span
                                ><br />
                                <span>
                                    <strong>Relatório criado por: </strong>
                                    {{
                                        user.data.realInternals.vCHILD_EMAIL
                                    }}</span
                                ><br />
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="text-center">INDICADORES DA OPERAÇÃO</h2>

                    <div style="width: 100%; padding: 10px 30px 10px 30px">
                        <div class="row" style="background-color: white">
                            <div
                                class="
                                    col-3
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                Total previsto
                            </div>
                            <div
                                class="
                                    col-2
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                Realizados
                            </div>
                            <div
                                class="
                                    col-3
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                Não Realizados
                            </div>
                            <div
                                class="
                                    col-4
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                Tempo médio de voo
                            </div>
                            <div
                                class="col-3 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                "
                            >
                                {{
                                    droneReportMissionsKpi.filter(
                                        (v) => v.label === "Total Previsto"
                                    )[0].value
                                }}
                            </div>
                            <div
                                class="col-2 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                    color: #00c689;
                                "
                            >
                                {{
                                    droneReportMissionsKpi.filter(
                                        (v) => v.label === "Realizados"
                                    )[0].value
                                }}
                            </div>
                            <div
                                class="col-3 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                    color: #ef5350;
                                "
                            >
                                {{
                                    droneReportMissionsKpi.filter(
                                        (v) => v.label === "Não Realizados"
                                    )[0].value
                                }}
                            </div>
                            <div
                                class="col-4 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                "
                            >
                                {{
                                    `${droneReportMissionsKpi
                                        .filter(
                                            (v) =>
                                                v.label === "Tempo médio de voo"
                                        )[0]
                                        .value.substring(3)} min`
                                }}
                            </div>
                        </div>
                    </div>

                    <div
                        style="
                            width: 100%;
                            padding: 0px 20px 20px 10px;
                            height: 560px;
                        "
                    >
                        <div class="row h-100" style="background-color: white">
                            <div
                                class="col-6 text-center"
                                style="padding: 10px"
                            >
                                <div
                                    class="chart-container"
                                    style="height: 100%"
                                >
                                    <app-drone-report-battery-states
                                        :datacollection="
                                            droneReportBatteryStatesCollection
                                        "
                                        :show-all-tooltips="false"
                                    ></app-drone-report-battery-states>
                                </div>
                            </div>
                            <div
                                class="col-6 text-center"
                                style="padding: 10px"
                            >
                                <div
                                    class="chart-container"
                                    style="height: 100%"
                                >
                                    <app-drone-report-missions-executed
                                        :datacollection="
                                            droneReportMissionsExecutedCollection
                                        "
                                        :show-all-tooltips="true"
                                    ></app-drone-report-missions-executed>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        style="
                            width: 100%;
                            padding: 0px 20px 20px 10px;
                            height: 1121.5px;
                        "
                    >
                        <div
                            class="row"
                            style="padding: 0; margin: 0; height: 100%"
                        >
                            <div
                                class="col-5 h-100"
                                style="padding: 0; margin: 0"
                            >
                                <div
                                    class="row"
                                    style="padding: 0; margin: 0; height: 60%"
                                >
                                    <div
                                        class="col-12"
                                        style="height: 100%; padding: 5px"
                                    >
                                        <div
                                            class="chart-container"
                                            style="
                                                height: 100%;
                                                background-color: white;
                                            "
                                        >
                                            <app-drone-report-events
                                                :datacollection="
                                                    droneReportEventsCollection
                                                "
                                            ></app-drone-report-events>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    style="padding: 0; margin: 0; height: 40%"
                                >
                                    <div
                                        class="col-12"
                                        style="height: 100%; padding: 5px"
                                    >
                                        <div
                                            class="chart-container"
                                            style="
                                                height: 100%;
                                                background-color: white;
                                            "
                                        >
                                            <app-drone-report-artificial-intelligence-events
                                                :datacollection="
                                                    droneReportArtificialIntelligenceEventsCollection
                                                "
                                                :showAllTooltips="true"
                                            ></app-drone-report-artificial-intelligence-events>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-7 h-100"
                                style="padding: 0; margin: 0"
                            >
                                <div
                                    class="row"
                                    style="
                                        padding: 0;
                                        margin: 0;
                                        height: calc(100% / 3);
                                    "
                                >
                                    <div
                                        class="col-12"
                                        style="height: 100%; padding: 5px"
                                    >
                                        <div
                                            class="chart-container"
                                            style="
                                                height: 100%;
                                                padding-left: 100px;
                                                background-color: white;
                                            "
                                        >
                                            <app-drone-report-scheduled-missions
                                                :datacollection="
                                                    droneReportScheduledMissionsCollection
                                                "
                                                :showAllTooltips="true"
                                            ></app-drone-report-scheduled-missions>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    style="
                                        padding: 0;
                                        margin: 0;
                                        height: calc(100% / 3);
                                    "
                                >
                                    <div
                                        class="col-12"
                                        style="height: 100%; padding: 5px"
                                    >
                                        <div
                                            class="chart-container"
                                            style="
                                                height: 100%;
                                                padding-left: 100px;
                                                background-color: white;
                                            "
                                        >
                                            <app-drone-report-missions-by-operator
                                                :datacollection="
                                                    droneReportMissionsByOperatorCollection
                                                "
                                                :showAllTooltips="true"
                                            ></app-drone-report-missions-by-operator>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="row"
                                    style="
                                        padding: 0;
                                        margin: 0;
                                        height: calc(100% / 3);
                                    "
                                >
                                    <div
                                        class="col-12"
                                        style="height: 100%; padding: 5px"
                                    >
                                        <div
                                            class="chart-container"
                                            style="
                                                height: 100%;
                                                padding-left: 100px;
                                                background-color: white;
                                            "
                                        >
                                            <app-drone-report-missions-not-executed
                                                :datacollection="
                                                    droneReportMissionsNotExecutedCollection
                                                "
                                                :showAllTooltips="true"
                                            ></app-drone-report-missions-not-executed>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style="
                            width: 100%;
                            padding: 0px 20px 20px 10px;
                            height: calc(1121.5px / 2);
                        "
                    >
                        <div
                            class="chart-container"
                            style="height: 100%; background-color: white"
                        >
                            <app-drone-report-missions-executed-by-hour
                                :datacollection="
                                    droneReportMissionsExecutedByHourCollection
                                "
                            ></app-drone-report-missions-executed-by-hour>
                        </div>
                    </div>
                    <div
                        style="
                            width: 100%;
                            padding: 0px 20px 20px 10px;
                            height: calc(1121.5px / 2);
                        "
                    >
                        <div
                            class="chart-container"
                            style="height: 100%; background-color: white"
                        >
                            <app-drone-report-missions-executed-by-day
                                :datacollection="
                                    droneReportMissionsExecutedByDayCollection
                                "
                            ></app-drone-report-missions-executed-by-day>
                        </div>
                    </div>
                </section>
            </VueHtml2pdf>
        </div>
    </div>
</template>


<script>
import appDroneReportMissionsExecuted from "@/components/drone/containers/report/DroneReportMissionsExecuted";
import appDroneReportMissionsByOperator from "@/components/drone/containers/report/DroneReportMissionsByOperator";
import appDroneReportScheduledMissions from "@/components/drone/containers/report/DroneReportScheduledMissions";
import appDroneReportMissionsNotExecuted from "@/components/drone/containers/report/DroneReportMissionsNotExecuted";
import appDroneReportMissionsExecutedByHour from "@/components/drone/containers/report/DroneReportMissionsExecutedByHour";
import appDroneReportMissionsExecutedByDay from "@/components/drone/containers/report/DroneReportMissionsExecutedByDay";
import appDroneReportEvents from "@/components/drone/containers/report/DroneReportEvents";
import appDroneReportArtificialIntelligenceEvents from "@/components/drone/containers/report/DroneReportArtificialIntelligenceEvents";
import appDroneReportBatteryStates from "@/components/drone/containers/report/DroneReportBatteryStates";
import VueHtml2pdf from "vue-html2pdf";

import html2pdf from "html2pdf.js";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters([
            "dronesReportFilter",
            "dronesElements",
            "dronesReportStatus",
            "droneReportMissionsExecutedCollection",
            "droneReportMissionsByOperatorCollection",
            "droneReportScheduledMissionsCollection",
            "droneReportMissionsNotExecutedCollection",
            "droneReportMissionsExecutedByHourCollection",
            "droneReportMissionsExecutedByDayCollection",
            "droneReportEventsCollection",
            "droneReportArtificialIntelligenceEventsCollection",
            "droneReportBatteryStatesCollection",
            "droneReportMissionsKpi",
            "droneReportMissionsEvents",
            "droneReportMissionsLocations",
            "user",
        ]),
        htmlToPdfOptions() {
            return {
                margin: 0,
                image: {
                    type: "jpeg",
                    quality: 0.98,
                },
                enableLinks: true,
                html2canvas: {
                    scale: 2,
                    useCORS: true,
                },
                jsPDF: {
                    unit: "in",
                    format: "a4",
                    orientation: "portrait",
                },
            };
        },
        _reviewFilterDate: {
            get() {
                return this.dronesReportFilter.date;
            },
            set(val) {
                this.setDronesReportFilter({ key: "date", value: val });
            },
        },
        _reviewFilterDevice: {
            get() {
                return this.dronesReportFilter.device;
            },
            set(val) {
                this.setDronesReportFilter({ key: "device", value: val });
            },
        },
        optionsDevice() {
            let array = [];
            for (let e of this.dronesElements) {
                array.push({
                    value: e.vUID,
                    label: e.internals.vCHILD_NAME,
                });
            }
            return array.sort((a, b) => (a.label > b.label ? 1 : -1));
        },
    },
    methods: {
        ...mapActions([
            "setDronesReportFilter",
            "fetchDronesReport",
            "resetDronesReport",
            "setNavForcedLoading",
        ]),
        exportToPDF() {
            console.log(this.$refs.document);
            html2pdf(this.$refs.document, {
                margin: 1,
                filename: "document.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { dpi: 192, letterRendering: true },
                jsPDF: {
                    unit: "in",
                    format: "letter",
                    orientation: "landscape",
                },
            });
        },
        handleFetchDronesReport() {
            this.$message({
                message: this.$t("Message_analysing_period_data"),
                type: "info",
                showClose: true,
                offset: 60,
            });
            this.fetchDronesReport();
        },
        handleGenerateDronesReport() {
            this.$refs.contentDroneReport.generatePdf();
        },
        msToDDMMYYYY(ms) {
            return moment(ms).format("DD/MM/YYYY");
        },
        msToDDMMYYYYHHmmss(ms) {
            return moment(ms).format("DD/MM/YYYY HH:mm:ss");
        },
        msToLLL(ms) {
            return moment(ms).locale("pt-br").format("LLL");
        },
    },
    components: {
        appDroneReportMissionsExecuted,
        appDroneReportMissionsByOperator,
        appDroneReportScheduledMissions,
        appDroneReportMissionsNotExecuted,
        appDroneReportMissionsExecutedByHour,
        appDroneReportMissionsExecutedByDay,
        appDroneReportEvents,
        appDroneReportArtificialIntelligenceEvents,
        appDroneReportBatteryStates,
        VueHtml2pdf,
    },
    mounted() {},
};
</script>


<style scoped>
.main-topbar {
    position: absolute;
    top: 50px;
    left: 0px;
    height: 64px;
    width: 100vw;
}
</style>