<template>
    <div class="main-review-header bg-dark-low-gray">
        <div class="row text-left w-100">
            <div class="col">
                <div class="row row-title-review">
                    <div class="col-12">{{ $t("Date") }}</div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <el-date-picker
                            v-model="_reviewFilterDate"
                            type="daterange"
                            range-separator="a"
                            start-placeholder="-"
                            end-placeholder="-"
                            text-color="red"
                            format="dd/MM/yyyy"
                            value-format="timestamp"
                        >
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-title-review">
                    <div class="col-12">{{ $t("Time") }}</div>
                    <div class="col-12">
                        <el-time-picker
                            is-range
                            v-model="_reviewFilterTime"
                            range-separator="a"
                            start-placeholder="-"
                            end-placeholder="-"
                            format="HH:mm"
                            value-format="HH:mm"
                        >
                        </el-time-picker>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-title-review">
                    <div class="col-12 row-title-review">{{ $t("Types") }}</div>
                    <div class="col-12">
                        <el-select
                            v-model="_reviewFilterType"
                            placeholder="-"
                            multiple
                            collapse-tags
                            clearable
                            filterable
                            @change="handleReviewFilterType"
                        >
                            <el-option
                                v-for="item in optionsType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="item.disabled"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-title-review">
                    <div class="col-12 row-title-review">
                        {{ $t("Devices") }}
                    </div>
                    <div class="col-12" style="max-height: 20px">
                        <el-select
                            v-model="_reviewFilterDevice"
                            placeholder="-"
                            multiple
                            collapse-tags
                            clearable
                            filterable
                            @change="handleReviewFilterDevice"
                        >
                            <el-option
                                v-for="item in optionsDevice"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-title-review">
                    <div class="col-12 row-title-review">
                        {{ $t("Events") }}
                    </div>
                    <div class="col-12">
                        <el-select
                            v-model="_reviewFilterEvent"
                            placeholder="-"
                            multiple
                            collapse-tags
                            clearable
                            filterable
                        >
                            <el-option
                                v-for="item in optionsEvents"
                                :key="item.value"
                                :label="item[$t('label')]"
                                :value="item.value"
                            >
                                <div class="row">
                                    <div class="col-10 text-left">
                                        {{ item[$t("label")] }}
                                    </div>
                                    <div
                                        class="col-2 text-right"
                                        v-if="
                                            events
                                                .map((v) => v.value)
                                                .indexOf(item.value) !== -1
                                        "
                                    >
                                        <i
                                            :style="{
                                                color: events[
                                                    events
                                                        .map((v) => v.value)
                                                        .indexOf(item.value)
                                                ].color,
                                            }"
                                            class="fas fa-circle text-right"
                                        ></i>
                                    </div>
                                </div>
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-title-review">
                    <div class="col-12 row-title-review">
                        {{ $t("Location") }}
                    </div>
                    <div
                        class="col-12"
                        @click="
                            mapFilterCoordinates = JSON.parse(
                                JSON.stringify(filter.coordinates)
                            );
                            $bvModal.show('modal-choose-location-filter');
                            handleFilterCoordinatesInputClick();
                        "
                    >
                        <el-input
                            placeholder="- "
                            v-model="filterCoordinates"
                            readonly
                            clearable
                        ></el-input>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row row-title-review">
                    <div class="col-12 row-title-review">
                        {{ $t("Medias") }}
                    </div>
                    <div class="col-12 align-middle">
                        <!-- <el-checkbox
                            v-model="_reviewFilterWithMedias"
                        ></el-checkbox> -->

                        <el-select
                            v-model="_reviewFilterMedias"
                            placeholder="-"
                            clearable
                            filterable
                        >
                            <el-option
                                v-for="item in optionsMedias"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <!-- <div class="col text-center mx-0 px-0"> -->
            <div class="row row-title-review mx-2 px-0 text-right">
                <div
                    class="col-12 row-title-review mx-0 px-0"
                    style="color: transparent"
                >
                    {{ "-" }}
                </div>
                <div class="col-12 mx-0 px-0">
                    <button
                        class="btn btn-primary"
                        @click="handleFetchReviews"
                        :disabled="
                            (_reviewFilterDate && _reviewFilterDevice
                                ? _reviewFilterDate.length !== 2 ||
                                  _reviewFilterDevice.length === 0
                                    ? true
                                    : false
                                : true) || logReviewsStatus === 'waiting'
                        "
                    >
                        {{ $t("SEARCH") }}
                    </button>
                </div>
            </div>
            <!-- </div> -->
        </div>

        <!-- <div class="row">
            <div class="col">{{ filter }}</div>
        </div> -->
        <div>
            <b-modal
                id="modal-choose-location-filter"
                size="xl"
                class="my-modal"
                @show="placeSetMarker = []"
                @shown="fitElements"
            >
                <template v-slot:modal-title>{{
                    $t("Select_a_location")
                }}</template>
                <div style="width: 100%; height: 60vh; margin-bottom: 60px">
                    <div
                        style="
                            position: relative;
                            top: 60px;
                            z-index: 9999;
                            width: calc(100% - 400px);
                            margin-left: 200px;
                            margin-right: 200px;
                        "
                        class="text-center"
                    >
                        <gmap-autocomplete
                            :placeholder="$t('Search_by_address')"
                            style="
                                width: 400px;
                                color: #908f8f;
                                border-radius: 20px;
                                box-shadow: none !important;
                                border: none;
                                height: 40px;
                                outline: none;
                            "
                            ref="gmapAutocompleteFilter"
                            class="text-center bg-dark-low-gray"
                            @place_changed="setPlace"
                            :select-first-on-enter="true"
                        >
                            <template v-slot:input="slotProps">
                                <v-text-field
                                    outlined
                                    prepend-inner-icon="place"
                                    placeholder="Location Of Event"
                                    ref="inputGmapAutocompleteFilter"
                                    v-on:listeners="slotProps.listeners"
                                    v-on:attrs="slotProps.attrs"
                                    :value="selectedAddress"
                                >
                                </v-text-field>
                            </template>
                        </gmap-autocomplete>
                    </div>
                    <gmap-map
                        :zoom="4"
                        ref="mapRefLocationFilter"
                        style="
                            width: 100%;
                            height: 100%;
                            padding: 0;
                            margin: 0;
                            outline: none;
                        "
                        @dblclick="doubleClickOnGmapsLocationFilter($event)"
                        :options="{ disableDoubleClickZoom: true, tilt: 0 }"
                        :center="center"
                    >
                        <template>
                            <gmap-marker
                                v-for="(p, index) in placeSetMarker"
                                :key="'place-set-marker-' + index"
                                :position="p.position"
                                :icon="p.icon"
                            ></gmap-marker>

                            <gmap-info-window
                                v-for="(p, index) in placeSetMarker"
                                :key="'place-set-marker-' + index"
                                :position="p.position"
                                :options="p.infoOptions"
                                :opened="true"
                                :clickable="false"
                            >
                            </gmap-info-window>
                        </template>
                        <gmap-circle
                            v-if="
                                mapFilterCoordinates.lat &&
                                mapFilterCoordinates.lng &&
                                mapFilterCoordinates.radius
                            "
                            ref="circleFilterRef"
                            :center="{
                                lat: mapFilterCoordinates.lat,
                                lng: mapFilterCoordinates.lng,
                            }"
                            :radius="mapFilterCoordinates.radius"
                            :visible="true"
                            :options="{
                                fillColor: 'red',
                                fillOpacity: 0.3,
                                strokeColor: 'red',
                            }"
                            clickable
                            draggable
                            :editable="true"
                            @center_changed="handleCenterChanged"
                            @radius_changed="handleRadiusChanged"
                            @dragend="handleDragend"
                        />
                    </gmap-map>
                </div>
                <br />
                <div
                    v-if="
                        mapFilterCoordinates.lat &&
                        mapFilterCoordinates.lng &&
                        mapFilterCoordinates.radius
                    "
                >
                    <div>
                        <div class="row">
                            <div class="col-4 text-center">Latitude (°)</div>
                            <div class="col-4 text-center">Longitude (°)</div>
                            <div class="col-4 text-center">Raio (m)</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-center">
                                <el-input-number
                                    v-model="mapFilterCoordinates.lat"
                                    controls-position="right"
                                    :min="-90"
                                    :max="90"
                                    :step="0.1"
                                    label="°"
                                ></el-input-number>
                            </div>
                            <div class="col-4 text-center">
                                <el-input-number
                                    v-model="mapFilterCoordinates.lng"
                                    controls-position="right"
                                    :min="-180"
                                    :max="180"
                                    :step="0.1"
                                    label="°"
                                ></el-input-number>
                            </div>
                            <div class="col-4 text-center">
                                <el-input-number
                                    v-model="mapFilterCoordinates.radius"
                                    controls-position="right"
                                    :min="1"
                                    :max="5000000"
                                    :step="1"
                                    label="m"
                                ></el-input-number>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-12 text-center">
                            Clique duas vezes no mapa para definir uma região de
                            interesse
                        </div>
                    </div>
                </div>
                <template #modal-footer="{ ok, cancel }">
                    <button
                        class="btn btn-primary btn-bold"
                        @click="
                            filter.coordinates = mapFilterCoordinates;
                            ok();
                        "
                    >
                        {{ $t("Confirm") }}
                    </button>
                    <button
                        class="btn btn-secondary btn-bold"
                        @click="resetFilterCoordinates()"
                    >
                        {{ $t("Clear") }}
                    </button>
                    <button
                        class="btn btn-secondary btn-bold"
                        @click="cancel()"
                    >
                        {{ $t("Cancel") }}
                    </button>
                </template>
            </b-modal>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
    data() {
        return {
            ignoreNoneReviewFilterEvent: false,
            filter: {
                date: null,
                time: null,
                type: null,
                device: null,
                event: null,
                coordinates: {
                    lat: null,
                    lng: null,
                    radius: null,
                },
                medias: null,
            },
            mapFilterCoordinates: {
                lat: null,
                lng: null,
                radius: null,
            },
            center: { lat: -23.508, lng: -46.587 },
            placeSetMarker: [],
        };
    },
    computed: {
        ...mapGetters([
            "logReviewsFilter",
            "smartElements",
            "logReviewsStatus",
            "events",
            "dronesMarkers",
            "solosMarkers",
        ]),
        options() {
            return {
                type: [
                    { value: "drone", label: "Drone" },
                    { value: "solo", label: "SmartCam" },
                ],
                medias: [
                    { value: false, label: this.$t("Without_medias") },
                    { value: true, label: this.$t("With_medias") },
                ],
            };
        },
        filterCoordinates() {
            this.setLogReviewsFilter({
                key: "coordinates",
                value: this.filter.coordinates,
            });
            if (
                this.filter.coordinates.lat !== null &&
                this.filter.coordinates.lng !== null &&
                this.filter.coordinates.radius !== null
            ) {
                return `(${this.filter.coordinates.lat.toFixed(
                    0
                )}°, ${this.filter.coordinates.lng.toFixed(0)}°, ${(
                    this.filter.coordinates.radius / 1000.0
                ).toFixed(0)} km)`;
            } else {
                return "-";
            }
        },
        optionsType() {
            return [
                { value: "drone", label: "Drone" },
                { value: "solo", label: "SmartCam" },
            ].filter(
                (e) =>
                    this.smartElements
                        .map((v) => v.internals.vCHILD_TYPE)
                        .filter(
                            (value, index, self) =>
                                self.indexOf(value) === index
                        )
                        .indexOf(e.value) !== -1
            );
        },
        optionsDevice() {
            let self = this;
            let array = [];

            for (let e of this.smartElements.filter(
                (v) =>
                    self._reviewFilterType.indexOf(v.internals.vCHILD_TYPE) !==
                        -1 || self._reviewFilterType.length === 0
            )) {
                array.push({
                    value: e.vUID,
                    label: e.internals.vCHILD_NAME.substring(0, 20),
                });
            }
            return [
                {
                    label:
                        this._reviewFilterDevice.filter((v) => !v.includes("$"))
                            .length !== self.smartElements.length
                            ? this.$t("Select_all")
                            : this.$t("Clear_selection"),
                    value:
                        this._reviewFilterDevice.filter((v) => !v.includes("$"))
                            .length !== self.smartElements.length
                            ? "$select_all"
                            : "$deselect_all",
                },
            ].concat(array.sort((a, b) => (a.label > b.label ? 1 : -1)));
        },
        optionsEvents() {
            let self = this;
            let array = [];
            for (let e of this.events.filter(
                (v) =>
                    (self._reviewFilterType.includes("drone") &&
                        v.consideredByDrone) ||
                    (self._reviewFilterType.includes("solo") &&
                        v.consideredBySolo) ||
                    self._reviewFilterType.length === 0
            )) {
                array.push(e);
            }

            if (self.$t("label") === "label_pt") {
                return [{ label_pt: "Nenhum evento", value: "none" }].concat(
                    array.sort((a, b) =>
                        a[self.$t("label")] > b[self.$t("label")] ? 1 : -1
                    )
                );
            } else {
                return [{ label_en: "None event", value: "none" }].concat(
                    array.sort((a, b) =>
                        a[self.$t("label")] > b[self.$t("label")] ? 1 : -1
                    )
                );
            }
        },
        optionsMedias() {
            let array = [];
            for (let e of this.options.medias) {
                array.push(e);
            }
            return array.sort((a, b) => (a.label > b.label ? 1 : -1));
        },
        _reviewFilterDate: {
            get() {
                return this.logReviewsFilter.date;
            },
            set(val) {
                this.setLogReviewsFilter({ key: "date", value: val });
            },
        },
        _reviewFilterTime: {
            get() {
                return this.logReviewsFilter.time;
            },
            set(val) {
                this.setLogReviewsFilter({ key: "time", value: val });
            },
        },
        _reviewFilterType: {
            get() {
                return this.logReviewsFilter.type;
            },
            set(val) {
                this.setLogReviewsFilter({ key: "type", value: val });
            },
        },
        _reviewFilterDevice: {
            get() {
                return this.logReviewsFilter.device;
            },
            set(val) {
                this.setLogReviewsFilter({ key: "device", value: val });
            },
        },
        _reviewFilterEvent: {
            get() {
                return this.logReviewsFilter.event;
            },
            set(val) {
                this.setLogReviewsFilter({ key: "event", value: val });
            },
        },
        _reviewFilterMedias: {
            get() {
                return this.logReviewsFilter.medias;
            },
            set(val) {
                this.setLogReviewsFilter({
                    key: "medias",
                    value: val === true || val === false ? val : null,
                });
            },
        },
    },
    methods: {
        ...mapActions(["setLogReviewsFilter", "fetchReviews"]),
        handleReviewFilterType(val) {
            let self = this;
            let devicesFiltered = this._reviewFilterDevice.filter(
                (e) =>
                    val.indexOf(
                        self.smartElements[
                            self.smartElements.map((v) => v.vUID).indexOf(e)
                        ].internals.vCHILD_TYPE
                    ) !== -1
            );
            this.setLogReviewsFilter({ key: "device", value: devicesFiltered });

            let eventsFiltered = this._reviewFilterEvent.filter(
                (e) =>
                    (self.events[self.events.map((v) => v.value).indexOf(e)][
                        "consideredBySolo"
                    ] &&
                        val.includes("solo")) ||
                    (self.events[self.events.map((v) => v.value).indexOf(e)][
                        "consideredByDrone"
                    ] &&
                        val.includes("drone"))
            );
            this.setLogReviewsFilter({ key: "event", value: eventsFiltered });
        },
        handleReviewFilterDevice(val) {},
        resetFilterCoordinates() {
            this.mapFilterCoordinates = {
                lat: null,
                lng: null,
                radius: null,
            };
            this.placeSetMarker = [];
        },
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        doubleClickOnGmapsLocationFilter(e) {
            let self = this;
            this.$refs.mapRefLocationFilter.$mapPromise.then((map) => {
                let bounds = map.getBounds();
                let ne = bounds.getNorthEast();
                let sw = bounds.getSouthWest();

                let distance = self.distanceInKm([
                    { lat: ne.lat(), lng: ne.lng() },
                    { lat: sw.lat(), lng: sw.lng() },
                ]);

                this.mapFilterCoordinates.lat = e.latLng.lat();
                this.mapFilterCoordinates.lng = e.latLng.lng();
                this.mapFilterCoordinates.radius = Math.floor(
                    ((((distance * 1000) / 2) * 1) / Math.pow(2, 0.5)) * 0.8
                );

                setTimeout(() => {
                    this.fitCircle("circleFilterRef");
                }, 200);
            });
        },
        fitCircle(ref) {
            this.$refs[ref].$circlePromise.then(() => {
                const { $circleObject } = this.$refs[ref]; //get google.maps.Circle object
                const map = $circleObject.getMap();
                map.fitBounds($circleObject.getBounds());
            });
        },
        handleFilterCoordinatesInputClick() {
            let self = this;

            setTimeout(() => {
                this.$refs.mapRefLocationFilter.$mapPromise.then((map) => {
                    map.setCenter(
                        self.mapFilterCoordinates.lat &&
                            self.mapFilterCoordinates.lng
                            ? {
                                  lat: self.mapFilterCoordinates.lat,
                                  lng: self.mapFilterCoordinates.lng,
                              }
                            : { lat: -23.508, lng: -46.587 }
                    );

                    if (
                        self.mapFilterCoordinates.lat &&
                        self.mapFilterCoordinates.lng
                    ) {
                        setTimeout(() => {
                            this.fitCircle("circleFilterRef");
                        }, 200);
                    }
                });
            }, 200);
        },
        handleCenterChanged(e) {
            this.mapFilterCoordinates.lat = e.lat();
            this.mapFilterCoordinates.lng = e.lng();
        },
        handleRadiusChanged(e) {
            this.mapFilterCoordinates.radius = Math.round(e);
            this.fitCircle("circleFilterRef");
        },
        handleDragend(e) {
            this.fitCircle("circleFilterRef");
        },
        handleFetchReviews() {
            this.$message({
                message: this.$t("Message_analysing_period_data"),
                type: "info",
                showClose: true,
                offset: 60,
            });
            this.fetchReviews();
        },
        setPlace(place) {
            if (!place) {
                return;
            }
            let self = this;
            self.center = {
                lat: place.geometry.location.lat() + Math.random() * 0.0000001,
                lng: place.geometry.location.lng() + Math.random() * 0.0000001,
            };
            this.$refs.mapRefLocationFilter.$mapPromise.then((map) => {
                map.setZoom(15);
                self.$refs.gmapAutocompleteFilter.$refs.input.value = "";
            });

            this.placeSetMarker.push({
                position: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                },
                icon: {
                    rotation: 0,
                    scale: 8,
                    anchor: new google.maps.Point(0, 0),
                },
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: 50,
                    },
                    disableAutoPan: true,
                    content:
                        "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'> " +
                        place.formatted_address +
                        "</strong></div>",
                },
            });
        },
        fitElements() {
            let markers = this.dronesMarkers.concat(this.solosMarkers);
            this.$refs.mapRefLocationFilter.$mapPromise.then((map) => {
                if (markers.length === 1) {
                    map.setCenter(markers[0].position);
                    map.setZoom(8);
                } else if (markers.length > 1) {
                    const bounds = new google.maps.LatLngBounds();
                    for (let m of markers) {
                        bounds.extend(m.position);
                    }
                    map.fitBounds(bounds);
                }
            });
        },
    },
    watch: {
        _reviewFilterEvent: function (newVal, oldVal) {
            if (newVal.includes("none") && !oldVal.includes("none")) {
                this.ignoreNoneReviewFilterEvent = true;
                this.setLogReviewsFilter({ key: "event", value: ["none"] });
            } else if (newVal.includes("none") && oldVal.includes("none")) {
                if (!this.ignoreNoneReviewFilterEvent) {
                    this.setLogReviewsFilter({
                        key: "event",
                        value: newVal.filter((v) => v !== "none"),
                    });
                } else {
                    this.ignoreNoneReviewFilterEvent = false;
                }
            }
        },
        _reviewFilterDevice: function (newVal, oldVal) {
            if (newVal.includes("$select_all")) {
                this.setLogReviewsFilter({
                    key: "device",
                    value: this.smartElements.map((v) => v.vUID),
                });
            } else if (newVal.includes("$deselect_all")) {
                this.setLogReviewsFilter({ key: "device", value: [] });
            }
        },
    },
};
</script>

<style scoped>
.main-review-header {
    padding: 10px;
}
.pac-container {
    z-index: 99999 !important;
}
</style>