<template>
    <div @click="handleStreamElementClick()">
        <div
            style="position:absolute;left:0px:top:0px;z-index:9999;background-color:#FFE082;color:#121212;padding:0px 20px 0px 20px;font-size:1rem;
    border-bottom-right-radius:10px;"
        >
            <div>{{ element.internals.vCHILD_NAME }}</div>
        </div>
        <div
            class="main-stream-element"
            v-if="
                (element.vIS_CONNECTED || element.vIS_STANDBY) &&
                element.stream.vIS_STREAMING_TO_CLOUD
            "
            style="width: 100%; height: 100%; background-color: #292929"
        >
            <div
                :id="'id-main-stream-element-video-' + element.vUID"
                class="main-stream-element-video"
                style="width: 100%; height: 100%; background-color: #292929"
                v-if="
                    element.internals.vCHILD_TYPE === 'drone' &&
                    element.stream.vIS_RTMP_STREAM !== true
                "
            ></div>
            <div
                class="main-stream-element-video"
                style="width: 100%; height: 100%; background-color: #292929"
                v-else-if="element.internals.vCHILD_TYPE === 'solo'"
            >
                <app-solo-stream-video-element
                    style="height: 100%; width: 100%"
                    :id="'id-mosaic-solo-stream-video-element-' + element.vUID"
                    :url="element.stream.vCLOUD_STREAM_PLAYBACK || ''"
                ></app-solo-stream-video-element>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import appSoloStreamVideoElement from "@/components/solo/SoloStreamVideoElement";

export default {
    data() {
        return {
            session: null,
            publisher: null,
            subscriber: null,
            apiKey: null,
            sessionId: null,
            token: null,
        };
    },
    components: {
        appSoloStreamVideoElement,
    },
    methods: {
        ...mapActions([
            "setNavStreamDialogDrawerVisible",
            "setNavMainNav",
            "setDroneActiveMission",
            "setNavTopbar",
            "setDroneActive",
            "setSoloActive",
        ]),
        startStream() {
            let self = this;

            if (self.element.internals.vCHILD_TYPE === "drone") {
                axios
                    .get(
                        "https://aeroguard-opentok.herokuapp.com/room/" +
                            self.element.vUID
                    )
                    .then(function (response) {
                        self.apiKey = response.data.apiKey;
                        self.sessionId = response.data.sessionId;
                        self.token = response.data.token;

                        self.session = OT.initSession(
                            self.apiKey,
                            self.sessionId
                        );
                        self.session ? self.session.disconnect() : false;
                        self.session = OT.initSession(
                            self.apiKey,
                            self.sessionId
                        );

                        self.session.on("streamCreated", function (event) {
                            if (!self.subscribed) {
                                self.session.subscribe(
                                    //myself
                                    event.stream,
                                    "id-main-stream-element-video-" +
                                        self.element.vUID,
                                    {
                                        // showControls: false,
                                        insertMode: "replace",
                                        width: "100%",
                                        height: "100%",
                                        style: { buttonDisplayMode: "off" },
                                        subscribeToAudio: false,
                                        subscribeToVideo: true,
                                    },
                                    self.handleError
                                );
                                self.subscribed = true;
                            }
                        });
                        self.session.connect(self.token, function (error) {
                            // If the connection is successful, initialize a publisher and publish to the session
                            if (error) {
                                self.handleError(error);
                            } else {
                                // self.session.publish(self.publisher, self.handleError);
                            }
                        });
                    });
            } else if (self.element.internals.vCHILD_TYPE === "solo") {
            }
        },
        handleStreamElementClick() {
            this.setNavStreamDialogDrawerVisible(false);

            let self = this;
            setTimeout(() => {
                self.setNavMainNav(self.element.internals.vCHILD_TYPE);
                self.setDroneActiveMission(null);
                self.setNavTopbar(1);
                if (self.element.internals.vCHILD_TYPE === "drone") {
                    self.setDroneActive(this.element.vUID);
                } else if (self.element.internals.vCHILD_TYPE === "solo") {
                    self.setSoloActive(this.element.vUID);
                }
            }, 250);
        },
    },
    props: ["element"],
    mounted() {
        let self = this;
        if (
            (this.element.vIS_CONNECTED || this.element.vIS_STANDBY) &&
            this.element.stream.vIS_STREAMING_TO_CLOUD
        ) {
            setTimeout(() => {
                if (
                    (self.element.vIS_CONNECTED || self.element.vIS_STANDBY) &&
                    self.element.stream.vIS_STREAMING_TO_CLOUD
                ) {
                    self.startStream();
                }
            }, 200);
        }
    },
    beforeDestroy() {
        let self = this;
        self.session ? self.session.disconnect() : false;
    },
};
</script>


<style scoped>
.main-stream-element {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

video {
    width: 100%;
    height: 100%;
}
</style>