<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">Gestão de Drones</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para criar, deletar ou editar contas
                            de drones.</span
                        >
                    </div>
                </div>
                <br />
                <br />
            </div>

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1;
                "
                @click="dialogCreateUser.visible = true"
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="
                        treatedDrones.filter(
                            (data) =>
                                !search ||
                                data.vCHILD_NAME
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vTEAM_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_EMAIL
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                    "
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column sortable prop="vCHILD_NAME" label="Nome">
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLAST_LOGIN"
                        label="Último login"
                        ><template slot-scope="scope"
                            >{{
                                msToDDMMYYYYHHmmssAndPhrase(
                                    scope.row.vLAST_LOGIN
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLAST_LOG_SERVICE_TIMESTAMP"
                        label="Última missão"
                        ><template slot-scope="scope">
                            {{
                                msToDDMMYYYYHHmmssAndPhrase(
                                    scope.row.vLAST_LOG_SERVICE_TIMESTAMP
                                )
                            }}
                            <button
                                @click="handleOpenLogService(scope.row)"
                                class="btn btn-link"
                                :disabled="
                                    !scope.row.vLAST_LOG_SERVICE_TIMESTAMP
                                "
                                v-if="scope.row.vLAST_LOG_SERVICE_TIMESTAMP"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vCHILD_UID" label="UID">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vCHILD_UID,
                                        'ID copiado para a área de transferência',
                                        'Não foi possível copiar o ID para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vCHILD_UID }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vTEAM_UID"
                        label="ID do time"
                    >
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vTEAM_UID,
                                        'ID do time copiado para a área de transferência',
                                        'Não foi possível copiar o ID do time para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vTEAM_UID }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vCHILD_EMAIL" label="Email">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vCHILD_EMAIL,
                                        'Email copiado para a área de transferência',
                                        'Não foi possível copiar o Email para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vCHILD_EMAIL }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable label="Seguindo">
                        <template slot-scope="scope">
                            <button
                                v-if="
                                    followedElements.indexOf(
                                        scope.row.vCHILD_UID
                                    ) !== -1
                                "
                                @click="handleUnfollowClick(scope.row)"
                                class="btn btn-link"
                            >
                                <i
                                    class="fas fa-user-minus"
                                    style="color: #e6cb6b"
                                ></i>
                            </button>
                            <button
                                v-else
                                class="btn btn-link"
                                @click="handleFollowClick(scope.row)"
                            >
                                <i class="fas fa-user-plus"></i>
                            </button>
                        </template>
                    </el-table-column>

                    <el-table-column sortable label="Associar">
                        <template slot-scope="scope">
                            <el-popover
                                placement="left"
                                title="Associar elemento ao time"
                                width="600"
                                trigger="click"
                                @show="teamUidToAssociate = ''"
                            >
                                <div class="row">
                                    <div class="col-9">
                                        <el-input
                                            placeholder="ID do time"
                                            v-model="teamUidToAssociate"
                                        ></el-input>
                                    </div>
                                    <div class="col-3">
                                        <button
                                            class="btn btn-primary"
                                            @click="
                                                handleAssociateChild(scope.row)
                                            "
                                            :disabled="
                                                teamUidToAssociate === ''
                                            "
                                        >
                                            Associar
                                        </button>
                                    </div>
                                </div>
                                <button class="btn btn-link" slot="reference">
                                    <i class="fas fa-link"></i>
                                </button>
                            </el-popover>

                            <el-popover
                                placement="left"
                                title="Associar elemento ao time"
                                width="600"
                                trigger="click"
                                @show="teamUidToDisassociate = ''"
                            >
                                <div class="row">
                                    <div class="col-9">
                                        <el-input
                                            placeholder="ID do time"
                                            v-model="teamUidToDisassociate"
                                        ></el-input>
                                    </div>
                                    <div class="col-3">
                                        <button
                                            class="btn btn-primary"
                                            @click="
                                                handleDisassociateChild(
                                                    scope.row
                                                )
                                            "
                                            :disabled="
                                                teamUidToDisassociate === ''
                                            "
                                        >
                                            Desassociar
                                        </button>
                                    </div>
                                </div>
                                <button class="btn btn-link" slot="reference">
                                    <i class="fas fa-ban"></i>
                                </button>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column align="right">
                        <template slot="header" slot-scope="scope">
                            <el-input
                                v-model="search"
                                size="mini"
                                placeholder="Procurar"
                                style="border: 1px solid #a1a0a0"
                                :id="scope"
                                clearable
                            >
                                > ></el-input
                            >
                        </template>
                        <template slot-scope="scope">
                            <button
                                @click="handleEdit(scope.$index, scope.row)"
                                class="btn btn-link"
                            >
                                <i
                                    class="fas fa-pen"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                :cancel-button-text="$t('Cancel')"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="
                                    confirmDeleteUser(scope.$index, scope.row)
                                "
                            >
                                <button class="btn btn-link" slot="reference">
                                    <i
                                        class="fas fa-trash"
                                        style="font-size: 1rem !important"
                                    ></i>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>

        <!-- dialog create user-->
        <el-dialog
            title="Adicionar usuário"
            :visible.sync="dialogCreateUser.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <div class="row">
                    <div class="col-12 text-left">Tipo</div>
                </div>
                <br />
                <div class="row">
                    <div class="col-12">
                        <el-select
                            class="w-100"
                            v-model="dialogCreateUser.userType"
                            placeholder="-"
                            disabled
                        >
                            <el-option
                                v-for="item in dialogCreateUser.userTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <br />

                <!--  -->
                <el-form
                    v-if="dialogCreateUser.userType === 'drone'"
                    ref="form-create-user-drone"
                    :model="dialogCreateUser.formDrone"
                    :rules="dialogCreateUser.rulesDrone"
                >
                    <!--  -->
                    <el-form-item label="Nome" prop="vCHILD_NAME">
                        <el-input
                            :maxlength="20"
                            v-model="dialogCreateUser.formDrone.vCHILD_NAME"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <el-form-item label="ID do Time" prop="vTEAM_UID">
                        <el-input
                            :maxlength="200"
                            v-model="dialogCreateUser.formDrone.vTEAM_UID"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogCreateUser.formDrone
                                            .vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <!--  -->
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="
                                        dialogCreateUser.formDrone.vCHILD_ID
                                    "
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <el-form-item
                        :label="$t('Expected_daily_missions')"
                        prop="vEXPECTED_DAILY_MISSIONS"
                    >
                        <el-input-number
                            class="w-100"
                            v-model="
                                dialogCreateUser.formDrone
                                    .vEXPECTED_DAILY_MISSIONS
                            "
                            controls-position="right"
                            :min="1"
                            :max="100"
                            :step="1"
                        ></el-input-number>
                    </el-form-item>
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogCreateUser.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="
                                submitCreateUserForm(
                                    `form-create-user-${dialogCreateUser.userType}`
                                )
                            "
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!-- dialog edit user-->
        <el-dialog
            title="Editar usuário"
            :visible.sync="dialogEditUser.visible"
            style=""
            width="570px"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    v-if="dialogEditUser.userType === 'drone'"
                    ref="form-edit-user-drone"
                    :model="dialogEditUser.formDrone"
                    :rules="dialogEditUser.rulesDrone"
                >
                    <div class="row">
                        <div class="col-6">
                            <el-form-item label="Nome" prop="vCHILD_NAME">
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogEditUser.formDrone.vCHILD_NAME
                                    "
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    :maxlength="20"
                                    v-model="
                                        dialogEditUser.formDrone.vCHILD_CONTACT
                                    "
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <el-form-item label="ID do Time" prop="vTEAM_UID">
                        <el-input
                            disabled
                            readonly
                            v-model="dialogEditUser.formDrone.vTEAM_UID"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    :maxlength="14"
                                    v-model="dialogEditUser.formDrone.vCHILD_ID"
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Expected_daily_missions')"
                                prop="vEXPECTED_DAILY_MISSIONS"
                            >
                                <el-input-number
                                    class="w-100"
                                    v-model="
                                        dialogEditUser.formDrone
                                            .vEXPECTED_DAILY_MISSIONS
                                    "
                                    controls-position="right"
                                    :min="1"
                                    :max="100"
                                    :step="1"
                                ></el-input-number>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="text-left">
                        <el-checkbox
                            :label="
                                dialogEditUser.formDrone
                                    .vIS_AVAILABLE_FOR_MISSION === false
                                    ? 'Indisponível para missões'
                                    : 'Disponível para missões'
                            "
                            v-model="
                                dialogEditUser.formDrone
                                    .vIS_AVAILABLE_FOR_MISSION
                            "
                        ></el-checkbox>
                    </div>
                    <!--  -->
                    <!--  -->
                    <br />
                    <!--  -->
                    <!--  -->
                    <!--  -->
                    <div class="row">
                        <div class="col-12 text-left">Lista de operadores</div>
                    </div>
                    <br />

                    <div class="row">
                        <div class="col-12 text-left">
                            <el-tag
                                :key="tag"
                                v-for="tag in dialogEditUser.formDrone
                                    .operators"
                                closable
                                :disable-transitions="false"
                                @close="handleClose(tag)"
                                class="mx-2 my-2"
                                type="info"
                            >
                                {{ tag }}
                            </el-tag>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-12 text-left">
                            <el-input
                                class="input-new-tag my-2"
                                v-if="inputVisible"
                                v-model="inputValue"
                                ref="saveTagInput"
                                size="mini"
                                @keyup.enter.native="handleInputConfirm"
                                @blur="handleInputConfirm"
                            >
                            </el-input>

                            <el-button
                                v-else
                                class="button-new-tag"
                                size="small"
                                @click="showInput"
                                >+ Novo operador</el-button
                            >
                        </div>
                    </div>
                    <br />
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialogEditUser.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="
                                submitEditUserForm(
                                    `form-edit-user-${dialogEditUser.userType}`
                                )
                            "
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
import momenttz from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            teamUidToAssociate: "",
            teamUidToDisassociate: "",
            masks: {
                vCHILD_ID: {
                    mask: "###.###.###-##",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
                vCHILD_CONTACT: {
                    mask: "(##)#####-####",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
            },
            treatedDrones: [],
            dialogCreateUser: {
                visible: false,
                userType: "drone",
                userTypeOptions: [{ label: "Drone", value: "drone" }],
                //
                formDrone: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "(00)00000-0000",
                    vCHILD_ID: "000.000.000-00",
                    vEXPECTED_DAILY_MISSIONS: 1,
                    vTEAM_UID: "",
                },
                rulesDrone: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vEXPECTED_DAILY_MISSIONS: [
                        {
                            required: true,
                            message:
                                "Defina a quantidade de missões diárias previstas",
                            trigger: "change",
                        },
                    ],
                    vTEAM_UID: [
                        {
                            required: true,
                            message: "Defina o ID do time",
                            trigger: "change",
                        },
                    ],
                },
            },
            dialogEditUser: {
                visible: false,
                userUid: null,
                userType: null,
                formDrone: {
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "",
                    vCHILD_ID: "",
                    vEXPECTED_DAILY_MISSIONS: 1,
                    vTEAM_UID: "",
                    vIS_AVAILABLE_FOR_MISSION: null,
                    operators: [],
                },
                rulesDrone: {
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vEXPECTED_DAILY_MISSIONS: [
                        {
                            required: true,
                            message:
                                "Defina a quantidade de missões diárias previstas",
                            trigger: "change",
                        },
                    ],
                    vTEAM_UID: [
                        {
                            required: true,
                            message: "Defina o ID do time",
                            trigger: "change",
                        },
                    ],
                },
            },
            inputVisible: false,
            inputValue: "",
            search: "",
            followedElements: [],
        };
    },
    computed: {
        ...mapGetters(["user", "dronesElements"]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
    },
    methods: {
        copyToClipboard(text, messageSuccess, messageError) {
            let self = this;
            this.$copyText(text).then(
                () => {
                    self.$message({
                        message: messageSuccess,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                },
                () => {
                    self.$message({
                        message: messageError,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            );
        },
        msToDDMMYYYYHHmmssAndPhrase(ms) {
            if (!isNaN(ms) && ms !== null) {
                if (ms !== 0) {
                    if (Date.now() - ms < 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / 1000
                        )} segundo${
                            Math.floor((Date.now() - ms) / 1000) > 1 ? "s" : ""
                        })`;
                    } else if (Date.now() - ms < 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / (60 * 1000)
                        )} minuto${
                            Math.floor((Date.now() - ms) / (60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else if (Date.now() - ms < 72 * 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (60 * 60 * 1000)
                        )} hora${
                            Math.floor((Date.now() - ms) / (60 * 60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (24 * 60 * 60 * 1000)
                        )} dia${
                            Math.floor(
                                (Date.now() - ms) / (24 * 60 * 60 * 1000)
                            ) > 1
                                ? "s"
                                : ""
                        })`;
                    }
                } else {
                    return "-";
                }
            } else {
                return "-";
            }
        },
        loadData() {
            this.loadFollowedElements();

            this.treatedDrones = [];
            let self = this;

            firebase
                .database()
                .ref("aegisv2/drones/")
                .orderByChild("vCHILD_UID")
                .once("value", function (snapshot) {
                    snapshot.forEach(function (childSnapshot) {
                        firebase
                            .database()
                            .ref(
                                "aegisv2/users/" +
                                    childSnapshot.child("vCHILD_UID").val() +
                                    "/internals"
                            )
                            .once("value", async function (data) {
                                let obj = {};
                                obj.vCHILD_UID = childSnapshot
                                    .child("vCHILD_UID")
                                    .val();

                                let parameters = [
                                    "vCHILD_CONTACT",
                                    "vCHILD_EMAIL",
                                    "vCHILD_NAME",
                                    "vLAST_LOGIN",
                                    "vTEAM_UID",
                                ];

                                for (let p of parameters) {
                                    obj[p] = data.child(p).val() || "-";
                                }
                                try {
                                    let currentLogServiceKey = data
                                        .child("vCURRENT_LOG_SERVICE_KEY")
                                        .val();
                                    if (currentLogServiceKey) {
                                        obj["vLAST_LOG_SERVICE_KEY"] =
                                            currentLogServiceKey;
                                        let currentLogServiceKeyTimestamp =
                                            await firebase
                                                .database()
                                                .ref(
                                                    `aegisv2/users/${childSnapshot
                                                        .child("vCHILD_UID")
                                                        .val()}/logServices/${currentLogServiceKey}/vTIMESTAMP`
                                                )
                                                .once("value");
                                        if (
                                            currentLogServiceKeyTimestamp.val()
                                        ) {
                                            obj["vLAST_LOG_SERVICE_TIMESTAMP"] =
                                                currentLogServiceKeyTimestamp.val();
                                        } else {
                                            obj["vLAST_LOG_SERVICE_TIMESTAMP"] =
                                                null;
                                        }
                                    } else {
                                        obj["vLAST_LOG_SERVICE_KEY"] = null;
                                        obj["vLAST_LOG_SERVICE_TIMESTAMP"] =
                                            null;
                                    }
                                } catch (error) {
                                    obj["vLAST_LOG_SERVICE_TIMESTAMP"] = null;
                                }
                                self.treatedDrones.push(obj);
                            });
                    });
                });
        },
        submitCreateUserForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let type =
                        formName.split("-")[formName.split("-").length - 1];
                    let f = type.charAt(0).toUpperCase() + type.slice(1);

                    self.confirmCreateChild(
                        type,
                        self.dialogCreateUser[`form${f}`]
                    );
                } else {
                    return false;
                }
            });
        },
        confirmCreateChild(type, data) {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(
                            `CLOUD_DASHBOARD_CREATE_${type.toUpperCase()}`
                        )({
                            payload: { internals: data },
                            command: `create-account-user-management-${type.toLowerCase()}`,
                            vTEAM_UID: data.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            vCHILD_TYPE: type,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            self.resetForm(`form-create-user-${type}`);
                            self.dialogCreateUser.visible = false;
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        handleEdit(i, r) {
            this.dialogEditUser.userUid = r.vCHILD_UID;

            let self = this;
            let promises = [];

            promises.push(
                new Promise(function (resolve, reject) {
                    firebase
                        .database()
                        .ref(`aegisv2/users/${r.vCHILD_UID}/internals`)
                        .once("value", function (data) {
                            resolve({
                                child: "internals",
                                data: data.val(),
                            });
                        });
                })
            );
            promises.push(
                new Promise(function (resolve, reject) {
                    firebase
                        .database()
                        .ref(`aegisv2/users/${r.vCHILD_UID}/elements`)
                        .once("value", function (data) {
                            resolve({
                                child: "elements",
                                data: (data.val() || []).filter(
                                    (v) => v !== null
                                ),
                            });
                        });
                })
            );
            promises.push(
                new Promise(function (resolve, reject) {
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${r.vCHILD_UID}/internals/operators`
                        )
                        .once("value", function (data) {
                            resolve({
                                child: "operators",
                                data: (data.val() || []).filter(
                                    (v) => v !== null
                                ),
                            });
                        });
                })
            );

            Promise.all(promises).then(function (values) {
                let r = {
                    internals: null,
                    elements: null,
                    operators: null,
                };

                for (let v of values) {
                    r[v.child] = v.data;
                }

                let type = r.internals.vCHILD_TYPE;
                let f = type.charAt(0).toUpperCase() + type.slice(1);

                self.dialogEditUser[`form${f}`].vCHILD_NAME =
                    r.internals.vCHILD_NAME;
                self.dialogEditUser[`form${f}`].vCHILD_ID =
                    r.internals.vCHILD_ID;
                self.dialogEditUser[`form${f}`].vCHILD_CONTACT =
                    r.internals.vCHILD_CONTACT;
                self.dialogEditUser.userType = type;

                self.dialogEditUser[`form${f}`].vIS_AVAILABLE_FOR_MISSION = !(
                    r.internals.vIS_AVAILABLE_FOR_MISSION === false
                );
                self.dialogEditUser[`form${f}`].vEXPECTED_DAILY_MISSIONS =
                    r.internals.vEXPECTED_DAILY_MISSIONS;
                self.dialogEditUser[`form${f}`].operators = r.operators;

                self.dialogEditUser[`form${f}`].vTEAM_UID =
                    r.internals.vTEAM_UID;

                self.dialogEditUser.visible = true;
            });
        },
        submitEditUserForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let type = self.dialogEditUser.userType;
                    let f = type.charAt(0).toUpperCase() + type.slice(1);

                    self.confirmEditChild(
                        type,
                        self.dialogEditUser[`form${f}`]
                    );
                } else {
                    return false;
                }
            });
        },
        confirmEditChild(type, data) {
            let self = this;

            // isolate internals data
            let internals = JSON.parse(JSON.stringify(data));
            delete internals.elements;
            delete internals.operators;
            delete internals.vCHILD_EMAIL;

            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(
                            `CLOUD_DASHBOARD_EDIT_${type.toUpperCase()}`
                        )({
                            payload: {
                                internals: internals,
                                elements: data.elements || [],
                                operators: data.operators || [],
                            },
                            command: `edit-account-user-management-${type.toLowerCase()}`,
                            vTEAM_UID: internals.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            vCHILD_UID: self.dialogEditUser.userUid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.dialogEditUser.visible = false;
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmDeleteUser(i, r) {
            const type = "drone";
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(
                            `CLOUD_DASHBOARD_DELETE_${type.toUpperCase()}`
                        )({
                            vTEAM_UID: r.vTEAM_UID,
                            vCREATOR_UID: self.user.data.uid,
                            vCHILD_UID: r.vCHILD_UID,
                            idToken: idToken,
                            command: `delete-account-user-management-${type}`,
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.loadData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick((_) => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.dialogEditUser.formDrone.operators.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = "";
        },
        handleClose(tag) {
            this.dialogEditUser.formDrone.operators.splice(
                this.dialogEditUser.formDrone.operators.indexOf(tag),
                1
            );
        },
        handleOpenLogService(data) {
            let self = this;

            let element =
                this.dronesElements[
                    this.dronesElements
                        .map((v) => v.vUID)
                        .indexOf(data.vCHILD_UID)
                ];

            firebase
                .functions()
                .httpsCallable(
                    `CLOUD_MANAGE_DYNAMIC_LINK_SHARE_${element.internals.vCHILD_TYPE.toUpperCase()}_MISSION`
                )({
                    userId:
                        Date.now() + "-" + Math.floor(Math.random() * 1000000),
                    additionalClaims: {
                        vCHILD_TYPE: "viewer",
                        vCREATOR_CHILD_UID: element.vUID,
                        vCREATOR_TEAM_UID: self.user.data.uid,
                        vCREATOR_CHILD_TYPE: element.internals.vCHILD_TYPE,
                        vCREATOR_CHILD_NAME: element.internals.vCHILD_NAME,
                        vCREATOR_MISSION_NAME: "-",
                        vEXPIRES_IN: Date.now() + 24 * 60 * 60 * 1000,
                        vCREATOR_LOG_SERVICE_KEY: data.vLAST_LOG_SERVICE_KEY,
                        vCREATOR_LOG_SERVICE_TIMESTAMP:
                            data.vLAST_LOG_SERVICE_TIMESTAMP,
                        vCREATOR_TIMEZONE: momenttz.tz.guess(),
                    },
                })
                .then(function (result) {
                    if (!result.data.error) {
                        self.$copyText(result.data.res).then(
                            () => {
                                self.$message({
                                    message: `Link copiado para a área de transferência. Acessando missão em nova aba...`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                window.open(result.data.res, "_blank");
                            },
                            () => {
                                self.$message({
                                    message: `Não foi possível copiar o link para a área de transferência`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        );
                    } else {
                        self.$message({
                            message: `Não foi possível copiar o link para a área de transferência`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    self.$message({
                        message: `Não foi possível copiar o link para a área de transferência`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                })
                .finally(() => {});
        },
        loadFollowedElements() {
            let self = this;
            firebase
                .database()
                .ref(`aegisv2/users/${this.user.data.uid}/elements`)
                .orderByChild("vCHILD_TYPE")
                .equalTo("drone")
                .once("value", function (snapshot) {
                    self.followedElements = Object.values(
                        snapshot.val() || {}
                    ).map((v) => v.vCHILD_UID);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        handleFollowClick(data) {
            let self = this;

            firebase
                .database()
                .ref(
                    `aegisv2/users/${this.user.data.uid}/elements/${data.vCHILD_UID}`
                )
                .set({ vCHILD_UID: data.vCHILD_UID, vCHILD_TYPE: "drone" })
                .then(() => {
                    self.$message({
                        message: `Você está seguindo ${data.vCHILD_NAME}`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                    self.loadData();
                })
                .catch((e) => {});
        },
        handleUnfollowClick(data) {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${this.user.data.uid}/elements/${data.vCHILD_UID}`
                )
                .set(null)
                .then(() => {
                    self.$message({
                        message: `Você parou de seguir ${data.vCHILD_NAME}`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                    self.loadData();
                })
                .catch((e) => {});
        },
        handleAssociateChild(data) {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${this.teamUidToAssociate}/elements/${data.vCHILD_UID}`
                )
                .set({ vCHILD_UID: data.vCHILD_UID, vCHILD_TYPE: "drone" })
                .then(() => {
                    self.$message({
                        message: `Elemento ${data.vCHILD_UID} associado ao time ${this.teamUidToAssociate}`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível associar o lemento ${data.vCHILD_UID} ao time ${this.teamUidToAssociate}`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        handleDisassociateChild(data) {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${this.teamUidToDisassociate}/elements/${data.vCHILD_UID}`
                )
                .set(null)
                .then(() => {
                    self.$message({
                        message: `Elemento ${data.vCHILD_UID} desassociado ao time ${this.teamUidToDisassociate}`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível desassociar o lemento ${data.vCHILD_UID} ao time ${this.teamUidToDisassociate}`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
    },
    beforeMount() {
        this.loadData();
    },
};
</script>



<style scoped>
h5 {
    color: #a1a0a0;
}
</style>