<template>
    <div class="main-stream-card-element" v-if="drone.vIS_CONNECTED">
        <div v-if="drone.stream.vIS_STREAMING_TO_CLOUD">
            <div>
                <div
                    style="
                        position: absolute;
                        top: 0px;
                        left: 30px;
                        height: 100%;
                        width: calc(100% - 60px);
                    "
                >
                    <div
                        v-if="drone.vIS_STREAMING"
                        @click="setNavStreamFocused(true)"
                        :id="'id-main-stream-card-element-video-' + drone.vUID"
                        :class="{
                            'main-stream-card-element-video':
                                !navState.streamFocused,
                            'main-stream-card-element-video-when-stream-focused':
                                navState.streamFocused,
                        }"
                    >
                        <app-drone-stream-video-element
                            style="height: 100%; width: 100%"
                            :id="'id-drone-stream-video-element-' + drone.vUID"
                            :url="drone.stream.vCLOUD_STREAM_PLAYBACK || ''"
                        ></app-drone-stream-video-element>
                    </div>

                    <div
                        v-else-if="
                            !drone.vIS_STREAMING &&
                            !drone.actions.aSTART_STREAM &&
                            !waitingForStream
                        "
                        style="
                            height: 100%;
                            width: 100%;
                            background-color: black;
                        "
                    >
                        <div class="row h-100">
                            <div class="col-12 my-auto">
                                <button
                                    class="btn btn-link"
                                    @click="handleStartStreamLink()"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="112"
                                        height="112"
                                        viewBox="0 0 112 112"
                                    >
                                        <g
                                            id="Grupo_1494"
                                            data-name="Grupo 1494"
                                            transform="translate(-1570 -614.15)"
                                        >
                                            <g
                                                id="Elipse_179"
                                                data-name="Elipse 179"
                                                transform="translate(1570 614.15)"
                                                fill="none"
                                                stroke="#a6a6a6"
                                                stroke-width="5"
                                            >
                                                <circle
                                                    cx="56"
                                                    cy="56"
                                                    r="56"
                                                    stroke="none"
                                                />
                                                <circle
                                                    cx="56"
                                                    cy="56"
                                                    r="53.5"
                                                    fill="none"
                                                />
                                            </g>
                                            <g
                                                id="play_arrow_black_24dp"
                                                transform="translate(1594.826 638.977)"
                                            >
                                                <path
                                                    id="Caminho_843"
                                                    data-name="Caminho 843"
                                                    d="M0,0H61.449V61.449H0Z"
                                                    fill="none"
                                                />
                                                <path
                                                    id="Caminho_844"
                                                    data-name="Caminho 844"
                                                    d="M8,5V40.845L36.164,22.923Z"
                                                    transform="translate(12.483 7.802)"
                                                    fill="#a6a6a6"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else-if="
                            !drone.vIS_STREAMING &&
                            (drone.actions.aSTART_STREAM || waitingForStream)
                        "
                        style="
                            height: 100%;
                            width: 100%;
                            background-color: black;
                        "
                    >
                        <div class="row h-100">
                            <div class="col-12 my-auto">
                                <i
                                    style="font-size: 2rem"
                                    class="el-icon-loading"
                                ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                :class="{
                    'main-stream-card-element-right-container':
                        !navState.streamFocused,
                    'main-stream-card-element-right-container-when-stream-focused':
                        navState.streamFocused,
                }"
                v-if="drone.vIS_STREAMING"
            >
                <!-- <button
                    class="btn btn-secondary w-100 mx-0 px-0 btn-stream-card"
                    style="border-top-right-radius: 10px !important"
                    @click="handleToggleCamera"
                >
                    <svg
                        id="cameraswitch_black_24dp"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <g id="Grupo_1225" data-name="Grupo 1225">
                            <path
                                id="Caminho_808"
                                data-name="Caminho 808"
                                d="M0,0H24V24H0Z"
                                fill="none"
                            />
                        </g>
                        <g id="Grupo_1227" data-name="Grupo 1227">
                            <g id="Grupo_1226" data-name="Grupo 1226">
                                <path
                                    id="Caminho_809"
                                    data-name="Caminho 809"
                                    d="M14,6H10L9,7H6V17H18V7H15Zm-2,8a2,2,0,1,1,2-2A2.006,2.006,0,0,1,12,14Z"
                                    fill="#fff"
                                />
                                <path
                                    id="Caminho_810"
                                    data-name="Caminho 810"
                                    d="M8.57.51l4.48,4.48V2.04A10.012,10.012,0,0,1,22,10.99h2A12.008,12.008,0,0,0,8.57.51Z"
                                    fill="#fff"
                                />
                                <path
                                    id="Caminho_811"
                                    data-name="Caminho 811"
                                    d="M10.95,21.96A10.012,10.012,0,0,1,2,13.01H0A12.008,12.008,0,0,0,15.43,23.49l-4.48-4.48v2.95Z"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </svg>
                </button> -->

                <el-popconfirm
                    :confirm-button-text="$t('Yes')"
                    :cancel-button-text="$t('No')"
                    icon="el-icon-info"
                    icon-color="red"
                    title="Existe uma transmissão em curso, ao prossegir a transmissão será encerrada. Deseja prossegir mesmo assim?"
                    @confirm="handleStopStreamLink()"
                    v-if="drone.vIS_STREAMING"
                >
                    <button
                        class="
                            btn btn-secondary
                            w-100
                            mx-0
                            px-0
                            btn-stream-card
                        "
                        style="position: absolute; bottom: 75.06px; right: 0px"
                        slot="reference"
                    >
                        <el-tooltip
                            :content="$t('End_transmission')"
                            placement="left"
                            :open-delay="1000"
                        >
                            <i
                                class="fas fa-stop"
                                style="font-size: 15px; color: red"
                            ></i>
                        </el-tooltip>
                    </button>
                </el-popconfirm>

                <el-tooltip
                    :content="$t('Restart_transmission')"
                    placement="left"
                    :open-delay="1000"
                >
                    <button
                        class="
                            btn btn-secondary
                            w-100
                            mx-0
                            px-0
                            btn-stream-card
                        "
                        style="position: absolute; bottom: 37.53px; right: 0px"
                        @click="handleRestartStreamLink"
                    >
                        <i class="fas fa-sync-alt" style="font-size: 15px"></i>
                    </button>
                </el-tooltip>

                <el-tooltip
                    :content="$t('Share_transmission_link')"
                    placement="left"
                    :open-delay="1000"
                >
                    <button
                        class="
                            btn btn-secondary
                            w-100
                            mx-0
                            px-0
                            btn-stream-card
                        "
                        style="
                            border-bottom-right-radius: 10px !important;
                            position: absolute;
                            bottom: 0px;
                            right: 0px;
                        "
                        @click="handleShareStreamLink"
                    >
                        <i class="fas fa-share-alt" style="font-size: 15px"></i>
                    </button>
                </el-tooltip>
            </div>
            <div
                :class="{
                    'main-stream-card-element-top-container':
                        !navState.streamFocused,
                    'main-stream-card-element-top-container-when-stream-focused':
                        navState.streamFocused,
                }"
                v-if="false"
            >
                <span
                    class="btn btn-secondary my-0 py-0 btn-stream-card"
                    style="
                        border-top-left-radius: 10px !important;
                        height: 30px;
                        line-height: 30px;
                    "
                >
                    FPS:
                    {{ (fps || 0).toFixed(2) }}
                </span>
                <span
                    class="btn btn-secondary my-0 py-0 btn-stream-card"
                    style="height: 30px; line-height: 30px"
                    ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34.492"
                        height="25.629"
                        viewBox="0 0 34.492 25.629"
                    >
                        <g
                            id="Grupo_1184"
                            data-name="Grupo 1184"
                            transform="translate(-0.002)"
                        >
                            <g
                                id="computer-24px"
                                transform="translate(20.682 11.816)"
                            >
                                <path
                                    id="Caminho_675"
                                    data-name="Caminho 675"
                                    d="M0,0H13.812V13.813H0Z"
                                    fill="none"
                                />
                                <path
                                    id="Caminho_676"
                                    data-name="Caminho 676"
                                    d="M11.51,12.057a1.15,1.15,0,0,0,1.145-1.151l.006-5.755A1.154,1.154,0,0,0,11.51,4H2.3A1.154,1.154,0,0,0,1.151,5.151v5.755A1.154,1.154,0,0,0,2.3,12.057H0v1.151H13.812V12.057ZM2.3,5.151H11.51v5.755H2.3Z"
                                    transform="translate(0 -1.698)"
                                    fill="#fff"
                                />
                            </g>
                            <g
                                id="signal_cellular_alt-24px"
                                transform="translate(0.002)"
                            >
                                <path
                                    id="Caminho_673"
                                    data-name="Caminho 673"
                                    d="M0,0H23.632V23.633H0Z"
                                    fill="none"
                                />
                                <path
                                    id="Caminho_674"
                                    data-name="Caminho 674"
                                    d="M16.816,4H19.77V19.755H16.816ZM5,13.847H7.954v5.908H5Zm5.908-4.923h2.954V19.755H10.908Z"
                                    transform="translate(-0.077 -0.061)"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </svg>

                    - Mbps
                </span>
                <span
                    class="btn btn-secondary my-0 py-0 btn-stream-card"
                    style="
                        border-top-right-radius: 10px !important;
                        height: 30px;
                        line-height: 30px;
                    "
                    ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39.445"
                        height="23.633"
                        viewBox="0 0 39.445 23.633"
                    >
                        <g
                            id="Grupo_1185"
                            data-name="Grupo 1185"
                            transform="translate(-1761 -108)"
                        >
                            <text
                                id="HD"
                                transform="translate(1792.445 129.09)"
                                fill="#fff"
                                font-size="10"
                                font-family="Helvetica"
                            >
                                <tspan x="-7.222" y="0">HD</tspan>
                            </text>
                            <g
                                id="signal_cellular_alt-24px"
                                transform="translate(1761 108)"
                            >
                                <path
                                    id="Caminho_673"
                                    data-name="Caminho 673"
                                    d="M0,0H23.632V23.633H0Z"
                                    fill="none"
                                />
                                <path
                                    id="Caminho_674"
                                    data-name="Caminho 674"
                                    d="M16.816,4H19.77V19.755H16.816ZM5,13.847H7.954v5.908H5Zm5.908-4.923h2.954V19.755H10.908Z"
                                    transform="translate(-0.077 -0.061)"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </svg>

                    <!-- {{ (drone.stream.vVIDEO_LINK_SIGNAL || 0).toFixed(0) }} % -->
                    {{ (quality || 0).toFixed(0) }} %
                </span>
            </div>
            <div
                v-if="
                    drone.telemetry.vMISSION_NAME &&
                    drone.telemetry.vMISSION_NAME !== 'none'
                "
                :class="{
                    'main-stream-card-element-bottom-container':
                        !navState.streamFocused,
                    'main-stream-card-element-bottom-container-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <span
                    class="btn btn-secondary my-0 py-0 btn-stream-card"
                    style="
                        border-bottom-left-radius: 10px !important;
                        border-bottom-right-radius: 10px !important;
                        height: 30px;
                        line-height: 30px;
                    "
                >
                    {{ drone.telemetry.vMISSION_NAME }}
                </span>
            </div>
        </div>
        <div
            style="position: absolute; top: 0px; right: 30px"
            v-if="!drone.stream.vIS_STREAMING_TO_CLOUD"
        >
            <button
                class="btn btn-primary"
                @click="
                    !drone.vIS_STREAMING
                        ? handleStartStreamLink()
                        : handleStopStreamLink()
                "
            >
                {{
                    !drone.vIS_STREAMING
                        ? "Iniciar transmissão"
                        : "Encerrar transmissão"
                }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";
import appDroneStreamVideoElement from "@/components/drone/DroneStreamVideoElement";
export default {
    data() {
        return {
            session: null,
            publisher: null,
            subscriber: null,
            subscribed: false,
            apiKey: null,
            sessionId: null,
            token: null,
            fps: 0,
            quality: 0,
            interval: null,
            gimbalPitch: 0,
            waitingForStream: false,
        };
    },
    components: {
        appDroneStreamVideoElement,
    },
    computed: {
        ...mapGetters(["user", "navState", "droneActive"]),
    },
    methods: {
        ...mapActions([
            "setNavStreamFocused",
            "setNavForcedLoading",
            "setDroneActive",
        ]),
        makeDynamicLongLink(
            customToken,
            socialTitle,
            androidPackageName,
            socialDescription,
            socialImageUrl
        ) {
            return {
                dynamicLinkInfo: {
                    domainUriPrefix: "https://aeroguard.live/link",
                    link: `https://aeroguard-viewer.web.app/live?key="${customToken}`,
                    androidInfo: {
                        androidPackageName: androidPackageName,
                    },
                    socialMetaTagInfo: {
                        socialTitle: socialTitle,
                        socialDescription: socialDescription,
                        socialImageLink: socialImageUrl,
                    },
                },
                suffix: {
                    option: "UNGUESSABLE",
                },
            };
        },
        handleShareStreamLink() {
            let self = this;

            this.setNavForcedLoading(true);

            firebase
                .functions()
                .httpsCallable("createDynamicLinkFromCustomToken_v2")({
                    userId:
                        Date.now() + "-" + Math.floor(Math.random() * 1000000),
                    additionalClaims: {
                        vCHILD_TYPE: "viewer",
                        vCREATOR_CHILD_UID: self.drone.vUID,
                        vCREATOR_TEAM_UID: self.user.data.uid,
                        vCREATOR_CHILD_TYPE: self.drone.internals.vCHILD_TYPE,
                        vCREATOR_CHILD_NAME: self.drone.internals.vCHILD_NAME,
                        vEXPIRES_IN: Date.now() + 59 * 60 * 1000,
                    },
                })
                .then(function (result) {
                    if (result.data.statusCode === 200) {
                        self.$copyText(result.data.res).then(
                            () => {
                                self.$message({
                                    message: `Link copiado para a área de transferência`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                            },
                            () => {
                                self.$message({
                                    message: `Não foi possível copiar o link para a área de transferência`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        );
                    } else {
                        self.$message({
                            message: `Não foi possível copiar o link para a área de transferência`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                    }
                })
                .catch(() => {
                    self.$message({
                        message: `Não foi possível copiar o link para a área de transferência`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                })
                .finally(function () {
                    self.setNavForcedLoading(false);
                });
        },
        handleRestartStreamLink() {
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/actions/aRESTART_STREAM"
                )
                .set(true);
        },
        handleStopStreamLink() {
            let self = this;
            firebase
                .database()
                .ref(
                    "aegisv2/users/" + this.drone.vUID + "/actions/aSTOP_STREAM"
                )
                .set(true)
                .then(() => {
                    self.waitingForStream = false;
                    self.$message({
                        message: `Transmissão encerrada`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível encerrar a transmissão`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        handleStartStreamLink() {
            let self = this;

            this.waitingForStream = true;
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/actions/aSTART_STREAM"
                )
                .set(!self.drone.stream.vIS_STREAMING)
                .then(function () {
                    let currentDroneActive = self.drone.vUID;
                    setTimeout(() => {
                        if (
                            currentDroneActive === self.droneActive &&
                            self.drone.actions.aSTART_STREAM
                        ) {
                            firebase
                                .database()
                                .ref(
                                    "aegisv2/users/" +
                                        self.drone.vUID +
                                        "/actions/aSTART_STREAM"
                                )
                                .set(false)
                                .then(function () {
                                    self.waitingForStream = false;
                                    self.$message({
                                        message: `Não foi possível iniciar a transmissão`,
                                        type: "error",
                                        showClose: true,
                                        offset: 60,
                                    });
                                });
                        }
                    }, 10000);
                });
        },
        handleToggleCamera() {},
        startFakeStream() {
            let self = this;
            setInterval(() => {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.drone.vUID +
                            "/telemetry/vHEART_BEAT"
                    )
                    .set(Math.round(Math.random() * 100));
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.drone.vUID +
                            "/stream/vIS_STREAMING"
                    )
                    .set(true);
            }, 2000);
        },
        startFakeHeartBeat() {
            setInterval(() => {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.drone.vUID +
                            "/telemetry/vHEART_BEAT"
                    )
                    .set(Math.round(Math.random() * 100));
            }, 2000);
        },
        startStream() {
            let self = this;
        },
    },
    props: ["drone"],
    watch: {
        "drone.vIS_STREAMING": function (newVal) {
            if (newVal === false) {
                this.waitingForStream = false;
            }
        },
    },
    mounted() {
        let self = this;
        if (
            (this.drone.vIS_STANDBY || this.drone.vIS_CONNECTED) &&
            this.drone.stream.vIS_STREAMING_TO_CLOUD
        ) {
            setTimeout(() => {
                if (
                    (self.drone.vIS_STANDBY || self.drone.vIS_CONNECTED) &&
                    self.drone.stream.vIS_STREAMING_TO_CLOUD
                ) {
                    self.startStream();
                }
            }, 200);
        }

        if (this.interval !== null) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
            if (self.subscribed && self.subscriber) {
                self.subscriber.getStats(function (error, stats) {
                    if (!error) {
                        self.fps = stats.video.frameRate;
                        self.quality =
                            (100 *
                                (stats.video.packetsReceived -
                                    stats.video.packetsLost)) /
                            stats.video.packetsReceived;
                    }
                });
            }
        }, 1000);
    },
    beforeDestroy() {
        let self = this;
        if (self.session) {
            self.session.disconnect();
        }
        if (this.interval !== null) {
            clearInterval(this.interval);
        }
    },
    beforeMount() {},
};
</script>


<style scoped>
.main-stream-card-element {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.main-stream-card-element-left-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 30px);
    height: 100%;
    /* border: 1px solid blue; */
}

.main-stream-card-element-left-container-when-stream-focused {
    position: absolute;
    top: 100px;
    left: calc(100vw - 560px);
    width: calc(100% - 30px);
    height: 100%;
    z-index: 300;
    /* border: 1px solid blue; */
}

.main-stream-card-element-right-container {
    opacity: 0.5;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 100%;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.main-stream-card-element-right-container-when-stream-focused {
    opacity: 0.5;
    position: absolute;
    top: 100px;
    right: 20px;
    width: 30px;
    height: 270px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.main-stream-card-element-top-container {
    opacity: 0.5;
    position: absolute;
    top: -30px;
    right: 30px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.main-stream-card-element-top-container-when-stream-focused {
    opacity: 0.5;
    position: absolute;
    top: 70px;
    right: 50px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.main-stream-card-element-bottom-container-when-stream-focused {
    opacity: 0.5;
    position: absolute;
    top: 370px;
    right: 50px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.main-stream-card-element-bottom-container {
    opacity: 0.5;
    position: absolute;
    bottom: -30px;
    right: 30px;
    height: 30px;
    /* border: 1px solid red; */
    background-color: #3b3b3b;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.btn-stream-card {
    border-color: #868686 !important;
    background-color: #535353 !important;
}

.main-stream-card-element-video {
    width: 100% !important;
    height: 100% !important;
    background-color: #292929;
}
.main-stream-card-element-video-when-stream-focused {
    width: calc(100% - 300px) !important;
    margin-left: 0px !important;
    height: 100% !important;
    background-color: #292929;
}
</style>