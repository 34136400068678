import Vue from 'vue'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'pt', // set locale
    fallbackLocale: 'pt', // set fallback locale
    messages: {
        'en': {
            DRONES: 'DRONES',
            List_of_Drones: 'List of Drones',
            Missions: 'Missions',
            missions: 'missions',
            Reports: 'Reports',
            SMARTCAMS: 'SMARTCAMS',
            List_of_SmartCams: 'List of SmartCams',
            REVIEW: 'REVIEW',
            SETTINGS: 'SETTINGS',
            Settings: 'Settings',
            Search: 'Search',
            SEARCH: 'SEARCH',
            // 
            Drone_settings: 'Drone settings',
            Altitude: 'Altitude',
            Speed: 'Speed',
            Satellites: 'Satellites',
            Battery: 'Battery',
            Wind: 'Wind',
            Pilot: 'Pilot',
            Unknown_pilot: 'Unknown pilot',
            Equipment: 'Equipment',
            Transmission: 'Transmission',
            Drone_name: 'Drone name',
            Model: 'Model',
            Maximum_distance: 'Maximum distance (m)',
            Maximum_altitude: 'Maximum altitude (m)',
            Expected_daily_missions: 'Expected daily missions',
            Video_transmission: 'Stream transmission',
            Cloud_server: 'Cloud server',
            Custom_server: 'Custom server',
            Custom_server_address: 'Customer server address',
            Search_by_address: 'Search by address or coordinates',
            Create_new_mission: 'Create new mission',
            Mission: 'Mission',
            mission: 'mission',
            Waypoints: 'Waypoints',
            // 
            SmartCam_settings: 'SmartCam settings',
            Device_settings: 'Device settings',
            Camera_settings: 'Configurações da câmera',
            Connection: 'Connection',
            Storage: 'Storage',
            GPS_precision: 'GPS precision',
            Driving: 'Driving',
            Resolution: 'Resolution',
            Bitrate: 'Bitrate',
            Icon_color: 'Icon color',
            Orientation: 'Orientation',
            Device_id: 'Device ID',
            IMEI: 'IMEI',
            Device_name: 'Device name',
            // 
            Select_all: 'Select all',
            Clear_selection: 'Clear selection',
            Clear: 'Clear',
            Yes: 'Yes',
            No: 'No',
            // 
            Date: 'Date',
            Time: 'Time',
            Types: 'Types',
            Events: 'Events',
            Devices: 'Devices',
            Location: 'Location',
            Medias: 'Medias',
            With_medias: 'With medias',
            Without_medias: 'Without medias',
            None_event: 'None event',
            Device: 'Device',
            Duration: 'Duration',
            Begin: 'Begin',
            End: 'End',
            Click_on_the_map: 'Click twice on the map to define a region of interest',
            Select_a_location: 'Select a location',
            // settings
            My_account: 'My account',
            Registration_data: 'Registration data',
            Team_name: 'Team name',
            Company_name: 'Company name',
            Company_id: 'Company ID',
            Child_name: 'Name',
            Contact: 'Contact',
            Registered_email: 'Email',
            Child_id: 'ID',
            Edit_registration_data: 'Edit registration data',
            Company: 'Company',
            Email: 'Email',
            Cancel: 'Cancel',
            Confirm: 'Confirm',
            Save: 'Save',
            // 
            APIS_and_Integration: 'APIs and Integration',
            APIS_and_Integration_desc: 'Connect AeroGuard to other software and services.',
            Integration: 'Integration',
            Integration_token: 'Integration token',
            Team_logo: 'Team logo',
            Remove_team_logo: 'Remove team logo',
            Copy: 'Copy',
            // 
            Users_management: 'Users management',
            Users_management_desc: 'Use this tab to create, delete or edit user accounts.',
            IncorporateUserByToken: 'Incorporate user by token',
            IncorporationToken: 'Incorporation token',
            Name: 'Name',
            Type: 'Type',
            Add_user: 'Add user',
            Edit_user: 'Edit user',
            Available_for_missions: 'Available for missions',
            List_of_operators: 'List of operators',
            New_operator: 'New operator',
            Subscribers_list: 'Subscribers list',
            New_subscriber: 'New subscriber',
            Are_you_sure_question: 'Are you sure?',
            Account_type: 'Account type',
            Data_fetch_period: 'Data fetch period',
            Password: 'Password',
            // 
            Licensing: 'Licensing',
            Licensing_desc: 'Use this tab to create, delete or edit software licenses.',
            License: 'License',
            Elements: 'Elements',
            Expires_at: 'Expires at',
            Add_license: 'Add license',
            License_key: 'License key',
            // 
            Subscribers: 'Subscribers',
            Subscribers_desc: 'Use this tab to create, delete or edit operation subscribers.',
            Operations: 'Operations',
            Add_subscriber: 'Add subscriber',
            Edit_subscriber: 'Edit subscriber',
            // 

            // 
            Message_analysing_period_data: 'Analyzing period data',
            Message_loading: 'Loading',
            Messsage_data_obtained: 'Data obtained',
            Message_recording_started: 'Recording started',
            Message_recording_started_fails: 'Impossible to start recording',
            Message_recording_ended: 'Recording ended',
            Message_recording_ended_fails: 'Impossible to end recording',
            // 
            Device_serial_number: 'Device serial number',
            Battery_serial_number: 'Battery serial number',
            Radio_serial_number: 'Radio serial number',
            Battery: 'Battery',
            Operator: 'Operator',
            Conformity: 'Conformity',
            Distance: 'Distance',
            //
            BATTERY: 'BATTERY',
            GPS: 'GPS',
            SPEED: 'SPEED',
            // 
            Cancel_all_actions: 'Cancel all actions',
            Center_map: 'Center map',
            Return_to_home: 'Return to home',
            Cancel_return_to_home: 'Cancel return to home',
            Start_flight: 'Start flight',
            Resume_mission: 'Resume mission',
            Pause_mission: 'Pause mission',
            End_transmission: 'End transmission',
            Restart_transmission: 'Restart transmission',
            Share_transmission_link: 'Share transmission link',
            vTOOLTIP_LANGUAGE: 'vTOOLTIP_EN',
            vLABEL_LANGUAGE: 'vLABEL_EN',
            vRANGE_INPUT_LABEL_LANGUAGE: 'vRANGE_INPUT_LABEL_EN',
            label: 'label_en',
            Stop_recording: 'Stop recording',
            Start_recording: 'Start recording',
            Switch_camera: 'Switch camera',
            Force_upload: 'Force upload medias to cloud',
            Restart_app: 'Restart app',
            Unknown_event: 'Unknown event',
            SHARE: 'SHARE',
            Events_detection: 'Events detection',
            Points_locations_and_fencings: 'Spots and fencings',
            // 
            Phone_orientation_0: '(USB facing down)',
            Phone_orientation_90: '(USB facing right)',
            Phone_orientation_180: '(USB facing up)',
            Phone_orientation_270: '(USB facing left)',
            // 
            Phone_orientation_desc_0: 'Portrait',
            Phone_orientation_desc_90: 'Inverted landscape',
            Phone_orientation_desc_180: 'Inverted portrait',
            Phone_orientation_desc_270: 'Landscape',
        },
        'pt': {
            DRONES: 'DRONES',
            List_of_Drones: 'Lista de Drones',
            Missions: 'Missões',
            missions: 'missões',
            Reports: 'Relatórios',
            SMARTCAMS: 'SMARTCAMS',
            List_of_SmartCams: 'Lista de SmartCams',
            REVIEW: 'REVISÃO',
            SETTINGS: 'CONFIGURAÇÕES',
            Settings: 'Configurações',
            Search: 'Procurar',
            SEARCH: 'BUSCAR',
            Drone_settings: 'Configurações do drone',
            Altitude: 'Altura',
            Speed: 'Velocidade',
            Satellites: 'Satélites',
            Battery: 'Bateria',
            Wind: 'Vento',
            Pilot: 'Piloto',
            Unknown_pilot: 'Piloto desconhecido',
            Equipment: 'Equipamento',
            Transmission: 'Transmissão',
            Drone_name: 'Nome do Drone',
            Model: 'Modelo',
            Maximum_distance: 'Distância máxima (m)',
            Maximum_altitude: 'Altitude máxima (m)',
            Expected_daily_missions: 'Missões diárias previstas',
            Video_transmission: 'Transmissão de vídeo',
            Cloud_server: 'Servidor em nuvem',
            Custom_server: 'Servidor customizado',
            Custom_server_address: 'Endereço servidor customizado',
            Search_by_address: 'Buscar por endereço ou coordenadas',
            Create_new_mission: 'Criar nova missão',
            Mission: 'Missão',
            mission: 'missão',
            Waypoints: 'Waypoints',
            // 
            SmartCam_settings: 'Configuração da SmartCam',
            Device_settings: 'Configurações do dispositivo',
            Camera_settings: 'Configurações da câmera',
            Connection: 'Conexão',
            Storage: 'Memória',
            GPS_precision: 'Precisão GPS',
            Driving: 'Condução',
            Resolution: 'Resolução',
            Bitrate: 'Bitrate',
            Icon_color: 'Cor do ícone',
            Orientation: 'Orientação',
            Device_id: 'Identificação do dispositivo',
            IMEI: 'IMEI',
            Device_name: 'Nome do dispositivo',
            // 
            Select_all: 'Selecionar todos',
            Clear_selection: 'Limpar seleção',
            Clear: 'Limpar',
            Yes: 'Sim',
            No: 'Não',
            // 
            Date: 'Data',
            Time: 'Horário',
            Types: 'Tipos',
            Events: 'Eventos',
            Devices: 'Dispositivos',
            Location: 'Localização',
            Medias: 'Midias',
            With_medias: 'Com mídias',
            Without_medias: 'Sem mídias',
            None_event: 'Sem eventos',
            Device: 'Dispositivo',
            Duration: 'Duração',
            Begin: 'Início',
            End: 'Término',
            Click_on_the_map: 'Clique duas vezes no mapa para definir uma região de interesse',
            Select_a_location: 'Escolha uma localização',
            // settings
            My_account: 'Minha conta',
            Registration_data: 'Dados de cadastro',
            Team_name: 'Nome do time',
            Company_name: 'Nome da empresa',
            Company_id: 'CNPJ',
            Child_name: 'Responsável técnico',
            Contact: 'Contato',
            Registered_email: 'Email cadastrado',
            Child_id: 'CPF',
            Edit_registration_data: 'Editar dados de cadastro',
            Company: 'Empresa',
            Email: 'Email',
            Cancel: 'Cancelar',
            Confirm: 'Confirmar',
            Save: 'Salvar',
            // 
            APIS_and_Integration: 'APIs e integração',
            APIS_and_Integration_desc: 'Conecte o AeroGuard à outros softwares e serviços.',
            Integration: 'Integração',
            Integration_token: 'Token de integração',
            Team_logo: 'Logo do time',
            Remove_team_logo: 'Remover logo do time',
            Copy: 'Copiar',
            // 
            Users_management: 'Gestão de usuários',
            Users_management_desc: 'Use essa tela para criar, deletar ou editar contas de usuários.',
            IncorporateUserByToken: 'Incorporar usuário via token',
            IncorporationToken: 'Incorporation token',
            Name: 'Nome',
            Type: 'Tipo',
            Add_user: 'Adicionar usuário',
            Edit_user: 'Editar usuário',
            Available_for_missions: 'Disponível para missões',
            List_of_operators: 'Lista de operadores',
            New_operator: 'Novo operador',
            Subscribers_list: 'Lista de assinantes',
            New_subscriber: 'Novo assinante',
            Are_you_sure_question: 'Você tem certeza disso?',
            Account_type: 'Tipo de conta',
            Data_fetch_period: 'Período de revisão',
            Password: 'Senha',
            // 
            Licensing: 'Licenciamento',
            Licensing_desc: 'Use essa tela para criar, deletar ou editar licenças de software.',
            License: 'Licença',
            Elements: 'Elementos',
            Expires_at: 'Expira em',
            Add_license: 'Adicionar licença',
            License_key: 'Chave de licença',
            // 
            Subscribers: 'Assinantes',
            Subscribers_desc: 'Use essa tela para criar, deletar ou editar assinantes da operação.',
            Operations: 'Operações',
            Add_subscriber: 'Adicionar assinante',
            Edit_subscriber: 'Editar assinante',
            // 
            Message_analysing_period_data: 'Analisando os dados do período',
            Message_loading: 'Carregando',
            Messsage_data_obtained: 'Data obtained',
            Message_recording_started: 'Gravação iniciada',
            Message_recording_started_fails: 'Não foi possível iniciar a gravação',
            Message_recording_ended: 'Gravação encerrada',
            Message_recording_ended_fails: 'Não foi possível encerrar a gravação',
            Messsage_data_obtained: 'Dados obtidos',
            // 
            Device_serial_number: 'No. serial do dispositivo',
            Battery_serial_number: 'No. serial da bateria',
            Radio_serial_number: 'No. serial do rádio',
            Battery: 'Bateria',
            Operator: 'Operador',
            Conformity: 'Conformidade',
            Distance: 'Distância',
            // 
            BATTERY: 'BATERIA',
            GPS: 'GPS',
            SPEED: 'VELOCIDADE',
            // 
            Cancel_all_actions: 'Cancelar todas as ações',
            Center_map: 'Centralizar mapa',
            Return_to_home: 'Voltar para a base',
            Cancel_return_to_home: 'Cancelar volta para a base',
            Start_flight: 'Iniciar voo',
            Resume_mission: 'Continuar missão',
            Pause_mission: 'Pausar missão',
            End_transmission: 'Encerrar transmissão',
            Restart_transmission: 'Reiniciar transmissão',
            Share_transmission_link: 'Compartilhar link de transmissão',
            vTOOLTIP_LANGUAGE: 'vTOOLTIP_PT',
            vLABEL_LANGUAGE: 'vLABEL_PT',
            vRANGE_INPUT_LABEL_LANGUAGE: 'vRANGE_INPUT_LABEL_PT',
            label: 'label_pt',
            Stop_recording: 'Parar gravação',
            Start_recording: 'Iniciar gravação',
            Switch_camera: 'Inverter câmera',
            Force_upload: 'Enviar mídias para a nuvem',
            Restart_app: 'Reiniciar aplicativo',
            Unknown_event: 'Evento desconhecido',
            SHARE: 'COMPARTILHAR',
            Events_detection: 'Detecção de eventos',
            Points_locations_and_fencings: 'Pontos e cercas',
            // 
            Phone_orientation_0: '(USB voltada para baixo)',
            Phone_orientation_90: '(USB voltada para direita)',
            Phone_orientation_180: '(USB voltada para cima)',
            Phone_orientation_270: '(USB voltada para esquerda)',
            // 
            Phone_orientation_desc_0: 'Retrato',
            Phone_orientation_desc_90: 'Paisagem invertido',
            Phone_orientation_desc_180: 'Retrato invertido',
            Phone_orientation_desc_270: 'Paisagem',
        }
    },
});