<template>
    <div class="main-solo-report bg-dark-high-gray">
        <div style="height: 100%">
            <div class="row mx-0 px-0 py-0" style="height: 50%">
                <div class="col-4 h-100 px-1 py-1">
                    <div style="height: 100%; width: 100%">
                        <div
                            class="chart-container bg-dark-medium-gray"
                            style="height: 100%"
                        >
                            <app-solo-report-events
                                :datacollection="soloReportEventsCollection"
                                :showAllTooltips="false"
                            ></app-solo-report-events>
                        </div>
                    </div>
                </div>
                <div class="col-8 h-100 px-1 py-1">
                    <div style="height: 100%; width: 100%">
                        <div
                            style="width: 100%; height: 100%"
                            class="bg-dark-medium-gray"
                        >
                            <div
                                class="row h-100"
                                style="padding: 0; margin: 0"
                            >
                                <div
                                    v-for="(
                                        kpi, index
                                    ) in soloReportMissionsKpi"
                                    :key="index"
                                    style=""
                                    class="col my-auto align-middle"
                                >
                                    {{ kpi.label }}:
                                    <h1
                                        :class="{
                                            'color-light-red':
                                                kpi.color === '#EF9A9A',
                                            'color-light-green':
                                                kpi.color === '#A5D6A7',
                                        }"
                                    >
                                        {{ kpi.value }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-0 px-0 py-0" style="height: 50%">
                <div class="col-8 h-100 px-1 py-1">
                    <el-carousel
                        style="height: 100%; width: 100%"
                        height="100%"
                        direction="horizontal"
                        :autoplay="false"
                        :interval="null"
                        trigger="click"
                        :loop="false"
                    >
                        <el-carousel-item style="height: 100%; width: 100%">
                            <div
                                class="chart-container bg-dark-medium-gray"
                                style="height: 100%"
                            >
                                <app-solo-report-tagged-important-by-device
                                    :datacollection="
                                        soloReportTaggedImportantByDeviceCollection
                                    "
                                    :showAllTooltips="false"
                                    style="height: 100%"
                                ></app-solo-report-tagged-important-by-device>
                            </div>
                        </el-carousel-item>
                        <el-carousel-item
                            style="height: 100%; width: 100%"
                            v-for="(a, index) in Object.values(
                                soloReportAdditionalGraphs
                            )"
                            :key="index"
                        >
                            <div
                                class="chart-container bg-dark-medium-gray"
                                style="height: 100%"
                            >
                                <!-- {{ a }}
                                {{
                                    a.labels.map(
                                        (v) =>
                                            solosElements[
                                                solosElements
                                                    .map((v) => v.vUID)
                                                    .indexOf(v)
                                            ].internals.vCHILD_NAME
                                    )
                                }} -->
                                <app-solo-report-events-by-device
                                    :datacollection="a"
                                    :showAllTooltips="false"
                                    style="height: 100%"
                                ></app-solo-report-events-by-device>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="col-4 h-100 px-1 py-1">
                    <div style="height: 100%; width: 100%">
                        <div
                            style="height: 100%; outline: none"
                            class="bg-dark-medium-gray"
                        >
                            <gmap-map
                                :options="{
                                    tilt: 0,
                                }"
                                :center="{ lat: -23.508, lng: -46.587 }"
                                :zoom="4"
                                ref="mapRefSoloReportHeatmap"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    padding: 0;
                                    margin: 0;
                                "
                            >
                            </gmap-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import appSoloReportEvents from "@/components/solo/containers/report/SoloReportEvents";
import appSoloReportTaggedImportantByDevice from "@/components/solo/containers/report/SoloReportTaggedImportantByDevice";
import appSoloReportEventsByDevice from "@/components/solo/containers/report/SoloReportEventsByDevice";

import { mapGetters } from "vuex";

export default {
    data() {
        return {
            heatmap: { map: null, currentZoom: 0, desiredRadiusInMeters: 35 },
        };
    },
    computed: {
        ...mapGetters([
            "soloReportEventsCollection",
            "soloReportTaggedImportantByDeviceCollection",
            "soloReportMissionsKpi",
            "soloReportMissionsLocations",
            "solosReportStatus",
            "soloReportAdditionalGraphs",
            "solosElements",
        ]),
    },
    components: {
        appSoloReportEvents,
        appSoloReportTaggedImportantByDevice,
        appSoloReportEventsByDevice,
    },
    methods: {
        getHeatmapRadius(desiredRadiusInMeters, zoomLevel, latitudeLevel) {
            let metersPerPx =
                (156543.03392 * Math.cos((latitudeLevel * Math.PI) / 180)) /
                Math.pow(2, zoomLevel);
            return desiredRadiusInMeters / metersPerPx;
        },
    },
    watch: {
        solosReportStatus: function (newVal, oldVal) {
            if (newVal) {
                if (newVal === "success") {
                    this.$message({
                        message: this.$t("Messsage_data_obtained"),
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                } else if (newVal === "error") {
                    this.$message({
                        message: `Não foi possível carregar os dados`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            }
        },
        soloReportMissionsLocations: function (newVal, oldVal) {
            this.heatmap.map.setMap(null);
            let self = this;
            this.$refs.mapRefSoloReportHeatmap.$mapPromise.then((map) => {
                self.heatmap.map = new google.maps.visualization.HeatmapLayer({
                    data: newVal.map(
                        (v) => new google.maps.LatLng(v.lat, v.lng)
                    ),
                });

                const bounds = new google.maps.LatLngBounds();
                if (newVal.length > 0) {
                    for (let p of newVal) {
                        bounds.extend(p);
                    }
                } else {
                    bounds.extend({ lat: -23, lng: -46 });
                }

                map.fitBounds(bounds);
                let val = self.getHeatmapRadius(
                    self.heatmap.desiredRadiusInMeters,
                    map.getZoom(),
                    map.getCenter().lat()
                );
                self.heatmap.map.setOptions({
                    radius: Math.max(val * 1, 20),
                });
                self.heatmap.map.setMap(map);
            });
        },
        "heatmap.currentZoom": function (newVal, oldVal) {
            let self = this;
            this.$refs.mapRefSoloReportHeatmap.$mapPromise.then((map) => {
                let val = self.getHeatmapRadius(
                    self.heatmap.desiredRadiusInMeters,
                    map.getZoom(),
                    map.getCenter().lat()
                );
                self.heatmap.map.setOptions({
                    radius: Math.max(val * 1, 10),
                });
            });
        },
    },
    mounted() {
        let self = this;
        if (!this.heatmap.map) {
            this.$refs.mapRefSoloReportHeatmap.$mapPromise.then((map) => {
                if (self.soloReportMissionsLocations.length) {
                    self.heatmap.map =
                        new google.maps.visualization.HeatmapLayer({
                            data: self.soloReportMissionsLocations.map(
                                (v) => new google.maps.LatLng(v.lat, v.lng)
                            ),
                        });
                    const bounds = new google.maps.LatLngBounds();
                    for (let l of self.soloReportMissionsLocations.map(
                        (v) => new google.maps.LatLng(v.lat, v.lng)
                    )) {
                        bounds.extend(l);
                    }

                    map.fitBounds(bounds);

                    let val = self.getHeatmapRadius(
                        self.heatmap.desiredRadiusInMeters,
                        map.getZoom(),
                        map.getCenter().lat()
                    );
                    self.heatmap.map.setOptions({
                        radius: Math.max(val * 1, 20),
                    });
                    self.heatmap.map.setMap(map);
                } else {
                    self.heatmap.map =
                        new google.maps.visualization.HeatmapLayer({
                            data: [],
                        });
                    self.heatmap.map.setMap(map);
                }

                self.heatmap.currentZoom = map.getZoom();

                google.maps.event.addListener(map, "zoom_changed", function () {
                    self.heatmap.currentZoom = map.getZoom();
                });
            });
        }
    },
};
</script>

<style scoped>
.main-solo-report {
    position: absolute;
    left: 0px;
    top: 100px;
    width: calc(100vw);
    height: calc(100vh - 100px);
    padding: 10px;

    -webkit-touch-callout: text !important; /* iOS Safari */
    -webkit-user-select: text !important; /* Safari */
    -khtml-user-select: text !important; /* Konqueror HTML */
    -moz-user-select: text !important; /* Firefox */
    -ms-user-select: text !important; /* Internet Explorer/Edge */
    user-select: text !important; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.no-padding-no-margin {
    padding: 0;
    margin: 0;
}

.no-padding {
    padding: 0;
}
.no-margin {
    margin: 0;
}

.chart-container {
    padding: 1rem;
    flex-grow: 1;
    min-height: 0;
}

.color-light-red {
    color: #ef9a9a;
}

.color-light-green {
    color: #a5d6a7;
}
</style>