<template>
    <div class="container" v-on:keyup.enter="signIn">
        <video
            :controls="false"
            autoplay
            muted
            loop
            :preload="true"
            style="
                position: fixed;
                right: 0;
                bottom: 0;
                min-width: 100%;
                min-height: 100%;
                pointer-events: none !important;
            "
            class="login-video"
        >
            <source
                src="https://firebasestorage.googleapis.com/v0/b/aeroguard-b83bc.appspot.com/o/internals%2Fproduction%20ID_4873244.mp4?alt=media&token=4c7347f2-f2a3-4f83-9691-ce652b9422ba"
                type="video/mp4"
            />
            Your browser does not support HTML5 video.
        </video>
        <div class="row justify-content-center h-100">
            <div class="col-6 text-center" style="height: 100%">
                <img
                    class="rounded mx-auto d-block"
                    :src="require('@/assets/logoAeroguard.png')"
                    alt
                />
            </div>
        </div>
        <br />
        <br />
        <div class="row justify-content-center h-100">
            <div class="col-4 text-center" style="height: 100%">
                <el-input
                    class="login-input"
                    placeholder="Email"
                    v-model="email"
                    type="email"
                    v-on:keyup.enter="signIn"
                ></el-input>
                <!-- <input
                    v-model="email"
                    placeholder="Email"
                    type="email"
                    class="form-control text-center"
                    style="
                        border-radius: 0px;
                        border: none;
                        border-bottom: 1px solid white;
                        box-shadow: none;
                        color: black !important;
                    "
                /> -->
                <br />
                <br />
                <el-input
                    class="login-input"
                    placeholder="Senha"
                    v-model="password"
                    type="password"
                    v-on:keyup.enter="signIn"
                ></el-input>

                <!-- <input
                    v-model="password"
                    placeholder="Senha"
                    type="password"
                    class="form-control text-center"
                    style="
                        border-radius: 0px;
                        border: none;
                        border-bottom: 1px solid white;
                        box-shadow: none;
                        color: black !important;
                    "
                /> -->
                <br />
                <br />
                <button
                    @click="signIn"
                    v-on:keyup.enter="signIn"
                    class="btn btn-primary"
                >
                    ENTRAR
                </button>
                <br />
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters({
            user: "user",
        }),
    },
    data() {
        return {
            email: "",
            password: "",
        };
    },
    methods: {
        signIn() {
            let self = this;
            firebase
                .auth()
                .signInWithEmailAndPassword(this.email, this.password)
                .then(
                    function (user) {
                        self.$message({
                            message: `Login realizado com sucesso`,
                            type: "success",
                            showClose: true,
                            offset: 60,
                        });
                        console.log(
                            "You are now connected, going to dashboard"
                        );
                        self.email = "";
                        self.password = "";
                        self.$router.push("/dashboard");
                    },
                    function (err) {
                        self.$message({
                            message: `Não foi possível fazer o login (${err})`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                        console.log("Error: " + err);
                        self.email = "";
                        self.password = "";
                    }
                );
        },
    },
    beforeCreate() {
        let self = this;
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase
                    .database()
                    .ref(`aegisv2/users/${user.uid}/internals/vLAST_LOGIN`)
                    .set(Date.now());

                self.$notify({
                    title: "Bem-vindo(a)",
                    message: user.email,
                    type: "info",
                });
                self.$router.push("/dashboard");
            }
        });
    },
};
</script>

<style scoped>
img {
    width: 100%;
}
.container {
    padding-top: 15%;
}
</style>