var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"100%","padding":"20px","overflow-y":"scroll","overflow-x":"hidden"}},[_c('div',{staticClass:"h-100 d-flex flex-column"},[_c('div',{staticClass:"flex-column justify-content-center"},[_c('div',{staticClass:"row text-left"},[_c('div',{staticClass:"col-12 my-auto align-middle",staticStyle:{"color":"#bebebe"}},[_c('span',{staticStyle:{"font-size":"2rem"}},[_vm._v(_vm._s(_vm.$t("Events_detection")))])])]),_vm._m(0),_c('br'),_c('br'),_c('div',[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-left"},[_c('h5',[_vm._v(_vm._s(_vm.$t("Events")))])])]),_c('div',{staticClass:"row"},_vm._l((_vm.pilotEvents.drone),function(p,index){return _c('div',{key:'pilot-event-' + index,staticClass:"col-3 my-2"},[_c('div',{staticClass:"bg-dark-medium-gray",staticStyle:{"border-radius":"20px"}},[_c('div',{staticClass:"row px-4 py-2"},[_c('div',{staticClass:"col-9 text-left px-2 py-2"},[_c('el-checkbox',{attrs:{"label":p[_vm.$t('vLABEL_LANGUAGE')]},on:{"change":function($event){return _vm.handleCheckBoxChange(
                                                    p.vKEY,
                                                    'vIS_ENABLED',
                                                    $event
                                                )}},model:{value:(p.vIS_ENABLED),callback:function ($$v) {_vm.$set(p, "vIS_ENABLED", $$v)},expression:"p.vIS_ENABLED"}})],1),_c('div',{staticClass:"col-3 text-right"},[_c('el-tooltip',{attrs:{"content":p[_vm.$t('vTOOLTIP_LANGUAGE')],"placement":"top"}},[_c('i',{staticClass:"\n                                                    fas\n                                                    fa-question-circle\n                                                ",staticStyle:{"font-size":"1rem","color":"#ffe082"}})])],1)]),_c('div',{class:{ hidden: !p.vRANGE_INPUT }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n                                                col-12\n                                                text-left\n                                                mx-4\n                                                px-2\n                                                py-2\n                                            "},[_vm._v(" "+_vm._s(p[ _vm.$t( "vRANGE_INPUT_LABEL_LANGUAGE" ) ])+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-8"},[_c('el-slider',{attrs:{"min":p.vMIN_VALUE,"max":p.vMAX_VALUE,"marks":p.vMARKS,"show-tooltip":p.vSHOW_TOOLTIP,"disabled":!p.vIS_ENABLED},on:{"change":function($event){return _vm.handleSliderChange(
                                                        p.vKEY,
                                                        'vVALUE',
                                                        $event
                                                    )}},model:{value:(p.vVALUE),callback:function ($$v) {_vm.$set(p, "vVALUE", $$v)},expression:"p.vVALUE"}},[_vm._v(" >")])],1),_c('div',{staticClass:"col-3 align-middle"},[_c('span',{staticClass:"align-middle",class:{
                                                    hidden: !p.vSHOW_RANGE_INPUT_VALUE,
                                                }},[_vm._v(_vm._s(p.vVALUE)+" "+_vm._s(p.vUNIT))])])])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n                                            col-12\n                                            text-left\n                                            mx-4\n                                            px-2\n                                            py-2\n                                        "},[_c('el-checkbox',{attrs:{"label":"Notificar por email","disabled":!p.vIS_ENABLED},on:{"change":function($event){return _vm.handleCheckBoxChange(
                                                    p.vKEY,
                                                    'vNOTIFY_BY_EMAIL',
                                                    $event
                                                )}},model:{value:(p.vNOTIFY_BY_EMAIL),callback:function ($$v) {_vm.$set(p, "vNOTIFY_BY_EMAIL", $$v)},expression:"p.vNOTIFY_BY_EMAIL"}})],1)])])])}),0),_c('br')]),_c('div',[_vm._m(1),_c('div',{staticClass:"row"},_vm._l((_vm.artificialIntelligenceEvents.drone),function(p,index){return _c('div',{key:'pilot-event-' + index,staticClass:"col-3 my-2"},[_c('div',{staticClass:"bg-dark-medium-gray",staticStyle:{"border-radius":"20px"}},[_c('div',{staticClass:"row px-4 py-2"},[_c('div',{staticClass:"col-9 text-left px-2 py-2"},[_c('el-checkbox',{attrs:{"label":p[_vm.$t('vLABEL_LANGUAGE')]},on:{"change":function($event){return _vm.handleCheckBoxChange(
                                                    p.vKEY,
                                                    'vIS_ENABLED',
                                                    $event
                                                )}},model:{value:(p.vIS_ENABLED),callback:function ($$v) {_vm.$set(p, "vIS_ENABLED", $$v)},expression:"p.vIS_ENABLED"}})],1),_c('div',{staticClass:"col-3 text-right"},[_c('el-tooltip',{attrs:{"content":p[_vm.$t('vTOOLTIP_LANGUAGE')],"placement":"top"}},[_c('i',{staticClass:"\n                                                    fas\n                                                    fa-question-circle\n                                                ",staticStyle:{"font-size":"1rem","color":"#ffe082"}})])],1)]),_c('div',{class:{ hidden: !p.vRANGE_INPUT }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n                                                col-12\n                                                text-left\n                                                mx-4\n                                                px-2\n                                                py-2\n                                            "},[_vm._v(" "+_vm._s(p[ _vm.$t( "vRANGE_INPUT_LABEL_LANGUAGE" ) ])+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-8"},[_c('el-slider',{attrs:{"min":p.vMIN_VALUE,"max":p.vMAX_VALUE,"marks":p.vMARKS,"show-tooltip":p.vSHOW_TOOLTIP,"disabled":!p.vIS_ENABLED},on:{"change":function($event){return _vm.handleSliderChange(
                                                        p.vKEY,
                                                        'vVALUE',
                                                        $event
                                                    )}},model:{value:(p.vVALUE),callback:function ($$v) {_vm.$set(p, "vVALUE", $$v)},expression:"p.vVALUE"}},[_vm._v(" >")])],1),_c('div',{staticClass:"col-3 align-middle"},[_c('span',{staticClass:"align-middle",class:{
                                                    hidden: !p.vSHOW_RANGE_INPUT_VALUE,
                                                }},[_vm._v(_vm._s(p.vVALUE)+" "+_vm._s(p.vUNIT))])])])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"\n                                            col-12\n                                            text-left\n                                            mx-4\n                                            px-2\n                                            py-2\n                                        "},[_c('el-checkbox',{attrs:{"label":"Notificar por email","disabled":!p.vIS_ENABLED},on:{"change":function($event){return _vm.handleCheckBoxChange(
                                                    p.vKEY,
                                                    'vNOTIFY_BY_EMAIL',
                                                    $event
                                                )}},model:{value:(p.vNOTIFY_BY_EMAIL),callback:function ($$v) {_vm.$set(p, "vNOTIFY_BY_EMAIL", $$v)},expression:"p.vNOTIFY_BY_EMAIL"}})],1)])])])}),0),_c('br')])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row text-left"},[_c('div',{staticClass:"col-12 my-auto align-middle",staticStyle:{"color":"#bebebe"}},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v("Use essa tela para configurar quais eventos serão detectados por drones.")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-left"},[_c('h5',[_vm._v("Inteligência artificial")])])])}]

export { render, staticRenderFns }