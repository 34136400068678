<template>
    <div
        class="main-settings-left-container bg-dark-medium-gray"
        style="padding: 20px 20px 20px 20px"
    >
        <!-- account -->
        <div>
            <div class="row">
                <div class="col-3 my-auto text-center">
                    <i class="fas fa-users"></i>
                </div>
                <div class="col-9 my-auto text-left">
                    <h5>{{ $t("My_account") }}</h5>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'account-registration-data';
                            $emit(
                                'setupMenuChange',
                                'account-registration-data'
                            );
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'account-registration-data',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Registration_data") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'account-apis-and-integration';
                            $emit(
                                'setupMenuChange',
                                'account-apis-and-integration'
                            );
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'account-apis-and-integration',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("APIS_and_Integration") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'account-user-management';
                            $emit('setupMenuChange', 'account-user-management');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'account-user-management',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Users_management") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'account-licensing';
                            $emit('setupMenuChange', 'account-licensing');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'account-licensing',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Licensing") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'account-followers';
                            $emit('setupMenuChange', 'account-followers');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'account-followers',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Subscribers") }}
                    </button>
                </div>
            </div>
        </div>

        <br />

        <!-- drone  -->
        <div v-if="dronesElements.length > 0">
            <div class="row">
                <div class="col-3 my-auto text-center">
                    <svg
                        id="Camada_2"
                        data-name="Camada 2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 17.99 17.83"
                    >
                        <g
                            id="Camada_1"
                            data-name="Camada 1"
                            transform="translate(0)"
                        >
                            <path
                                id="Caminho_477"
                                data-name="Caminho 477"
                                d="M21.935,25.926a4.687,4.687,0,0,0-.956-3.083,2.372,2.372,0,0,0-1.434-.97,1.617,1.617,0,0,1-1.2-1.7,1.691,1.691,0,0,1,1.514-1.542,1.585,1.585,0,0,1,1.715,1.1c.313,1.409,1.393,1.9,2.573,2.218a7.933,7.933,0,0,0,2.048.142,4.028,4.028,0,0,0,2.782-1.156,2.311,2.311,0,0,0,.578-.98,1.737,1.737,0,0,1,1.75-1.332,1.7,1.7,0,0,1,1.559,1.548,1.644,1.644,0,0,1-1.323,1.742,2.116,2.116,0,0,0-1.123.712,4.849,4.849,0,0,0,.182,6.487,2.254,2.254,0,0,0,.912.517A1.732,1.732,0,0,1,32.875,31.4,1.7,1.7,0,0,1,31.3,32.945,1.733,1.733,0,0,1,29.561,31.6a2.411,2.411,0,0,0-.619-1.007,4.317,4.317,0,0,0-2.808-1.127,4.907,4.907,0,0,0-4.019,1.278,1.826,1.826,0,0,0-.441.789,1.678,1.678,0,1,1-1.95-1.92,1.967,1.967,0,0,0,.892-.523A4.843,4.843,0,0,0,21.935,25.926Zm5.129-.134a1.452,1.452,0,1,0-2.9-.028,1.723,1.723,0,0,0,1.309,1.557A1.64,1.64,0,0,0,27.064,25.791Z"
                                transform="translate(-16.618 -16.868)"
                                fill="#fff"
                            />
                            <path
                                id="Caminho_478"
                                data-name="Caminho 478"
                                d="M123.636,6.51a2.128,2.128,0,0,1,1.221-.931,2.2,2.2,0,0,0,1.649-2.367,2.274,2.274,0,0,0-2.067-2.029,2.209,2.209,0,0,0-2.331,1.7,1.785,1.785,0,0,1-.945,1.146A3.209,3.209,0,0,1,122.677.462a3.316,3.316,0,0,1,4.487,4.618A3.215,3.215,0,0,1,123.636,6.51Z"
                                transform="translate(-109.684 -0.009)"
                                fill="#fff"
                            />
                            <path
                                id="Caminho_479"
                                data-name="Caminho 479"
                                d="M6.537,122a3.2,3.2,0,0,1-1.429,3.514,3.336,3.336,0,0,1-4.068-.426,3.343,3.343,0,0,1-.6-3.971A3.254,3.254,0,0,1,4,119.491a1.644,1.644,0,0,1-1.067.939A2.209,2.209,0,0,0,1.2,122.823a2.284,2.284,0,0,0,2.073,2.023,2.207,2.207,0,0,0,2.34-1.743A1.791,1.791,0,0,1,6.537,122Z"
                                transform="translate(-0.038 -108.188)"
                                fill="#fff"
                            />
                            <path
                                id="Caminho_480"
                                data-name="Caminho 480"
                                d="M3.97,6.606A3.183,3.183,0,0,1,.538,5.2,3.332,3.332,0,0,1,.913,1.125,3.345,3.345,0,0,1,5,.539,3.225,3.225,0,0,1,6.529,4.095,1.689,1.689,0,0,1,5.6,3.007a2.212,2.212,0,0,0-2.2-1.745,2.251,2.251,0,0,0-.453,4.45A1.409,1.409,0,0,1,3.97,6.606Z"
                                transform="translate(-0.013 -0.086)"
                                fill="#fff"
                            />
                            <path
                                id="Caminho_481"
                                data-name="Caminho 481"
                                d="M123.713,119.374a3.165,3.165,0,0,1,3.481,1.386,3.317,3.317,0,0,1-4.415,4.687,3.232,3.232,0,0,1-1.571-3.577,1.669,1.669,0,0,1,.933,1.084,2.212,2.212,0,0,0,2.2,1.745,2.246,2.246,0,0,0,.451-4.44A1.865,1.865,0,0,1,123.713,119.374Z"
                                transform="translate(-109.733 -108.044)"
                                fill="#fff"
                            />
                        </g>
                    </svg>
                </div>
                <div class="col-9 my-auto text-left">
                    <h5>{{ $t("Drone") }}</h5>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        disabled
                        @click="
                            setupMenu = 'drone-events-detection';
                            $emit('setupMenuChange', 'drone-events-detection');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'drone-events-detection',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Events_detection") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'drone-points-and-fencings';
                            $emit(
                                'setupMenuChange',
                                'drone-points-and-fencings'
                            );
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'drone-points-and-fencings',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Points_locations_and_fencings") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        disabled
                        @click="
                            setupMenu = 'drone-setup';
                            $emit('setupMenuChange', 'drone-setup');
                        "
                        :class="{
                            'active-setup-menu': setupMenu === 'drone-setup',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Settings") }}
                    </button>
                </div>
            </div>
        </div>

        <br />

        <!-- solo  -->
        <div v-if="solosElements.length > 0">
            <div class="row">
                <div class="col-3 my-auto text-center">
                    <svg
                        id="camera_black_24dp"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                    >
                        <path
                            id="Caminho_937"
                            data-name="Caminho 937"
                            d="M0,0H30V30H0Z"
                            fill="none"
                        />
                        <path
                            id="Caminho_938"
                            data-name="Caminho 938"
                            d="M11.62,13.05l6.2-10.738A13.4,13.4,0,0,0,15,2,12.979,12.979,0,0,0,6.784,4.925l4.758,8.255.078-.13ZM27.4,11.1a13.032,13.032,0,0,0-7.8-8.242L14.844,11.1Zm.338,1.3H18l.377.65,6.188,10.725A12.884,12.884,0,0,0,28,15a13.161,13.161,0,0,0-.26-2.6ZM10.5,15,5.432,6.225A12.945,12.945,0,0,0,2,15a13.161,13.161,0,0,0,.26,2.6H12ZM2.6,18.9a13.032,13.032,0,0,0,7.8,8.242L15.156,18.9Zm14.651,0-5.07,8.788A13.4,13.4,0,0,0,15,28a12.979,12.979,0,0,0,8.216-2.925L18.458,16.82Z"
                            transform="translate(0 0)"
                            fill="#fff"
                        />
                    </svg>
                </div>
                <div class="col-9 my-auto text-left"><h5>SmartCams</h5></div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'solo-events-detection';
                            $emit('setupMenuChange', 'solo-events-detection');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'solo-events-detection',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Events_detection") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'solo-points-and-fencings';
                            $emit(
                                'setupMenuChange',
                                'solo-points-and-fencings'
                            );
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'solo-points-and-fencings',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Points_locations_and_fencings") }}
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'solo-setup';
                            $emit('setupMenuChange', 'solo-setup');
                        "
                        :class="{
                            'active-setup-menu': setupMenu === 'solo-setup',
                        }"
                        style="font-size: 18px"
                    >
                        {{ $t("Settings") }}
                    </button>
                </div>
            </div>
        </div>

        <br />

        <!-- admin -->
        <div v-if="user.data.internals.vIS_ADMIN">
            <div class="row">
                <div class="col-3 my-auto text-center">
                    <i class="fas fa-user-shield"></i>
                </div>
                <div class="col-9 my-auto text-left">
                    <h5>Administrador</h5>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-forecast';
                            $emit('setupMenuChange', 'admin-forecast');
                        "
                        :class="{
                            'active-setup-menu': setupMenu === 'admin-forecast',
                        }"
                        style="font-size: 18px"
                    >
                        Previsão
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-batteries';
                            $emit('setupMenuChange', 'admin-batteries');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'admin-batteries',
                        }"
                        style="font-size: 18px"
                    >
                        Baterias
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-manage-teams';
                            $emit('setupMenuChange', 'admin-manage-teams');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'admin-manage-teams',
                        }"
                        style="font-size: 18px"
                    >
                        Times
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-manage-subscribers';
                            $emit(
                                'setupMenuChange',
                                'admin-manage-subscribers'
                            );
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'admin-manage-subscribers',
                        }"
                        style="font-size: 18px"
                    >
                        Inscritos
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-manage-supervisors';
                            $emit(
                                'setupMenuChange',
                                'admin-manage-supervisors'
                            );
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'admin-manage-supervisors',
                        }"
                        style="font-size: 18px"
                    >
                        Supervisores
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-manage-drones';
                            $emit('setupMenuChange', 'admin-manage-drones');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'admin-manage-drones',
                        }"
                        style="font-size: 18px"
                    >
                        Drones
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-manage-solos';
                            $emit('setupMenuChange', 'admin-manage-solos');
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'admin-manage-solos',
                        }"
                        style="font-size: 18px"
                    >
                        SmartCams
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="row">
                <div class="col-3 my-auto text-center"></div>
                <div class="col-9 my-auto text-left">
                    <button
                        class="btn btn-link"
                        @click="
                            setupMenu = 'admin-manage-licenses-keys';
                            $emit(
                                'setupMenuChange',
                                'admin-manage-licenses-keys'
                            );
                        "
                        :class="{
                            'active-setup-menu':
                                setupMenu === 'admin-manage-licenses-keys',
                        }"
                        style="font-size: 18px"
                    >
                        Licenças
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            setupMenu: "account-registration-data",
        };
    },
    computed: {
        ...mapGetters(["user", "dronesElements", "solosElements"]),
    },
};
</script>

<style scoped>
.main-settings-left-container {
    width: 350px;
    height: calc(100vh - 50px);

    overflow-y: auto;
}

.active-setup-menu {
    color: #ffe082 !important;
}

i {
    font-size: 2rem;
}
</style>