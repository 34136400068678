<template>
    <div class="main-mission bg-dark-medium-gray">
        <div class="main-mission-header">
            <div class="row" style="line-height: calc(54px - 10px)">
                <div class="col-12 text-left" style="font-size: 18px">
                    <button
                        class="btn btn-link btn-bold"
                        style="padding: 0 0 0 20px; font-size: 18px"
                        :class="{
                            'active-label':
                                droneActiveMission === mission.vMISSION_KEY,
                            'not-active-not-selected-label':
                                droneActiveMission !== mission.vMISSION_KEY,
                        }"
                        @click="handleMissionSelectButton()"
                        v-if="
                            !dronesMissionsEditMode ||
                            droneActiveMission !== mission.vMISSION_KEY
                        "
                    >
                        {{ mission.vMISSION_NAME }}
                    </button>
                    <div v-else>
                        <el-input
                            type="text"
                            placeholder="Nome da missão"
                            v-model="missionEditName"
                            maxlength="20"
                            show-word-limit
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="main-mission-body"
            v-if="
                droneActiveMission === mission.vMISSION_KEY &&
                droneActiveMissionElement
            "
        >
            <br />
            <div class="row">
                <div class="col-6 text-right" style="padding-right: 0px">
                    <button
                        class="btn btn-bold"
                        style="width: calc(100% - 20px); border-radius: 0px"
                        :class="{
                            'btn-primary': menu === 'mission',
                            'btn-secondary': menu !== 'mission',
                        }"
                        @click="menu = 'mission'"
                    >
                        {{ $t("Mission") }}
                    </button>
                </div>
                <div class="col-6 text-left" style="padding-left: 0px">
                    <button
                        class="btn btn-bold"
                        style="width: calc(100% - 20px); border-radius: 0px"
                        :class="{
                            'btn-primary': menu === 'waypoints',
                            'btn-secondary': menu !== 'waypoints',
                        }"
                        @click="menu = 'waypoints'"
                    >
                        {{ $t("Waypoints") }}
                    </button>
                </div>
            </div>
            <br />
            <div v-if="menu === 'mission'">
                <div>
                    <div class="row row-title">
                        <div class="col-12 text-left mx-4">Altitude (m)</div>
                    </div>
                    <div class="row row-info-sm">
                        <div
                            class="col-8 text-left mx-4"
                            v-if="dronesMissionsEditMode"
                        >
                            <el-input-number
                                v-model="_altitude"
                                controls-position="right"
                                :min="minAltitude"
                                :max="maxAltitude"
                                :step="1"
                                >m</el-input-number
                            >
                        </div>
                        <div class="col-8 text-left mx-4" v-else>
                            {{ droneActiveMissionElement.vALTITUDE }}
                        </div>
                    </div>
                </div>
                <br />
                <div>
                    <div class="row row-title">
                        <div class="col-12 text-left mx-4">
                            Velocidade (m/s)
                        </div>
                    </div>
                    <div class="row row-info-sm">
                        <div
                            class="col-8 text-left mx-4"
                            v-if="dronesMissionsEditMode"
                        >
                            <el-input-number
                                v-model="_speed"
                                controls-position="right"
                                :min="1"
                                :max="15"
                                :step="1"
                                >m</el-input-number
                            >
                        </div>
                        <div class="col-8 text-left mx-4" v-else>
                            {{ droneActiveMissionElement.vSPEED }}
                        </div>
                    </div>
                </div>
                <br />
                <div>
                    <div class="row row-title">
                        <div class="col-12 text-left mx-4">
                            Direcionamento da câmera
                        </div>
                    </div>
                    <div class="row row-info-sm">
                        <div
                            class="col-8 text-left mx-4"
                            v-if="dronesMissionsEditMode"
                        >
                            <el-select
                                v-model="_gimbalManualRotation"
                                placeholder="-"
                            >
                                <el-option
                                    v-for="item in gimbalManualRotationOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-8 text-left mx-4" v-else>
                            {{
                                droneActiveMissionElement.vGIMBAL_MANUAL_ROTATION
                                    ? "Manual"
                                    : "Auto"
                            }}
                        </div>
                    </div>
                </div>
                <br />
                <div>
                    <div class="row row-title">
                        <div class="col-12 text-left mx-4">
                            Direcionamento do drone
                        </div>
                    </div>
                    <div class="row row-info-sm">
                        <div
                            class="col-8 text-left mx-4"
                            v-if="dronesMissionsEditMode"
                        >
                            <el-select v-model="_headingMode" placeholder="-">
                                <el-option
                                    v-for="item in headingModeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-8 text-left mx-4" v-else>
                            {{
                                droneActiveMissionElement.vHEADING_MODE ===
                                "auto"
                                    ? "Automático"
                                    : "Configurar em cada ponto"
                            }}
                        </div>
                    </div>
                </div>
                <br />
                <div>
                    <div class="row row-title">
                        <div class="col-12 text-left mx-4">
                            Ação ao finalizar a missão
                        </div>
                    </div>
                    <div class="row row-info-sm">
                        <div
                            class="col-8 text-left mx-4"
                            v-if="dronesMissionsEditMode"
                        >
                            <el-select v-model="_endAction" placeholder="-">
                                <el-option
                                    v-for="item in endActionOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-8 text-left mx-4" v-else>
                            {{
                                droneActiveMissionElement.vEND_ACTION ===
                                "no_action"
                                    ? "Não fazer nada"
                                    : "Voltar para a base"
                            }}
                        </div>
                    </div>
                </div>
                <br />
                <div v-if="_endAction === 'go_home'">
                    <div class="row row-title">
                        <div class="col-12 text-left mx-4">
                            Altitude ao retornar para a base (m)
                        </div>
                    </div>
                    <div class="row row-info-sm">
                        <div
                            class="col-8 text-left mx-4"
                            v-if="dronesMissionsEditMode"
                        >
                            <el-input-number
                                v-model="_altitudeGoHome"
                                controls-position="right"
                                :min="minAltitude"
                                :max="maxAltitude"
                                :step="1"
                                >m</el-input-number
                            >
                        </div>
                        <div class="col-8 text-left mx-4" v-else>
                            {{ droneActiveMissionElement.vALTITUDE_GO_HOME }}
                        </div>
                    </div>
                    <br />
                </div>
                <div>
                    <div class="row row-title">
                        <div class="col-12 text-left mx-4">
                            Drone que irá executar a missão
                        </div>
                    </div>
                    <div class="row row-info-sm">
                        <div
                            class="col-8 text-left mx-4"
                            v-if="dronesMissionsEditMode"
                        >
                            <el-select
                                v-model="_childToExecuteMission"
                                placeholder="-"
                            >
                                <el-option
                                    v-for="item in optionsDevice"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-8 text-left mx-4" v-else>
                            {{
                                droneActiveMissionElement.vCHILD_TO_EXECUTE_MISSION
                                    ? optionsDevice
                                          .map((v) => v.value)
                                          .indexOf(
                                              droneActiveMissionElement.vCHILD_TO_EXECUTE_MISSION
                                          ) !== -1
                                        ? optionsDevice[
                                              optionsDevice
                                                  .map((v) => v.value)
                                                  .indexOf(
                                                      droneActiveMissionElement.vCHILD_TO_EXECUTE_MISSION
                                                  )
                                          ].label
                                        : "Nenhum"
                                    : "Nenhum"
                            }}
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="menu === 'waypoints'">
                <!-- if there is no waypoint -->
                <div
                    class="row"
                    v-if="droneActiveMissionElement.waypoints.length === 0"
                >
                    <div class="col-12">Nenhum waypoint</div>
                </div>
                <!-- if there are waypoints -->
                <div class="row" v-else>
                    <div class="col-2 my-auto">
                        <button
                            class="btn btn-link"
                            style="margin: 0; padding: 0"
                            v-if="
                                dronesMissionsEditMode &&
                                droneActiveMissionElement.vCHILD_TO_EXECUTE_MISSION
                            "
                            @click="
                                addChiltToExecuteMissionLocationAsMarkerFromDroneActiveMissionElement(
                                    droneActiveMissionEditWaypointIndex
                                )
                            "
                        >
                            <svg
                                id="gps_fixed-24px"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24.608"
                                height="24.608"
                                viewBox="0 0 24.608 24.608"
                            >
                                <path
                                    id="Caminho_682"
                                    data-name="Caminho 682"
                                    d="M0,0H24.608V24.608H0Z"
                                    fill="none"
                                />
                                <path
                                    id="Caminho_683"
                                    data-name="Caminho 683"
                                    d="M12.279,8.177a4.1,4.1,0,1,0,4.1,4.1A4.1,4.1,0,0,0,12.279,8.177Zm9.167,3.076A9.222,9.222,0,0,0,13.3,3.112V1H11.253V3.112a9.222,9.222,0,0,0-8.141,8.141H1V13.3H3.112a9.222,9.222,0,0,0,8.141,8.141v2.112H13.3V21.445A9.222,9.222,0,0,0,21.445,13.3h2.112V11.253H21.445Zm-9.167,8.2a7.177,7.177,0,1,1,7.177-7.177A7.172,7.172,0,0,1,12.279,19.456Z"
                                    transform="translate(0.025 0.025)"
                                    fill="#a0a0a0"
                                />
                            </svg>
                        </button>
                    </div>
                    <div class="col-8 my-auto">
                        <button
                            class="btn btn-link"
                            style="margin: 0; padding: 0"
                            @click="
                                setDroneActiveMissionEditWaypointIndex(
                                    droneActiveMissionEditWaypointIndex - 1
                                )
                            "
                            :class="{
                                'keep-hidden':
                                    droneActiveMissionEditWaypointIndex === 0,
                            }"
                        >
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        Waypoint {{ droneActiveMissionEditWaypointIndex + 1 }}
                        <button
                            class="btn btn-link"
                            style="margin: 0; padding: 0"
                            @click="
                                setDroneActiveMissionEditWaypointIndex(
                                    droneActiveMissionEditWaypointIndex + 1
                                )
                            "
                            :class="{
                                'keep-hidden':
                                    droneActiveMissionEditWaypointIndex ===
                                    droneActiveMissionElement.waypoints.length -
                                        1,
                            }"
                        >
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                    <div class="col-2 my-auto">
                        <button
                            class="btn btn-link"
                            style="margin: 0; padding: 0"
                            v-if="dronesMissionsEditMode"
                            @click="
                                deleteMarkerFromDroneActiveMissionElement(
                                    droneActiveMissionEditWaypointIndex
                                )
                            "
                        >
                            <i class="fas fa-trash" style="font-size: 1rem"></i>
                        </button>
                    </div>
                </div>
                <br />
                <!-- altitude -->
                <div class="row">
                    <div class="col-4 text-left" style="padding-left: 2rem">
                        <div class="row row-title">
                            <div class="col-12">ALTITUDE(m)</div>
                        </div>
                    </div>

                    <div class="col-8" v-if="dronesMissionsEditMode">
                        <el-input-number
                            v-model="_altitudeEditWaypointIndex"
                            controls-position="right"
                            :min="minAltitude"
                            :max="maxAltitude"
                            :step="1"
                            >m</el-input-number
                        >
                    </div>
                    <div class="col-8" v-else>
                        {{
                            droneActiveMissionElement.waypoints.length > 0
                                ? droneActiveMissionElement.waypoints[
                                      droneActiveMissionEditWaypointIndex
                                  ].vALTITUDE
                                : "-"
                        }}
                    </div>
                </div>
                <br />
                <!-- camera -->
                <div class="row">
                    <div class="col-4 text-left" style="padding-left: 2rem">
                        <div class="row row-title">
                            <div class="col-12">ROTAÇÃO(°)</div>
                        </div>
                    </div>

                    <div class="col-8" v-if="dronesMissionsEditMode">
                        <el-input-number
                            @change="handleHeadingYawChange($event)"
                            v-model="_headingYawEditWaypointIndex"
                            controls-position="right"
                            :min="minHeadingYaw"
                            :max="maxHeadingYaw"
                            :step="1"
                            >m</el-input-number
                        >
                    </div>
                    <div class="col-8" v-else>
                        {{
                            droneActiveMissionElement.waypoints.length > 0
                                ? droneActiveMissionElement.waypoints[
                                      droneActiveMissionEditWaypointIndex
                                  ].vHEADING_YAW
                                : "-"
                        }}
                    </div>
                </div>
                <br />
                <!-- rotation -->
                <div class="row">
                    <div class="col-4 text-left" style="padding-left: 2rem">
                        <div class="row row-title">
                            <div class="col-12">CÂMERA(°)</div>
                        </div>
                    </div>

                    <div class="col-8" v-if="dronesMissionsEditMode">
                        <el-input-number
                            v-model="_gimbalPitchEditWaypointIndex"
                            controls-position="right"
                            :min="minGimbalPitch"
                            :max="maxGimbalPitch"
                            :step="1"
                            >m</el-input-number
                        >
                    </div>
                    <div class="col-8" v-else>
                        {{
                            droneActiveMissionElement.waypoints.length > 0
                                ? droneActiveMissionElement.waypoints[
                                      droneActiveMissionEditWaypointIndex
                                  ].vGIMBAL_PITCH
                                : "-"
                        }}
                    </div>
                </div>
                <hr />
                <!-- actions -->

                <div class="row">
                    <div class="col-12 text-left" style="padding-left: 2rem">
                        <div class="row row-title">
                            <div class="col-12">
                                {{
                                    droneActiveMissionElement.waypoints.length >
                                    0
                                        ? droneActiveMissionElement.waypoints[
                                              droneActiveMissionEditWaypointIndex
                                          ].actions.length > 0 ||
                                          dronesMissionsEditMode
                                            ? "AÇÕES"
                                            : "NENHUMA AÇÃO"
                                        : "NENHUMA AÇÃO"
                                }}
                                <button
                                    class="btn btn-primary btn-command-sm"
                                    v-if="dronesMissionsEditMode"
                                    :disabled="
                                        droneActiveMissionElement.waypoints
                                            .length > 0
                                            ? droneActiveMissionElement
                                                  .waypoints[
                                                  droneActiveMissionEditWaypointIndex
                                              ].actions.length >= 10
                                                ? true
                                                : false
                                            : true
                                    "
                                    @click="pushIndividualAction"
                                >
                                    <i
                                        class="
                                            fas
                                            fa-plus
                                            icon-button-command-sm
                                        "
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div
                    class="row"
                    v-for="(action, index) in droneActiveMissionElement
                        .waypoints.length > 0
                        ? droneActiveMissionElement.waypoints[
                              droneActiveMissionEditWaypointIndex
                          ].actions
                        : []"
                    :key="index"
                >
                    <!-- edit mode -->
                    <div class="col-6" v-if="dronesMissionsEditMode">
                        <el-select
                            v-model="action.vTYPE"
                            placeholder="-"
                            @change="
                                (value) =>
                                    handleActionTypeOptionChange(value, index)
                            "
                        >
                            <el-option
                                v-for="item in actionsTypesOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-4" v-if="dronesMissionsEditMode">
                        <el-select v-model="action.vPARAMETER" placeholder="-">
                            <el-option
                                v-for="item in actionsTypesOptions[
                                    actionsTypesOptions
                                        .map((v) => v.value)
                                        .indexOf(action.vTYPE)
                                ].parameters"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div
                        class="col-2 align-middle"
                        style="margin: auto"
                        v-if="dronesMissionsEditMode"
                    >
                        <button
                            class="btn btn-primary btn-command-sm"
                            v-if="dronesMissionsEditMode"
                            @click="removeIndividualAction(index)"
                            style="margin: auto !important"
                        >
                            <i class="fas fa-minus icon-button-command-sm"></i>
                        </button>
                    </div>
                    <!-- readonly mode -->
                    <div
                        class="col-6 text-left"
                        style="padding-left: 2rem"
                        v-if="!dronesMissionsEditMode"
                    >
                        {{
                            actionsTypesOptions[
                                actionsTypesOptions
                                    .map((v) => v.value)
                                    .indexOf(action.vTYPE)
                            ].label
                        }}
                    </div>
                    <div class="col-4" v-if="!dronesMissionsEditMode">
                        {{
                            actionsTypesOptions[
                                actionsTypesOptions
                                    .map((v) => v.value)
                                    .indexOf(action.vTYPE)
                            ].parameters.map((v) => v.label)[
                                actionsTypesOptions[
                                    actionsTypesOptions
                                        .map((v) => v.value)
                                        .indexOf(action.vTYPE)
                                ].parameters
                                    .map((v) => v.value)
                                    .indexOf(action.vPARAMETER)
                            ]
                        }}
                    </div>
                    <br />
                    <br />
                </div>
                <!--  -->
                <!-- {{ mission.waypoints }} -->
            </div>

            <!-- bottom -->
            <div v-if="user.data.realInternals.vCHILD_TYPE === 'main'">
                <div>
                    <div class="row">
                        <div class="col-6">
                            <button
                                class="btn btn-secondary btn-bold"
                                style="width: 100%; border-radius: 0px"
                                @click="handleEditSaveClickButton()"
                                :class="{
                                    'keep-hidden': dronesMissionsEditMode,
                                }"
                            >
                                Editar
                            </button>
                        </div>
                        <div class="col-6">
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                cancel-button-text="Cancelar"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="deleteDroneActiveMission()"
                            >
                                <button
                                    class="btn btn-danger btn-bold"
                                    style="width: 100%; border-radius: 0px"
                                    :class="{
                                        'keep-hidden': dronesMissionsEditMode,
                                    }"
                                    slot="reference"
                                >
                                    Excluir
                                </button>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <br />
                <div>
                    <!-- save or cancel -->
                    <div class="row" v-if="dronesMissionsEditMode">
                        <div class="col-6">
                            <button
                                class="btn btn-primary btn-bold"
                                style="width: 100%; border-radius: 0px"
                                @click="handleEditSaveClickButton()"
                                :disabled="
                                    !droneActiveMissionFencingsRespected ||
                                    missionEditName.length === 0 ||
                                    missionEditName.length > 20
                                "
                            >
                                Salvar
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                class="btn btn-secondary btn-bold"
                                style="width: 100%; border-radius: 0px"
                                @click="handleEditCancelClickButton"
                            >
                                {{ $t("Cancel") }}
                            </button>
                        </div>
                    </div>
                    <!-- execute -->
                    <!-- <div class="row" v-else>
                    <div class="col-12">
                        <button
                            class="btn btn-primary"
                            style="width: 100%; border-radius: 0px"
                            :disabled="!droneActiveMissionFencingsRespected"
                        >
                            Executar
                        </button>
                    </div>
                </div> -->
                </div>
                <br />
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            menu: "mission", //mission,waypoints
            missionEditName: "",
            minSpeed: 1,
            maxSpeed: 15,
            minAltitude: -100,
            maxAltitude: 300,
            minGimbalPitch: -90,
            maxGimbalPitch: 0,
            minHeadingYaw: -180,
            maxHeadingYaw: 180,
            actionsTypesOptions: [
                {
                    value: "stay",
                    label: "Pairar",
                    // parameters: {
                    //     values: [1000, 5000, 10000, 20000, 30000],
                    //     labels: ["1s", "5s", "10s", "20s", "30s"],
                    // },
                    parameters: [
                        { value: 1000, label: "1s" },
                        { value: 5000, label: "5s" },
                        { value: 10000, label: "10s" },
                        { value: 20000, label: "20s" },
                        { value: 30000, label: "30s" },
                    ],
                },
                {
                    value: "start_take_photo",
                    label: "Fotografar",
                    parameters: [
                        {
                            value: "none",
                            label: "-",
                        },
                    ],
                },
                {
                    value: "start_record",
                    label: "Filmar",
                    parameters: [
                        {
                            value: "none",
                            label: "-",
                        },
                    ],
                },
                {
                    value: "stop_record",
                    label: "Parar filmagem",
                    parameters: [
                        {
                            value: "none",
                            label: "-",
                        },
                    ],
                },
                {
                    value: "rotate_aircraft",
                    label: "Rotacionar",
                    // parameters: {
                    //     values: [-180, -90, 0, 90, 180],
                    //     labels: ["-180°", "-90°", "0°", "+90°", "+180°"],
                    // },
                    parameters: [
                        { value: -180, label: "-180°" },
                        { value: -90, label: "-90°" },
                        { value: 0, label: "0°" },
                        { value: 90, label: "90°" },
                        { value: 180, label: "180°" },
                    ],
                },
                {
                    value: "gimbal_pitch",
                    label: "Gimbal",
                    // parameters: {
                    //     values: [0, -30, -60, -90],
                    //     labels: ["0°", "-30°", "-60°", "-90°"],
                    // },
                    parameters: [
                        { value: 0, label: "0°" },
                        { value: -30, label: "-30°" },
                        { value: -60, label: "-60°" },
                        { value: -90, label: "-90°" },
                    ],
                },
            ],
            gimbalManualRotationOptions: [
                {
                    value: false,
                    label: "Individual",
                },
                {
                    value: true,
                    label: "Controle manual",
                },
            ],
            headingModeOptions: [
                {
                    value: "auto",
                    label: "Individual",
                },
                {
                    value: "control_by_remote_controler",
                    label: "Controle manual",
                },
            ],
            endActionOptions: [
                {
                    value: "go_home",
                    label: "Voltar para a base",
                },
                {
                    value: "no_action",
                    label: "Não fazer nada",
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            "dronesElements",
            "droneActiveMission",
            "droneActiveMissionElement",
            "droneActiveMissionEditWaypointIndex",
            "dronesMissionsEditMode",
            "droneActiveMissionFencingsRespected",
            "user",
        ]),
        _altitude: {
            get() {
                return this.droneActiveMissionElement.vALTITUDE;
            },
            set(val) {
                this.droneActiveMissionElement.vALTITUDE = isNaN(parseInt(val))
                    ? this.minAltitude
                    : Math.min(
                          Math.max(parseInt(val), this.minAltitude),
                          this.maxAltitude
                      );
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _speed: {
            get() {
                return this.droneActiveMissionElement.vSPEED;
            },
            set(val) {
                this.droneActiveMissionElement.vSPEED = isNaN(parseInt(val))
                    ? this.minSpeed
                    : Math.min(
                          Math.max(parseInt(val), this.minSpeed),
                          this.maxSpeed
                      );
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _gimbalManualRotation: {
            get() {
                return this.droneActiveMissionElement.vGIMBAL_MANUAL_ROTATION;
            },
            set(val) {
                this.droneActiveMissionElement.vGIMBAL_MANUAL_ROTATION = val;
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _headingMode: {
            get() {
                return this.droneActiveMissionElement.vHEADING_MODE;
            },
            set(val) {
                this.droneActiveMissionElement.vHEADING_MODE = val;
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _endAction: {
            get() {
                return this.droneActiveMissionElement.vEND_ACTION;
            },
            set(val) {
                this.droneActiveMissionElement.vEND_ACTION = val;
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _altitudeGoHome: {
            get() {
                return this.droneActiveMissionElement.vALTITUDE_GO_HOME;
            },
            set(val) {
                this.droneActiveMissionElement.vALTITUDE_GO_HOME = val;
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _altitudeEditWaypointIndex: {
            get() {
                return this.droneActiveMissionElement.waypoints[
                    this.droneActiveMissionEditWaypointIndex
                ].vALTITUDE;
            },
            set(val) {
                this.droneActiveMissionElement.waypoints[
                    this.droneActiveMissionEditWaypointIndex
                ].vALTITUDE = isNaN(parseInt(val))
                    ? this.minAltitude
                    : Math.min(
                          Math.max(parseInt(val), this.minAltitude),
                          this.maxAltitude
                      );
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _headingYawEditWaypointIndex: {
            get() {
                return this.droneActiveMissionElement.waypoints[
                    this.droneActiveMissionEditWaypointIndex
                ].vHEADING_YAW;
            },
            set(val) {
                this.droneActiveMissionElement.waypoints[
                    this.droneActiveMissionEditWaypointIndex
                ].vHEADING_YAW = isNaN(parseInt(val))
                    ? this.minHeadingYaw
                    : Math.min(
                          Math.max(parseInt(val), this.minHeadingYaw),
                          this.maxHeadingYaw
                      );
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _gimbalPitchEditWaypointIndex: {
            get() {
                return this.droneActiveMissionElement.waypoints[
                    this.droneActiveMissionEditWaypointIndex
                ].vGIMBAL_PITCH;
            },
            set(val) {
                this.droneActiveMissionElement.waypoints[
                    this.droneActiveMissionEditWaypointIndex
                ].vGIMBAL_PITCH = isNaN(parseInt(val))
                    ? this.minGimbalPitch
                    : Math.min(
                          Math.max(parseInt(val), this.minGimbalPitch),
                          this.maxGimbalPitch
                      );
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        _childToExecuteMission: {
            get() {
                return (
                    this.droneActiveMissionElement.vCHILD_TO_EXECUTE_MISSION ||
                    null
                );
            },
            set(val) {
                this.droneActiveMissionElement.vCHILD_TO_EXECUTE_MISSION = val;
                this.updateDroneActiveMissionElement(
                    this.droneActiveMissionElement
                );
            },
        },
        optionsDevice() {
            return []
                .concat(
                    this.dronesElements.map(function (v) {
                        return {
                            value: v.vUID,
                            label: v.internals.vCHILD_NAME,
                        };
                    })
                )
                .sort((a, b) => (a.label > b.label ? 1 : -1))
                .concat([{ value: null, label: "Nenhum" }]);
        },
    },
    methods: {
        ...mapActions([
            "setDroneActiveMission",
            "setDroneActiveMissionEditWaypointIndex",
            "setUpdateDronesEditMode",
            "updateDroneActiveMissionElement",
            "initializeDronesMissions",
            "deleteMarkerFromDroneActiveMissionElement",
            "insertMarkerIntoDroneActiveMissionElement",
            "updateDroneActiveMissionElementMarker",
        ]),
        handleEditSaveClickButton() {
            if (!this.dronesMissionsEditMode) {
                this.missionEditName = this.mission.vMISSION_NAME;
                this.setUpdateDronesEditMode(true);
            } else {
                this.saveDroneActiveMission();
                this.missionEditName = "";
                this.setUpdateDronesEditMode(false);
            }
        },
        handleEditCancelClickButton() {
            if (this.dronesMissionsEditMode) {
                let currentMission = this.droneActiveMission;
                this.setUpdateDronesEditMode(false);
                this.setDroneActiveMission(null);
                this.setDroneActiveMission(currentMission);
                this.missionEditName = "";
            }
        },
        handleMissionSelectButton() {
            if (
                !this.dronesMissionsEditMode ||
                this.droneActiveMission !== this.mission.vMISSION_KEY
            ) {
                this.droneActiveMission === this.mission.vMISSION_KEY
                    ? this.setDroneActiveMission(null)
                    : this.setDroneActiveMission(this.mission.vMISSION_KEY);

                this.setUpdateDronesEditMode(false);

                this.droneActiveMission === this.mission.vMISSION_KEY
                    ? this.setDroneActiveMissionEditWaypointIndex(
                          this.mission.waypoints.length - 1
                      )
                    : this.setDroneActiveMissionEditWaypointIndex(0);

                this.menu = "mission";

                this.missionEditName = "";
            }
        },
        updateIndividualActionType(obj) {
            var defaultParameters = {
                stay: 1000,
                start_take_photo: "none",
                start_record: "none",
                stop_record: "none",
                rotate_aircraft: 0,
                gimbal_pitch: 0,
            };

            this.droneActiveMissionElement.waypoints[
                this.droneActiveMissionEditWaypointIndex
            ].actions[obj.index].vTYPE = obj.val;

            this.droneActiveMissionElement.waypoints[
                this.droneActiveMissionEditWaypointIndex
            ].actions[obj.index].vPARAMETER = defaultParameters[obj.val];

            this.updateDroneActiveMissionElement(
                this.droneActiveMissionElement
            );
        },
        removeIndividualAction(index) {
            var m = JSON.parse(JSON.stringify(this.droneActiveMissionElement));
            var a =
                m.waypoints[this.droneActiveMissionEditWaypointIndex].actions;
            a.splice(index, 1); //remove element
            m.waypoints[this.droneActiveMissionEditWaypointIndex].actions = a;

            this.updateDroneActiveMissionElement(m);
        },
        pushIndividualAction() {
            var m = JSON.parse(JSON.stringify(this.droneActiveMissionElement));
            var a =
                m.waypoints[this.droneActiveMissionEditWaypointIndex].actions;

            if (a.length < 15) {
                a.push({ vPARAMETER: 1000, vTYPE: "stay" });
            }
            m.waypoints[this.droneActiveMissionEditWaypointIndex].actions = a;

            this.updateDroneActiveMissionElement(m);
        },
        saveDroneActiveMission() {
            if (this.droneActiveMission) {
                var m = JSON.parse(
                    JSON.stringify(this.droneActiveMissionElement)
                );

                for (let w of m.waypoints) {
                    w.actions = Object.assign({}, w.actions); //convert array actions into object
                }
                delete m.markers;

                m.vMISSION_NAME = this.missionEditName;

                let self = this;

                if (m.vMISSION_NAME !== "" && m.vMISSION_NAME.length <= 20) {
                    firebase
                        .database()
                        .ref(
                            "aegisv2/users/" +
                                this.user.data.uid +
                                "/missions/" +
                                this.droneActiveMission
                        )
                        .set(m)
                        .then(() => {
                            self.$message({
                                message: `Missão salva`,
                                type: "success",
                                showClose: true,
                                offset: 60,
                            });

                            self.initializeDronesMissions(
                                self.droneActiveMission
                            );
                        })
                        .catch(() => {
                            self.$message({
                                message: `Erro ao salvar missão`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                } else {
                    self.$message({
                        message: `Erro ao salvar missão`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            } else {
            }
        },
        deleteDroneActiveMission() {
            let self = this;
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.user.data.uid +
                        "/missions/" +
                        this.droneActiveMission
                )
                .remove()
                .then(() => {
                    self.$message({
                        message: `Missão excluida`,
                        type: "warning",
                        showClose: true,
                        offset: 60,
                    });

                    self.initializeDronesMissions(null);
                });
        },
        handleActionTypeOptionChange(value, index) {
            // set first possible value when action changes
            this.droneActiveMissionElement.waypoints[
                this.droneActiveMissionEditWaypointIndex
            ].actions[index].vPARAMETER =
                this.actionsTypesOptions[
                    this.actionsTypesOptions.map((v) => v.value).indexOf(value)
                ].parameters[0].value;
        },
        addChiltToExecuteMissionLocationAsMarkerFromDroneActiveMissionElement(
            index
        ) {
            let m = JSON.parse(JSON.stringify(this.droneActiveMissionElement));
            let i = this.dronesElements
                .map((v) => v.vUID)
                .indexOf(
                    this.droneActiveMissionElement.vCHILD_TO_EXECUTE_MISSION
                );

            if (i !== -1) {
                let d = this.dronesElements[i];

                this.insertMarkerIntoDroneActiveMissionElement({
                    lat: d.telemetry.vAIRCRAFT_LOCATION_LATITUDE,
                    lng: d.telemetry.vAIRCRAFT_LOCATION_LONGITUDE,
                    alt: d.telemetry.vALTITUDE,
                    yaw:
                        d.telemetry.vYAW <= 180
                            ? d.telemetry.vYAW
                            : -(360 - d.telemetry.vYAW),
                    pitch: d.flight.state.vGIMBAL_PITCH,
                    index: index + 1,
                });

                this.setDroneActiveMissionEditWaypointIndex(index + 1);
                this.handleHeadingYawChange(
                    d.telemetry.vYAW <= 180
                        ? d.telemetry.vYAW
                        : -(360 - d.telemetry.vYAW)
                );
            }
        },
        handleHeadingYawChange(e) {
            this.updateDroneActiveMissionElementMarker({
                index: this.droneActiveMissionEditWaypointIndex,
                lat: null,
                lng: null,
                alt: null,
                headingYaw: e,
            });
        },
    },
    props: ["mission"],
    watch: {
        dronesMissionsEditMode: function (newVal) {
            if (newVal) {
                this.missionEditName = this.mission.vMISSION_NAME;
            }
        },
    },
};
</script>


<style scoped>
.main-mission {
    width: 100%;
    /* height: 475px; */
    margin: 5px 0px 5px 0px;
    padding: 0px 5px 0px 5px;
}

.btn-command {
    width: 2.2rem;
    height: 2.2rem;
    padding: 0px;
}

.icon-button-command {
    color: black;
    font-size: 1rem;
}

.keep-hidden {
    visibility: hidden;
}

.btn-command {
    width: 2.2rem;
    height: 2.2rem;
    padding: 0px;
}
.btn-command-sm {
    width: 1.8rem;
    height: 1.8rem;
    padding: 0px;
}

.icon-button-command {
    color: black;
    font-size: 1rem;
}
.icon-button-command-sm {
    color: black;
    font-size: 0.8rem;
}

.el-input__inner {
    color: white !important;
}
</style>



<style >
i.el-icon-arrow-up {
    font-size: 13px !important;
}
i.el-icon-arrow-down {
    font-size: 13px !important;
}
</style>