<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">Gestão de Inscritos</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para criar, deletar ou editar contas
                            de inscritos.</span
                        >
                    </div>
                </div>
                <br />
                <br />
            </div>

            <!-- {{
                dronesElements.map(function (v) {
                    return {
                        [v.vUID]: {
                            vCHILD_UID: v.vUID,
                        },
                    };
                })
            }} -->

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1;
                "
                disabled
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="
                        treatedSubscribers.filter(
                            (data) =>
                                !search ||
                                data.vCHILD_NAME
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vTEAM_UID
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                data.vCHILD_EMAIL
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                    "
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column sortable prop="vCHILD_NAME" label="Nome">
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLAST_LOGIN"
                        label="Último login"
                        ><template slot-scope="scope"
                            >{{
                                msToDDMMYYYYHHmmssAndPhrase(
                                    scope.row.vLAST_LOGIN
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vCHILD_UID" label="UID">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vCHILD_UID,
                                        'ID copiado para a área de transferência',
                                        'Não foi possível copiar o ID para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vCHILD_UID }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vTEAM_UID"
                        label="ID do time"
                    >
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vTEAM_UID,
                                        'ID do time copiado para a área de transferência',
                                        'Não foi possível copiar o ID do time para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vTEAM_UID }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vCHILD_EMAIL" label="Email">
                        <template slot-scope="scope">
                            <button
                                @click="
                                    copyToClipboard(
                                        scope.row.vCHILD_EMAIL,
                                        'Email copiado para a área de transferência',
                                        'Não foi possível copiar o Email para a área de transferência'
                                    )
                                "
                                class="btn btn-link"
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {{ scope.row.vCHILD_EMAIL }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_DRONES"
                        label="Drones"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_SOLOS"
                        label="SmartCam"
                    >
                    </el-table-column>
                    <el-table-column align="right" v-if="true">
                        <template slot="header" slot-scope="scope">
                            <el-input
                                v-model="search"
                                size="mini"
                                placeholder="Procurar"
                                style="border: 1px solid #a1a0a0"
                                :id="scope"
                                clearable
                            >
                                > ></el-input
                            >
                        </template>
                        <template slot-scope="scope">
                            <button
                                @click="handleEdit(scope.$index, scope.row)"
                                class="btn btn-link"
                                disabled
                            >
                                <i
                                    class="fas fa-pen"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                            <button
                                @click="handleDelete(scope.$index, scope.row)"
                                class="btn btn-link"
                                disabled
                            >
                                <i
                                    class="fas fa-trash"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "firebase";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            treatedSubscribers: [],
            search: "",
        };
    },
    computed: {
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
    },
    methods: {
        handleEdit(i, r) {
            console.log(i, r);
        },
        handleDelete(i, r) {
            console.log(i, r);
        },
        copyToClipboard(text, messageSuccess, messageError) {
            let self = this;
            this.$copyText(text).then(
                () => {
                    self.$message({
                        message: messageSuccess,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                },
                () => {
                    self.$message({
                        message: messageError,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            );
        },
        msToDDMMYYYYHHmmssAndPhrase(ms) {
            if (!isNaN(ms)) {
                if (ms !== 0) {
                    if (Date.now() - ms < 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / 1000
                        )} segundo${
                            Math.floor((Date.now() - ms) / 1000) > 1 ? "s" : ""
                        })`;
                    } else if (Date.now() - ms < 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \n (Há ${Math.floor(
                            (Date.now() - ms) / (60 * 1000)
                        )} minuto${
                            Math.floor((Date.now() - ms) / (60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else if (Date.now() - ms < 72 * 60 * 60 * 1000) {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (60 * 60 * 1000)
                        )} hora${
                            Math.floor((Date.now() - ms) / (60 * 60 * 1000)) > 1
                                ? "s"
                                : ""
                        })`;
                    } else {
                        return `${moment(ms).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )} \(Há ${Math.floor(
                            (Date.now() - ms) / (24 * 60 * 60 * 1000)
                        )} dia${
                            Math.floor(
                                (Date.now() - ms) / (24 * 60 * 60 * 1000)
                            ) > 1
                                ? "s"
                                : ""
                        })`;
                    }
                } else {
                    return "-";
                }
            } else {
                return "-";
            }
        },
        loadData() {
            this.treatedSubscribers = [];
            let self = this;

            firebase
                .database()
                .ref("aegisv2/subscribers/")
                .orderByChild("vCHILD_UID")
                .once("value", function (snapshot) {
                    snapshot.forEach(function (childSnapshot) {
                        firebase
                            .database()
                            .ref(
                                "aegisv2/users/" +
                                    childSnapshot.child("vCHILD_UID").val() +
                                    "/internals"
                            )
                            .once("value", function (data) {
                                let obj = {};
                                obj.vCHILD_UID = childSnapshot
                                    .child("vCHILD_UID")
                                    .val();

                                let parameters = [
                                    "vCHILD_CONTACT",
                                    "vCHILD_EMAIL",
                                    "vCHILD_NAME",
                                    "vLAST_LOGIN",
                                    "vTEAM_UID",
                                ];
                                for (let p of parameters) {
                                    obj[p] = data.child(p).val() || "-";
                                }

                                firebase
                                    .database()
                                    .ref(
                                        "aegisv2/users/" +
                                            childSnapshot
                                                .child("vCHILD_UID")
                                                .val() +
                                            "/elements"
                                    )
                                    .once("value", function (data) {
                                        obj.elements = Object.values(
                                            data.val() || {}
                                        );

                                        obj.vNUMBER_OF_DRONES =
                                            obj.elements.filter(
                                                (v) => v.vCHILD_TYPE === "drone"
                                            ).length;
                                        obj.vNUMBER_OF_SOLOS =
                                            obj.elements.filter(
                                                (v) => v.vCHILD_TYPE === "solo"
                                            ).length;

                                        self.treatedSubscribers.push(obj);
                                    });
                            });
                    });
                });
        },
    },
    beforeMount() {
        this.loadData();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>