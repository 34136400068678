<script>
//Importing Line class from the vue-chartjs wrapper
import { HorizontalBar } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
    extends: HorizontalBar,
    data() {
        return {
            pluginConfig: {
                id: "my-plugin",
                beforeRender: function (chart) {
                    if (chart.config.options.showAllTooltips) {
                        // create an array of tooltips
                        // we can't use the chart tooltip because there is only one tooltip per chart
                        chart.pluginTooltips = [];
                        chart.config.data.datasets.forEach(function (
                            dataset,
                            i
                        ) {
                            chart
                                .getDatasetMeta(i)
                                .data.forEach(function (sector, j) {
                                    chart.pluginTooltips.push(
                                        new Chart.Tooltip(
                                            {
                                                _chart: chart.chart,
                                                _chartInstance: chart,
                                                _data: chart.data,
                                                _options:
                                                    chart.options.tooltips,
                                                _active: [sector],
                                            },
                                            chart
                                        )
                                    );
                                });
                        });

                        // turn off normal tooltips
                        chart.options.tooltips.enabled = true;
                    }
                },
                afterDraw: function (chart, easing) {
                    if (chart.config.options.showAllTooltips) {
                        // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
                        if (!chart.allTooltipsOnce) {
                            if (easing !== 1) return;
                            chart.allTooltipsOnce = true;
                        }

                        // turn on tooltips
                        chart.options.tooltips.enabled = true;
                        let index = 0;
                        Chart.helpers.each(
                            chart.pluginTooltips,
                            function (tooltip) {
                                // if (index < 1) {
                                index++;
                                tooltip.initialize();
                                tooltip.update();
                                // we don't actually need this since we are not animating tooltips
                                tooltip.pivot();
                                tooltip.transition(easing).draw();
                                // }
                            }
                        );
                        chart.options.tooltips.enabled = true;
                    }
                },
            },
        };
    },
    computed: {
        options() {
            return {
                title: {
                    display: true,
                    text: this.title,
                    fontSize: 18,
                },
                responsive: true,
                maintainAspectRatio: false,
                showAllTooltips: this.showAllTooltips,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                maxTicksLimit: 3,
                                fontSize: 18,
                                precision: 0,
                            },
                            display: true,
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                fontSize: 18,
                            },
                        },
                    ],
                },
                pieceLabel: {
                    render: "value",
                    precision: 1,
                },
                tooltips: {
                    callbacks: {
                        // label: function (tooltipItem, data) {
                        //     return (
                        //         data["datasets"][0]["data"][
                        //             tooltipItem["index"]
                        //         ] +
                        //         (!isNaN(
                        //             100 *
                        //                 (data["datasets"][0]["data"][
                        //                     tooltipItem["index"]
                        //                 ] /
                        //                     data.datasets[0].data.reduce(
                        //                         (a, b) => a + b,
                        //                         0
                        //                     ))
                        //         )
                        //             ? " (" +
                        //               (
                        //                   100 *
                        //                   (data["datasets"][0]["data"][
                        //                       tooltipItem["index"]
                        //                   ] /
                        //                       data.datasets[0].data.reduce(
                        //                           (a, b) => a + b,
                        //                           0
                        //                       ))
                        //               ).toFixed(2) +
                        //               "%" +
                        //               ")"
                        //             : "")
                        //     );
                        // },

                        title: function (tooltipItems, data) {
                            return "";
                        },
                        label: function (tooltipItem, data) {
                            return data["datasets"][0]["data"][
                                tooltipItem["index"]
                            ] === 0
                                ? "0"
                                : data["datasets"][0]["data"][
                                      tooltipItem["index"]
                                  ];
                        },
                    },
                },
            };
        },
    },
    props: ["chartData", "title", "showAllTooltips"],
    watch: {
        "chartData.datasets": function (newVal, oldVal) {
            this.renderChart(this.chartData, this.options);
        },
    },
    mounted() {
        this.addPlugin(this.pluginConfig);
        this.renderChart(this.chartData, this.options);
    },
};
</script>