<script>
//Importing Line class from the vue-chartjs wrapper
import { Bar } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
    extends: Bar,
    data() {
        return {
            pluginConfig: {
                id: "my-plugin",
                beforeRender: function (chart) {
                    if (chart.config.options.showAllTooltips) {
                        // create an array of tooltips
                        // we can't use the chart tooltip because there is only one tooltip per chart
                        chart.pluginTooltips = [];
                        chart.config.data.datasets.forEach(function (
                            dataset,
                            i
                        ) {
                            chart
                                .getDatasetMeta(i)
                                .data.forEach(function (sector, j) {
                                    chart.pluginTooltips.push(
                                        new Chart.Tooltip(
                                            {
                                                _chart: chart.chart,
                                                _chartInstance: chart,
                                                _data: chart.data,
                                                _options:
                                                    chart.options.tooltips,
                                                _active: [sector],
                                            },
                                            chart
                                        )
                                    );
                                });
                        });

                        // turn off normal tooltips
                        chart.options.tooltips.enabled = true;
                    }
                },
                afterDraw: function (chart, easing) {
                    if (chart.config.options.showAllTooltips) {
                        // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
                        if (!chart.allTooltipsOnce) {
                            if (easing !== 1) return;
                            chart.allTooltipsOnce = true;
                        }

                        // turn on tooltips
                        chart.options.tooltips.enabled = true;
                        let index = 0;
                        Chart.helpers.each(
                            chart.pluginTooltips,
                            function (tooltip) {
                                // if (index < 1) {
                                index++;
                                tooltip.initialize();
                                tooltip.update();
                                // we don't actually need this since we are not animating tooltips
                                tooltip.pivot();
                                tooltip.transition(easing).draw();
                                // }
                            }
                        );
                        chart.options.tooltips.enabled = true;
                    }
                },
            },
        };
    },
    computed: {
        options() {
            return {
                title: {
                    display: true,
                    text: this.title,
                    fontSize: 18,
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    position: "bottom",
                    display: false,
                    labels: {
                        usePointStyle: false,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            // barPercentage: 0.4,
                            ticks: {
                                fontSize: 18,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            stacked: true,
                            display: true,
                            position: "left",
                            ticks: {
                                beginAtZero: true,
                                suggestedMin: 0,
                                maxTicksLimit: 3,
                                fontSize: 18,
                            },
                        },
                    ],
                },
                showAllTooltips: this.showAllTooltips,
                pieceLabel: {
                    render: "value",
                    precision: 1,
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: (item, data) => {
                            return this.chartData.tooltips[item.index];
                        },
                    },
                },
            };
        },
    },
    props: ["chartData", "title", "showAllTooltips"],
    watch: {
        "chartData.datasets": function (newVal, oldVal) {
            this.renderChart(this.chartData, this.options);
        },
    },
    mounted() {
        this.addPlugin(this.pluginConfig);
        this.renderChart(this.chartData, this.options);
    },
};
</script>