<template>
    <div
        style="height: 100%; width: 100%; outline: none"
        @click="setNavStreamFocused(false)"
    >
        <div
            style="
                position: relative;
                top: 50px;
                z-index: 1;
                width: calc(100% - 600px);
                margin-left: 30px;
                margin-right: 300px;
            "
            class="text-left"
        >
            <gmap-autocomplete
                :placeholder="$t('Search_by_address')"
                style="
                    width: 400px;
                    color: #908f8f;
                    border-radius: 20px;
                    box-shadow: none !important;
                    border: none;
                    height: 40px;
                    outline: none;
                "
                ref="gmapAutocomplete"
                class="text-center bg-dark-low-gray"
                @place_changed="setPlace"
                :select-first-on-enter="true"
                v-show="!navState.streamFocused"
            >
                <template v-slot:input="slotProps">
                    <v-text-field
                        outlined
                        prepend-inner-icon="place"
                        placeholder="Location Of Event"
                        ref="inputGmapAutocomplete"
                        v-on:listeners="slotProps.listeners"
                        v-on:attrs="slotProps.attrs"
                        :value="selectedAddress"
                    >
                    </v-text-field>
                </template>
            </gmap-autocomplete>
        </div>

        <div v-if="user.data.internals.vTEAM_LOGO != null">
            <img
                v-if="dronesElements.length > 0"
                style="
                    position: absolute;
                    bottom: 50px;
                    left: 50px;
                    width: 200px;
                    z-index: 1;
                "
                :src="require('@/assets/logoAeroguard.png')"
            />
            <img
                v-else
                style="
                    position: absolute;
                    bottom: 50px;
                    left: 50px;
                    width: 200px;
                    z-index: 1;
                "
                :src="require('@/assets/logoSolo.png')"
            />
        </div>

        <gmap-map
            :options="options"
            :center="center"
            :zoom="4"
            ref="mapRef"
            style="
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                position: absolute;
                top: 0px;
                left: 0px;
            "
            @dblclick="doubleClickOnGmaps($event)"
            @drag="!mapDragged ? setMapDragged(true) : false"
        >
            <!-- drones markers -->
            <template>
                <gmap-cluster
                    :gridSize="20"
                    :zoomOnClick="true"
                    :enableRetinaIcons="true"
                    :minimumClusterSize="2"
                >
                    <gmap-custom-marker
                        :key="'drone-custom-markers-' + index"
                        v-for="(d, index) in dronesElements"
                        :marker="d.marker.position"
                    >
                        <div
                            style="
                                background-color: white;
                                padding: 5px;
                                border-radius: 20px;
                                box-shadow: 0px 2px 7px 1px rgb(0 0 0 / 40%);
                                position: relative;
                                top: 50px;
                            "
                        >
                            <span style="color: black; font-size: 0.8rem">
                                <strong>{{ d.internals.vCHILD_NAME }}</strong>
                            </span>
                        </div>
                    </gmap-custom-marker>
                </gmap-cluster>

                <gmap-cluster
                    :gridSize="20"
                    :zoomOnClick="true"
                    :enableRetinaIcons="true"
                    :minimumClusterSize="2"
                >
                    <gmap-marker
                        :key="'drone-markers-' + index"
                        v-for="(d, index) in dronesElements"
                        :position="d.marker.position"
                        :icon="d.marker.icon"
                        @click="
                            center = d.marker.position;
                            handleDroneMarkerClick(index);
                        "
                    ></gmap-marker>
                </gmap-cluster>
            </template>

            <!-- <gmap-info-window
                :key="'drone-markers-info-window-' + index"
                v-for="(d, index) in dronesElements"
                :options="d.marker.infoOptions"
                :position="d.marker.position"
                :opened="true"
                :clickable="false"
            >
            </gmap-info-window> -->
            <!--  -->
            <!-- mission active markers -->
            <template>
                <div v-if="droneActiveMission">
                    <gmap-marker
                        :key="index"
                        v-for="(dm, index) in droneActiveMissionElement.markers"
                        :position="dm.position"
                        :icon="{
                            path: dm.icon.path,
                            rotation: dm.icon.rotation + 180,
                            fillColor:
                                index === droneActiveMissionEditWaypointIndex
                                    ? dm.icon.fillColor
                                    : '#797979',
                            fillOpacity: dm.icon.fillOpacity,
                            anchor: dm.icon.anchor,
                            labelOrigin: dm.icon.labelOrigin,
                            strokeWeight: dm.icon.strokeWeight,
                            scale: dm.icon.scale,
                        }"
                        @click="
                            handleDroneActiveMissionMarkerClick(
                                index,
                                dm.position
                            )
                        "
                        :clickable="true"
                        :draggable="dronesMissionsEditMode"
                        :label="{
                            color:
                                index === droneActiveMissionEditWaypointIndex
                                    ? '#000000'
                                    : '#FFFFFF',
                            fontSize: '16px',
                            fontWeight: '500',
                            text: (index + 1).toString(),
                        }"
                        @dragend="
                            handleDroneActiveMissionMarkerDragEnd(index, $event)
                        "
                    ></gmap-marker>

                    <!-- :opacity="
                        dronesMissionsEditMode
                            ? droneActiveElement
                                ? 1
                                : droneActiveMissionEditWaypointIndex === index
                                ? 1
                                : 0.5
                            : 1
                    " -->

                    <!--markers altitude info window -->
                    <gmap-info-window
                        v-for="(
                            dm, index
                        ) in droneActiveMissionElementAltitudeInfoWindows"
                        :key="'marker-altitude-info-window-' + index"
                        :position="dm.position"
                        :options="dm.infoOptions"
                        :opened="true"
                        :clickable="false"
                    >
                    </gmap-info-window>
                </div>
            </template>

            <!-- add waypoint between drone active mission element marker -->
            <template>
                <div v-if="droneActiveMission && dronesMissionsEditMode">
                    <gmap-marker
                        :key="index"
                        v-for="(
                            dm, index
                        ) in droneActiveMissionElementIntermediaryMarkers"
                        :position="dm.position"
                        :icon="dm.icon"
                        @click="
                            handleCLickDroneActiveMissionElementIntermediaryMarkers(
                                dm.position,
                                index
                            )
                        "
                    ></gmap-marker>

                    <!--intermediary markers distance info window -->
                    <gmap-info-window
                        v-for="(
                            dm, index
                        ) in droneActiveMissionElementIntermediaryMarkers"
                        :key="'intermediary-marker-info-window-' + index"
                        :position="dm.position"
                        :options="dm.infoOptions"
                        :opened="true"
                        :clickable="false"
                    >
                    </gmap-info-window>
                </div>
            </template>

            <template>
                <div v-if="droneActiveMission">
                    <gmap-polyline
                        v-bind:path.sync="droneActiveMissionPolyline"
                        v-bind:options="{
                            strokeColor: droneActiveMissionFencingsRespected
                                ? '#A1A0A0'
                                : '#FF0000',
                            zIndex: 99,
                        }"
                    ></gmap-polyline>
                    <gmap-polyline
                        v-bind:path.sync="droneActiveMissionPolyline"
                        v-bind:options="{
                            strokeColor: droneActiveMissionFencingsRespected
                                ? 'black'
                                : 'black',
                            strokeWeight: '4',
                            zIndex: 98,
                        }"
                    ></gmap-polyline>
                </div>
            </template>

            <template>
                <!-- events notifications -->
                <gmap-marker
                    v-for="(e, index) in markersEventsNotifications"
                    :key="'event-notification-marker-' + index"
                    :position="e.position"
                    :icon="e.icon"
                    clickable
                ></gmap-marker>

                <!-- events notifcations info window -->
                <gmap-info-window
                    v-for="(e, index) in markersEventsNotifications"
                    :key="'event-notification-marker-info-window-' + index"
                    :position="e.position"
                    :options="e.infoOptions"
                    :opened="true"
                    :clickable="false"
                >
                </gmap-info-window>
            </template>
            <!--  -->

            <template>
                <!-- fencings drone active mission -->
                <gmap-circle
                    v-for="(e, index) in markersFencingsDroneActiveMission"
                    :key="'fencing-drone-active-mission-marker-' + index"
                    ref="circle-fencing-drone-active-mission"
                    :center="{
                        lat: e.position.lat,
                        lng: e.position.lng,
                    }"
                    :radius="e.position.radius"
                    :visible="true"
                    :options="{
                        fillColor: e.color,
                        fillOpacity: 0.3,
                        strokeColor: e.color,
                    }"
                    :editable="false"
                    clickable
                />

                <!-- fencings drone active mission info window -->
                <gmap-info-window
                    v-for="(e, index) in markersFencingsDroneActiveMission"
                    :key="'fencing-drone-active-mission-info-window-' + index"
                    :position="e.position"
                    :options="e.infoOptions"
                    :opened="true"
                    :clickable="false"
                >
                </gmap-info-window>
            </template>
            <!--  -->

            <!-- solos markers -->
            <template>
                <gmap-cluster
                    :gridSize="20"
                    :zoomOnClick="true"
                    :enableRetinaIcons="true"
                    :minimumClusterSize="2"
                >
                    <gmap-custom-marker
                        :key="'solo-custom-markers-' + index"
                        v-for="(s, index) in solosElements"
                        :marker="s.marker.position"
                    >
                        <div
                            style="
                                background-color: white;
                                padding: 5px;
                                border-radius: 20px;
                                box-shadow: 0px 2px 7px 1px rgb(0 0 0 / 40%);
                                position: relative;
                                top: 50px;
                                right: 0%;
                            "
                        >
                            <span style="color: black; font-size: 0.8rem">
                                <strong>{{ s.internals.vCHILD_NAME }}</strong>
                            </span>
                        </div>
                    </gmap-custom-marker>
                </gmap-cluster>

                <gmap-cluster
                    :gridSize="20"
                    :zoomOnClick="true"
                    :enableRetinaIcons="true"
                    :minimumClusterSize="2"
                >
                    <gmap-marker
                        :key="'solo-markers-' + index"
                        v-for="(s, index) in solosMarkers"
                        :position="s.position"
                        :icon="s.icon"
                        :ref="'solo-markers-' + index"
                        @click="
                            center = s.position;
                            handleSoloMarkerClick(index);
                        "
                    ></gmap-marker>
                </gmap-cluster>
            </template>

            <!-- <gmap-info-window
                :key="'solo-markers-info-window-' + index"
                v-for="(s, index) in solosElements"
                :options="s.marker.infoOptions"
                :position="s.marker.position"
                :opened="true"
                :clickable="false"
            >
            </gmap-info-window> -->

            <template>
                <!-- fencings settings solo -->
                <gmap-circle
                    v-for="(t, index) in treatedFencings.solo"
                    :key="'fencing-settings-solo-' + index"
                    :center="{
                        lat: t.vFENCING_LOCATION_LATITUDE,
                        lng: t.vFENCING_LOCATION_LONGITUDE,
                    }"
                    :radius="t.vFENCING_LOCATION_RADIUS"
                    :visible="true"
                    :options="{
                        fillColor: t.vFENCING_COLOR,
                        fillOpacity: 0.3,
                        strokeColor: t.vFENCING_COLOR,
                    }"
                    :editable="false"
                    :clickable="false"
                />
                <!-- fencings settings drone -->
                <gmap-circle
                    v-for="(t, index) in treatedFencings.drone"
                    :key="'fencing-settings-drone-' + index"
                    :center="{
                        lat: t.vFENCING_LOCATION_LATITUDE,
                        lng: t.vFENCING_LOCATION_LONGITUDE,
                    }"
                    :radius="t.vFENCING_LOCATION_RADIUS"
                    :visible="true"
                    :options="{
                        fillColor: t.vFENCING_COLOR,
                        fillOpacity: 0.3,
                        strokeColor: t.vFENCING_COLOR,
                    }"
                    :editable="false"
                    :clickable="false"
                />
            </template>
            <!--  -->
            <!--  -->
        </gmap-map>
    </div>
</template>

<script>
// import * as VueGoogleMaps from "vue2-google-maps";

import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import firebase from "firebase";
import gmapCustomMarker from "vue2-gmap-custom-marker";
import gmapCluster from "vue2-google-maps/dist/components/cluster";

export default {
    name: "GoogleMap",
    data() {
        return {
            hoverIntermediaryDronesActiveMissionIndex: null,
            center: { lat: -23.508, lng: -46.587 },
            options: {
                disableDoubleClickZoom: true,
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                ],
                tilt: 0,
                disableDefaultUI: false,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: true,
                controlSize: 30,
            },
            opened: true,
            fencings: [],
            selectedAddress: "bauru",
            treatedFencings: {
                solo: null,
                drone: null,
            },
        };
    },
    components: { gmapCustomMarker, gmapCluster },
    computed: {
        ...mapGetters([
            "droneActive",
            "droneActiveElement",
            "dronesElements",
            "dronesMarkers",
            "droneActiveMission",
            "droneActiveMissionElement",
            "droneActiveMissionEditWaypointIndex",
            "dronesMissionsEditMode",
            "soloActive",
            "soloActiveElement",
            "solosElements",
            "solosMarkers",
            "utilsMarkers",
            "navState",
            "mapDragged",
            "eventsNotifications",
            "eventNotificationFocusedLocation",
            "droneActiveMissionFencingsRespected",
            "user",
            "events",
            "showAllEventsNotifications",
            "checkedEventsNotifications",
        ]),
        droneActiveMissionPolyline() {
            var path = [];
            for (let w of this.droneActiveMissionElement
                ? this.droneActiveMissionElement.waypoints
                : []) {
                path.push({
                    lat: w.vLATITUDE,
                    lng: w.vLONGITUDE,
                });
            }
            return path;
        },
        droneActiveMissionElementIntermediaryMarkers() {
            let originalMarkers = this.droneActiveMissionElement.markers;
            if (originalMarkers.length > 1) {
                let intermediaryMarkers = [];
                for (let i = 0; i < originalMarkers.length - 1; i++) {
                    let oBefore = originalMarkers[i];
                    let oAfter = originalMarkers[i + 1];

                    let m = JSON.parse(JSON.stringify(oAfter));
                    m.position.lat =
                        (oBefore.position.lat + oAfter.position.lat) / 2;
                    m.position.lng =
                        (oBefore.position.lng + oAfter.position.lng) / 2;
                    intermediaryMarkers.push(m);
                    m.icon = {
                        url: this.utilsMarkers.missionIntermediaryWaypoint,
                        anchor: {
                            x: 10,
                            y: 10,
                        },
                        scaledSize: {
                            width: 20,
                            height: 20,
                        },
                    };

                    m.infoOptions = {
                        pixelOffset: {
                            width: 0,
                            height: 0,
                        },
                        disableAutoPan: true,
                        content:
                            "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'> " +
                            this.distanceInKm([
                                {
                                    lat: oBefore.position.lat,
                                    lng: oBefore.position.lng,
                                },
                                {
                                    lat: oAfter.position.lat,
                                    lng: oAfter.position.lng,
                                },
                            ]).toFixed(2) +
                            " km</strong></div>",
                    };
                }
                return intermediaryMarkers;
            } else {
                return [];
            }
        },
        droneActiveMissionElementAltitudeInfoWindows() {
            let waypoints = this.droneActiveMissionElement.waypoints;
            if (waypoints.length > 1) {
                let altitudeInfoWindows = [];
                for (let w of waypoints) {
                    let a = {
                        infoOptions: {
                            pixelOffset: {
                                width: 0,
                                height: -10,
                            },
                            disableAutoPan: true,
                            content:
                                "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'> " +
                                w.vALTITUDE +
                                " m</strong></div>",
                        },
                        position: {
                            lat: w.vLATITUDE,
                            lng: w.vLONGITUDE,
                        },
                    };

                    altitudeInfoWindows.push(a);
                }
                return altitudeInfoWindows;
            } else {
                return [];
            }
        },
        markersEventsNotifications() {
            let array = this.eventsNotifications.filter(
                (v) =>
                    v.vEVENT_LOCATION_LATITUDE &&
                    v.vEVENT_LOCATION_LONGITUDE &&
                    (this.checkedEventsNotifications.includes(v.vEVENT_KEY) ||
                        this.showAllEventsNotifications)
            );
            let self = this;
            array = array.map(function (e) {
                return {
                    icon: {
                        strokeWeight: 2,
                        strokeColor: "white",
                        fillColor:
                            self.events[
                                self.events
                                    .map((v) => v.value)
                                    .indexOf(e.vEVENT_TYPE)
                            ].color,
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 8,
                    },
                    position: {
                        lat: e.vEVENT_LOCATION_LATITUDE,
                        lng: e.vEVENT_LOCATION_LONGITUDE,
                    },
                    infoOptions: {
                        pixelOffset: {
                            width: 0,
                            height: 0,
                        },
                        disableAutoPan: true,
                        content:
                            "<div style='background-color:" +
                            (self.events
                                .map((v) => v.value)
                                .indexOf(e.vEVENT_TYPE) !== -1
                                ? self.events[
                                      self.events
                                          .map((v) => v.value)
                                          .indexOf(e.vEVENT_TYPE)
                                  ].color
                                : "#FF949F") +
                            ";box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);padding:5px;border-radius:20px'><span style='color:black;font-size:1.2rem;padding:0px;font-weight:700;margin-left:2.5px;margin-right:2.5px;'> " +
                            (self.events
                                .map((v) => v.value)
                                .indexOf(e.vEVENT_TYPE) !== -1
                                ? self.events[
                                      self.events
                                          .map((v) => v.value)
                                          .indexOf(e.vEVENT_TYPE)
                                  ][self.$t("label")]
                                : $t("Unknown_event")) +
                            " - " +
                            moment(e.vTIMESTAMP).format("HH:mm") +
                            "h" +
                            "</span>" +
                            (e.vEVENT_MESSAGE
                                ? " <br><button ref='buttonTest' style='text-decoration:underline;color:black!important;font-size:1.2rem!important;margin:0;padding:0;' class='btn btn-link'>" +
                                      e.vEVENT_MESSAGE || "" + "</button>"
                                : "") +
                            "</div>",
                    },
                };
            });
            return array;
        },
        markersFencingsDroneActiveMission() {
            let array = [];
            let self = this;
            array = this.fencings.map(function (e) {
                return {
                    icon: {
                        strokeWeight: 2,
                        strokeColor: e.vFENCING_COLOR,
                        fillColor: e.vFENCING_COLOR,
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 500,
                    },
                    position: {
                        lat: e.vFENCING_LOCATION_LATITUDE,
                        lng: e.vFENCING_LOCATION_LONGITUDE,
                        radius: e.vFENCING_LOCATION_RADIUS,
                    },
                    infoOptions: {
                        pixelOffset: {
                            width: 0,
                            height: 0,
                        },
                        disableAutoPan: true,
                        content:
                            "<div style='background-color:white;padding:5px;border-radius:20px'><span style='color:black;font-size:0.6rem;margin-left:2.5px;margin-right:2.5px;'> " +
                            e.vFENCING_NAME +
                            "</span></div>",
                    },
                    color: e.vFENCING_COLOR,
                };
            });
            return array;
        },
    },
    methods: {
        ...mapActions([
            "pushMarkerIntoDroneActiveMissionElement",
            "updateDroneActiveMissionElementMarker",
            "setDroneActiveMissionEditWaypointIndex",
            "setNavMainNav",
            "setNavTopbar",
            "setDroneActive",
            "setSoloActive",
            "setDroneActiveMission",
            "setDroneActiveMissionUpdateTimestamp",
            "setDroneActiveMissionFencingsRespected",
            "insertMarkerIntoDroneActiveMissionElement",
            "setUpdateDronesEditMode",
            "setMapDragged",
            "setEventNotificationFocusedLocation",
            "setNavStreamFocused",
        ]),
        refreshUserInternalsSettings() {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${self.user.data.uid}/internals/settings/solo/fencings`
                )
                .on("value", function (snapshot) {
                    self.treatedFencings.solo = (
                        Object.values(snapshot.val() || {}) || []
                    ).filter((v) => v.vFENCING_KEY && v.vIS_FENCING_VISIBLE);
                });
            firebase
                .database()
                .ref(
                    `aegisv2/users/${self.user.data.uid}/internals/settings/drone/fencings`
                )
                .on("value", function (snapshot) {
                    self.treatedFencings.drone = (
                        Object.values(snapshot.val() || {}) || []
                    ).filter((v) => v.vFENCING_KEY && v.vIS_FENCING_VISIBLE);
                });
        },
        setPlace(place) {
            if (!place) {
                return;
            }

            this.setMapDragged(true);
            let self = this;

            self.center = {
                lat: place.geometry.location.lat() + Math.random() * 0.0000001,
                lng: place.geometry.location.lng() + Math.random() * 0.0000001,
            };
            this.$refs.mapRef.$mapPromise.then((map) => {
                map.setZoom(15);
                self.$refs.gmapAutocomplete.$refs.input.value = "";
            });
        },
        handleDroneActiveMissionMarkerClick(index, position) {
            if (this.droneActiveMissionEditWaypointIndex !== index) {
                // this.center = position;
                this.setDroneActiveMissionEditWaypointIndex(index);
            }
        },
        handleDroneActiveMissionMarkerDragEnd(index, e) {
            this.dragEndDroneActiveMissionMarkerOnGmaps(index, e);
            this.dragDroneActiveMissionMarkerOnGmaps(index, e);
        },
        handleDroneMarkerClick(i) {
            this.setNavMainNav("drone");
            this.setNavTopbar(1);
            this.setDroneActiveMission(null);
            this.setSoloActive(null);
            this.setDroneActive(this.dronesElements[i].vUID);
        },
        handleSoloMarkerClick(i) {
            this.setNavMainNav("solo");
            this.setNavTopbar(1);
            this.setDroneActiveMission(null);
            this.setDroneActive(null);
            this.setSoloActive(this.solosElements[i].vUID);
        },
        doubleClickOnGmaps(e) {
            if (this.droneActiveMission) {
                !this.dronesMissionsEditMode
                    ? this.setUpdateDronesEditMode(true)
                    : false;
                this.pushMarkerIntoDroneActiveMissionElement({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                    alt: this.droneActiveMissionElement.vALTITUDE,
                });
                this.setDroneActiveMissionEditWaypointIndex(
                    this.droneActiveMissionElement.waypoints.length - 1
                );

                this.$nextTick(() => {
                    let fencings = this.fencings.map(function (v) {
                        return {
                            lat: v.vFENCING_LOCATION_LATITUDE,
                            lng: v.vFENCING_LOCATION_LONGITUDE,
                            radius: v.vFENCING_LOCATION_RADIUS,
                        };
                    });
                    //
                    let waypointsMissionActive =
                        this.droneActiveMissionElement.waypoints.map(function (
                            v
                        ) {
                            return {
                                lat: v.vLATITUDE,
                                lng: v.vLONGITUDE,
                            };
                        });

                    if (
                        this.checkIfWaypointsCrossFencings(
                            fencings,
                            waypointsMissionActive
                        )
                    ) {
                        this.$message({
                            message: `Cerca não respeitada`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                        this.setDroneActiveMissionFencingsRespected(false);
                    } else {
                        this.$message({
                            message: `Cerca respeitada`,
                            type: "success",
                            showClose: true,
                            offset: 60,
                        });
                        this.setDroneActiveMissionFencingsRespected(true);
                    }
                });
            }
        },
        dragDroneActiveMissionMarkerOnGmaps(i, e) {
            if (this.droneActiveMission && this.dronesMissionsEditMode) {
                this.updateDroneActiveMissionElementMarker({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                    alt: null,
                    index: i,
                    headingYaw: null,
                });
            }
        },
        dragEndDroneActiveMissionMarkerOnGmaps(i, e) {
            this.setDroneActiveMissionUpdateTimestamp(new Date());

            this.$nextTick(() => {
                let fencings = this.fencings.map(function (v) {
                    return {
                        lat: v.vFENCING_LOCATION_LATITUDE,
                        lng: v.vFENCING_LOCATION_LONGITUDE,
                        radius: v.vFENCING_LOCATION_RADIUS,
                    };
                });

                let waypointsMissionActive =
                    this.droneActiveMissionElement.waypoints.map(function (v) {
                        return {
                            lat: v.vLATITUDE,
                            lng: v.vLONGITUDE,
                        };
                    });

                if (
                    this.checkIfWaypointsCrossFencings(
                        fencings,
                        waypointsMissionActive
                    )
                ) {
                    this.$message({
                        message: `Cerca não respeitada`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                    this.setDroneActiveMissionFencingsRespected(false);
                } else {
                    // this.$message({
                    //     message: `Cerca respeitada`,
                    //     type: "success",
                    // showClose: true,
                    // offset: 60
                    // });
                    this.setDroneActiveMissionFencingsRespected(true);
                }
            });
        },
        handleCLickDroneActiveMissionElementIntermediaryMarkers(
            position,
            index
        ) {
            if (this.droneActiveMission && this.dronesMissionsEditMode) {
                this.insertMarkerIntoDroneActiveMissionElement({
                    lat: position.lat,
                    lng: position.lng,
                    alt: this.droneActiveMissionElement.vALTITUDE,
                    index: index + 1,
                });
                this.setDroneActiveMissionEditWaypointIndex(index + 1);
            }
        },
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        checkIfWaypointsCrossFencings(fencings, waypointsMissionActive) {
            for (let f of fencings) {
                if (waypointsMissionActive.length <= 2) {
                    if (waypointsMissionActive.length === 0) {
                        // console.log("None segments touches fencings");
                        continue;
                    } else if (waypointsMissionActive.length === 1) {
                        if (
                            this.distanceInKm([f, waypointsMissionActive[0]]) <
                            f.radius / 1000.0
                        ) {
                            // console.log(`Fail on segment 0`);
                            return true;
                        } else {
                            // console.log("None segments touches fencings");
                            continue;
                        }
                    } else {
                        let x0 =
                            this.distanceInKm([
                                {
                                    lat: 0,
                                    lng: waypointsMissionActive[0].lng,
                                },
                                { lat: 0, lng: f.lng },
                            ]) *
                            (waypointsMissionActive[0].lng > f.lng ? 1 : -1);

                        let y0 =
                            this.distanceInKm([
                                {
                                    lat: waypointsMissionActive[0].lat,
                                    lng: 0,
                                },
                                { lat: f.lat, lng: 0 },
                            ]) *
                            (waypointsMissionActive[0].lat > f.lat ? 1 : -1);

                        let x1 =
                            this.distanceInKm([
                                {
                                    lat: 0,
                                    lng: waypointsMissionActive[1].lng,
                                },
                                { lat: 0, lng: f.lng },
                            ]) *
                            (waypointsMissionActive[1].lng > f.lng ? 1 : -1);

                        let y1 =
                            this.distanceInKm([
                                {
                                    lat: waypointsMissionActive[1].lat,
                                    lng: 0,
                                },
                                { lat: f.lat, lng: 0 },
                            ]) *
                            (waypointsMissionActive[1].lat > f.lat ? 1 : -1);

                        if (
                            this.checkIfLineTouchesCircle(
                                (y1 - y0) / (x1 - x0),
                                ((y1 - y0) / (x1 - x0)) * -x0 + y0,
                                f.radius / 1000.0,
                                [x0, x1]
                            )
                        ) {
                            // console.log(`Fail on segment 1`);
                            return true;
                        } else {
                            // console.log("None segments touches fencings-");
                            continue;
                        }
                    }
                } else {
                    let slicedWaypointsMissionActive =
                        waypointsMissionActive.slice(
                            1,
                            waypointsMissionActive.length - 1
                        );
                    // console.log(waypointsMissionActive);
                    // console.log(slicedWaypointsMissionActive);
                    for (let s of slicedWaypointsMissionActive) {
                        // intermediaries waypoints
                        let index = slicedWaypointsMissionActive.indexOf(s);

                        let x0 =
                            this.distanceInKm([
                                {
                                    lat: 0,
                                    lng: waypointsMissionActive[index].lng,
                                },
                                { lat: 0, lng: f.lng },
                            ]) *
                            (waypointsMissionActive[index].lng > f.lng
                                ? 1
                                : -1);

                        let y0 =
                            this.distanceInKm([
                                {
                                    lat: waypointsMissionActive[index].lat,
                                    lng: 0,
                                },
                                { lat: f.lat, lng: 0 },
                            ]) *
                            (waypointsMissionActive[index].lat > f.lat
                                ? 1
                                : -1);

                        let x1 =
                            this.distanceInKm([
                                {
                                    lat: 0,
                                    lng: s.lng,
                                },
                                { lat: 0, lng: f.lng },
                            ]) * (s.lng > f.lng ? 1 : -1);

                        let y1 =
                            this.distanceInKm([
                                {
                                    lat: s.lat,
                                    lng: 0,
                                },
                                { lat: f.lat, lng: 0 },
                            ]) * (s.lat > f.lat ? 1 : -1);

                        if (
                            this.checkIfLineTouchesCircle(
                                (y1 - y0) / (x1 - x0),
                                ((y1 - y0) / (x1 - x0)) * -x0 + y0,
                                f.radius / 1000.0,
                                [x0, x1]
                            )
                        ) {
                            // console.log(`Fail on segment ${index}`);
                            return true;
                        }

                        let x2 =
                            this.distanceInKm([
                                {
                                    lat: 0,
                                    lng: s.lng,
                                },
                                { lat: 0, lng: f.lng },
                            ]) * (s.lng > f.lng ? 1 : -1);

                        let y2 =
                            this.distanceInKm([
                                {
                                    lat: s.lat,
                                    lng: 0,
                                },
                                { lat: f.lat, lng: 0 },
                            ]) * (s.lat > f.lat ? 1 : -1);

                        let x3 =
                            this.distanceInKm([
                                {
                                    lat: 0,
                                    lng: waypointsMissionActive[index + 2].lng,
                                },
                                { lat: 0, lng: f.lng },
                            ]) *
                            (waypointsMissionActive[index + 2].lng > f.lng
                                ? 1
                                : -1);

                        let y3 =
                            this.distanceInKm([
                                {
                                    lat: waypointsMissionActive[index + 2].lat,
                                    lng: 0,
                                },
                                { lat: f.lat, lng: 0 },
                            ]) *
                            (waypointsMissionActive[index + 2].lat > f.lat
                                ? 1
                                : -1);

                        if (
                            this.checkIfLineTouchesCircle(
                                (y3 - y2) / (x3 - x2),
                                ((y3 - y2) / (x3 - x2)) * -x2 + y2,
                                f.radius / 1000.0,
                                [x2, x3]
                            )
                        ) {
                            // console.log(`Fail on segment ${index + 1}`);
                            return true;
                        }
                    }
                }
            }
            // console.log("None segments touches fencings");
            return false;
        },
        checkIfLineTouchesCircle(m, c, a, limits) {
            let delta =
                Math.pow((2 * c * m) / (1 + Math.pow(m, 2)), 2) -
                4 * ((Math.pow(c, 2) - Math.pow(a, 2)) / (1 + Math.pow(m, 2)));

            if (delta >= 0) {
                let x1 =
                    ((-2 * c * m) / (1 + Math.pow(m, 2)) + Math.sqrt(delta)) /
                    2;
                let x2 =
                    ((-2 * c * m) / (1 + Math.pow(m, 2)) - Math.sqrt(delta)) /
                    2;

                let limitsSorted = limits.sort((a, b) => (a > b ? 1 : -1));

                let lPlus = limitsSorted[1];
                let lLess = limitsSorted[0];

                if (
                    (x1 >= lLess && x1 <= lPlus) ||
                    (x2 >= lLess && x2 <= lPlus)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },
    watch: {
        droneActiveMission: function (newVal, oldVal) {
            //
            if (newVal) {
                let self = this;
                firebase
                    .database()
                    .ref(
                        `aegisv2/users/${self.user.data.uid}/internals/settings/drone/fencings`
                    )
                    .once("value", function (snapshot) {
                        self.fencings = (
                            Object.values(snapshot.val() || {}) || []
                        ).filter((v) => v.vFENCING_KEY);

                        //
                        self.$nextTick(() => {
                            let fencings = self.fencings.map(function (v) {
                                return {
                                    lat: v.vFENCING_LOCATION_LATITUDE,
                                    lng: v.vFENCING_LOCATION_LONGITUDE,
                                    radius: v.vFENCING_LOCATION_RADIUS,
                                };
                            });
                            //
                            let waypointsMissionActive =
                                self.droneActiveMissionElement.waypoints.map(
                                    function (v) {
                                        return {
                                            lat: v.vLATITUDE,
                                            lng: v.vLONGITUDE,
                                        };
                                    }
                                );

                            if (
                                self.checkIfWaypointsCrossFencings(
                                    fencings,
                                    waypointsMissionActive
                                )
                            ) {
                                self.$message({
                                    message: `Cerca não respeitada`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.setDroneActiveMissionFencingsRespected(
                                    false
                                );
                            } else {
                                // self.$message({
                                //     message: `Cerca respeitada`,
                                //     type: "success",
                                // showClose: true,
                                // offset: 60
                                // });
                                self.setDroneActiveMissionFencingsRespected(
                                    true
                                );
                            }
                        });
                        //
                    });
            } else {
                this.fencings = [];
            }
            //
            if (newVal && this.droneActiveMissionElement) {
                if (this.droneActiveMissionElement.markers) {
                    if (this.droneActiveMissionElement.markers.length > 1) {
                        this.$refs.mapRef.$mapPromise.then((map) => {
                            const bounds = new google.maps.LatLngBounds();
                            for (let m of this.droneActiveMissionElement
                                .markers) {
                                bounds.extend(m.position);
                            }
                            map.fitBounds(bounds);
                        });
                    }
                }
            }
        },
        droneActive: function (newVal, oldVal) {
            if (newVal) {
                this.setMapDragged(false);
                if (this.droneActiveElement.marker) {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        this.$refs.mapRef.$mapPromise.then((map) => {
                            map.setCenter(
                                this.droneActiveElement.marker.position
                            );
                            map.setZoom(18);
                        });
                    });
                }
            } else {
                let markers = this.dronesMarkers.concat(this.solosMarkers);
                this.$refs.mapRef.$mapPromise.then((map) => {
                    if (markers.length === 1) {
                        map.setCenter(markers[0].position);
                        map.setZoom(8);
                    } else if (markers.length > 1) {
                        const bounds = new google.maps.LatLngBounds();
                        for (let m of markers) {
                            bounds.extend(m.position);
                        }
                        map.fitBounds(bounds);
                    }
                });
            }
        },
        soloActive: function (newVal, oldVal) {
            if (newVal) {
                this.setMapDragged(false);
                if (this.soloActiveElement.marker) {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        this.$refs.mapRef.$mapPromise.then((map) => {
                            map.setCenter(
                                this.soloActiveElement.marker.position
                            );
                            map.setZoom(18);
                        });
                    });
                }
            } else {
                let markers = this.dronesMarkers.concat(this.solosMarkers);
                this.$refs.mapRef.$mapPromise.then((map) => {
                    if (markers.length === 1) {
                        map.setCenter(markers[0].position);
                        map.setZoom(8);
                    } else if (markers.length > 1) {
                        const bounds = new google.maps.LatLngBounds();
                        for (let m of markers) {
                            bounds.extend(m.position);
                        }
                        map.fitBounds(bounds);
                    }
                });
            }
        },
        "droneActiveElement.telemetry": function (newVal, oldVal) {
            let self = this;
            if (this.droneActive && this.droneActiveElement) {
                if (this.droneActive === this.droneActiveElement.vUID) {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        if (!self.mapDragged) {
                            map.setCenter(
                                this.droneActiveElement.marker.position
                            );
                        }
                    });
                }
            }
        },
        "soloActiveElement.telemetry": function (newVal, oldVal) {
            let self = this;
            if (this.soloActive && this.soloActiveElement) {
                if (this.soloActive === this.soloActiveElement.vUID) {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        if (!self.mapDragged) {
                            map.setCenter(
                                this.soloActiveElement.marker.position
                            );
                        }
                    });
                }
            }
        },
        mapDragged: function (newVal, oldValue) {
            if (!newVal && this.droneActive) {
                if (this.droneActiveElement.marker) {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        this.$refs.mapRef.$mapPromise.then((map) => {
                            map.setCenter(
                                this.droneActiveElement.marker.position
                            );
                            map.setZoom(18);
                        });
                    });
                }
            } else if (!newVal && this.soloActive) {
                if (this.soloActiveElement.marker) {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        this.$refs.mapRef.$mapPromise.then((map) => {
                            map.setCenter(
                                this.soloActiveElement.marker.position
                            );
                            map.setZoom(18);
                        });
                    });
                }
            }
        },
        "dronesElements.length": function (newVal, oldVal) {
            let markers = this.dronesMarkers.concat(this.solosMarkers);
            if (markers.length > 0) {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    const bounds = new google.maps.LatLngBounds();
                    for (let m of markers) {
                        bounds.extend(m.position);
                    }
                    map.fitBounds(bounds);
                });
            }
        },
        "solosElements.length": function (newVal, oldVal) {
            let markers = this.dronesMarkers.concat(this.solosMarkers);
            if (markers.length > 0) {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    const bounds = new google.maps.LatLngBounds();
                    for (let m of markers) {
                        bounds.extend(m.position);
                    }
                    map.fitBounds(bounds);
                });
            }
        },
        eventNotificationFocusedLocation: function (newVal, oldVal) {
            let self = this;
            if (newVal) {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.setZoom(18);
                    map.setCenter({
                        lat: newVal.vEVENT_LOCATION_LATITUDE,
                        lng: newVal.vEVENT_LOCATION_LONGITUDE,
                    });
                    self.setEventNotificationFocusedLocation(null);
                });
            }
        },
    },
    mounted() {
        let self = this;
        self.refreshUserInternalsSettings(this.user.data.uid);
        // if (this.dronesMarkers.length > 0) {
        //     this.$refs.mapRef.$mapPromise.then((map) => {
        //         const bounds = new google.maps.LatLngBounds();
        //         for (let m of this.dronesMarkers) {
        //             bounds.extend(m.position);
        //         }
        //         map.fitBounds(bounds);
        //     });
        // }
    },
};
</script>

<style scoped>
.pac-container {
    z-index: 99999 !important;
}
</style>