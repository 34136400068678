<template>
    <div class="main-review-body bg-dark-high-gray">
        <div
            class="row"
            style="padding: 0; margin: 0; height: calc(40vh - 20px)"
        >
            <div class="col-10" style="padding: 0; margin: 0">
                <div
                    class="row"
                    style="padding: 0; margin: 0; height: calc(40vh - 20px)"
                >
                    <div
                        class="col-6 text-center;"
                        style="padding: 0px 5px 0px 0px"
                    >
                        <div
                            v-if="
                                logReviewActive &&
                                logReviewActiveElement.vVIDEO_URL
                            "
                        >
                            <div
                                style="
                                    background-color: black;
                                    width: 100%;
                                    height: 100%;
                                "
                            >
                                <video
                                    ref="videoReview"
                                    controls
                                    style="
                                        max-width: 100%;
                                        height: calc(40vh - 20px);
                                        background-color: black;
                                    "
                                    @timeupdate="
                                        updateWaypointIndexFromLogReviewActive()
                                    "
                                    @play="handlePlayVideoReview"
                                >
                                    <source
                                        :src="
                                            logReviewActiveElement
                                                ? logReviewActiveElement.vVIDEO_URL
                                                : ''
                                        "
                                    />
                                </video>

                                <el-select
                                    v-model="playbackRate"
                                    style="
                                        position: absolute;
                                        right: 0px;
                                        top: 0px;
                                    "
                                    :width="'30px'"
                                    class="input-playback-ratio-select"
                                    @change="handleChangePlaybackRate"
                                >
                                    <el-option
                                        v-for="item in playbackRateOptions"
                                        :key="
                                            'playbackRateOptions-' + item.value
                                        "
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                                <!--  -->
                                <!--  -->
                            </div>
                        </div>

                        <div v-else style="height: calc(40vh - 20px)">
                            <img
                                class="coverMission"
                                :src="imgUnavailableMedia"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="col-6" style="padding: 0px 0px 0px 5px">
                        <div
                            style="height: 40px"
                            v-show="
                                logReviewActive
                                    ? Object.values(
                                          logReviewActiveElement.locations || {}
                                      ).length > 0 ||
                                      logReviewActiveElement.vVIDEO_URL
                                    : false
                            "
                        >
                            <div class="row h-100 my-auto my-0 mx-0">
                                <div class="col-9">
                                    <div class="row mx-0 h-100 my-auto my-0">
                                        <div
                                            class="
                                                col-4
                                                text-center
                                                mx-0
                                                my-auto my-0
                                            "
                                        >
                                            <span
                                                style="
                                                    color: #dbdbdb;
                                                    font-family: Montserrat;
                                                    font-weight: 300;
                                                    font-size: 0.8rem;
                                                "
                                                >{{ $t("BATTERY") }}</span
                                            >
                                            {{
                                                currentTelemetry.vBATTERY_CHARGE_REMAINING_IN_PERCENT !==
                                                null
                                                    ? Math.floor(
                                                          currentTelemetry.vBATTERY_CHARGE_REMAINING_IN_PERCENT
                                                      ) + " %"
                                                    : "-"
                                            }}
                                        </div>
                                        <div
                                            class="
                                                col-4
                                                text-center
                                                mx-0
                                                my-auto my-0
                                            "
                                        >
                                            <span
                                                style="
                                                    color: #dbdbdb;
                                                    font-family: Montserrat;
                                                    font-weight: 300;
                                                    font-size: 0.8rem;
                                                "
                                                >{{ $t("GPS") }}</span
                                            >
                                            {{
                                                currentTelemetry.vGPS_ACCURACY !==
                                                null
                                                    ? Math.floor(
                                                          currentTelemetry.vGPS_ACCURACY ||
                                                              0
                                                      ) + " m"
                                                    : "-"
                                            }}
                                        </div>
                                        <div
                                            class="
                                                col-4
                                                text-center
                                                mx-0
                                                my-auto my-0
                                            "
                                        >
                                            <span
                                                style="
                                                    color: #dbdbdb;
                                                    font-family: Montserrat;
                                                    font-weight: 300;
                                                    font-size: 0.8rem;
                                                "
                                                >{{ $t("SPEED") }}</span
                                            >
                                            {{
                                                currentTelemetry.vSPEED !== null
                                                    ? Math.floor(
                                                          (currentTelemetry.vSPEED ||
                                                              0) * 3.6
                                                      ) + " km/h"
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 px-0 text-right">
                                    <el-popover
                                        placement="right"
                                        width="400"
                                        v-model="popover.visible"
                                        style=""
                                    >
                                        <p>Defina a data de expiração</p>
                                        <div
                                            style="text-align: right; margin: 0"
                                        >
                                            <div class="row w-100">
                                                <div
                                                    class="
                                                        col-8
                                                        text-left
                                                        my-auto
                                                    "
                                                >
                                                    <el-select
                                                        v-model="popover.value"
                                                        placeholder="Select"
                                                    >
                                                        <el-option
                                                            v-for="item in popover.options"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value"
                                                            :disabled="
                                                                item.disabled
                                                            "
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </div>

                                                <div
                                                    class="col-4 text-left px-0"
                                                >
                                                    <button
                                                        class="btn btn-primary"
                                                        slot="reference"
                                                        @click="
                                                            popover.visible = false;
                                                            handleShareLogService(
                                                                popover.value
                                                            );
                                                        "
                                                    >
                                                        Gerar link
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            class="btn btn-primary"
                                            slot="reference"
                                            @click="popover.value = 'one_hour'"
                                            :disabled="!logReviewActive"
                                        >
                                            {{ $t("SHARE") }}
                                        </button>
                                    </el-popover>
                                </div>
                            </div>
                        </div>
                        <div style="height: calc(100% - 50px)">
                            <div
                                style="
                                    width: 100%;
                                    height: 100%;
                                    background-color: black;
                                    outline: none;
                                "
                                v-show="
                                    logReviewActive
                                        ? Object.values(
                                              logReviewActiveElement.locations ||
                                                  {}
                                          ).length > 0
                                        : false
                                "
                            >
                                <gmap-map
                                    :options="options"
                                    :center="{
                                        lat: logReviewActive
                                            ? Object.values(
                                                  logReviewActiveElement.locations ||
                                                      {}
                                              )
                                                  .map(
                                                      (v) =>
                                                          v.vLOCATION_LATITUDE
                                                  )
                                                  .filter((v) => !isNaN(v))
                                                  .length === 0
                                                ? -23
                                                : (Math.min(
                                                      ...Object.values(
                                                          logReviewActiveElement.locations ||
                                                              {}
                                                      )
                                                          .map(
                                                              (v) =>
                                                                  v.vLOCATION_LATITUDE
                                                          )
                                                          .filter(
                                                              (v) => !isNaN(v)
                                                          )
                                                  ) +
                                                      Math.max(
                                                          ...Object.values(
                                                              logReviewActiveElement.locations ||
                                                                  {}
                                                          )
                                                              .map(
                                                                  (v) =>
                                                                      v.vLOCATION_LATITUDE
                                                              )
                                                              .filter(
                                                                  (v) =>
                                                                      !isNaN(v)
                                                              )
                                                      )) /
                                                  2
                                            : -23,
                                        lng: logReviewActive
                                            ? Object.values(
                                                  logReviewActiveElement.locations ||
                                                      {}
                                              )
                                                  .map(
                                                      (v) =>
                                                          v.vLOCATION_LONGITUDE
                                                  )
                                                  .filter((v) => !isNaN(v))
                                                  .length === 0
                                                ? -46
                                                : (Math.min(
                                                      ...Object.values(
                                                          logReviewActiveElement.locations ||
                                                              {}
                                                      )
                                                          .map(
                                                              (v) =>
                                                                  v.vLOCATION_LONGITUDE
                                                          )
                                                          .filter(
                                                              (v) => !isNaN(v)
                                                          )
                                                  ) +
                                                      Math.max(
                                                          ...Object.values(
                                                              logReviewActiveElement.locations ||
                                                                  {}
                                                          )
                                                              .map(
                                                                  (v) =>
                                                                      v.vLOCATION_LONGITUDE
                                                              )
                                                              .filter(
                                                                  (v) =>
                                                                      !isNaN(v)
                                                              )
                                                      )) /
                                                  2
                                            : -46,
                                    }"
                                    :zoom="4"
                                    ref="mapRefReview"
                                    style="
                                        width: 100%;
                                        height: 100%;
                                        padding: 0;
                                        margin: 0;
                                    "
                                >
                                    <!-- child polyline -->
                                    <gmap-polyline
                                        :path="
                                            logReviewActive
                                                ? Object.values(
                                                      logReviewActiveElement.locations
                                                  )
                                                      .sort((a, b) =>
                                                          a.vTIMESTAMP >
                                                          b.vTIMESTAMP
                                                              ? 1
                                                              : -1
                                                      )
                                                      .filter(
                                                          (v) =>
                                                              v.vTIMESTAMP >=
                                                              currentLogReviewActiveElementTimeRelatedToVideo
                                                      )
                                                      .map(function (v) {
                                                          return {
                                                              lat: v.vLOCATION_LATITUDE,
                                                              lng: v.vLOCATION_LONGITUDE,
                                                          };
                                                      }) || []
                                                : []
                                        "
                                        v-bind:options="{
                                            strokeColor: '#A1A0A0',
                                        }"
                                    ></gmap-polyline>
                                    <gmap-polyline
                                        :path="
                                            logReviewActive
                                                ? Object.values(
                                                      logReviewActiveElement.locations
                                                  )
                                                      .sort((a, b) =>
                                                          a.vTIMESTAMP >
                                                          b.vTIMESTAMP
                                                              ? 1
                                                              : -1
                                                      )
                                                      .filter(
                                                          (v) =>
                                                              v.vTIMESTAMP <=
                                                              currentLogReviewActiveElementTimeRelatedToVideo
                                                      )
                                                      .map(function (v) {
                                                          return {
                                                              lat: v.vLOCATION_LATITUDE,
                                                              lng: v.vLOCATION_LONGITUDE,
                                                          };
                                                      }) || []
                                                : []
                                        "
                                        v-bind:options="{
                                            strokeColor: '#03A9F4',
                                        }"
                                    ></gmap-polyline>

                                    <!-- child marker -->
                                    <gmap-marker
                                        :position="
                                            Object.values(
                                                logReviewActiveElement.locations
                                            )
                                                .sort((a, b) =>
                                                    a.vTIMESTAMP > b.vTIMESTAMP
                                                        ? 1
                                                        : b.vTIMESTAMP >
                                                          a.vTIMESTAMP
                                                        ? -1
                                                        : 0
                                                )
                                                .map(function (v) {
                                                    return {
                                                        lat: v.vLOCATION_LATITUDE,
                                                        lng: v.vLOCATION_LONGITUDE,
                                                    };
                                                })[
                                                waypointIndexFromLogReviewActive
                                            ]
                                        "
                                        v-if="
                                            logReviewActive &&
                                            Object.values(
                                                logReviewActiveElement.locations
                                            ).length > 0
                                        "
                                        :icon="marker.icon"
                                        :clickable="false"
                                    ></gmap-marker>
                                    <!--  -->

                                    <!-- child name label -->
                                    <gmap-info-window
                                        :options="infoOptions"
                                        :position="
                                            Object.values(
                                                logReviewActiveElement.locations
                                            )
                                                .sort((a, b) =>
                                                    a.vTIMESTAMP > b.vTIMESTAMP
                                                        ? 1
                                                        : b.vTIMESTAMP >
                                                          a.vTIMESTAMP
                                                        ? -1
                                                        : 0
                                                )
                                                .map(function (v) {
                                                    return {
                                                        lat: v.vLOCATION_LATITUDE,
                                                        lng: v.vLOCATION_LONGITUDE,
                                                    };
                                                })[
                                                waypointIndexFromLogReviewActive
                                            ]
                                        "
                                        :opened="true"
                                        :clickable="false"
                                        v-if="
                                            logReviewActive &&
                                            Object.values(
                                                logReviewActiveElement.locations
                                            ).length > 0
                                        "
                                    >
                                    </gmap-info-window>

                                    <!-- events -->
                                    <gmap-marker
                                        v-for="(e, index) in logReviewActive
                                            ? markersEvents
                                            : []"
                                        :key="'events-markers-' + index"
                                        :position="e.position"
                                        :icon="e.icon"
                                        clickable
                                        @click="
                                            handleMarkerEventClick(e.position)
                                        "
                                    ></gmap-marker>

                                    <gmap-info-window
                                        v-for="(e, index) in logReviewActive
                                            ? markersEvents
                                            : []"
                                        :key="
                                            'events-markers-info-window-' +
                                            index
                                        "
                                        :position="e.position"
                                        :options="e.infoOptions"
                                        :opened="true"
                                        clickable
                                    >
                                    </gmap-info-window>

                                    <!-- mission -->
                                    <gmap-marker
                                        v-for="(m, index) in markersMission"
                                        :key="'markersMission-' + index"
                                        :position="m.position"
                                        :icon="m.icon"
                                        :label="{
                                            color: '#000000',
                                            fontSize: '10px',
                                            text: (index + 1).toString(),
                                        }"
                                        :clickable="false"
                                    ></gmap-marker>

                                    <gmap-polyline
                                        v-bind:path.sync="
                                            markersMissionLocations
                                        "
                                        v-bind:options="{
                                            strokeColor: '#292929',
                                        }"
                                        :clickable="false"
                                    ></gmap-polyline>
                                </gmap-map>
                            </div>
                            <div
                                v-show="
                                    !(logReviewActive
                                        ? Object.values(
                                              logReviewActiveElement.locations ||
                                                  {}
                                          ).length > 0
                                        : false)
                                "
                                :class="{
                                    coverExpanded:
                                        !logReviewActive ||
                                        (Object.values(
                                            logReviewActiveElement.locations ||
                                                {}
                                        ).length === 0 &&
                                            !logReviewActiveElement.vVIDEO_URL),
                                    coverRetracted:
                                        logReviewActive &&
                                        (Object.values(
                                            logReviewActiveElement.locations ||
                                                {}
                                        ).length !== 0 ||
                                            logReviewActiveElement.vVIDEO_URL),
                                }"
                            >
                                <img
                                    class="coverMission"
                                    :src="imgUnavailableLocations"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="col-2"
                style="
                    padding-right: 15px !important;
                    padding-left: 25px !important;
                "
            >
                <div class="row bg-dark-low-gray" style="height: 40px">
                    <div class="col-3 text-right">
                        <button
                            class="btn btn-link"
                            v-if="
                                currentTimelineEventIndex(
                                    timelineEvents,
                                    currentLogReviewActiveElementTimeRelatedToVideo
                                ) !== 0
                            "
                            @click="
                                handleEventClick(
                                    timelineEvents[
                                        currentTimelineEventIndex(
                                            timelineEvents,
                                            currentLogReviewActiveElementTimeRelatedToVideo
                                        ) - 1
                                    ].timestamp
                                )
                            "
                        >
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </div>
                    <div class="col-6 my-auto">
                        <strong>{{ $t("Events") }}</strong>
                    </div>
                    <div class="col-3 text-left">
                        <button
                            class="btn btn-link"
                            v-if="
                                currentTimelineEventIndex(
                                    timelineEvents,
                                    currentLogReviewActiveElementTimeRelatedToVideo
                                ) !==
                                timelineEvents.length - 1
                            "
                            @click="
                                handleEventClick(
                                    timelineEvents[
                                        currentTimelineEventIndex(
                                            timelineEvents,
                                            currentLogReviewActiveElementTimeRelatedToVideo
                                        ) + 1
                                    ].timestamp
                                )
                            "
                        >
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
                <div class="row my-auto my-0">
                    <div
                        class="col-12 mx-0 px-2 bg-dark-medium-gray"
                        style="
                            overflow-y: auto;
                            overflow-x: hidden;
                            height: calc(40vh - 60px);
                            padding: 20px;
                        "
                    >
                        <el-timeline v-if="logReviewActive">
                            <el-timeline-item
                                v-for="(t, index) in timelineEvents"
                                :key="index"
                                :timestamp="t.time"
                                :color="t.color"
                                class="text-left"
                            >
                                <button
                                    class="btn btn-link text-left"
                                    style="
                                        font-size: 0.8rem;
                                        font-weight: 700 !important;
                                        color: white !important;
                                    "
                                    :style="{
                                        color: isCurrentTimelineEvent(
                                            index,
                                            timelineEvents,
                                            t,
                                            currentLogReviewActiveElementTimeRelatedToVideo
                                        )
                                            ? '#FFE082'
                                            : 'white',
                                    }"
                                    @click="handleEventClick(t.timestamp)"
                                >
                                    {{ t.content }}
                                </button>
                                <span v-if="t.message">
                                    Mensagem: "{{ t.message }}"</span
                                >
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
    </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import momenttz from "moment-timezone";
import firebase from "firebase";

export default {
    data() {
        return {
            popover: {
                visible: false,
                options: [
                    {
                        value: "one_hour",
                        label: "1 hora",
                    },
                    {
                        value: "one_day",
                        label: "1 dia",
                    },
                    {
                        value: "one_week",
                        label: "1 semana",
                    },
                    {
                        value: "indefinite",
                        label: "Indeterminado",
                    },
                ],
                value: "one_hour",
            },
            waypointIndexFromLogReviewActive: 0,
            currentTelemetry: {
                vALTITUDE: null,
                vSPEED: null,
                vSATELLITES_COUNT: null,
                vBATTERY_CHARGE_REMAINING_IN_PERCENT: null,
                vFLIGHT_WIND_WARNING: null,
                vWEBRTC_BITRATE: null,
                vGPS_ACCURACY: null,
            },
            options: {
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                ],
                tilt: 0,
                disableDefaultUI: false,
                streetViewControl: false,
                mapTypeControl: true,
                controlSize: 30,
            },
            imgUnavailableMedia: require("../../../assets/unavailable-media.png"),
            imgUnavailableLocations: require("../../../assets/unavailable-locations.png"),
            playbackRate: 1,
            playbackRateOptions: [
                { value: 0.5, label: "0.5x" },
                { value: 1, label: "1.0x" },
                { value: 1.5, label: "1.5x" },
                { value: 2, label: "2.0x" },
                { value: 4, label: "4.0x" },
                { value: 8, label: "8.0x" },
                { value: 16, label: "16.0x" },
            ],
            currentLogReviewActiveElementTimeRelatedToVideo: 0,
        };
    },
    computed: {
        ...mapGetters([
            "logReviewsElements",
            "logReviewActive",
            "logReviewActiveElement",
            "smartElements",
            "dronesElements",
            "solosElements",
            "user",
            "events",
        ]),
        logReviewActiveElementEventsMedias() {
            return this.logReviewActive
                ? Object.values(
                      this.logReviewActiveElement.events || {}
                  ).filter(
                      (v) =>
                          v.vMEDIA_URL &&
                          (v.vMEDIA_TYPE === "video" ||
                              v.vMEDIA_TYPE === "image")
                  )
                : [];
        },
        treatedEvents() {
            if (this.logReviewActiveElement) {
                let array = Object.values(
                    this.logReviewActiveElement.events || {}
                )
                    .map((v) => v.vEVENT_TYPE)
                    .filter(
                        (v) =>
                            v !== "start_service" &&
                            v !== "stop_service" &&
                            v !== "take_off" &&
                            v !== "mission_paused" &&
                            v !== "start_go_home" &&
                            v !== "take_off"
                    );

                let r = [];
                for (let a of array) {
                    let index = r
                        .map((v) => v.name)
                        .indexOf(
                            this.events.map((v) => v.value).indexOf(a) !== -1
                                ? this.events[
                                      this.events.map((v) => v.value).indexOf(a)
                                  ][this.$t("label")]
                                : this.$t("Unknown_event")
                        );
                    if (index !== -1) {
                        r[index].count++;
                    } else {
                        r.push({
                            name:
                                this.events.map((v) => v.value).indexOf(a) !==
                                -1
                                    ? this.events[
                                          this.events
                                              .map((v) => v.value)
                                              .indexOf(a)
                                      ][this.$t("label")]
                                    : this.$t("Unknown_event"),
                            count: 1,
                        });
                    }
                }
                return r
                    .slice(0, 5)
                    .sort((a, b) =>
                        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    );
            } else {
                return [];
            }
        },
        marker() {
            let e =
                this.smartElements[
                    this.smartElements
                        .map((v) => v.vUID)
                        .indexOf(this.logReviewActiveElement.vCHILD_UID)
                ];
            return {
                icon: {
                    strokeWeight: 2,
                    strokeColor: "#343434",
                    fillColor:
                        e.internals.vCHILD_TYPE === "drone"
                            ? "#FFE082"
                            : e.internals.vMARKER_COLOR || "blue",
                    fillOpacity: 1,
                    // path: require("@/assets/markerDrone.svg"),
                    path:
                        e.internals.vCHILD_TYPE === "drone"
                            ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                            : google.maps.SymbolPath.CIRCLE,
                    rotation: Object.values(
                        this.logReviewActiveElement.locations
                    ).sort((a, b) =>
                        a.vTIMESTAMP > b.vTIMESTAMP
                            ? 1
                            : b.vTIMESTAMP > a.vTIMESTAMP
                            ? -1
                            : 0
                    )[this.waypointIndexFromLogReviewActive].vYAW,
                    scale: 5,
                    anchor: new google.maps.Point(0, 2.5),
                },
            };
        },
        infoOptions() {
            return {
                pixelOffset: {
                    width: 0,
                    height: 50,
                },
                disableAutoPan: true,
                content:
                    "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'>" +
                    this.smartElements[
                        this.smartElements
                            .map((v) => v.vUID)
                            .indexOf(this.logReviewActiveElement.vCHILD_UID)
                    ].internals.vCHILD_NAME +
                    "</strong></div>",
            };
        },
        markersEvents() {
            let eventsArray = Object.values(
                this.logReviewActiveElement.events || {}
            ).filter(
                (v) =>
                    v.vEVENT_TYPE !== "start_service" &&
                    v.vEVENT_TYPE !== "stop_service" &&
                    v.vEVENT_TYPE !== "take_off"
            );

            let a = [];

            for (let e of eventsArray) {
                let t = e.vTIMESTAMP;

                let closest = Object.values(
                    this.logReviewActiveElement.locations
                )
                    .sort((a, b) => (a.vTIMESTAMP > b.vTIMESTAMP ? 1 : -1))
                    .map((a) => a.vTIMESTAMP)
                    .reduce((a, b) => {
                        return Math.abs(b - t) < Math.abs(a - t) ? b : a;
                    }, 0);

                let locations = Object.values(
                    this.logReviewActiveElement.locations
                ).sort((a, b) => (a.vTIMESTAMP > b.vTIMESTAMP ? 1 : -1));

                let index =
                    locations.map((a) => a.vTIMESTAMP).indexOf(closest) == -1
                        ? 0
                        : locations.map((a) => a.vTIMESTAMP).indexOf(closest);

                if (locations.length === 0) return a;
                let l = locations[index];

                let messageHtml = e.vEVENT_MESSAGE
                    ? " <br><button ref='buttonTest' style='text-decoration:underline;color:#03A9F4!important;font-size:0.6rem!important;margin:0;padding:0;' class='btn btn-link'>" +
                          e.vEVENT_MESSAGE || "" + "</button>"
                    : "";

                a.push({
                    icon: {
                        strokeWeight: 2,
                        strokeColor: "#343434",
                        fillColor:
                            this.events
                                .map((v) => v.value)
                                .indexOf(e.vEVENT_TYPE) !== -1
                                ? this.events[
                                      this.events
                                          .map((v) => v.value)
                                          .indexOf(e.vEVENT_TYPE)
                                  ].color
                                : "#e4e7ed",
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 5,
                    },
                    position: {
                        lat: l.vLOCATION_LATITUDE,
                        lng: l.vLOCATION_LONGITUDE,
                        index: index,
                        closest: closest,
                    },
                    infoOptions: {
                        pixelOffset: {
                            width: 0,
                            height: 0,
                        },
                        content:
                            "<div style='background-color:white;padding:5px;border-radius:20px'><span style='color:black;font-size:0.6rem;margin-left:2.5px;margin-right:2.5px;'> " +
                            (this.events
                                .map((v) => v.value)
                                .indexOf(e.vEVENT_TYPE) !== -1
                                ? this.events[
                                      this.events
                                          .map((v) => v.value)
                                          .indexOf(e.vEVENT_TYPE)
                                  ][this.$t("label")]
                                : this.$t("Unknown_event")) +
                            " - " +
                            moment(e.vTIMESTAMP).format("HH:mm") +
                            "h" +
                            messageHtml +
                            "</span></div>",
                    },
                });
            }
            return a;
        },
        timelineEvents() {
            if (!this.logReviewActive) return [];

            let eventsArray = Object.values(
                this.logReviewActiveElement.events || {}
            )
                .sort((a, b) => (a.vTIMESTAMP > b.vTIMESTAMP ? 1 : -1))
                .filter(
                    (v) =>
                        v.vEVENT_TYPE !== "start_service" &&
                        v.vEVENT_TYPE !== "stop_service"
                );

            let array = [];

            // for (let e of eventsArray) {
            for (let i = 0; i < eventsArray.length; i++) {
                let e = eventsArray[i];
                array.push({
                    content:
                        this.events
                            .map((v) => v.value)
                            .indexOf(e.vEVENT_TYPE) !== -1
                            ? this.events[
                                  this.events
                                      .map((v) => v.value)
                                      .indexOf(e.vEVENT_TYPE)
                              ][this.$t("label")]
                            : this.$t("Unknown_event"),
                    timestamp: e.vTIMESTAMP,
                    time: moment(e.vTIMESTAMP).format("HH:mm:ss"),
                    color:
                        this.events
                            .map((v) => v.value)
                            .indexOf(e.vEVENT_TYPE) !== -1
                            ? this.events[
                                  this.events
                                      .map((v) => v.value)
                                      .indexOf(e.vEVENT_TYPE)
                              ].color
                            : "#e4e7ed",
                    index: i,
                    value: e.vEVENT_TYPE,
                    message: e.vEVENT_MESSAGE,
                });
            }
            // }
            return array;
        },
        markersMissionLocations() {
            return (
                this.logReviewActive
                    ? this.logReviewActiveElement.executedMission
                        ? this.logReviewActiveElement.executedMission
                              .waypoints || []
                        : []
                    : []
            ).map(function (v) {
                return {
                    lat: v.vLATITUDE,
                    lng: v.vLONGITUDE,
                };
            });
        },
        markersMission() {
            let locations = this.markersMissionLocations;

            let markers = locations.map((v) => {
                return {
                    icon: {
                        url: require("@/assets/img/markerMissionWaypoint.svg"),
                        anchor: {
                            x: 40,
                            y: 55,
                        },
                        scaledSize: {
                            width: 80,
                            height: 80,
                        },
                    },
                    position: {
                        lat: v.lat,
                        lng: v.lng,
                    },
                };
            });

            return markers;
        },
    },
    props: ["height", "width"],
    watch: {
        logReviewActive: function (newVal, oldVal) {
            this.currentLogReviewActiveElementTimeRelatedToVideo = 0;
            this.waypointIndexFromLogReviewActive = 0;
            this.currentTelemetry = {
                vALTITUDE: null,
                vSPEED: null,
                vSATELLITES_COUNT: null,
                vBATTERY_CHARGE_REMAINING_IN_PERCENT: null,
                vFLIGHT_WIND_WARNING: null,
                vWEBRTC_BITRATE: null,
                vGPS_ACCURACY: null,
            };
            if (this.logReviewActive) {
                if (this.logReviewActiveElement.locations) {
                    if (
                        Object.values(this.logReviewActiveElement.locations)
                            .length > 0
                    ) {
                        let self = this;
                        const factor = 1.2;

                        this.$refs.mapRefReview.$mapPromise.then((map) => {
                            const bounds = new google.maps.LatLngBounds();

                            // element locations
                            for (let l of Object.values(
                                this.logReviewActiveElement.locations
                            )) {
                                bounds.extend({
                                    lat: l.vLOCATION_LATITUDE * factor,
                                    lng: l.vLOCATION_LONGITUDE * factor,
                                });
                            }

                            // executed mission
                            for (let l of this.logReviewActiveElement
                                .executedMission
                                ? this.logReviewActiveElement.executedMission
                                      .waypoints || []
                                : []) {
                                bounds.extend({
                                    lat: l.vLATITUDE * factor,
                                    lng: l.vLONGITUDE * factor,
                                });
                            }

                            map.fitBounds(bounds);
                        });
                    }
                } else {
                    this.$refs.mapRefReview.$mapPromise.then((map) => {
                        map.setCenter({ lat: -23.508, lng: -46.587 });
                        map.setZoom(18);
                    });
                }
            }
        },
    },
    methods: {
        ...mapActions(["setNavForcedLoading"]),
        msToDDMMYYYY(ms) {
            return moment(ms).format("DD/MM/YYYY");
        },
        currentTimelineEventIndex(array, t) {
            let events = (array || []).map((v) => v);
            return this.logReviewActive && events.length > 0
                ? events.sort((a, b) =>
                      Math.abs(a.timestamp - t) > Math.abs(b.timestamp - t)
                          ? 1
                          : -1
                  )[0].index
                : 0;
        },
        isCurrentTimelineEvent(index, array, event, t) {
            let events = (array || []).map((v) => v);
            return t === 0 || events.length === 0
                ? index === 0
                    ? true
                    : false
                : events
                      .map((v) => v)
                      .sort((a, b) =>
                          Math.abs(a.timestamp - t) > Math.abs(b.timestamp - t)
                              ? 1
                              : -1
                      )[0].timestamp === event.timestamp;
            //   &&
            //   Math.abs(event.timestamp - t) < 10 * 1000;
        },
        updateWaypointIndexFromLogReviewActive() {
            if (
                this.$refs.videoReview &&
                Object.values(this.logReviewActiveElement.events || {}).filter(
                    (v) => v.vEVENT_TYPE === "start_service"
                ).length > 0
            ) {
                let t0 =
                    this.logReviewActiveElement.vTIMESTAMP ||
                    Object.values(
                        this.logReviewActiveElement.events || {}
                    ).filter((v) => v.vEVENT_TYPE === "start_service")[0]
                        .vTIMESTAMP;
                let t =
                    t0 +
                    Math.floor(1000.0 * this.$refs.videoReview.currentTime);

                this.currentLogReviewActiveElementTimeRelatedToVideo = t;

                const locations = Object.values(
                    this.logReviewActiveElement.locations || {}
                ).sort((a, b) => (a.vTIMESTAMP > b.vTIMESTAMP ? 1 : -1));

                if (locations.length === 0) {
                    return;
                }

                let closest = locations
                    .map((a) => a.vTIMESTAMP)
                    .reduce((a, b) => {
                        return Math.abs(b - t) < Math.abs(a - t) ? b : a;
                    });

                this.waypointIndexFromLogReviewActive =
                    locations.map((a) => a.vTIMESTAMP).indexOf(closest) === -1
                        ? 0
                        : locations.map((a) => a.vTIMESTAMP).indexOf(closest);

                this.currentTelemetry =
                    locations[this.waypointIndexFromLogReviewActive];

                let l = locations[this.waypointIndexFromLogReviewActive];

                this.currentTelemetry = {
                    vALTITUDE: l.vALTITUDE,
                    vSPEED:
                        l.vSPEED ||
                        Math.pow(
                            Math.pow(l.vVELOCITY_X, 2) +
                                Math.pow(l.vVELOCITY_Y, 2),
                            0.5
                        ) ||
                        0,
                    vSATELLITES_COUNT: l.vSATELLITES_COUNT,
                    vBATTERY_CHARGE_REMAINING_IN_PERCENT:
                        l.vBATTERY_CHARGE_REMAINING_IN_PERCENT,
                    vFLIGHT_WIND_WARNING: l.vFLIGHT_WIND_WARNING,
                    vWEBRTC_BITRATE: l.vWEBRTC_BITRATE,
                    vGPS_ACCURACY: l.vGPS_ACCURACY,
                };
            }
        },
        handleEventClick(timestamp) {
            let t = timestamp;
            const locations = Object.values(
                JSON.parse(
                    JSON.stringify(this.logReviewActiveElement.locations || {})
                )
            ).sort((a, b) => (a.vTIMESTAMP > b.vTIMESTAMP ? 1 : -1));
            let closest = locations
                .map((a) => a.vTIMESTAMP)
                .reduce((a, b) => {
                    return Math.abs(b - t) < Math.abs(a - t) ? b : a;
                });

            this.waypointIndexFromLogReviewActive =
                locations.map((a) => a.vTIMESTAMP).indexOf(closest) === -1
                    ? 0
                    : locations.map((a) => a.vTIMESTAMP).indexOf(closest);

            this.$refs.videoReview
                ? (this.$refs.videoReview.currentTime = Math.floor(
                      Math.max(
                          closest - this.logReviewActiveElement.vTIMESTAMP,
                          0
                      ) / 1000
                  ))
                : false;
        },
        handleChangePlaybackRate(e) {
            if (this.$refs.videoReview) {
                this.$refs.videoReview.playbackRate = e;
            }
        },
        handlePlayVideoReview(e) {
            if (this.$refs.videoReview) {
                this.$refs.videoReview.playbackRate = this.playbackRate;
            }
        },
        handleShareLogService(value) {
            let expirationValues = {
                one_hour: Date.now() + 60 * 60 * 1000,
                one_day: Date.now() + 24 * 60 * 60 * 1000,
                one_week: Date.now() + 7 * 24 * 60 * 60 * 1000,
                indefinite: "indefinite",
            };
            let self = this;

            let element = this.dronesElements.concat(this.solosElements)[
                this.dronesElements
                    .concat(this.solosElements)
                    .map((v) => v.vUID)
                    .indexOf(this.logReviewActiveElement.vCHILD_UID)
            ];

            this.setNavForcedLoading(true);

            firebase
                .database()
                .ref(`aegisv2/tokens/`)
                .push({
                    vCHILD_TYPE: element.internals.vCHILD_TYPE,
                    vCHILD_UID: element.vUID,
                    vCREATED_AT: Date.now(),
                    vEXPIRES_IN: expirationValues[value],
                    vLABEL_TEXT: "vLABEL_TEXT",
                    vLOG_SERVICE_KEY: self.logReviewActive,
                    vTEAM_UID: element.internals.vTEAM_UID,
                    vTOKEN_TYPE: "share-mission",
                })
                .then(function (data) {
                    firebase
                        .database()
                        .ref(`aegisv2/tokens/${data.key}`)
                        .update({
                            vTOKEN_KEY: data.key,
                            payload: {
                                userId:
                                    Date.now() +
                                    "-" +
                                    Math.floor(Math.random() * 1000000),
                                additionalClaims: {
                                    vTOKEN_KEY: data.key,
                                    vCHILD_TYPE: "viewer",
                                    vCREATOR_CHILD_UID: element.vUID,
                                    vCREATOR_TEAM_UID: self.user.data.uid,
                                    vCREATOR_CHILD_TYPE:
                                        element.internals.vCHILD_TYPE,
                                    vCREATOR_CHILD_NAME:
                                        element.internals.vCHILD_NAME,
                                    vCREATOR_MISSION_NAME:
                                        self.logReviewActiveElement
                                            .vMISSION_NAME || "-",
                                    vEXPIRES_IN: expirationValues[value],
                                    vCREATOR_LOG_SERVICE_KEY:
                                        self.logReviewActive,
                                    vCREATOR_LOG_SERVICE_TIMESTAMP:
                                        self.logReviewActiveElement
                                            .vTIMESTAMP ||
                                        Object.values(
                                            self.logReviewActiveElement
                                                .events || {}
                                        )
                                            .map((v) => v.vTIMESTAMP)
                                            .sort()[0],
                                    vCREATOR_TIMEZONE: momenttz.tz.guess(),
                                },
                            },
                        })
                        .then(function () {
                            firebase
                                .functions()
                                .httpsCallable(
                                    `CLOUD_MANAGE_DYNAMIC_LINK_SHARE_${element.internals.vCHILD_TYPE.toUpperCase()}_MISSION`
                                )({
                                    userId:
                                        Date.now() +
                                        "-" +
                                        Math.floor(Math.random() * 1000000),
                                    additionalClaims: {
                                        vTOKEN_KEY: data.key,
                                        vCHILD_TYPE: "viewer",
                                        vCREATOR_CHILD_UID: element.vUID,
                                        vCREATOR_TEAM_UID: self.user.data.uid,
                                        vCREATOR_CHILD_TYPE:
                                            element.internals.vCHILD_TYPE,
                                        vCREATOR_CHILD_NAME:
                                            element.internals.vCHILD_NAME,
                                        vCREATOR_MISSION_NAME:
                                            self.logReviewActiveElement
                                                .vMISSION_NAME || "-",
                                        vEXPIRES_IN: expirationValues[value],
                                        vCREATOR_LOG_SERVICE_KEY:
                                            self.logReviewActive,
                                        vCREATOR_LOG_SERVICE_TIMESTAMP:
                                            self.logReviewActiveElement
                                                .vTIMESTAMP ||
                                            Object.values(
                                                self.logReviewActiveElement
                                                    .events || {}
                                            )
                                                .map((v) => v.vTIMESTAMP)
                                                .sort()[0],
                                        vCREATOR_TIMEZONE: momenttz.tz.guess(),
                                    },
                                })
                                .then(function (result) {
                                    if (!result.data.error) {
                                        self.$copyText(result.data.res).then(
                                            () => {
                                                self.$message({
                                                    message: `Link copiado para a área de transferência`,
                                                    type: "success",
                                                    showClose: true,
                                                    offset: 60,
                                                });
                                            },
                                            () => {
                                                self.$message({
                                                    message: `Não foi possível copiar o link para a área de transferência`,
                                                    type: "error",
                                                    showClose: true,
                                                    offset: 60,
                                                });
                                            }
                                        );
                                    } else {
                                        self.$message({
                                            message: `Não foi possível copiar o link para a área de transferência`,
                                            type: "error",
                                            showClose: true,
                                            offset: 60,
                                        });
                                    }
                                })
                                .catch((e) => {
                                    self.$message({
                                        message: `Não foi possível copiar o link para a área de transferência`,
                                        type: "error",
                                        showClose: true,
                                        offset: 60,
                                    });
                                })
                                .finally(() => {
                                    self.setNavForcedLoading(false);
                                });
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível copiar o link para a área de transferência`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                            self.setNavForcedLoading(false);
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível copiar o link para a área de transferência`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                    self.setNavForcedLoading(false);
                });
        },
        handleMarkerEventClick(position) {
            this.waypointIndexFromLogReviewActive = position.index;
            this.$refs.videoReview
                ? (this.$refs.videoReview.currentTime = Math.floor(
                      Math.max(
                          position.closest -
                              this.logReviewActiveElement.vTIMESTAMP,
                          0
                      ) / 1000
                  ))
                : false;
        },
    },

    mounted() {
        // console.log(this.$refs);
        // this.$refs.buttonTest.$el.addEventListener(
        //     "click",
        //     function () {
        //         console.log("here");
        //     },
        //     false
        // );
    },
};
</script>

<style scoped>
.main-review-body {
    padding: 10px;
}

.coverMission {
    width: 100%;
    min-height: 100%;
    height: 100%;
    object-fit: cover;
}

.input-playback-ratio-select {
    width: 100px;
}

.coverExpanded {
    height: calc(40vh - 20px);
}

.coverRetracted {
    height: calc(40vh - 60px);
}
</style>