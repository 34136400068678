<template>
    <div class="main-drone-mission-card-elevation">
        <app-single-bar-chart
            :chart-data="datacollection"
            :style="style"
            :showAllTooltips="false"
        ></app-single-bar-chart>
    </div>
</template>

<script>
import appSingleBarChart from "@/components/charts/ChartNotStacked";

export default {
    data() {
        return {
            style: { height: "100%" },
        };
    },
    components: {
        appSingleBarChart,
    },
    methods: {},
    props: ["datacollection"],
    mounted() {},
    watch: {
        datacollection: function (val) {
            // console.log(JSON.parse(JSON.stringify(val)));
        },
    },
};
</script>

<style>
.main-drone-mission-card-elevation {
    height: 100%;
    position: relative;
}
</style>