<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">Previsão do tempo</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para acompanhar a previsão do
                            tempo.</span
                        >
                    </div>
                </div>
                <br />
                <br />
            </div>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <div style="height: 60%; overflow-y: scroll">
                    <div class="row" style="height: 100%">
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-9"
                        >
                            <app-single-bar-chart
                                v-if="three_day_forecast"
                                :chart-data="three_day_forecast_datacollection"
                                :style="style"
                                :title="'three_day_forecast'"
                                :showAllTooltips="false"
                            ></app-single-bar-chart>
                        </div>
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-3"
                        >
                            <h6>
                                Daily regular magnetic field variation arise
                                from current systems caused by regular solar
                                radiation changes. Other irregular current
                                systems produce magnetic field changes caused by
                                the interaction of the solar wind with the
                                magnetosphere, by the magnetosphere itself, by
                                the interactions between the magnetosphere and
                                ionosphere, and by the ionosphere itself.
                            </h6>
                        </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div class="row" style="height: 100%">
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-9"
                        >
                            <app-single-bar-chart
                                v-if="xrays"
                                :chart-data="
                                    daily_geomagnetic_indices_datacollection
                                "
                                :style="style"
                                :title="'planetary_k_index'"
                            ></app-single-bar-chart>
                        </div>
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-3"
                        >
                            <h6>
                                The GOES X-ray plots shown here are used to
                                track solar activity and solar flares. Large
                                solar X-ray flares can change the Earth’s
                                ionosphere, which blocks high-frequency (HF)
                                radio transmissions on the sunlit side of the
                                Earth. Solar flares are also associated with
                                Coronal Mass Ejections (CMEs) which can
                                ultimately lead to geomagnetic storms. SWPC
                                sends out space weather alerts at the M5 (5x10-5
                                Watts/mw) level. Some large flares are
                                accompanied by strong radio bursts that may
                                interfere with other radio frequencies and cause
                                problems for satellite communication and radio
                                navigation (GPS).
                            </h6>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row" style="height: 100%">
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-9"
                        >
                            <app-single-log-bar-chart
                                v-if="xrays"
                                :chart-data="flux_datacollection"
                                :style="style"
                                :title="'flux'"
                            ></app-single-log-bar-chart>
                        </div>
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-3"
                        >
                            <el-table
                                :data="radioBlackoutsClasses"
                                style="width: 100%"
                                height="100%"
                                highlight-current-row
                                :row-class-name="tableRowClassName"
                                header-cell-class-name="header-row-class-name-custom"
                            >
                                <el-table-column prop="level" label="Nível">
                                </el-table-column>
                                <el-table-column prop="label" label="Descrição">
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row" style="height: 100%">
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-9"
                        >
                            <app-single-bar-chart
                                v-if="xrays"
                                :chart-data="planetary_k_index_datacollection"
                                :style="style"
                                :title="'planetary_k_index'"
                            ></app-single-bar-chart>
                        </div>
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-3"
                        >
                            <!-- <h5>Histórico</h5> -->
                        </div>
                    </div>
                    <!--  -->
                    <div class="row" style="height: 100%">
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-9"
                        >
                            <app-single-bar-chart
                                v-if="geospace_pred_est_kp"
                                :chart-data="kp_datacollection"
                                :style="style"
                                :title="'geospace_pred_est_kp'"
                            ></app-single-bar-chart>
                        </div>
                        <div
                            style="
                                height: 100%;
                                position: relative;
                                width: 100%;
                            "
                            class="chart-container col-3"
                        >
                            <!-- <h5>Previsão</h5> -->
                        </div>
                    </div>
                </div>
                <div v-if="alerts" style="height: 40%">
                    <el-table
                        :data="treatedAlerts"
                        style="width: 100%"
                        height="100%"
                        highlight-current-row
                        :row-class-name="tableRowClassName"
                        header-cell-class-name="header-row-class-name-custom"
                    >
                        <el-table-column sortable prop="timestamp" label="Data">
                            <template slot-scope="scope"
                                >{{ msToDDMMYYYYHHmmss(scope.row.timestamp) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable
                            prop="message"
                            label="Mensagem"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import appSingleLogBarChart from "@/components/charts/SingleLogBarChart";
import appSingleBarChart from "@/components/charts/SingleBarChart";
import momenttz from "moment-timezone";
import moment from "moment";

export default {
    data() {
        return {
            xrays: [],
            geospace_pred_est_kp: [],
            planetary_k_index: [],
            alerts: [],
            daily_geomagnetic_indices: [],
            three_day_forecast: [],
            style: { height: "100%", width: "100%" },
            radioBlackoutsClasses: [
                {
                    level: "R5",
                    label: "Extreme (>2x10-3)",
                },
                {
                    level: "R4",
                    label: "Severe (>1x10-3)",
                },
                {
                    level: "R3",
                    label: "Strong (>1x10-4)",
                },
                {
                    level: "R2",
                    label: "Moderate (>5x10-5)",
                },
                {
                    level: "R1",
                    label: "Minor (>1x10-5)",
                },
            ],
        };
    },
    computed: {
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
        flux_datacollection() {
            return {
                datasets: [
                    {
                        label: "observed_flux",
                        backgroundColor: "#A5D6A7",
                        borderColor: "#A5D6A7",
                        type: "line",
                        fill: true,
                        data: this.xrays.map((v) => v.observed_flux),
                        pointRadius: 0,
                    },
                    {
                        label: "flux",
                        backgroundColor: "#EF9A9A",
                        borderColor: "#EF9A9A",
                        type: "line",
                        fill: true,
                        data: this.xrays.map((v) => v.flux),
                        pointRadius: 0,
                    },
                    // {
                    //     label: "R1",
                    //     backgroundColor: "white",
                    //     borderColor: "white",
                    //     type: "line",
                    //     fill: false,
                    //     data: this.xrays.map((v) => 0.00001),
                    //     pointRadius: 0,
                    // },
                    // {
                    //     label: "R2",
                    //     backgroundColor: "white",
                    //     borderColor: "white",
                    //     type: "line",
                    //     fill: false,
                    //     data: this.xrays.map((v) => 0.00005),
                    //     pointRadius: 0,
                    // },
                    // {
                    //     label: "R3",
                    //     backgroundColor: "white",
                    //     borderColor: "white",
                    //     type: "line",
                    //     fill: false,
                    //     data: this.xrays.map((v) => 0.0001),
                    //     pointRadius: 0,
                    // },
                    // {
                    //     label: "R4",
                    //     backgroundColor: "white",
                    //     borderColor: "white",
                    //     type: "line",
                    //     fill: false,
                    //     data: this.xrays.map((v) => 0.001),
                    //     pointRadius: 0,
                    // },
                    // {
                    //     label: "R5",
                    //     backgroundColor: "white",
                    //     borderColor: "white",
                    //     type: "line",
                    //     fill: false,
                    //     data: this.xrays.map((v) => 0.002),
                    //     pointRadius: 0,
                    // },
                ],
                // labels: this.xrays.map((v) =>
                //     moment(momenttz.tz(v.time_tag, "Europe/Paris")).format(
                //         "DD/MM HH:mm"
                //     )
                // ),
                // labels: this.xrays.map((v) =>
                //     moment(momenttz.tz(v.time_tag, "America/Sao_Paulo")).format(
                //         "DD/MM HH:mm"
                //     )
                // ),
                labels: this.xrays.map((v) =>
                    moment(momenttz.tz(v.time_tag, momenttz.tz.guess())).format(
                        "DD/MM HH:mm"
                    )
                ),
            };
        },
        kp_datacollection() {
            return {
                datasets: [
                    {
                        label: "kp",
                        backgroundColor: "#EF9A9A",
                        borderColor: "#EF9A9A",
                        type: "line",
                        fill: false,
                        data: this.geospace_pred_est_kp.map((v) => v.k),
                        pointRadius: 0,
                    },
                ],
                labels: this.geospace_pred_est_kp.map((v) =>
                    moment(
                        momenttz.tz(
                            v.model_prediction_time,
                            momenttz.tz.guess()
                        )
                    ).format("DD/MM HH:mm")
                ),
            };
        },
        planetary_k_index_datacollection() {
            return {
                datasets: [
                    {
                        label: "planetary_k_index",
                        backgroundColor: "#EF9A9A",
                        borderColor: "#EF9A9A",
                        type: "line",
                        fill: false,
                        data: this.planetary_k_index.map((v) => v.kp_index),
                        pointRadius: 0,
                    },
                ],
                labels: this.planetary_k_index.map((v) =>
                    moment(
                        momenttz.tz(v.time_tag + "Z", momenttz.tz.guess())
                    ).format("DD/MM HH:mm")
                ),
            };
        },
        daily_geomagnetic_indices_datacollection() {
            return {
                datasets: [
                    {
                        label: "daily_geomagnetic_indices",
                        backgroundColor: "#EF9A9A",
                        borderColor: "#EF9A9A",
                        type: "bar",
                        fill: false,
                        data: this.daily_geomagnetic_indices.map(
                            (v) => v.k_index
                        ),
                        pointRadius: 0,
                    },
                ],
                labels: this.daily_geomagnetic_indices.map((v) =>
                    moment(momenttz.tz(v.datetime, momenttz.tz.guess())).format(
                        "DD/MM HH:mm"
                    )
                ),
            };
        },
        three_day_forecast_datacollection() {
            return {
                datasets: [
                    {
                        label: "three_day_forecast",
                        backgroundColor: "#EF9A9A",
                        borderColor: "#EF9A9A",
                        type: "bar",
                        fill: false,
                        data: this.three_day_forecast.map((v) => v.k_index),
                        pointRadius: 0,
                    },
                ],
                labels: this.three_day_forecast.map((v) =>
                    moment(momenttz.tz(v.datetime, momenttz.tz.guess())).format(
                        "DD/MM HH:mm"
                    )
                ),
            };
        },
        treatedAlerts() {
            return this.alerts
                .map(function (v) {
                    return {
                        timestamp: moment(
                            momenttz.tz(
                                v.issue_datetime + "Z",
                                momenttz.tz.guess()
                            )
                        ).valueOf(),
                        message: v.message,
                    };
                })
                .sort((a, b) => (a > b ? -1 : 1));
        },
    },
    methods: {
        msToDDMMYYYYHHmmss(ms) {
            return moment(ms).format("DD/MM/YYYY HH:mm:ss");
        },
        replaceAlll(target, search, replacement) {
            return target.replace(new RegExp(search, "g"), replacement);
        },
    },
    components: {
        appSingleLogBarChart,
        appSingleBarChart,
    },
    beforeMount() {
        let self = this;
        axios
            .get(
                "https://services.swpc.noaa.gov/json/goes/primary/xrays-6-hour.json"
            )
            .then(function (response) {
                self.xrays = response.data.map(function (v) {
                    return v;
                });
            });

        axios
            .get(
                "https://services.swpc.noaa.gov/json/geospace/geospace_pred_est_kp_1_hour.json"
            )
            .then(function (response) {
                self.geospace_pred_est_kp = response.data.map(function (v) {
                    return v;
                });
            });

        axios
            .get(
                "https://services.swpc.noaa.gov/json/planetary_k_index_1m.json"
            )
            .then(function (response) {
                self.planetary_k_index = response.data.map(function (v) {
                    return v;
                });
            });

        axios
            .get("https://services.swpc.noaa.gov/products/alerts.json")
            .then(function (response) {
                self.alerts = response.data.map(function (v) {
                    return v;
                });
            });

        axios
            .get(
                "https://services.swpc.noaa.gov/text/daily-geomagnetic-indices.txt"
            )
            .then(function (response) {
                let s = response.data.split("\n");
                s = s.slice(12, s.length - 1);
                s = s.map(function (v) {
                    let s = self
                        .replaceAlll(v, "-", " ")
                        .split(" ")
                        .filter((v) => v !== "")
                        .map((v) => v);
                    let date = `${s[0]}-${s[1]}-${s[2]}T`;
                    let obj = {
                        "00:00:00Z": s[22],
                        "03:00:00Z": s[23],
                        "06:00:00Z": s[24],
                        "09:00:00Z": s[25],
                        "12:00:00Z": s[26],
                        "15:00:00Z": s[27],
                        "18:00:00Z": s[28],
                        "21:00:00Z": s[29],
                    };

                    let array = [];
                    let keys = Object.keys(obj);
                    let values = Object.values(obj);
                    for (let i = 0; i < Object.keys(obj).length; i++) {
                        array.push({
                            datetime: `${date}${keys[i]}`,
                            k_index: parseInt(values[i]),
                        });
                    }
                    return array;
                });
                self.daily_geomagnetic_indices = s.reduce(
                    (a, b) => a.concat(b),
                    []
                );
            });

        axios
            .get("https://services.swpc.noaa.gov/text/3-day-forecast.txt")
            .then(function (response) {
                let s = response.data.split("\n");
                s = s.slice(13, 22);
                s = s.map((v) =>
                    v
                        .split("  ")
                        .filter((v) => v !== "")
                        .map((v) => self.replaceAlll(v, " ", ""))
                        .filter((v) => v !== "")
                );
                let r = s[0][0];
                let m = r
                    .split("")
                    .filter((v) => v > "9")
                    .join("");
                let d = r
                    .split("")
                    .filter((v) => v <= "9")
                    .join("");

                let dates = [];

                let y = momenttz
                    .tz(moment().format("YYYY-MM-DD HH:mm"), "UTC")
                    .format("YYYY");
                dates[0] = moment(`${m} ${d}, ${y}`, "LL");
                dates[1] = moment(dates[0]).add(1, "days");
                dates[2] = moment(dates[1]).add(1, "days");
                dates = dates.map((v) => moment(v).format("YYYY-MM-DD"));

                s = s.slice(1, s.length);
                s = s.map(function (v) {
                    let t = v.map(function (t) {
                        return t.split("(")[0];
                    });
                    return t;
                });

                let results = [];

                for (let i = 0; i < dates.length; i++) {
                    results.push({
                        datetime: dates[i] + "T00:00:00Z",
                        k_index: parseInt(s[0][i + 1]),
                    });
                    results.push({
                        datetime: dates[i] + "T03:00:00Z",
                        k_index: parseInt(s[1][i + 1]),
                    });
                    results.push({
                        datetime: dates[i] + "T06:00:00Z",
                        k_index: parseInt(s[2][i + 1]),
                    });
                    results.push({
                        datetime: dates[i] + "T09:00:00Z",
                        k_index: parseInt(s[3][i + 1]),
                    });
                    results.push({
                        datetime: dates[i] + "T12:00:00Z",
                        k_index: parseInt(s[4][i + 1]),
                    });
                    results.push({
                        datetime: dates[i] + "T15:00:00Z",
                        k_index: parseInt(s[5][i + 1]),
                    });
                    results.push({
                        datetime: dates[i] + "T18:00:00Z",
                        k_index: parseInt(s[6][i + 1]),
                    });
                    results.push({
                        datetime: dates[i] + "T21:00:00Z",
                        k_index: parseInt(s[7][i + 1]),
                    });
                }
                self.three_day_forecast = results;
            });
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}

.chart-container {
    padding: 1rem;
    flex-grow: 1;
    min-height: 0;
}
</style>