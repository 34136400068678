<template>
    <div class="main-drone-report-flights-by-operator">
        <app-pie-chart
            :chart-data="datacollection"
            :style="style"
            :title="'Voos por operador'"
            :show-all-tooltips="showAllTooltips"
        ></app-pie-chart>
    </div>
</template>

<script>
import appPieChart from "@/components/charts/PieChart";

export default {
    data() {
        return {
            style: { height: "100%" },
        };
    },
    components: {
        appPieChart,
    },
    methods: {},
    props: ["datacollection", "showAllTooltips"],
    mounted() {},
};
</script>

<style>
.main-drone-report-flights-by-operator {
    height: 100%;
    position: relative;
}
</style>