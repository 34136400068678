<template>
    <div class="main-settings-right-container">
        <!--  -->
        <app-settings-account-registration-data
            v-if="setupMenu === 'account-registration-data'"
        ></app-settings-account-registration-data>
        <app-settings-account-apis-and-integration
            v-if="setupMenu === 'account-apis-and-integration'"
        ></app-settings-account-apis-and-integration>
        <app-settings-account-user-management
            v-if="setupMenu === 'account-user-management'"
        ></app-settings-account-user-management>
        <app-settings-account-licensing
            v-if="setupMenu === 'account-licensing'"
        ></app-settings-account-licensing>
        <app-settings-account-followers
            v-if="setupMenu === 'account-followers'"
        ></app-settings-account-followers>
        <!--  -->
        <app-settings-admin-forecast
            v-if="setupMenu === 'admin-forecast'"
        ></app-settings-admin-forecast>
        <app-settings-admin-batteries
            v-if="setupMenu === 'admin-batteries'"
        ></app-settings-admin-batteries>
        <app-settings-admin-manage-teams
            v-if="setupMenu === 'admin-manage-teams'"
        ></app-settings-admin-manage-teams>
        <app-settings-admin-manage-subscribers
            v-if="setupMenu === 'admin-manage-subscribers'"
        ></app-settings-admin-manage-subscribers>
        <app-settings-admin-manage-supervisors
            v-if="setupMenu === 'admin-manage-supervisors'"
        ></app-settings-admin-manage-supervisors>
        <app-settings-admin-manage-drones
            v-if="setupMenu === 'admin-manage-drones'"
        ></app-settings-admin-manage-drones>
        <app-settings-admin-manage-solos
            v-if="setupMenu === 'admin-manage-solos'"
        ></app-settings-admin-manage-solos>
        <app-settings-admin-manage-licenses-keys
            v-if="setupMenu === 'admin-manage-licenses-keys'"
        ></app-settings-admin-manage-licenses-keys>
        <!--  -->
        <app-settings-drone-events-detection
            v-if="setupMenu === 'drone-events-detection'"
        ></app-settings-drone-events-detection>
        <app-settings-drone-points-and-fencings
            v-if="setupMenu === 'drone-points-and-fencings'"
        ></app-settings-drone-points-and-fencings>
        <app-settings-drone-setup
            v-if="setupMenu === 'drone-setup'"
        ></app-settings-drone-setup>
        <!--  -->
        <app-settings-solo-events-detection
            v-if="setupMenu === 'solo-events-detection'"
        ></app-settings-solo-events-detection>
        <app-settings-solo-points-and-fencings
            v-if="setupMenu === 'solo-points-and-fencings'"
        ></app-settings-solo-points-and-fencings>
        <app-settings-solo-setup
            v-if="setupMenu === 'solo-setup'"
        ></app-settings-solo-setup>
    </div>
</template>

<script>
//
import appSettingsAccountRegistrationData from "@/components/settings/containers/account/SettingsAccountRegistrationData";
import appSettingsAccountApisAndIntegration from "@/components/settings/containers/account/SettingsAccountApisAndIntegration";
import appSettingsAccountUserManagement from "@/components/settings/containers/account/SettingsAccountUserManagement";
import appSettingsAccountLicensing from "@/components/settings/containers/account/SettingsAccountLicensing";
import appSettingsAccountFollowers from "@/components/settings/containers/account/SettingsAccountFollowers";
//
import appSettingsAdminForecast from "@/components/settings/containers/admin/SettingsAdminForecast";
import appSettingsAdminBatteries from "@/components/settings/containers/admin/SettingsAdminBatteries";
import appSettingsAdminManageTeams from "@/components/settings/containers/admin/SettingsAdminManageTeams";
import appSettingsAdminManageDrones from "@/components/settings/containers/admin/SettingsAdminManageDrones";
import appSettingsAdminManageSolos from "@/components/settings/containers/admin/SettingsAdminManageSolos";
import appSettingsAdminManageSubscribers from "@/components/settings/containers/admin/SettingsAdminManageSubscribers";
import appSettingsAdminManageSupervisors from "@/components/settings/containers/admin/SettingsAdminManageSupervisors";
import appSettingsAdminManageLicensesKeys from "@/components/settings/containers/admin/SettingsAdminManageLicensesKeys";
//
import appSettingsDroneEventsDetection from "@/components/settings/containers/drone/SettingsDroneEventsDetection";
import appSettingsDronePointsAndFencings from "@/components/settings/containers/drone/SettingsDronePointsAndFencings";
import appSettingsDroneSetup from "@/components/settings/containers/drone/SettingsDroneSetup";
//
import appSettingsSoloEventsDetection from "@/components/settings/containers/solo/SettingsSoloEventsDetection";
import appSettingsSoloPointsAndFencings from "@/components/settings/containers/solo/SettingsSoloPointsAndFencings";
import appSettingsSoloSetup from "@/components/settings/containers/solo/SettingsSoloSetup";

export default {
    components: {
        //
        appSettingsAccountRegistrationData,
        appSettingsAccountApisAndIntegration,
        appSettingsAccountUserManagement,
        appSettingsAccountLicensing,
        appSettingsAccountFollowers,
        //
        appSettingsAdminForecast,
        appSettingsAdminBatteries,
        appSettingsAdminManageTeams,
        appSettingsAdminManageDrones,
        appSettingsAdminManageSolos,
        appSettingsAdminManageSubscribers,
        appSettingsAdminManageSupervisors,
        appSettingsAdminManageLicensesKeys,
        //
        appSettingsDroneEventsDetection,
        appSettingsDronePointsAndFencings,
        appSettingsDroneSetup,
        //
        appSettingsSoloEventsDetection,
        appSettingsSoloPointsAndFencings,
        appSettingsSoloSetup,
    },
    props: ["setupMenu"],
};
</script>

<style scoped>
.main-settings-right-container {
    width: calc(100vw - 350px);
    height: calc(100vh - 50px);
    position: absolute;
    left: 350px;
    top: 0px;
}
</style>