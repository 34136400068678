<template>
    <div
        class="main-pilot-controls"
        v-if="
            drone.vIS_CONNECTED &&
            user.data.internals.settings.drone.vIS_VIRTUAL_JOYSTICK_ENABLED !==
                false
        "
    >
        <div
            style="
                height: 100%;
                width: 370px;
                border: 1px solid blue;
                background-color: #303030;
                border-color: #707070;
                border-radius: calc(174px / 2);
            "
        >
            <div
                style="
                    height: 145px;
                    width: 145px;
                    border-radius: 145px;
                    position: absolute;
                    left: 14.5px;
                    top: 14.5px;
                    border: 4px solid #e8e8e8;
                    background-color: #898989;
                "
            >
                <div
                    style="
                        background-color: #e8e8e8;
                        width: 120px;
                        height: 32px;
                        position: absolute;
                        left: 8px;
                        top: 52.5px;
                        border-radius: 7px;
                    "
                ></div>
                <div
                    style="
                        background-color: #e8e8e8;
                        height: 120px;
                        width: 32px;
                        position: absolute;
                        left: 52px;
                        top: 9px;
                        border-radius: 7px;
                    "
                >
                    <button
                        class="btn"
                        style="
                            width: 32px !important;
                            height: 32px;
                            padding: 0px !important;
                        "
                        @mousedown="
                            handlePilotControlsCommandMouseDown('left', 'up')
                        "
                        @mouseup="
                            handlePilotControlsCommandMouseUp('left', 'up')
                        "
                    >
                        <i
                            class="fas fa-caret-up px-0 py-0"
                            style="font-size: 2rem !important; color: #757575"
                        ></i>
                    </button>

                    <button
                        class="btn"
                        style="
                            width: 32px !important;
                            height: 32px;
                            padding: 0px !important;
                            margin-top: 56px;
                        "
                        @mousedown="
                            handlePilotControlsCommandMouseDown('left', 'down')
                        "
                        @mouseup="
                            handlePilotControlsCommandMouseUp('left', 'down')
                        "
                    >
                        <i
                            class="fas fa-caret-down px-0 py-0"
                            style="font-size: 2rem !important; color: #757575"
                        ></i>
                    </button>
                </div>

                <button
                    class="btn"
                    style="
                        width: 32px !important;
                        height: 32px;
                        padding: 0px !important;
                        position: absolute;
                        left: 8px;
                        top: 52.5px;
                    "
                    @mousedown="
                        handlePilotControlsCommandMouseDown('left', 'left')
                    "
                    @mouseup="handlePilotControlsCommandMouseUp('left', 'left')"
                >
                    <!-- <i
                        class="fas fa-chevron-left px-0 py-0"
                        style="font-size: 0.8rem !important"
                    ></i> -->
                    <svg
                        id="rotate_left-24px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.507"
                        height="29.507"
                        viewBox="0 0 29.507 29.507"
                    >
                        <path
                            id="Caminho_684"
                            data-name="Caminho 684"
                            d="M0,0H29.507V29.507H0Z"
                            fill="none"
                        />
                        <path
                            id="Caminho_685"
                            data-name="Caminho 685"
                            d="M7.808,10.258,6.074,8.512a9.739,9.739,0,0,0-2,4.783H6.553A7.3,7.3,0,0,1,7.808,10.258Zm-1.254,5.5H4.07a9.72,9.72,0,0,0,1.992,4.783L7.8,18.79a7.371,7.371,0,0,1-1.242-3.037ZM7.8,22.294a9.742,9.742,0,0,0,4.795,1.979v-2.5a7.271,7.271,0,0,1-3.024-1.266Zm7.254-17.52V1L9.455,6.594l5.594,5.471V7.258a7.368,7.368,0,0,1,0,14.532v2.483a9.826,9.826,0,0,0,0-19.5Z"
                            transform="translate(0.934 0.229)"
                            fill="#757575"
                        />
                    </svg>
                </button>
                <button
                    class="btn"
                    style="
                        width: 32px !important;
                        height: 32px;
                        padding: 0px !important;
                        position: absolute;
                        margin-left: 27.5px;
                        top: 52.5px;
                        color: #757575;
                    "
                    @mousedown="
                        handlePilotControlsCommandMouseDown('left', 'right')
                    "
                    @mouseup="
                        handlePilotControlsCommandMouseUp('left', 'right')
                    "
                >
                    <!-- <i
                        class="fas fa-chevron-right px-0 py-0"
                        style="font-size: 0.8rem !important"
                    ></i> -->
                    <svg
                        id="rotate_left-24px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.508"
                        height="29.507"
                        viewBox="0 0 29.508 29.507"
                    >
                        <path
                            id="Caminho_684"
                            data-name="Caminho 684"
                            d="M29.508,0H0V29.507H29.508Z"
                            fill="none"
                        />
                        <path
                            id="Caminho_685"
                            data-name="Caminho 685"
                            d="M19.918,10.258l1.734-1.746a9.739,9.739,0,0,1,2,4.783H21.172A7.3,7.3,0,0,0,19.918,10.258Zm1.254,5.5h2.484a9.72,9.72,0,0,1-1.992,4.783L19.93,18.79a7.371,7.371,0,0,0,1.242-3.037ZM19.93,22.294a9.743,9.743,0,0,1-4.8,1.979v-2.5a7.271,7.271,0,0,0,3.025-1.266ZM12.676,4.774V1l5.594,5.594-5.594,5.471V7.258a7.368,7.368,0,0,0,0,14.532v2.483a9.825,9.825,0,0,1,0-19.5Z"
                            transform="translate(0.848 0.229)"
                            fill="#757575"
                        />
                    </svg>
                </button>
            </div>
            <div
                style="
                    height: 145px;
                    width: 145px;
                    border-radius: 145px;
                    position: absolute;
                    right: 80.5px;
                    top: 14.5px;
                    border: 4px solid #e8e8e8;
                    background-color: #898989;
                "
            >
                <div
                    style="
                        background-color: transparent;
                        height: 120px;
                        width: 32px;
                        position: absolute;
                        left: 52px;
                        top: 9px;
                        border-radius: 7px;
                    "
                >
                    <button
                        class="btn"
                        style="
                            width: 32px !important;
                            height: 32px;
                            padding: 0px !important;
                        "
                        @mousedown="
                            handlePilotControlsCommandMouseDown('right', 'up')
                        "
                        @mouseup="
                            handlePilotControlsCommandMouseUp('right', 'up')
                        "
                    >
                        <i
                            class="fas fa-caret-up px-0 py-0"
                            style="font-size: 2rem !important; color: #e8e8e8"
                        ></i>
                    </button>

                    <button
                        class="btn"
                        style="
                            width: 32px !important;
                            height: 32px;
                            padding: 0px !important;
                            margin-top: 56px;
                        "
                        @click="
                            handlePilotControlsCommandMouseDown('right', 'down')
                        "
                        @mouseup="
                            handlePilotControlsCommandMouseUp('right', 'down')
                        "
                    >
                        <i
                            class="fas fa-caret-down px-0 py-0"
                            style="font-size: 2rem !important; color: #e8e8e8"
                        ></i>
                    </button>
                </div>

                <button
                    class="btn"
                    style="
                        width: 32px !important;
                        height: 32px;
                        padding: 0px !important;
                        position: absolute;
                        left: 8px;
                        top: 52.5px;
                    "
                    @mousedown="
                        handlePilotControlsCommandMouseDown('right', 'left')
                    "
                    @mouseup="
                        handlePilotControlsCommandMouseUp('right', 'left')
                    "
                >
                    <i
                        class="fas fa-caret-left px-0 py-0"
                        style="font-size: 2rem !important; color: #e8e8e8"
                    ></i>
                </button>
                <button
                    class="btn"
                    style="
                        width: 32px !important;
                        height: 32px;
                        padding: 0px !important;
                        position: absolute;
                        margin-left: 27.5px;
                        top: 52.5px;
                    "
                    @mousedown="
                        handlePilotControlsCommandMouseDown('right', 'right')
                    "
                    @mouseup="
                        handlePilotControlsCommandMouseUp('right', 'right')
                    "
                >
                    <i
                        class="fas fa-caret-right px-0 py-0"
                        style="font-size: 2rem !important; color: #e8e8e8"
                    ></i>
                </button>
                <div
                    style="
                        width: 60px;
                        height: 60px;
                        position: absolute;
                        top: 41px;
                        left: 38px;
                        background-color: #a1a0a0;
                        border-radius: 60px;
                    "
                ></div>
            </div>
            <div
                style="
                    height: 100%;
                    width: 50px;
                    position: absolute;
                    right: -10px;
                    background-color: #303030;
                    border-radius: 10px;
                "
            >
                <div class="row mx-0 my-0 px-0 py-0" style="height: 35%">
                    <div class="col-12 mx-0 my-0 px-1 py-1 h-100 w-100">
                        <button
                            class="btn btn-link w-100 h-100"
                            style="
                                background-color: #898989;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                            "
                            @click="
                                handleManualSpeedChange(
                                    Math.min(
                                        (drone.flight.control.vMANUAL_SPEED ||
                                            1) + 1,
                                        15
                                    )
                                )
                            "
                        >
                            <i
                                class="fas fa-plus"
                                style="font-size: 1.2rem; color: white"
                            ></i>
                        </button>
                    </div>
                </div>
                <div class="row mx-0 my-0 px-0 py-0" style="height: 30%">
                    <div class="col-12 mx-0 my-0 px-1 py-1 h-100 w-100">
                        <button
                            class="btn btn-link w-100 h-100 px-0"
                            style="
                                background-color: #898989;
                                font-size: 0.7rem;
                                font-weight: 600;
                                color: white;
                            "
                        >
                            {{ drone.flight.control.vMANUAL_SPEED || 1 }} m/s
                        </button>
                    </div>
                </div>
                <div class="row mx-0 my-0 px-0 py-0" style="height: 35%">
                    <div class="col-12 mx-0 my-0 px-1 py-1 h-100 w-100">
                        <button
                            class="btn btn-link w-100 h-100"
                            style="
                                background-color: #898989;
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                            "
                            @click="
                                handleManualSpeedChange(
                                    Math.max(
                                        (drone.flight.control.vMANUAL_SPEED ||
                                            1) - 1,
                                        1
                                    )
                                )
                            "
                        >
                            <i
                                class="fas fa-minus"
                                style="font-size: 1.2rem; color: white"
                            ></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import firebase from "firebase";

export default {
    data() {
        return {
            commandsMap: {
                left: {
                    up: "move-up",
                    right: "turn-clockwise",
                    down: "move-down",
                    left: "turn-anticlockwise",
                },
                right: {
                    up: "move-forward",
                    right: "move-right",
                    down: "move-backward",
                    left: "move-left",
                },
            },
            currentCommand: "none",
            currentCommandStartedAt: 0,
            currentCommandFinishedAt: 0,
            interval: null,
        };
    },
    props: ["drone"],
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        handlePilotControlsCommandMouseDown(stick, command) {
            this.currentCommand = this.commandsMap[stick][command];
            this.currentCommandStartedAt = Date.now();
            this.currentCommandFinishedAt = Date.now();

            this.$message({
                message: `Comando ${this.currentCommand} enviado`,
                type: "success",
                showClose: true,
                offset: 60,
            });

            if (this.interval !== null) {
                clearInterval(this.interval);
            }

            let self = this;

            this.interval = setInterval(function () {
                self.currentCommandFinishedAt = Date.now();
                if (
                    self.currentCommandFinishedAt -
                        self.currentCommandStartedAt <
                    5 * 1000
                ) {
                    console.log(self.currentCommand);
                    // firebase
                    //     .database()
                    //     .ref(
                    //         "aegisv2/users/" +
                    //             self.drone.vUID +
                    //             "/flight/actions/virtualStickControl"
                    //     )
                    //     .update({
                    //         aCOMMAND: self.currentCommand,
                    //         vTIMESTAMP_STARTED_AT: self.currentCommandStartedAt,
                    //         vTIMESTAMP_FINISHED_AT:
                    //             self.currentCommandFinishedAt,
                    //     });
                } else {
                    clearInterval(self.interval);
                    self.currentCommand = "none";
                    self.currentCommandStartedAt = 0;
                    self.currentCommandFinishedAt = 0;
                    self.interval = null;

                    // firebase
                    //     .database()
                    //     .ref(
                    //         "aegisv2/users/" +
                    //             self.drone.vUID +
                    //             "/flight/actions/virtualStickControl"
                    //     )
                    //     .update({
                    //         aCOMMAND: self.currentCommand,
                    //         vTIMESTAMP_STARTED_AT: self.currentCommandStartedAt,
                    //         vTIMESTAMP_FINISHED_AT:
                    //             self.currentCommandFinishedAt,
                    //     });

                    self.$message({
                        message: `Comando ${self.currentCommand} anulado (muito longo)`,
                        type: "warning",
                        showClose: true,
                        offset: 60,
                    });
                }
            }, 200);
        },
        handlePilotControlsCommandMouseUp() {
            if (this.interval !== null) {
                console.log("clearInterval");
                clearInterval(this.interval);
            }

            this.$message({
                message: `Comando ${this.currentCommand} encerrado`,
                type: "error",
                showClose: true,
                offset: 60,
            });

            this.currentCommand = "none";
            this.currentCommandStartedAt = 0;
            this.currentCommandFinishedAt = 0;
            this.interval = null;

            // firebase
            //     .database()
            //     .ref(
            //         "aegisv2/users/" +
            //             this.drone.vUID +
            //             "/flight/actions/virtualStickControl"
            //     )
            //     .update({
            //         aCOMMAND: this.currentCommand,
            //         vTIMESTAMP_STARTED_AT: this.currentCommandStartedAt,
            //         vTIMESTAMP_FINISHED_AT: this.currentCommandFinishedAt,
            //     });
        },
        handleManualSpeedChange(value) {
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.drone.vUID +
                        "/flight/control/vMANUAL_SPEED"
                )
                .set(value);
        },
    },
};
</script>

<style  scoped>
.main-pilot-controls {
    height: 174px;
    width: 436px;
    /* border: 1px solid red; */
    opacity: 0.8;
}
</style>