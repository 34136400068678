<template>
    <div class="main-review-container">
        <app-review-header></app-review-header>
        <app-review-body class="main-review-body"></app-review-body>
        <app-review-list></app-review-list>
    </div>
</template>

<script>
import appReviewHeader from "@/components/review/containers/ReviewHeader";
import appReviewBody from "@/components/review/containers/ReviewBody";
import appReviewList from "@/components/review/containers/ReviewList";

export default {
    data() {
        return {};
    },
    components: {
        appReviewHeader,
        appReviewBody,
        appReviewList,
    },
};
</script>

<style scoped>
.main-review-container {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 50px);
    /* padding: 10px; */
}

.main-review-body {
    height: calc(40vh - 10px);
    width: 100%;
}
</style>