<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <!--  -->
        <div class="row text-left">
            <div class="col-12 my-auto align-middle" style="color: #bebebe">
                <span style="font-size: 2rem">{{
                    $t("Registration_data")
                }}</span>
                <button
                    class="btn btn-secondary mx-4"
                    @click="
                        handleResetFormRegistrationDataDialog();
                        dialog.visible = true;
                    "
                >
                    <i class="fas fa-pen" style="font-size: 1rem"></i>
                </button>
            </div>
        </div>
        <br />
        <br />
        <!--  -->
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="title">{{ $t("Team_name") }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="info">
                    {{ user.data.internals.vTEAM_NAME }}
                </h6>
            </div>
        </div>
        <br />
        <!--  -->
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="title">{{ $t("Company_name") }}</h6>
            </div>
            <div class="col-3 text-left">
                <h6 class="title">{{ $t("Company_id") }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="info">
                    {{ user.data.internals.vCOMPANY_NAME }}
                </h6>
            </div>
            <div class="col-3 text-left">
                <h6 class="info">
                    {{ user.data.internals.vCOMPANY_ID }}
                </h6>
            </div>
        </div>
        <br />
        <!--  -->
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="title">{{ $t("Child_name") }}</h6>
            </div>
            <div class="col-3 text-left">
                <h6 class="title">{{ $t("Contact") }}</h6>
            </div>
            <div class="col-3 text-left">
                <h6 class="title">{{ $t("Registered_email") }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="info">
                    {{ user.data.internals.vCHILD_NAME }}
                </h6>
            </div>
            <div class="col-3 text-left">
                <h6 class="info">
                    {{ user.data.internals.vCHILD_CONTACT }}
                </h6>
            </div>
            <div class="col-3 text-left">
                <h6 class="info">
                    {{ user.data.internals.vCHILD_EMAIL }}
                </h6>
            </div>
        </div>
        <br />
        <!--  -->
        <!--  -->
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="title">{{ $t("Child_id") }}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-3 text-left">
                <h6 class="info">
                    {{ user.data.internals.vCHILD_ID }}
                </h6>
            </div>
        </div>
        <br />
        <!--  -->

        <!-- dialog -->

        <el-dialog
            :title="$t('Edit_registration_data')"
            :visible.sync="dialog.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <el-form
                    ref="form-create-edit-account-registration-data"
                    :model="dialog.form"
                    :rules="dialog.rules"
                >
                    <!--  -->
                    <el-form-item :label="$t('Team_name')" prop="vTEAM_NAME">
                        <el-input v-model="dialog.form.vTEAM_NAME"></el-input>
                    </el-form-item>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Company')"
                                prop="vCOMPANY_NAME"
                            >
                                <el-input
                                    v-model="dialog.form.vCOMPANY_NAME"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Company_id')"
                                prop="vCOMPANY_ID"
                            >
                                <el-input
                                    v-model="dialog.form.vCOMPANY_ID"
                                    v-mask="masks.vCOMPANY_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Child_name')"
                                prop="vCHILD_NAME"
                            >
                                <el-input
                                    v-model="dialog.form.vCHILD_NAME"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Child_id')"
                                prop="vCHILD_ID"
                            >
                                <el-input
                                    v-model="dialog.form.vCHILD_ID"
                                    v-mask="masks.vCHILD_ID"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Contact')"
                                prop="vCHILD_CONTACT"
                            >
                                <el-input
                                    v-model="dialog.form.vCHILD_CONTACT"
                                    v-mask="masks.vCHILD_CONTACT"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <div class="col-6">
                            <el-form-item
                                :label="$t('Email')"
                                prop="vCHILD_EMAIL"
                            >
                                <el-input
                                    readonly
                                    v-model="dialog.form.vCHILD_EMAIL"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialog.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="
                                submitForm(
                                    'form-create-edit-account-registration-data'
                                )
                            "
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Save") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            masks: {
                vCHILD_ID: {
                    mask: "###.###.###-##",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
                vCHILD_CONTACT: {
                    mask: "(##)#####-####",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
                vCOMPANY_ID: {
                    mask: "##.###.###/####-##",
                    tokens: {
                        "#": { pattern: /[0-9]/ },
                    },
                },
            },
            dialog: {
                visible: false,
                form: {
                    vTEAM_NAME: "",
                    vCOMPANY_NAME: "",
                    vCOMPANY_ID: "",
                    vCHILD_NAME: "",
                    vCHILD_CONTACT: "",
                    vCHILD_EMAIL: "",
                    vCHILD_ID: "",
                },
                rules: {
                    vTEAM_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCOMPANY_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCOMPANY_ID: [
                        {
                            required: true,
                            message: "Defina um CNPJ",
                            trigger: "change",
                        },
                        {
                            min: 18,
                            max: 18,
                            message:
                                "O CNPJ deve respeitar o formato XX.XXX.XXX/XXXX-XX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_NAME: [
                        {
                            required: true,
                            message: "Defina um nome",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 20,
                            message: "O nome deve ter entre 3 e 20 letras",
                            trigger: "change",
                        },
                    ],
                    vCHILD_ID: [
                        {
                            required: true,
                            message: "Defina um CPF",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O CPF deve respeitar o formato XXX.XXX.XXX-XX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_CONTACT: [
                        {
                            required: true,
                            message: "Defina um número para contato",
                            trigger: "change",
                        },
                        {
                            min: 14,
                            max: 14,
                            message:
                                "O numéro para contato deve respeitar o formato (DD)XXXXX-XXXX",
                            trigger: "change",
                        },
                    ],
                    vCHILD_EMAIL: [
                        {
                            required: true,
                            message: "Defina um email",
                            trigger: "change",
                        },
                        {
                            min: 3,
                            max: 100,
                            message: "O email deve ter entre 3 e 100 dígitos",
                            trigger: "change",
                        },
                    ],
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            user: "user",
        }),
    },
    methods: {
        ...mapActions(["setNavForcedLoading"]),
        handleResetFormRegistrationDataDialog() {
            this.dialog.form = {
                vTEAM_NAME: this.user.data.internals.vTEAM_NAME,
                vCOMPANY_NAME: this.user.data.internals.vCOMPANY_NAME,
                vCOMPANY_ID: this.user.data.internals.vCOMPANY_ID,
                vCHILD_NAME: this.user.data.internals.vCHILD_NAME,
                vCHILD_CONTACT: this.user.data.internals.vCHILD_CONTACT,
                vCHILD_EMAIL: this.user.data.internals.vCHILD_EMAIL,
                vCHILD_ID: this.user.data.internals.vCHILD_ID,
            };
        },
        handleSaveFormRegistrationDataDialog() {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    self.dialog.visible = false;
                    self.setNavForcedLoading(true);
                    firebase
                        .functions()
                        .httpsCallable(
                            "CLOUD_DASHBOARD_EDIT_ACCOUNT_REGISTRATION_DATA"
                        )({
                            payload: { internals: self.dialog.form },
                            command: "edit-account-registration-data",
                            vTEAM_UID: self.user.data.uid,
                            idToken: idToken,
                        })
                        .then(function (result) {
                            self.setNavForcedLoading(false);
                            if (result.data.error)
                                throw `Não foi possível efetuar as modificações: ${result.data.error}`;
                            self.$message({
                                message: `Modificações realizadas com sucesso`,
                                type: "success",
                                showClose: true,
                                offset: 60,
                            });
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            self.handleResetFormRegistrationDataDialog();
                            self.$message({
                                message: `Não foi possível efetuar as modificações: ${e}`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch(function (error) {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações: ${error}`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        submitForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    self.handleSaveFormRegistrationDataDialog();
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped>
h6 {
    color: #a1a0a0;
}
</style>