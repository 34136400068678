<template>
    <div>
        <!--  -->
        <div
            v-if="navState.topBar === 1"
            class="main-solo-left-container bg-dark-high-gray"
        >
            <el-input
                :placeholder="$t('Search')"
                v-model="searchSolo"
                text-color="red"
                :disabled="soloActive !== null"
                style="font-size: 1rem"
            >
                <i
                    class="fas fa-search el-input__icon"
                    style="font-size: 1rem"
                    slot="suffix"
                >
                </i>
            </el-input>
            <div>
                <app-solo
                    v-for="(solo, index) in solosElements
                        .filter((v) => v.vIS_STANDBY || v.vIS_CONNECTED)
                        .sort(function (a, b) {
                            if (a.vIS_CONNECTED && b.vIS_CONNECTED) {
                                return a.internals.vCHILD_NAME.toUpperCase() >
                                    b.internals.vCHILD_NAME.toUpperCase()
                                    ? -3
                                    : -2;
                            } else if (!a.vIS_CONNECTED && b.vIS_CONNECTED) {
                                return 2;
                            } else if (a.vIS_CONNECTED && !b.vIS_CONNECTED) {
                                return -2;
                            } else {
                                if (a.vIS_STANDBY && !b.vIS_STANDBY) {
                                    return -1;
                                } else if (!a.vIS_STANDBY && b.vIS_STANDBY) {
                                    return 1;
                                } else {
                                    return a.internals.vCHILD_NAME.toUpperCase() >
                                        b.internals.vCHILD_NAME.toUpperCase()
                                        ? -0.5
                                        : -0.25;
                                }
                            }
                        })"
                    :key="'solo-element-connected-' + index"
                    :solo="solo"
                    v-show="
                        solo.internals.vCHILD_NAME
                            .toUpperCase()
                            .includes(searchSolo.toUpperCase())
                    "
                ></app-solo>

                <app-solo
                    v-for="(solo, index) in solosElements
                        .filter((v) => !(v.vIS_STANDBY || v.vIS_CONNECTED))
                        .sort((a, b) =>
                            a.internals.vCHILD_NAME.toUpperCase() >
                            b.internals.vCHILD_NAME.toUpperCase()
                                ? 1
                                : -1
                        )"
                    :key="'solo-element-disconnected-' + index"
                    :solo="solo"
                    v-show="
                        solo.internals.vCHILD_NAME
                            .toUpperCase()
                            .includes(searchSolo.toUpperCase())
                    "
                ></app-solo>
            </div>
        </div>
    </div>
</template>


<script>
import appSolo from "@/components/solo/Solo";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            searchSolo: "",
            checkTimestamp: null,
        };
    },
    computed: {
        ...mapGetters(["user", "navState", "soloActive", "solosElements"]),
        orderedSolosElements() {
            return this.solosElements.filter(
                (v) => !(v.vIS_STANDBY || v.vIS_CONNECTED)
            );
        },
    },
    methods: {
        ...mapActions([
            "updateSoloIsConnected",
            "updateSoloIsStandby",
            "updateSoloIsStreaming",
        ]),
    },
    components: {
        appSolo,
    },
    watch: {
        soloActive: function (newVal, oldVal) {
            this.searchSolo = "";
        },
    },
    mounted() {
        let self = this;
        this.checkTimestamp = setInterval(function () {
            for (let d of self.solosElements.filter(
                (v) => v.vHEART_BEAT !== 0
            )) {
                //
                if (Date.now() - d.vHEART_BEAT < 10 * 1000) {
                    self.updateSoloIsConnected({
                        uid: d.vUID,
                        isConnected: true,
                    });
                    self.updateSoloIsStandby({
                        uid: d.vUID,
                        isStandby: true,
                    });
                } else if (
                    Date.now() - d.vHEART_BEAT >= 10 * 1000 &&
                    Date.now() - d.vHEART_BEAT < 20 * 60 * 1000
                ) {
                    self.updateSoloIsConnected({
                        uid: d.vUID,
                        isConnected: false,
                    });
                    self.updateSoloIsStandby({
                        uid: d.vUID,
                        isStandby: true,
                    });
                } else {
                    self.updateSoloIsConnected({
                        uid: d.vUID,
                        isConnected: false,
                    });
                    self.updateSoloIsStandby({
                        uid: d.vUID,
                        isStandby: false,
                    });
                }
                //
                if (Date.now() - d.vSTREAM_HEART_BEAT < 10000) {
                    self.updateSoloIsStreaming({
                        uid: d.vUID,
                        isStreaming: true,
                    });
                } else {
                    self.updateSoloIsStreaming({
                        uid: d.vUID,
                        isStreaming: false,
                    });
                }
            }
        }, 2000);
    },
};
</script>


<style scoped>
.main-solo-left-container {
    padding: 5px;
    position: absolute;
    top: 50px;
    left: 0px;
    width: 350px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
}
</style>