<template>
    <div class="main-solo-report-events-by-device">
        <app-single-bar-chart
            :chart-data="datacollection"
            :style="style"
            :title="
                events[events.map((v) => v.value).indexOf(datacollection.key)]
                    .label
            "
            :tooltips="datacollection"
            :show-all-tooltips="showAllTooltips"
        ></app-single-bar-chart>
    </div>
</template>

<script>
// pilotEvents
import { mapGetters } from "vuex";
import appSingleBarChart from "@/components/charts/SingleBarChart";

export default {
    data() {
        return {
            style: { height: "100%" },
        };
    },
    components: {
        appSingleBarChart,
    },
    computed: {
        ...mapGetters(["events"]),
    },
    methods: {},
    props: ["datacollection", "showAllTooltips"],
    mounted() {
        console.log(this.datacollection);
    },
};
</script>

<style>
.main-solo-report-events-by-device {
    height: 100%;
    position: relative;
}
</style>