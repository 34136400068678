<template>
    <div
        style="
            width: 100%;
            height: 100%;
            padding: 20px;
            overflow-y: scroll;
            overflow-x: hidden;
        "
    >
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">{{
                            $t("Events_detection")
                        }}</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para configurar quais eventos serão
                            detectados por drones.</span
                        >
                    </div>
                </div>
                <br />
                <br />

                <!--  -->
                <div>
                    <!--  -->
                    <div>
                        <div class="row">
                            <div class="col-12 text-left">
                                <h5>{{ $t("Events") }}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-3 my-2"
                                style=""
                                :key="'pilot-event-' + index"
                                v-for="(p, index) in pilotEvents.drone"
                            >
                                <div
                                    style="border-radius: 20px"
                                    class="bg-dark-medium-gray"
                                >
                                    <div class="row px-4 py-2">
                                        <div class="col-9 text-left px-2 py-2">
                                            <el-checkbox
                                                :label="
                                                    p[$t('vLABEL_LANGUAGE')]
                                                "
                                                v-model="p.vIS_ENABLED"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vIS_ENABLED',
                                                        $event
                                                    )
                                                "
                                            ></el-checkbox>
                                        </div>
                                        <div class="col-3 text-right">
                                            <el-tooltip
                                                :content="
                                                    p[$t('vTOOLTIP_LANGUAGE')]
                                                "
                                                placement="top"
                                                ><i
                                                    style="
                                                        font-size: 1rem;
                                                        color: #ffe082;
                                                    "
                                                    class="
                                                        fas
                                                        fa-question-circle
                                                    "
                                                ></i>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div :class="{ hidden: !p.vRANGE_INPUT }">
                                        <div class="row">
                                            <div
                                                class="
                                                    col-12
                                                    text-left
                                                    mx-4
                                                    px-2
                                                    py-2
                                                "
                                            >
                                                {{
                                                    p[
                                                        $t(
                                                            "vRANGE_INPUT_LABEL_LANGUAGE"
                                                        )
                                                    ]
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-8">
                                                <el-slider
                                                    :min="p.vMIN_VALUE"
                                                    :max="p.vMAX_VALUE"
                                                    v-model="p.vVALUE"
                                                    :marks="p.vMARKS"
                                                    :show-tooltip="
                                                        p.vSHOW_TOOLTIP
                                                    "
                                                    @change="
                                                        handleSliderChange(
                                                            p.vKEY,
                                                            'vVALUE',
                                                            $event
                                                        )
                                                    "
                                                    :disabled="!p.vIS_ENABLED"
                                                >
                                                    ></el-slider
                                                >
                                            </div>
                                            <div class="col-3 align-middle">
                                                <span
                                                    class="align-middle"
                                                    :class="{
                                                        hidden: !p.vSHOW_RANGE_INPUT_VALUE,
                                                    }"
                                                    >{{ p.vVALUE }}
                                                    {{ p.vUNIT }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div
                                            class="
                                                col-12
                                                text-left
                                                mx-4
                                                px-2
                                                py-2
                                            "
                                        >
                                            <el-checkbox
                                                label="Notificar por email"
                                                v-model="p.vNOTIFY_BY_EMAIL"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vNOTIFY_BY_EMAIL',
                                                        $event
                                                    )
                                                "
                                                :disabled="!p.vIS_ENABLED"
                                            ></el-checkbox>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div
                                            class="
                                                col-12
                                                text-left
                                                mx-4
                                                px-2
                                                py-2
                                            "
                                        >
                                            <el-checkbox
                                                label="Notificar no Segware"
                                                v-model="p.vNOTIFY_BY_SEGWARE"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vNOTIFY_BY_SEGWARE',
                                                        $event
                                                    )
                                                "
                                                :disabled="!p.vIS_ENABLED"
                                            ></el-checkbox>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                    <!--  -->
                    <div>
                        <div class="row">
                            <div class="col-12 text-left">
                                <h5>Inteligência artificial</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-3 my-2"
                                style=""
                                :key="'pilot-event-' + index"
                                v-for="(
                                    p, index
                                ) in artificialIntelligenceEvents.drone"
                            >
                                <div
                                    style="border-radius: 20px"
                                    class="bg-dark-medium-gray"
                                >
                                    <div class="row px-4 py-2">
                                        <div class="col-9 text-left px-2 py-2">
                                            <el-checkbox
                                                :label="
                                                    p[$t('vLABEL_LANGUAGE')]
                                                "
                                                v-model="p.vIS_ENABLED"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vIS_ENABLED',
                                                        $event
                                                    )
                                                "
                                            ></el-checkbox>
                                        </div>
                                        <div class="col-3 text-right">
                                            <el-tooltip
                                                :content="
                                                    p[$t('vTOOLTIP_LANGUAGE')]
                                                "
                                                placement="top"
                                                ><i
                                                    style="
                                                        font-size: 1rem;
                                                        color: #ffe082;
                                                    "
                                                    class="
                                                        fas
                                                        fa-question-circle
                                                    "
                                                ></i>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div :class="{ hidden: !p.vRANGE_INPUT }">
                                        <div class="row">
                                            <div
                                                class="
                                                    col-12
                                                    text-left
                                                    mx-4
                                                    px-2
                                                    py-2
                                                "
                                            >
                                                {{
                                                    p[
                                                        $t(
                                                            "vRANGE_INPUT_LABEL_LANGUAGE"
                                                        )
                                                    ]
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1"></div>
                                            <div class="col-8">
                                                <el-slider
                                                    :min="p.vMIN_VALUE"
                                                    :max="p.vMAX_VALUE"
                                                    v-model="p.vVALUE"
                                                    :marks="p.vMARKS"
                                                    :show-tooltip="
                                                        p.vSHOW_TOOLTIP
                                                    "
                                                    @change="
                                                        handleSliderChange(
                                                            p.vKEY,
                                                            'vVALUE',
                                                            $event
                                                        )
                                                    "
                                                    :disabled="!p.vIS_ENABLED"
                                                >
                                                    ></el-slider
                                                >
                                            </div>
                                            <div class="col-3 align-middle">
                                                <span
                                                    class="align-middle"
                                                    :class="{
                                                        hidden: !p.vSHOW_RANGE_INPUT_VALUE,
                                                    }"
                                                    >{{ p.vVALUE }}
                                                    {{ p.vUNIT }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div
                                            class="
                                                col-12
                                                text-left
                                                mx-4
                                                px-2
                                                py-2
                                            "
                                        >
                                            <el-checkbox
                                                label="Notificar por email"
                                                v-model="p.vNOTIFY_BY_EMAIL"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vNOTIFY_BY_EMAIL',
                                                        $event
                                                    )
                                                "
                                                :disabled="!p.vIS_ENABLED"
                                            ></el-checkbox>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div
                                            class="
                                                col-12
                                                text-left
                                                mx-4
                                                px-2
                                                py-2
                                            "
                                        >
                                            <el-checkbox
                                                label="Notificar no Segware"
                                                v-model="p.vNOTIFY_BY_SEGWARE"
                                                @change="
                                                    handleCheckBoxChange(
                                                        p.vKEY,
                                                        'vNOTIFY_BY_SEGWARE',
                                                        $event
                                                    )
                                                "
                                                :disabled="!p.vIS_ENABLED"
                                            ></el-checkbox>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                    <!--  -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            automaticEventDeletion: false,
        };
    },
    computed: {
        ...mapGetters(["user", "pilotEvents", "artificialIntelligenceEvents"]),
    },
    methods: {
        refreshUserInternalsSettings() {},
        handleCheckBoxChange(key, parameter, value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/drone/events/${key}/${parameter}`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleSliderChange(key, parameter, value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/drone/events/${key}/${parameter}`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleAutomaticEventDeletionChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/drone/events/vAUTOMATIC_EVENT_DELETION`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
    },
    mounted() {
        this.refreshUserInternalsSettings();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}

.hidden {
    visibility: hidden;
}
</style>