<template>
    <div
        class="main-drone-stream-telemetry"
        style="
            position: absolute;
            bottom: 5px;
            left: 5px;
            width: 200px;
            background-color: #2d2d2d;
            opacity: 0.85;
            padding-top: 5px;
            font-size: 0.8rem;
            border-radius: 10px;
        "
    >
        <div class="row mx-0 px-0">
            <div class="col-2 mx-0 px-0" style="color: #ffe082"></div>
            <div class="col-8 mx-0 px-0 text-center" style="color: #ffe082">
                <!-- {{ drone.internals.vCHILD_NAME }} -->
                {{ drone.telemetry.vMISSION_NAME || "-" }}
            </div>
            <div
                class="col-2 mx-0 px-0"
                :class="{
                    'color-green':
                        isConnected &&
                        !drone.flight.state.vIS_EXECUTING_MISSION,
                    'color-yellow':
                        isConnected && drone.flight.state.vIS_EXECUTING_MISSION,
                    'color-red': !isConnected,
                }"
            >
                <i
                    class="fas fa-circle"
                    :class="{
                        pulse: isConnected,
                    }"
                ></i>
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="col-4 mx-0 px-0" style="color: #a7a7a7">Altura</div>
            <div class="col-4 mx-0 px-0" style="color: #a7a7a7">Velocidade</div>
            <div class="col-4 mx-0 px-0" style="color: #a7a7a7">Satélites</div>
            <div class="col-4 mx-0 px-0" style="color: white">
                {{
                    isConnected
                        ? Math.floor(drone.telemetry.vALTITUDE) + " m"
                        : "-"
                }}
            </div>
            <div class="col-4 mx-0 px-0" style="color: white">
                {{
                    isConnected
                        ? Math.floor(
                              Math.pow(
                                  Math.pow(drone.telemetry.vVELOCITY_X, 2) +
                                      Math.pow(drone.telemetry.vVELOCITY_Y, 2),
                                  0.5
                              ) * 3.6
                          ) + " km/h"
                        : "-"
                }}
            </div>
            <div class="col-4 mx-0 px-0" style="color: white">
                {{ isConnected ? drone.telemetry.vSATELLITES_COUNT : "-" }}
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="col-4 mx-0 px-0" style="color: #a7a7a7">Bateria</div>
            <div class="col-4 mx-0 px-0" style="color: #a7a7a7">Vento</div>
            <div class="col-4 mx-0 px-0" style="color: #a7a7a7">
                <i class="fas fa-cloud"></i>
            </div>
            <div class="col-4 mx-0 px-0" style="color: white">
                {{
                    isConnected
                        ? Math.floor(
                              drone.telemetry
                                  .vBATTERY_CHARGE_REMAINING_IN_PERCENT
                          ) + " %"
                        : "-"
                }}
            </div>
            <div
                class="col-4 mx-0 px-0"
                :class="{
                    'color-green':
                        drone.telemetry.vFLIGHT_WIND_WARNING === 'LEVEL_0' &&
                        isConnected,
                    'color-yellow':
                        drone.telemetry.vFLIGHT_WIND_WARNING === 'LEVEL_1' &&
                        isConnected,
                    'color-red':
                        drone.telemetry.vFLIGHT_WIND_WARNING === 'LEVEL_2' &&
                        isConnected,
                    'color-white': !isConnected,
                }"
            >
                {{
                    isConnected
                        ? drone.telemetry.vFLIGHT_WIND_WARNING === "LEVEL_0"
                            ? "Baixo"
                            : drone.telemetry.vFLIGHT_WIND_WARNING === "LEVEL_1"
                            ? "Médio"
                            : drone.telemetry.vFLIGHT_WIND_WARNING === "LEVEL_2"
                            ? "Ventania"
                            : "-"
                        : "-"
                }}
            </div>
            <div
                class="col-4 mx-0 px-0"
                :class="{
                    'color-red':
                        drone.stream.vWEBRTC_BITRATE < 500 && isConnected,
                    'color-yellow':
                        drone.stream.vWEBRTC_BITRATE >= 500 &&
                        drone.stream.vWEBRTC_BITRATE < 1300 &&
                        isConnected,
                    'color-green':
                        drone.stream.vWEBRTC_BITRATE >= 1300 && isConnected,
                    'color-white': !isConnected,
                }"
            >
                {{
                    isConnected
                        ? (drone.stream.vWEBRTC_BITRATE / 1000).toFixed(2) +
                          "Mbps"
                        : "-"
                }}
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            isConnected: true,
        };
    },
    props: ["drone"],
};
</script>


<style scoped>
i {
    font-size: 1rem;
}

.color-green {
    color: #00c689 !important;
}
.color-red {
    color: red !important;
}

.color-yellow {
    color: #ffe082 !important;
}

.color-white {
    color: white !important;
}

.pulse {
    animation: pulse 2s infinite;
    border-radius: 50%;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}
</style>