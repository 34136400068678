<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">
                            {{ $t("Points_locations_and_fencings") }}</span
                        >
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para criar, deletar ou editar pontos
                            e cercas.</span
                        >
                    </div>
                </div>
                <br />
                <br />
            </div>

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1000;
                "
                @click="
                    currentEditFencingKey = null;
                    resetCoordinates();
                    $bvModal.show('modal-choose-solo-fencing');
                "
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="treatedFencings"
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column sortable prop="vFENCING_NAME" label="Nome">
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vFENCING_TYPE"
                        :label="$t('Type')"
                    >
                        <template slot-scope="scope">
                            {{
                                fencingTypesOptions[
                                    fencingTypesOptions
                                        .map((v) => v.value)
                                        .indexOf(scope.row.vFENCING_TYPE)
                                ].label
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vFENCING_LOCATION_NAME"
                        label="Local"
                    >
                    </el-table-column>

                    <el-table-column
                        sortable
                        prop="vIS_FENCING_VISIBLE"
                        label="Visualizar no mapa"
                    >
                        <template slot-scope="scope">
                            <el-checkbox
                                @change="handleVisible(scope.$index, scope.row)"
                                v-model="scope.row.vIS_FENCING_VISIBLE"
                            ></el-checkbox>
                        </template>
                    </el-table-column>

                    <el-table-column align="right">
                        <template slot-scope="scope">
                            <button
                                @click="handleEdit(scope.$index, scope.row)"
                                class="btn btn-link"
                            >
                                <i
                                    class="fas fa-pen"
                                    style="font-size: 1rem !important"
                                ></i>
                            </button>
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                :cancel-button-text="$t('Cancel')"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="handleDelete(scope.$index, scope.row)"
                            >
                                <button class="btn btn-link" slot="reference">
                                    <i
                                        class="fas fa-trash"
                                        style="font-size: 1rem !important"
                                    ></i>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>

        <div>
            <b-modal
                id="modal-choose-solo-fencing"
                size="xl"
                class="my-modal"
                @show="placeSetMarker = []"
                @shown="fitElements"
            >
                <template v-slot:modal-title>{{
                    $t("Select_a_location")
                }}</template>
                <div style="width: 100%; height: 40vh; margin-bottom: 60px">
                    <div
                        style="
                            position: relative;
                            top: 60px;
                            z-index: 9999;
                            width: calc(100% - 400px);
                            margin-left: 200px;
                            margin-right: 200px;
                        "
                        class="text-center"
                    >
                        <gmap-autocomplete
                            :placeholder="$t('Search_by_address')"
                            style="
                                width: 400px;
                                color: #908f8f;
                                border-radius: 20px;
                                box-shadow: none !important;
                                border: none;
                                height: 40px;
                                outline: none;
                            "
                            ref="gmapAutocompleteSoloFencing"
                            class="text-center bg-dark-low-gray"
                            @place_changed="setPlace"
                            :select-first-on-enter="true"
                        >
                            <template v-slot:input="slotProps">
                                <v-text-field
                                    outlined
                                    prepend-inner-icon="place"
                                    placeholder="Location Of Event"
                                    ref="inputGmapAutocompleteSoloFencing"
                                    v-on:listeners="slotProps.listeners"
                                    v-on:attrs="slotProps.attrs"
                                    :value="selectedAddress"
                                >
                                </v-text-field>
                            </template>
                        </gmap-autocomplete>
                    </div>
                    <gmap-map
                        :zoom="4"
                        ref="mapRefLocationSoloFencing"
                        style="width: 100%; height: 100%; padding: 0; margin: 0"
                        @dblclick="doubleClickOnGmaps($event)"
                        :options="{ disableDoubleClickZoom: true, tilt: 0 }"
                        :center="center"
                    >
                        <template>
                            <gmap-marker
                                v-for="(p, index) in placeSetMarker"
                                :key="'place-set-marker-' + index"
                                :position="p.position"
                                :icon="p.icon"
                            ></gmap-marker>

                            <gmap-info-window
                                v-for="(p, index) in placeSetMarker"
                                :key="'place-set-marker-' + index"
                                :position="p.position"
                                :options="p.infoOptions"
                                :opened="true"
                                :clickable="false"
                            >
                            </gmap-info-window>
                        </template>
                        <gmap-circle
                            v-if="
                                currentFencing.mapCoordinates.lat &&
                                currentFencing.mapCoordinates.lng &&
                                currentFencing.mapCoordinates.radius
                            "
                            ref="circleRef"
                            :center="{
                                lat: currentFencing.mapCoordinates.lat,
                                lng: currentFencing.mapCoordinates.lng,
                            }"
                            :radius="currentFencing.mapCoordinates.radius"
                            :visible="true"
                            :options="{
                                fillColor: currentFencing.color,
                                fillOpacity: 0.3,
                                strokeColor: currentFencing.color,
                            }"
                            clickable
                            draggable
                            :editable="true"
                            @center_changed="handleCenterChanged"
                            @radius_changed="handleRadiusChanged"
                            @dragend="handleDragend"
                        />
                    </gmap-map>
                </div>
                <br />
                <div
                    v-if="
                        currentFencing.mapCoordinates.lat &&
                        currentFencing.mapCoordinates.lng &&
                        currentFencing.mapCoordinates.radius
                    "
                >
                    <el-form
                        ref="form-add-solo-fencing"
                        :model="currentFencing"
                        :rules="rulesFencing"
                    >
                        <div>
                            <div class="row">
                                <div class="col-3 text-center">
                                    <el-form-item
                                        label="Latitude (°)"
                                        prop="lat"
                                    >
                                        <br />
                                        <el-input-number
                                            v-model="
                                                currentFencing.mapCoordinates
                                                    .lat
                                            "
                                            controls-position="right"
                                            :min="-90"
                                            :max="90"
                                            :step="0.1"
                                            label="°"
                                        ></el-input-number
                                    ></el-form-item>
                                </div>
                                <div class="col-3 text-center">
                                    <el-form-item
                                        label="Longitude (°)"
                                        prop="lng"
                                    >
                                        <br />
                                        <el-input-number
                                            v-model="
                                                currentFencing.mapCoordinates
                                                    .lng
                                            "
                                            controls-position="right"
                                            :min="-180"
                                            :max="180"
                                            :step="0.1"
                                            label="°"
                                        ></el-input-number
                                    ></el-form-item>
                                </div>
                                <div class="col-3 text-left">
                                    <el-form-item
                                        label="Raio (m)"
                                        prop="radius"
                                    >
                                        <br />
                                        <el-input-number
                                            v-model="
                                                currentFencing.mapCoordinates
                                                    .radius
                                            "
                                            controls-position="right"
                                            :min="1"
                                            :max="5000000"
                                            :step="1"
                                            label="°"
                                        ></el-input-number
                                    ></el-form-item>
                                </div>
                                <div class="col-3 text-left">
                                    <el-form-item label="Cor" prop="color">
                                        <br />
                                        <el-color-picker
                                            v-model="currentFencing.color"
                                        ></el-color-picker>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <div class="row">
                                <div class="col-3 text-center">
                                    <el-form-item label="Nome" prop="name">
                                        <br />
                                        <el-input
                                            v-model="currentFencing.name"
                                            type="text"
                                        ></el-input
                                    ></el-form-item>
                                </div>
                                <div class="col-3 text-center">
                                    <el-form-item
                                        :label="$t('Type')"
                                        prop="type"
                                    >
                                        <br />
                                        <el-select
                                            v-model="currentFencing.type"
                                            placeholder="Select"
                                        >
                                            <el-option
                                                v-for="item in fencingTypesOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="col-3 text-center">
                                    <el-form-item
                                        label="Local"
                                        prop="locationName"
                                    >
                                        <br />
                                        <el-input
                                            v-model="
                                                currentFencing.locationName
                                            "
                                            type="text"
                                        ></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-12 text-center">
                            Clique duas vezes no mapa para definir uma região de
                            interesse
                        </div>
                    </div>
                </div>
                <template #modal-footer="{ ok, cancel }">
                    <button
                        class="btn btn-primary btn-bold"
                        @click="submitForm('form-add-solo-fencing', ok)"
                    >
                        {{ $t("Confirm") }}
                    </button>
                    <button
                        class="btn btn-secondary btn-bold"
                        @click="resetCoordinates()"
                    >
                        {{ $t("Clear") }}
                    </button>
                    <button
                        class="btn btn-secondary btn-bold"
                        @click="cancel()"
                    >
                        {{ $t("Cancel") }}
                    </button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";
import LoginVue from "../../../../views/Login.vue";

export default {
    data() {
        return {
            treatedFencings: [],
            fencingTypesOptions: [
                {
                    label: "Geopoint",
                    value: "geopoint",
                },
            ],
            currentFencing: {
                mapCoordinates: {
                    lat: 0,
                    lng: 0,
                    radius: 0,
                },
                name: "",
                type: "geopoint",
                locationName: "",
                color: "#FF0000",
            },
            rulesFencing: {
                name: [
                    {
                        required: true,
                        message: "Defina um nome para a cerca",
                        trigger: "change",
                    },
                    {
                        min: 3,
                        max: 20,
                        message: "O nome deve entre 3 e 20 caractéres",
                        trigger: "change",
                    },
                ],
                locationName: [
                    {
                        required: true,
                        message: "Defina um nome para o local",
                        trigger: "change",
                    },
                    {
                        min: 3,
                        max: 20,
                        message: "O nome deve ter entre 3 e 20 caractéres",
                        trigger: "change",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "Defina um tipo de cerca",
                        trigger: "change",
                        validator: (rule, value, callback) => {
                            if (["geopoint"].indexOf(value) === -1) {
                                callback(new Error("Valor inválido"));
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                lat: [
                    {
                        required: true,
                        message: "Defina uma coordenada",
                        trigger: "change",
                        validator: (rule, value, callback) => {
                            if (!isNaN(value)) {
                                if (value >= -90 && value <= 90) {
                                    callback(new Error("Valor inválido"));
                                } else {
                                    callback();
                                }
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                lng: [
                    {
                        required: true,
                        message: "Defina uma coordenada",
                        trigger: "change",
                        validator: (rule, value, callback) => {
                            if (!isNaN(value)) {
                                if (value >= -180 && value <= 180) {
                                    callback(new Error("Valor inválido"));
                                } else {
                                    callback();
                                }
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                radius: [
                    {
                        required: true,
                        message: "Defina um valor",
                        trigger: "change",
                        validator: (rule, value, callback) => {
                            if (!isNaN(value)) {
                                if (value >= 1 && value <= 5000000) {
                                    callback(new Error("Valor inválido"));
                                } else {
                                    callback();
                                }
                            } else {
                                callback();
                            }
                        },
                    },
                ],
            },
            currentEditFencingKey: null,
            center: { lat: -23.508, lng: -46.587 },
            placeSetMarker: [],
        };
    },
    computed: {
        ...mapGetters(["user", "solosMarkers"]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
    },
    methods: {
        ...mapActions(["setNavForcedLoading"]),
        handleVisible(i, data) {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${self.user.data.uid}/internals/settings/solo/fencings/${data.vFENCING_KEY}/vIS_FENCING_VISIBLE`
                )
                .set(data.vIS_FENCING_VISIBLE)
                .then(() => {
                    self.refreshData();
                });
        },
        resetCoordinates() {
            this.currentFencing = {
                mapCoordinates: {
                    lat: 0,
                    lng: 0,
                    radius: 0,
                },
                name: "",
                type: "geopoint",
                color: "#FF0000",
                locationName: "",
            };
            this.placeSetMarker = [];
        },
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        doubleClickOnGmaps(e) {
            let self = this;
            this.$refs.mapRefLocationSoloFencing.$mapPromise.then((map) => {
                let bounds = map.getBounds();
                let ne = bounds.getNorthEast();
                let sw = bounds.getSouthWest();

                let distance = self.distanceInKm([
                    { lat: ne.lat(), lng: ne.lng() },
                    { lat: sw.lat(), lng: sw.lng() },
                ]);

                this.currentFencing.mapCoordinates.lat = e.latLng.lat();
                this.currentFencing.mapCoordinates.lng = e.latLng.lng();
                this.currentFencing.mapCoordinates.radius = Math.floor(
                    ((((distance * 1000) / 2) * 1) / Math.pow(2, 0.5)) * 0.8
                );

                setTimeout(() => {
                    this.fitCircle("circleRef");
                }, 200);
            });
        },
        handleCenterChanged(e) {
            this.currentFencing.mapCoordinates.lat = e.lat();
            this.currentFencing.mapCoordinates.lng = e.lng();
        },
        handleRadiusChanged(e) {
            this.currentFencing.mapCoordinates.radius = Math.round(e);
            this.fitCircle("circleRef");
        },
        handleDragend(e) {
            this.fitCircle("circleRef");
        },
        fitCircle(ref) {
            this.$refs[ref].$circlePromise.then(() => {
                const { $circleObject } = this.$refs[ref]; //get google.maps.Circle object
                const map = $circleObject.getMap();
                map.fitBounds($circleObject.getBounds());
            });
        },
        handleConfirm() {
            let self = this;

            if (!this.currentEditFencingKey) {
                firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(function (idToken) {
                        self.setNavForcedLoading(true);
                        firebase
                            .functions()
                            .httpsCallable("CLOUD_DASHBOARD_ADD_SOLO_FENCING")({
                                vTEAM_UID: self.user.data.uid,
                                payload: {
                                    vFENCING_NAME: self.currentFencing.name,
                                    vFENCING_TYPE: self.currentFencing.type,
                                    vFENCING_LOCATION_NAME:
                                        self.currentFencing.locationName,
                                    vFENCING_COLOR: self.currentFencing.color,
                                    vFENCING_LOCATION_LATITUDE:
                                        self.currentFencing.mapCoordinates.lat,
                                    vFENCING_LOCATION_LONGITUDE:
                                        self.currentFencing.mapCoordinates.lng,
                                    vFENCING_LOCATION_RADIUS:
                                        self.currentFencing.mapCoordinates
                                            .radius,
                                },
                                idToken: idToken,
                                command: "add-solo-fencing",
                            })
                            .then(function (result) {
                                if (!result.data.error) {
                                    self.$message({
                                        message: `Modificações realizadas com sucesso`,
                                        type: "success",
                                        showClose: true,
                                        offset: 60,
                                    });
                                    self.refreshData();
                                } else {
                                    self.$message({
                                        message: `Não foi possível efetuar as modificações`,
                                        type: "error",
                                        showClose: true,
                                        offset: 60,
                                    });
                                }
                            })
                            .catch((e) => {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            })
                            .finally(() => {
                                self.setNavForcedLoading(false);
                            });
                    })
                    .catch((e) => {
                        self.$message({
                            message: `Não foi possível efetuar as modificações`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                    });
            } else {
                firebase
                    .auth()
                    .currentUser.getIdToken(true)
                    .then(function (idToken) {
                        self.setNavForcedLoading(true);
                        firebase
                            .functions()
                            .httpsCallable("CLOUD_DASHBOARD_EDIT_SOLO_FENCING")(
                                {
                                    vTEAM_UID: self.user.data.uid,
                                    vFENCING_KEY: self.currentEditFencingKey,
                                    payload: {
                                        vFENCING_NAME: self.currentFencing.name,
                                        vFENCING_TYPE: self.currentFencing.type,
                                        vFENCING_LOCATION_NAME:
                                            self.currentFencing.locationName,
                                        vFENCING_COLOR:
                                            self.currentFencing.color,
                                        vFENCING_LOCATION_LATITUDE:
                                            self.currentFencing.mapCoordinates
                                                .lat,
                                        vFENCING_LOCATION_LONGITUDE:
                                            self.currentFencing.mapCoordinates
                                                .lng,
                                        vFENCING_LOCATION_RADIUS:
                                            self.currentFencing.mapCoordinates
                                                .radius,
                                    },
                                    idToken: idToken,
                                    command: "edit-solo-fencing",
                                }
                            )
                            .then(function (result) {
                                if (!result.data.error) {
                                    self.$message({
                                        message: `Modificações realizadas com sucesso`,
                                        type: "success",
                                        showClose: true,
                                        offset: 60,
                                    });
                                    self.refreshData();
                                } else {
                                    self.$message({
                                        message: `Não foi possível efetuar as modificações`,
                                        type: "error",
                                        showClose: true,
                                        offset: 60,
                                    });
                                }
                            })
                            .catch((e) => {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            })
                            .finally(() => {
                                self.setNavForcedLoading(false);
                            });
                    })
                    .catch((e) => {
                        self.$message({
                            message: `Não foi possível efetuar as modificações`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                    })
                    .finally(() => {
                        self.currentEditFencingKey = null;
                    });
            }
        },
        submitForm(formName, callback) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    self.handleConfirm();
                    callback();
                } else {
                    return false;
                }
            });
        },
        fillCoordinatesToBeEdited(r) {
            this.currentFencing = {
                mapCoordinates: {
                    lat: r.vFENCING_LOCATION_LATITUDE,
                    lng: r.vFENCING_LOCATION_LONGITUDE,
                    radius: r.vFENCING_LOCATION_RADIUS,
                },
                name: r.vFENCING_NAME,
                type: r.vFENCING_TYPE,
                color: r.vFENCING_COLOR,
                locationName: r.vFENCING_LOCATION_NAME,
            };
        },
        handleEdit(i, r) {
            this.currentEditFencingKey = r.vFENCING_KEY;
            this.fillCoordinatesToBeEdited(r);
            this.$bvModal.show("modal-choose-solo-fencing");

            setTimeout(() => {
                this.fitCircle("circleRef");
            }, 200);
        },
        handleDelete(i, r) {
            let self = this;

            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    self.setNavForcedLoading(true);
                    firebase
                        .functions()
                        .httpsCallable("CLOUD_DASHBOARD_DELETE_SOLO_FENCING")({
                            vTEAM_UID: self.user.data.uid,
                            vFENCING_KEY: r.vFENCING_KEY,
                            idToken: idToken,
                            command: "delete-solo-fencing",
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                self.refreshData();
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        })
                        .finally(() => {
                            self.setNavForcedLoading(false);
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        setPlace(place) {
            if (!place) {
                return;
            }
            let self = this;

            self.center = {
                lat: place.geometry.location.lat() + Math.random() * 0.0000001,
                lng: place.geometry.location.lng() + Math.random() * 0.0000001,
            };
            this.$refs.mapRefLocationSoloFencing.$mapPromise.then((map) => {
                map.setZoom(15);
                self.$refs.gmapAutocompleteSoloFencing.$refs.input.value = "";
            });

            this.placeSetMarker.push({
                position: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                },
                icon: {
                    rotation: 0,
                    scale: 8,
                    anchor: new google.maps.Point(0, 0),
                },
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: 50,
                    },
                    disableAutoPan: true,
                    content:
                        "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'> " +
                        place.formatted_address +
                        "</strong></div>",
                },
            });
        },
        fitElements() {
            if (this.currentEditFencingKey === null) {
                let markers = this.solosMarkers;
                this.$refs.mapRefLocationSoloFencing.$mapPromise.then((map) => {
                    if (markers.length === 1) {
                        map.setCenter(markers[0].position);
                        map.setZoom(8);
                    } else if (markers.length > 1) {
                        const bounds = new google.maps.LatLngBounds();
                        for (let m of markers) {
                            bounds.extend(m.position);
                        }
                        map.fitBounds(bounds);
                    }
                });
            }
        },
        refreshData() {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${self.user.data.uid}/internals/settings/solo/fencings`
                )
                .on("value", function (snapshot) {
                    self.treatedFencings = (
                        Object.values(snapshot.val() || {}) || []
                    ).filter((v) => v.vFENCING_KEY);
                });
        },
    },
    mounted() {
        this.refreshData();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}

.pac-container {
    z-index: 99999 !important;
}
</style>