<template>
    <div>
        <gmap-map
            :zoom="4"
            ref="mapRefDroneSelectedMission"
            style="width: 100%; height: 100%; padding: 0; margin: 0"
            :options="{
                disableDoubleClickZoom: true,
                disableDefaultUI: true,
                draggable: false,
                tilt: 0,
            }"
            :center="center"
        >
            <template>
                <gmap-marker
                    :key="'drone-mission-selected-marker-' + index"
                    v-for="(dm, index) in mission.markers"
                    :position="dm.position"
                    :icon="{
                        path: dm.icon.path,
                        rotation: dm.icon.rotation + 180,
                        fillColor: '#FFE082',
                        fillOpacity: dm.icon.fillOpacity,
                        anchor: dm.icon.anchor,
                        labelOrigin: dm.icon.labelOrigin,
                        strokeWeight: dm.icon.strokeWeight,
                        scale: dm.icon.scale,
                    }"
                    :clickable="true"
                    :label="{
                        color: '#000000',
                        fontSize: '16px',
                        text: (index + 1).toString(),
                    }"
                ></gmap-marker>
            </template>

            <template>
                <gmap-polyline
                    v-bind:path.sync="polylines"
                    v-bind:options="{
                        strokeColor: '#A1A0A0',
                    }"
                ></gmap-polyline>
            </template>

            <template>
                <gmap-info-window
                    v-for="(dm, index) in altitudeInfoWindows"
                    :key="
                        'drone-mission-selected-altitude-info-window-' + index
                    "
                    :position="dm.position"
                    :options="dm.infoOptions"
                    :opened="true"
                    :clickable="false"
                >
                </gmap-info-window>
            </template>
        </gmap-map>
    </div>
</template>

<script>
export default {
    data() {
        return {
            center: { lat: -23.508, lng: -46.587 },
        };
    },
    props: ["mission"],
    computed: {
        polylines() {
            return this.mission.waypoints.map(function (v) {
                return { lat: v.vLATITUDE, lng: v.vLONGITUDE };
            });
        },
        altitudeInfoWindows() {
            let waypoints = this.mission.waypoints;
            if (waypoints.length > 1) {
                let altitudeInfoWindows = [];
                for (let w of waypoints) {
                    let a = {
                        infoOptions: {
                            pixelOffset: {
                                width: 0,
                                height: -10,
                            },
                            disableAutoPan: true,
                            content:
                                "<div style='background-color:white;padding:5px;border-radius:20px'><strong style='color:black!important;font-weight:bold;font-size:0.8rem;margin-left:2.5px;margin-right:2.5px;'> " +
                                w.vALTITUDE +
                                " m</strong></div>",
                        },
                        position: {
                            lat: w.vLATITUDE,
                            lng: w.vLONGITUDE,
                        },
                    };

                    altitudeInfoWindows.push(a);
                }
                return altitudeInfoWindows;
            } else {
                return [];
            }
        },
    },
    methods: {
        fitBounds() {
            if (this.mission.markers.length > 1) {
                this.$refs.mapRefDroneSelectedMission.$mapPromise.then(
                    (map) => {
                        const bounds = new google.maps.LatLngBounds();
                        for (let m of this.mission.markers) {
                            bounds.extend(m.position);
                        }
                        map.fitBounds(bounds);
                    }
                );
            }
        },
    },
    watch: {
        mission: function (newVal, oldVal) {
            if (newVal) {
                this.fitBounds();
            }
        },
    },
    mounted() {
        this.fitBounds();
    },
};
</script>