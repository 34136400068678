var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.droneActiveMission &&
        (_vm.droneActiveMissionElement
            ? _vm.droneActiveMissionElement.waypoints
            : false)
    )?_c('div',{staticClass:"main-drone-mission-card bg-dark-high-gray"},[_vm._m(0),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h6',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.droneActiveMissionElement.vMISSION_NAME)+" ")])])]),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm.droneActiveMissionElement.waypoints.length)+" ")]),_c('div',{staticClass:"col-6",class:{
                'good-value-color': _vm.distanceTotal <= 10,
                'bad-value-color': _vm.distanceTotal > 10,
            }},[_vm._v(" "+_vm._s(_vm.distanceTotal.toFixed(2) + " km")+" ")])]),_c('br'),_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6",class:{
                'good-value-color': _vm.distanceMax <= 5,
                'bad-value-color': _vm.distanceMax > 5,
            }},[_vm._v(" "+_vm._s(_vm.distanceMax.toFixed(2) + " km")+" ")]),_c('div',{staticClass:"col-6",class:{
                'good-value-color': _vm.altitudeMax <= 300,
                'bad-value-color': _vm.altitudeMax > 300,
            }},[_vm._v(" "+_vm._s(_vm.altitudeMax.toFixed(2) + " m")+" ")])]),_c('br'),_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",class:{
                'good-value-color': _vm.timeEstimatedInSeconds <= 30 * 60,
                'bad-value-color': _vm.timeEstimatedInSeconds > 30 * 60,
            }},[_vm._v(" "+_vm._s(_vm.timeEstimated)+" ")])]),_c('br'),_c('div',{staticClass:"chart-container",staticStyle:{"height":"calc(25vh - 32px - 10px)"}},[_c('app-drone-mission-card-elevation',{attrs:{"datacollection":_vm.datacollection}})],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticStyle:{"color":"#a1a0a0"}},[_vm._v("Dados da Missão")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6",staticStyle:{"color":"#a1a0a0"}},[_vm._v("Waypoints")]),_c('div',{staticClass:"col-6",staticStyle:{"color":"#a1a0a0"}},[_vm._v("Distância total")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6",staticStyle:{"color":"#a1a0a0"}},[_vm._v("Distância máx.")]),_c('div',{staticClass:"col-6",staticStyle:{"color":"#a1a0a0"}},[_vm._v("Altura máx.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"color":"#a1a0a0"}},[_vm._v("Tempo estimado")])])}]

export { render, staticRenderFns }