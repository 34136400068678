<template>
    <div>
        Relevo
        <div class="chart-container" style="height: 80%">
            <app-drone-mission-card-elevation
                :datacollection="datacollection"
            ></app-drone-mission-card-elevation>
        </div>
    </div>
</template>

<script>
import appDroneMissionCardElevation from "@/components/drone/containers/DroneMissionCardElevation";
import { gmapApi } from "vue2-google-maps";

export default {
    data() {
        return {
            elevations: [],
        };
    },
    props: ["mission"],
    components: {
        appDroneMissionCardElevation,
    },
    computed: {
        google: gmapApi,
        distanceTotal() {
            return this.distanceInKm(
                this.mission.vEND_ACTION === "no_action"
                    ? this.mission.waypoints.map(function (v) {
                          return {
                              lat: v.vLATITUDE,
                              lng: v.vLONGITUDE,
                          };
                      })
                    : this.mission.waypoints
                          .map(function (v) {
                              return {
                                  lat: v.vLATITUDE,
                                  lng: v.vLONGITUDE,
                              };
                          })
                          .concat(
                              this.mission.waypoints.map(function (v) {
                                  return {
                                      lat: v.vLATITUDE,
                                      lng: v.vLONGITUDE,
                                  };
                              })[0]
                          ) || 0
            );
        },
        missionElementRelativeWaypointsElevations() {
            if (this.mission.waypoints.length >= 2) {
                let labels = this.elevations.map(
                    (v) =>
                        this.distanceTotal *
                        (this.elevations.indexOf(v) / this.elevations.length)
                );
                let relativeWaypointsElevations = this.elevations.map(
                    (v) => null
                );

                if (this.mission.vEND_ACTION === "no_action") {
                    let w0 = this.mission.waypoints[0];
                    let positions = [
                        {
                            lat: w0.vLATITUDE,
                            lng: w0.vLONGITUDE,
                        },
                    ];
                    for (let w of this.mission.waypoints) {
                        positions.push({
                            lat: w.vLATITUDE,
                            lng: w.vLONGITUDE,
                        });
                        let value = this.distanceInKm(positions);
                        let closestValue = labels.reduce((a, b) => {
                            return Math.abs(b - value) < Math.abs(a - value)
                                ? b
                                : a;
                        }, 0);
                        let closestValueIndex = labels.indexOf(closestValue);
                        relativeWaypointsElevations[closestValueIndex] =
                            w.vALTITUDE;
                    }
                } else {
                    let w0 = this.mission.waypoints[0];
                    let positions = [
                        {
                            lat: w0.vLATITUDE,
                            lng: w0.vLONGITUDE,
                        },
                    ];
                    for (let w of this.mission.waypoints) {
                        positions.push({
                            lat: w.vLATITUDE,
                            lng: w.vLONGITUDE,
                        });
                        let value = this.distanceInKm(positions);
                        let closestValue = labels.reduce((a, b) => {
                            return Math.abs(b - value) < Math.abs(a - value)
                                ? b
                                : a;
                        }, 0);
                        let closestValueIndex = labels.indexOf(closestValue);
                        relativeWaypointsElevations[closestValueIndex] =
                            w.vALTITUDE;
                        if (
                            this.mission.waypoints.indexOf(w) ===
                            this.mission.waypoints.length - 1
                        ) {
                            relativeWaypointsElevations[
                                closestValueIndex + 1
                            ] = this.mission.vALTITUDE_GO_HOME;
                            relativeWaypointsElevations[
                                labels.length - 1
                            ] = this.mission.vALTITUDE_GO_HOME;
                        }
                    }
                    for (
                        let i = 0;
                        i < relativeWaypointsElevations.length;
                        i++
                    ) {
                        relativeWaypointsElevations[i] === 0
                            ? (relativeWaypointsElevations[i] =
                                  relativeWaypointsElevations[i - 1])
                            : false;
                    }
                }
                return relativeWaypointsElevations;
            } else {
                return [];
            }
        },
        datacollection() {
            return {
                datasets: [
                    {
                        label: "Relevo",
                        backgroundColor: "#a1a0a0",
                        borderColor: "#a1a0a0",
                        pointRadius: 0,
                        type: "line",
                        // tension: 0,
                        fill: false,
                        data: this.elevations,
                    },
                    {
                        label: "Waypoints",
                        backgroundColor: "#a5d6a7",
                        borderColor: "#a5d6a7",
                        pointRadius: 2.5,
                        type: "line",
                        tension: 0,
                        // steppedLine: true,
                        fill: false,
                        data: this.missionElementRelativeWaypointsElevations,
                    },
                ],
                labels: this.elevations.map(
                    (v) =>
                        (
                            this.distanceTotal *
                            (this.elevations.indexOf(v) /
                                this.elevations.length)
                        ).toFixed(1) + " km"
                ),
            };
        },
    },
    methods: {
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        getElevationAlongPath() {
            let self = this;
            if (this.mission.waypoints.length >= 2) {
                const elevator = new this.google.maps.ElevationService();
                elevator.getElevationAlongPath(
                    {
                        path:
                            self.mission.vEND_ACTION === "no_action"
                                ? self.mission.waypoints.map(function (v) {
                                      return {
                                          lat: v.vLATITUDE,
                                          lng: v.vLONGITUDE,
                                      };
                                  })
                                : self.mission.waypoints
                                      .map(function (v) {
                                          return {
                                              lat: v.vLATITUDE,
                                              lng: v.vLONGITUDE,
                                          };
                                      })
                                      .concat(
                                          self.mission.waypoints.map(function (
                                              v
                                          ) {
                                              return {
                                                  lat: v.vLATITUDE,
                                                  lng: v.vLONGITUDE,
                                              };
                                          })[0]
                                      ),
                        samples:
                            this.mission.waypoints.map(function (v) {
                                return {
                                    lat: v.vLATITUDE,
                                    lng: v.vLONGITUDE,
                                };
                            }).length * 10,
                    },
                    (results, status) => {
                        if (status === "OK") {
                            if (results[0]) {
                                self.elevations = results.map(
                                    (v) => v.elevation - results[0].elevation
                                );
                            } else {
                                self.elevations = [];
                            }
                        } else {
                            self.elevations = [];
                        }
                    }
                );
            } else {
                this.elevations = [];
            }
        },
    },
    watch: {
        mission: function (newVal, oldVal) {
            if (newVal) {
                this.getElevationAlongPath();
            } else {
                this.elevations = [];
            }
        },
    },
    mounted() {
        this.getElevationAlongPath();
    },
};
</script>

<style scoped>
.chart-container {
    padding: 1rem;
    flex-grow: 1;
    min-height: 0;
}
</style>