<template>
    <div>
        <div
            style="position:absolute;left:0px:top:0px;z-index:9999;background-color:#FFE082;color:#121212;padding:0px 20px 0px 20px;font-size:1rem;
    border-bottom-right-radius:10px;"
        >
            <div>{{ element.internals.vCHILD_NAME }}</div>
        </div>
        <div
            class="main-fake-stream-element"
            v-if="!(element.vIS_CONNECTED || element.vIS_STANDBY)"
            style="height: 100%"
        >
            <div
                :id="'id-main-fake-stream-element-video-' + element.vUID"
                class="main-fake-stream-element-video"
                style="width: 100%; padding: 25%"
            >
                Sem vídeo
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {},
    props: ["element"],
    mounted() {},
    beforeDestroy() {},
};
</script>


<style scoped>
.main-fake-stream-element {
    width: 100%;
    height: 100%;
    /* background-color: #121212; */
    background: rgb(86, 85, 85);
    background: radial-gradient(
        circle,
        rgba(86, 85, 85, 1) 31%,
        rgba(0, 0, 0, 1) 100%
    );
    color: white;
    border: 1px solid #707070;
}
</style>