import Dashboard from './views/Dashboard.vue'
import Login from './views/Login.vue'

export const routes = [{
        path: '/',
        redirect: '/login'
    },
    {
        path: '/dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        component: Login
    }
]