<template>
    <div
        class="main-solo-commands"
        v-if="soloActive && soloActiveElement.vIS_CONNECTED"
        style="height: 60px; border-radius: 30px !important"
    >
        <div
            style="
                background-color: rgba(73, 73, 73, 0.7);
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                height: 100%;
            "
        >
            <div class="row h-100 my-auto align-center align-middle px-4">
                <div class="col-3 my-auto align-center align-middle">
                    <el-tooltip
                        :content="
                            soloActiveElement.telemetry.vIS_RECORDING === true
                                ? $t('Stop_recording')
                                : $t('Start_recording')
                        "
                        placement="top"
                        :open-delay="1000"
                        v-if="
                            soloActiveElement.telemetry.vIS_EVENT_RECORDING ===
                                false ||
                            soloActiveElement.telemetry.vIS_EVENT_RECORDING ===
                                null
                        "
                    >
                        <button
                            class="btn btn-link px-0 py-0"
                            style="
                                background-color: #4e4e4e;
                                border-radius: 50%;
                                width: 50px;
                                height: 50px;
                            "
                            @click="
                                soloActiveElement.telemetry.vIS_RECORDING ===
                                true
                                    ? handleStopRecording()
                                    : handleStartRecording()
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                v-if="
                                    soloActiveElement.telemetry
                                        .vIS_RECORDING === false
                                "
                            >
                                <circle
                                    id="Elipse_193"
                                    data-name="Elipse 193"
                                    cx="12"
                                    cy="12"
                                    r="12"
                                    fill="#ef5350"
                                />
                            </svg>
                            <i v-else class="fas fa-stop"></i>
                        </button>
                    </el-tooltip>
                </div>
                <div class="col-3 my-auto align-center align-middle">
                    <el-tooltip
                        :content="$t('Switch_camera')"
                        placement="top"
                        :open-delay="1000"
                    >
                        <button
                            class="btn btn-link px-0 py-0"
                            style="
                                background-color: #4e4e4e;
                                border-radius: 50%;
                                width: 50px;
                                height: 50px;
                            "
                            @click="handleSwitchCamera()"
                        >
                            <svg
                                id="Grupo_1227"
                                data-name="Grupo 1227"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32.596"
                                height="32.586"
                                viewBox="0 0 32.596 32.586"
                            >
                                <g id="Grupo_1226" data-name="Grupo 1226">
                                    <path
                                        id="Caminho_809"
                                        data-name="Caminho 809"
                                        d="M16.865,6H11.433L10.074,7.358H6V20.94H22.3V7.358H18.223ZM14.149,16.865a2.716,2.716,0,1,1,2.716-2.716A2.724,2.724,0,0,1,14.149,16.865Z"
                                        transform="translate(2.149 2.144)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Caminho_810"
                                        data-name="Caminho 810"
                                        d="M8.57.691l6.084,6.084V2.769A13.6,13.6,0,0,1,26.81,14.925h2.716A16.308,16.308,0,0,0,8.57.691Z"
                                        transform="translate(3.069 -0.003)"
                                        fill="#fff"
                                    />
                                    <path
                                        id="Caminho_811"
                                        data-name="Caminho 811"
                                        d="M14.872,25.165A13.6,13.6,0,0,1,2.716,13.01H0A16.308,16.308,0,0,0,20.956,27.243l-6.085-6.085Z"
                                        transform="translate(0 4.655)"
                                        fill="#fff"
                                    />
                                </g>
                            </svg>
                        </button>
                    </el-tooltip>
                </div>
                <div class="col-3 my-auto align-center align-middle">
                    <el-tooltip
                        :content="$t('Force_upload')"
                        placement="top"
                        :open-delay="1000"
                    >
                        <el-popconfirm
                            :confirm-button-text="$t('Confirm')"
                            :cancel-button-text="$t('Cancel')"
                            icon="el-icon-info"
                            icon-color="red"
                            title="Ao confirmar, as mídias serão enviadas utilizando a rede atual e isso pode acarretar em cobranças."
                            @confirm="handleForceUpload()"
                        >
                            <button
                                class="btn btn-link px-0 py-0"
                                style="
                                    background-color: #4e4e4e;
                                    border-radius: 50%;
                                    width: 50px;
                                    height: 50px;
                                "
                                slot="reference"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 25 25"
                                >
                                    <g
                                        id="backup_black_24dp"
                                        transform="translate(-0.001 0)"
                                    >
                                        <path
                                            id="Caminho_930"
                                            data-name="Caminho 930"
                                            d="M0,0H25V25H0Z"
                                            transform="translate(0.001 0)"
                                            fill="none"
                                        />
                                        <path
                                            id="Caminho_931"
                                            data-name="Caminho 931"
                                            d="M20.156,9.307C19.448,6.276,16.292,4,12.5,4A8.083,8.083,0,0,0,5.573,7.549C2.438,7.831,0,10.071,0,12.786c0,2.908,2.8,5.271,6.25,5.271H19.792c2.875,0,5.208-1.968,5.208-4.393C25,11.345,22.865,9.465,20.156,9.307Zm-5.573,2.6v3.514H10.417V11.907H7.292L12.5,7.514l5.208,4.393Z"
                                            transform="translate(0.001 1.472)"
                                            fill="#fff"
                                        />
                                    </g>
                                </svg>
                            </button>
                        </el-popconfirm>
                    </el-tooltip>
                </div>
                <div class="col-3 my-auto align-center align-middle">
                    <el-tooltip
                        :content="$t('Restart_app')"
                        placement="top"
                        :open-delay="1000"
                    >
                        <el-popconfirm
                            :confirm-button-text="$t('Confirm')"
                            :cancel-button-text="$t('Cancel')"
                            icon="el-icon-info"
                            icon-color="red"
                            title="Ao confirmar, as mídias serão enviadas utilizando a rede atual e isso pode acarretar em cobranças."
                            @confirm="handleRestartApp()"
                        >
                            <button
                                class="btn btn-link px-0 py-0"
                                style="
                                    background-color: #4e4e4e;
                                    border-radius: 50%;
                                    width: 50px;
                                    height: 50px;
                                "
                                slot="reference"
                            >
                                <svg
                                    id="power_settings_new_black_24dp"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="39"
                                    height="39"
                                    viewBox="0 0 39 39"
                                >
                                    <path
                                        id="Caminho_955"
                                        data-name="Caminho 955"
                                        d="M0,0H39V39H0Z"
                                        fill="none"
                                    />
                                    <path
                                        id="Caminho_956"
                                        data-name="Caminho 956"
                                        d="M19.25,3H16V19.25h3.25ZM27.1,6.526,24.791,8.834A11.245,11.245,0,0,1,29,17.625,11.375,11.375,0,1,1,10.443,8.818L8.151,6.526a14.611,14.611,0,1,0,24.1,11.1A14.515,14.515,0,0,0,27.1,6.526Z"
                                        transform="translate(1.875 1.875)"
                                        fill="#fff"
                                    />
                                </svg>
                            </button>
                        </el-popconfirm>
                    </el-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {};
    },
    components: {},
    computed: {
        ...mapGetters(["soloActive", "soloActiveElement", "user"]),
    },
    methods: {
        handleStartRecording() {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${this.soloActive}/actions/aSTART_RECORDING`
                )
                .set(true)
                .then(() => {
                    self.$message({
                        message: self.$t("Message_recording_started"),
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: self.$t("Message_recording_started_fails"),
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
            if (!this.soloActiveElement.vIS_CONNECTED) {
                self.$message({
                    message: self.$t("Message_recording_started_fails"),
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleStopRecording() {
            let self = this;
            firebase
                .database()
                .ref(`aegisv2/users/${this.soloActive}/actions/aSTOP_RECORDING`)
                .set(true)
                .then(() => {
                    self.$message({
                        message: self.$t("Message_recording_ended"),
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: self.$t("Message_recording_ended_fails"),
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
            if (!this.soloActiveElement.vIS_CONNECTED) {
                self.$message({
                    message: self.$t("Message_recording_ended_fails"),
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleSwitchCamera() {
            let self = this;
            firebase
                .database()
                .ref(`aegisv2/users/${this.soloActive}/actions/aSWITCH_CAMERA`)
                .set(true)
                .then(() => {
                    self.$message({
                        message: `Camêra invertida`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível inverter a câmera`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
            if (!this.soloActiveElement.vIS_CONNECTED) {
                self.$message({
                    message: `Não foi possível inverter a câmera`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleForceUpload() {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${this.soloActive}/actions/aFORCE_MEDIA_UPLOAD`
                )
                .set(true)
                .then(() => {
                    self.$message({
                        message: `Enviando mídias para a nuvem`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível enviar as mídias para a nuvem`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
            if (!this.soloActiveElement.vIS_CONNECTED) {
                self.$message({
                    message: `Não foi possível enviar as mídias para a nuvem`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleRestartApp() {
            let self = this;
            firebase
                .database()
                .ref(`aegisv2/users/${this.soloActive}/actions/aRESTART_APP`)
                .set(true)
                .then(() => {
                    self.$message({
                        message: `Reiniciando aplicativo`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível reiniciar o aplicativo`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
            if (!this.soloActiveElement.vIS_CONNECTED) {
                self.$message({
                    message: `Não foi possível reiniciar o aplicativo`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
    },
};
</script>

<style scoped>
.main-solo-commands {
}
</style>