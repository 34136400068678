<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem"
                            >SmartCam - {{ $t("Settings") }}</span
                        >
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Configurações relativas à operação do
                            SmartCam.</span
                        >
                    </div>
                </div>
                <br />
                <br />

                <!--  -->
                <div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <h5>Gravação de vídeo</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <el-radio
                                v-model="vIS_MANUAL_RECORDING"
                                :label="true"
                                @change="handleManualRecordingChange"
                                disabled
                                >Habilitado</el-radio
                            >
                            <!-- <el-radio
                                v-model="vIS_MANUAL_RECORDING"
                                :label="false"
                                @change="handleManualRecordingChange"
                                >Desabilitado</el-radio
                            > -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 text-left px-4">
                            <el-slider
                                v-model="vEVENT_VIDEO_DURATION_IN_SECONDS"
                                @change="handleEventVideoDurationSliderChange"
                                :min="10"
                                :max="60"
                                :marks="eventVideoDurationMarks"
                            >
                            </el-slider>
                        </div>
                    </div>
                    <br />
                </div>

                <br />
                <!--  -->
                <div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <h5>Exclusão automática de eventos</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <el-radio
                                v-model="vIS_ALERTS_AUTO_DELETION_ACTIVE"
                                :label="true"
                                >Habilitado</el-radio
                            >
                            <el-radio
                                v-model="vIS_ALERTS_AUTO_DELETION_ACTIVE"
                                :label="false"
                                @change="handleAlertsAutoDeletionChange"
                                >Desabilitado</el-radio
                            >
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-12 text-left">
                            Apaga evento do painel automaticamente em:
                        </div>
                        <div class="col-12 text-left">
                            <el-input-number
                                v-model="vDURATION_CLEAR_ALERTS_IN_MINUTES"
                                controls-position="right"
                                @change="handleDurationClearAlertsChange"
                                :min="1"
                                :max="120"
                            ></el-input-number>
                            minutos
                        </div>
                    </div>
                    <br />
                </div>
                <br />
                <!--  -->
                <div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <h5>Tempo de reenvio para eventos</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            Reenviar evento reincidente após:
                        </div>
                        <div class="col-12 text-left">
                            <el-input-number
                                v-model="vEVENT_DEBOUNCE_PERIOD_IN_SECONDS"
                                controls-position="right"
                                @change="handleEventDebouncePeriodChange"
                                :min="1"
                                :max="60"
                            ></el-input-number>
                            minutos
                        </div>
                    </div>
                    <br />
                </div>

                <!--  -->
                <div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <h5>Upload</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <el-checkbox
                                v-model="vUPLOAD_ON_WIFI_ONLY"
                                @change="handleUploadOnWifiOnlyChange"
                                >Enviar mídias somente no Wi-Fi</el-checkbox
                            >
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";

export default {
    data() {
        return {
            vIS_MANUAL_RECORDING: true,
            vEVENT_VIDEO_DURATION_IN_SECONDS: 10,
            vIS_ALERTS_AUTO_DELETION_ACTIVE: false,
            vDURATION_CLEAR_ALERTS_IN_MINUTES: 120,
            vEVENT_DEBOUNCE_PERIOD_IN_SECONDS: 60,
            vUPLOAD_ON_WIFI_ONLY: true,
            eventVideoDurationMarks: {
                10: "10s",
                30: "30s",
                60: "60s",
            },
        };
    },
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        refreshUserInternalsSettings() {
            if (this.user.data.internals.settings) {
                let settings = this.user.data.internals.settings;
                if (settings.solo) {
                    let settingsSolo = settings.solo;

                    //
                    this.vIS_MANUAL_RECORDING =
                        settingsSolo.vIS_MANUAL_RECORDING !== null
                            ? (this.vIS_MANUAL_RECORDING =
                                  settingsSolo.vIS_MANUAL_RECORDING)
                            : (this.vIS_MANUAL_RECORDING = true);

                    //
                    this.vEVENT_VIDEO_DURATION_IN_SECONDS =
                        settingsSolo.events.vEVENT_VIDEO_DURATION_IN_SECONDS !==
                        null
                            ? (this.vEVENT_VIDEO_DURATION_IN_SECONDS =
                                  settingsSolo.events.vEVENT_VIDEO_DURATION_IN_SECONDS)
                            : (this.vEVENT_VIDEO_DURATION_IN_SECONDS = 10);

                    //
                    this.vIS_ALERTS_AUTO_DELETION_ACTIVE =
                        settingsSolo.vIS_ALERTS_AUTO_DELETION_ACTIVE !== null
                            ? (this.vIS_ALERTS_AUTO_DELETION_ACTIVE =
                                  settingsSolo.vIS_ALERTS_AUTO_DELETION_ACTIVE)
                            : (this.vIS_ALERTS_AUTO_DELETION_ACTIVE = false);

                    //
                    this.vDURATION_CLEAR_ALERTS_IN_MINUTES =
                        settingsSolo.vDURATION_CLEAR_ALERTS_IN_MINUTES !== null
                            ? (this.vDURATION_CLEAR_ALERTS_IN_MINUTES =
                                  settingsSolo.vDURATION_CLEAR_ALERTS_IN_MINUTES)
                            : (this.vDURATION_CLEAR_ALERTS_IN_MINUTES = 120);

                    //
                    this.vEVENT_DEBOUNCE_PERIOD_IN_SECONDS =
                        settingsSolo.events
                            .vEVENT_DEBOUNCE_PERIOD_IN_SECONDS !== null
                            ? (this.vEVENT_DEBOUNCE_PERIOD_IN_SECONDS =
                                  settingsSolo.events.vEVENT_DEBOUNCE_PERIOD_IN_SECONDS)
                            : (this.vEVENT_DEBOUNCE_PERIOD_IN_SECONDS = 60);

                    //
                    this.vUPLOAD_ON_WIFI_ONLY =
                        settingsSolo.vUPLOAD_ON_WIFI_ONLY !== null
                            ? (this.vUPLOAD_ON_WIFI_ONLY =
                                  settingsSolo.vUPLOAD_ON_WIFI_ONLY)
                            : (this.vUPLOAD_ON_WIFI_ONLY = true);
                }
            }
        },
        handleManualRecordingChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/vIS_MANUAL_RECORDING/`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleEventVideoDurationSliderChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/events/vEVENT_VIDEO_DURATION_IN_SECONDS/`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleAlertsAutoDeletionChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/vIS_ALERTS_AUTO_DELETION_ACTIVE/`
                )
                .set(value)
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleDurationClearAlertsChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/vDURATION_CLEAR_ALERTS_IN_MINUTES/`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleEventDebouncePeriodChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/events/vEVENT_DEBOUNCE_PERIOD_IN_SECONDS/`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleUploadOnWifiOnlyChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/solo/vUPLOAD_ON_WIFI_ONLY/`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
    },
    watch: {
        "user.data.internals.settings": function (newVal, oldVal) {
            this.refreshUserInternalsSettings();
        },
    },
    mounted() {
        this.refreshUserInternalsSettings();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>