<template>
    <div class="main-topbar bg-dark-medium-gray">
        <div class="main-topbar-content">
            <div class="row mx-0 my-0 py-0" style="line-height: 50px">
                <div class="align-self-center text-left mx-0 px-0 py-0 my-0">
                    <el-date-picker
                        v-model="_reviewFilterDate"
                        type="daterange"
                        range-separator="a"
                        start-placeholder="-"
                        end-placeholder="-"
                        text-color="red"
                        format="dd/MM/yyyy"
                        value-format="timestamp"
                        class="mx-2"
                        @change="resetSolosReport()"
                    >
                    </el-date-picker>
                </div>
                <div class="align-self-center text-left mx-0 px-0 py-0 my-0">
                    <el-select
                        v-model="_reviewFilterDevice"
                        placeholder="-"
                        multiple
                        collapse-tags
                        class="mx-2"
                        :multiple-limit="10"
                        style="width: 300px"
                        @change="resetSolosReport()"
                    >
                        <el-option
                            v-for="item in optionsDevice"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-select
                        v-model="_reviewFilterEvent"
                        placeholder="-"
                        multiple
                        collapse-tags
                        clearable
                        style="width: 300px"
                        filterable
                        @change="resetSolosReport()"
                    >
                        <el-option
                            v-for="item in optionsEvents"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            <div class="row">
                                <div class="col-10 text-left">
                                    {{ item.label }}
                                </div>
                                <div
                                    class="col-2 text-right"
                                    v-if="
                                        events
                                            .map((v) => v.value)
                                            .indexOf(item.value) !== -1
                                    "
                                >
                                    <i
                                        :style="{
                                            color: events[
                                                events
                                                    .map((v) => v.value)
                                                    .indexOf(item.value)
                                            ].color,
                                        }"
                                        class="fas fa-circle text-right"
                                    ></i>
                                </div>
                            </div>
                        </el-option>
                    </el-select>

                    <button
                        class="btn btn-primary mx-2"
                        @click="handleFetchSolosReport"
                        :disabled="
                            (_reviewFilterDate &&
                            _reviewFilterDevice &&
                            _reviewFilterEvent
                                ? _reviewFilterDate.length !== 2 ||
                                  _reviewFilterDevice.length === 0 ||
                                  _reviewFilterEvent.length === 0
                                    ? true
                                    : false
                                : true) || solosReportStatus === 'waiting'
                        "
                    >
                        {{ $t("SEARCH") }}
                    </button>
                    <button
                        class="btn btn-primary mx-2"
                        v-if="
                            solosReportFilter.device.length > 0 &&
                            solosReportFilter.date.filter((v) => !isNaN(v))
                                .length === 2 &&
                            solosReportStatus === 'success'
                        "
                        @click="
                            setNavForcedLoading(true);
                            handleGenerateSolosReport();
                        "
                        :disabled="
                            (_reviewFilterDate &&
                            _reviewFilterDevice &&
                            _reviewFilterEvent
                                ? _reviewFilterDate.length !== 2 ||
                                  _reviewFilterDevice.length === 0 ||
                                  _reviewFilterEvent.length === 0
                                    ? true
                                    : false
                                : true) || solosReportStatus === 'waiting'
                        "
                    >
                        Gerar relatório
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="
                _reviewFilterDate &&
                _reviewFilterDevice.length > 0 &&
                _reviewFilterEvent.length > 0 &&
                _reviewFilterDate.filter((v) => !isNaN(v)).length === 2 &&
                solosReportStatus === 'success'
            "
        >
            <VueHtml2pdf
                :manual-pagination="true"
                :enable-download="false"
                ref="contentSoloReport"
                :preview-modal="true"
                style="color: #292929 !important"
                @hasDownloaded="setNavForcedLoading(false)"
                :html-to-pdf-options="htmlToPdfOptions"
            >
                <section slot="pdf-content" style="background-color: #f9f9f9">
                    <div
                        class="row"
                        style="height: 58px; background-color: #292929"
                    >
                        <div
                            class="col-12 text-center h-100"
                            style="padding: 10px"
                        >
                            <img
                                style="max-height: 100%; max-width: 100%"
                                :src="require('@/assets/logoAeroguard.png')"
                            />
                        </div>
                    </div>
                    <!--  -->
                    <div class="row my-auto h-100">
                        <div
                            class="col-12 text-center"
                            style="padding: 10px; font-size: 36px"
                        >
                            <strong>RELATÓRIO OPERACIONAL</strong>
                        </div>
                    </div>
                    <div style="width: 100%; padding: 10px 30px 10px 30px">
                        <div
                            class="row"
                            style="background-color: white; height: 227px"
                        >
                            <div
                                class="col-12 text-center"
                                style="padding: 10px"
                            >
                                <strong>RESUMO</strong>
                            </div>
                            <div
                                class="col-12 text-left"
                                style="padding: 10px; margin-left: 20px"
                            >
                                <span>
                                    <strong>Data: </strong>
                                    {{ msToLLL(Date.now()) }}</span
                                ><br />
                                <span>
                                    <strong>Período avaliado: </strong>

                                    {{
                                        msToDDMMYYYY(solosReportFilter.date[0])
                                    }}
                                    à
                                    {{
                                        msToDDMMYYYY(solosReportFilter.date[1])
                                    }}</span
                                ><br />
                                <span>
                                    <strong>Dispositivo: </strong>

                                    {{
                                        solosReportFilter.device
                                            .map(
                                                (v) =>
                                                    solosElements[
                                                        solosElements
                                                            .map((v) => v.vUID)
                                                            .indexOf(v)
                                                    ].internals.vCHILD_NAME
                                            )
                                            .reduce(
                                                (a, b) => a + (" | " + b),
                                                ""
                                            )
                                    }}</span
                                ><br />
                                <span>
                                    <strong>Eventos inclusos: </strong
                                    >{{
                                        soloReportMissionsEvents
                                            .filter((v) => v.count > 0)
                                            .map((v) => v.label)
                                            .reduce(
                                                (a, b) => a + (" | " + b),
                                                ""
                                            )
                                    }}</span
                                ><br />
                                <span>
                                    <strong>Relatório criado por: </strong>
                                    {{
                                        user.data.realInternals.vCHILD_EMAIL
                                    }}</span
                                ><br />
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="text-center">INDICADORES DA OPERAÇÃO</h2>

                    <div style="width: 100%; padding: 10px 30px 10px 30px">
                        <div class="row" style="background-color: white">
                            <div
                                class="
                                    col-3
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                Dispositivos
                            </div>
                            <div
                                class="
                                    col-2
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                {{ $t("Events") }}
                            </div>
                            <div
                                class="
                                    col-3
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                Alertas
                            </div>
                            <div
                                class="
                                    col-4
                                    text-center
                                    my-auto my-0
                                    align-middle
                                "
                                style="
                                    padding: 10px;
                                    font-size: 21px;
                                    font-family: Montserrat;
                                "
                            >
                                Sem comunicação (72h ou mais)
                            </div>
                            <div
                                class="col-3 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                "
                            >
                                {{
                                    soloReportMissionsKpi.filter(
                                        (v) => v.label === "Dispositivos"
                                    )[0].value
                                }}
                            </div>
                            <div
                                class="col-2 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                    color: #00c689;
                                "
                            >
                                {{
                                    soloReportMissionsKpi.filter(
                                        (v) => v.label === "Eventos"
                                    )[0].value
                                }}
                            </div>
                            <div
                                class="col-3 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                    color: #ef5350;
                                "
                            >
                                {{
                                    soloReportMissionsKpi.filter(
                                        (v) => v.label === "Alertas"
                                    )[0].value
                                }}
                            </div>
                            <div
                                class="col-4 text-center"
                                style="
                                    padding: 10px;
                                    font-size: 46px;
                                    font-family: Montserrat;
                                "
                            >
                                {{
                                    soloReportMissionsKpi.filter(
                                        (v) =>
                                            v.label ===
                                            "Sem comunicação (72h ou mais)"
                                    )[0].value
                                }}
                            </div>
                        </div>
                    </div>

                    <div
                        style="
                            width: 100%;
                            padding: 0px 20px 20px 10px;
                            height: 520px;
                        "
                    >
                        <div class="row h-100" style="background-color: white">
                            <div
                                class="col-6 text-center"
                                style="padding: 10px"
                            >
                                <div
                                    class="chart-container"
                                    style="height: 100%"
                                >
                                    <app-solo-report-events
                                        :datacollection="
                                            soloReportEventsCollection
                                        "
                                        :showAllTooltips="true"
                                    ></app-solo-report-events>
                                </div>
                            </div>
                            <div
                                class="col-6 text-center"
                                style="padding: 10px"
                            >
                                <div
                                    class="chart-container"
                                    style="height: 100%"
                                >
                                    <app-solo-report-tagged-important-by-device
                                        :datacollection="
                                            soloReportTaggedImportantByDeviceCollection
                                        "
                                        :showAllTooltips="true"
                                    ></app-solo-report-tagged-important-by-device>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 class="text-center">REGISTROS E OCORRÊNCIAS</h2>
                    <div
                        v-for="(e, i) in soloReportMissionsEventsDetails"
                        :key="i"
                        :class="{
                            'html2pdf__page-break': i % 2 !== 0,
                        }"
                        style="
                            height: 500px;
                            padding: 10px;
                            background-color: #f9f9f9;
                        "
                    >
                        <div style="background-color: white">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <h5>EVENTO REGISTRADO</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-12 text-left"
                                    style="padding: 10px; margin-left: 20px"
                                >
                                    <span>
                                        <strong>Tipo de evento: </strong>
                                        {{
                                            events[
                                                events
                                                    .map((v) => v.value)
                                                    .indexOf(
                                                        e.event.vEVENT_TYPE
                                                    )
                                            ].label
                                        }}</span
                                    ><br />
                                    <span>
                                        <strong>Data: </strong>
                                        {{ msToLLL(e.vTIMESTAMP) }}</span
                                    ><br />
                                    <span>
                                        <strong>Registrado por: </strong>
                                        {{
                                            solosElements[
                                                solosElements
                                                    .map((v) => v.vUID)
                                                    .indexOf(e.vCHILD_UID)
                                            ].internals.vCHILD_NAME
                                        }}</span
                                    ><br />
                                    <span v-if="e.event.vEVENT_MESSAGE">
                                        <strong>Mensagem do operador: </strong>
                                        {{ e.event.vEVENT_MESSAGE }}</span
                                    ><br />
                                </div>
                            </div>

                            <div class="row" style="height: 270px">
                                <div
                                    class="col-6 text-right"
                                    style="padding-right: 10px"
                                >
                                    <img
                                        style="
                                            object-fit: contain;
                                            height: 270px;
                                            width: 270px;
                                            border-radius: 10px;
                                        "
                                        :src="
                                            require('@/assets/smartcam-logo-mission.png')
                                        "
                                    />
                                </div>
                                <div
                                    class="col-6 text-left"
                                    style="padding-left: 10px"
                                >
                                    <img
                                        :src="e.vMAP_URL"
                                        alt=""
                                        style="
                                            max-height: 270px;
                                            max-width: 100%;
                                            border-radius: 10px;
                                        "
                                    />
                                </div>
                            </div>

                            <div
                                class="row"
                                v-if="
                                    missionsUrls
                                        .map((v) => v.vSERVICE_KEY)
                                        .indexOf(e.vSERVICE_KEY) !== -1
                                "
                            >
                                <div
                                    class="col-12 text-center"
                                    v-if="
                                        missionsUrls[
                                            missionsUrls
                                                .map((v) => v.vSERVICE_KEY)
                                                .indexOf(e.vSERVICE_KEY)
                                        ].vMISSION_URL &&
                                        missionsUrls[
                                            missionsUrls
                                                .map((v) => v.vSERVICE_KEY)
                                                .indexOf(e.vSERVICE_KEY)
                                        ].vMISSION_URL !== 'none'
                                    "
                                >
                                    <br />
                                    <span>Gravação do evento: </span>
                                    <a
                                        style="
                                            text-decoration: underline;
                                            color: #03a9f4 !important;
                                        "
                                        :href="
                                            missionsUrls[
                                                missionsUrls
                                                    .map((v) => v.vSERVICE_KEY)
                                                    .indexOf(e.vSERVICE_KEY)
                                            ].vMISSION_URL
                                        "
                                        target="_blank"
                                        >{{
                                            missionsUrls[
                                                missionsUrls
                                                    .map((v) => v.vSERVICE_KEY)
                                                    .indexOf(e.vSERVICE_KEY)
                                            ].vMISSION_URL
                                        }}</a
                                    >
                                    <br />
                                    <br />
                                </div>
                                <div class="col-12 text-center" v-else>
                                    <br />
                                    <span>Gravação do evento indisponível</span>
                                    <br />
                                    <br />
                                </div>
                            </div>

                            <div
                                class="row"
                                style="
                                    background-color: black;
                                    color: white;
                                    margin-top: 50px;
                                "
                                v-if="
                                    i ===
                                    soloReportMissionsEventsDetails.length - 1
                                "
                            >
                                <div class="col-12 text-center">
                                    <p>
                                        Aeroguard Mobile Surveillance Systems -
                                        Todos os direitos reservados
                                    </p>
                                    <p>
                                        <a
                                            style="
                                                text-decoration: underline;
                                                color: white !important;
                                            "
                                            href="www.aeroguard.com.br
                                        "
                                            target="_blank"
                                            >aeroguard.com.br</a
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </VueHtml2pdf>
        </div>
    </div>
</template>


<script>
import appSoloReportEvents from "@/components/solo/containers/report/SoloReportEvents";
import appSoloReportTaggedImportantByDevice from "@/components/solo/containers/report/SoloReportTaggedImportantByDevice";
import VueHtml2pdf from "vue-html2pdf";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import firebase from "firebase";
import momenttz from "moment-timezone";

export default {
    data() {
        return {
            ignoreNoneReviewFilterEvent: false,
            missionsUrls: [],
        };
    },
    computed: {
        ...mapGetters([
            "events",
            "solosReportFilter",
            "solosElements",
            "solosReportStatus",
            "soloReportEventsCollection",
            "soloReportTaggedImportantByDeviceCollection",
            "soloReportMissionsKpi",
            "soloReportMissionsEvents",
            "soloReportMissionsLocations",
            "soloReportMissionsEventsDetails",
            "user",
        ]),
        htmlToPdfOptions() {
            return {
                margin: 0,
                image: {
                    type: "jpeg",
                    quality: 0.98,
                },
                enableLinks: true,
                html2canvas: {
                    scale: 2,
                    useCORS: true,
                },
                jsPDF: {
                    unit: "in",
                    format: "a4",
                    orientation: "portrait",
                },
            };
        },
        _reviewFilterDate: {
            get() {
                return this.solosReportFilter.date;
            },
            set(val) {
                this.setSolosReportFilter({ key: "date", value: val });
            },
        },
        _reviewFilterDevice: {
            get() {
                return this.solosReportFilter.device;
            },
            set(val) {
                this.setSolosReportFilter({ key: "device", value: val });
            },
        },
        _reviewFilterEvent: {
            get() {
                return this.solosReportFilter.event;
            },
            set(val) {
                this.setSolosReportFilter({ key: "event", value: val });
            },
        },
        optionsDevice() {
            let self = this;
            let array = [];

            for (let e of this.solosElements) {
                array.push({
                    value: e.vUID,
                    label: e.internals.vCHILD_NAME.substring(0, 20),
                });
            }
            return [
                {
                    label:
                        this._reviewFilterDevice.filter((v) => !v.includes("$"))
                            .length !== self.solosElements.length
                            ? this.$t("Select_all")
                            : this.$t("Clear_selection"),
                    value:
                        this._reviewFilterDevice.filter((v) => !v.includes("$"))
                            .length !== self.solosElements.length
                            ? "$select_all"
                            : "$deselect_all",
                },
            ].concat(array.sort((a, b) => (a.label > b.label ? 1 : -1)));
        },
        optionsEvents() {
            let self = this;
            let array = [];
            for (let e of this.events.filter((v) => v.consideredBySolo)) {
                array.push(e);
            }
            return [
                {
                    label:
                        this._reviewFilterEvent.filter((v) => !v.includes("$"))
                            .length !==
                        this.events.filter((v) => v.consideredBySolo).length
                            ? this.$t("Select_all")
                            : this.$t("Clear_selection"),
                    value:
                        this._reviewFilterEvent.filter((v) => !v.includes("$"))
                            .length !==
                        this.events.filter((v) => v.consideredBySolo).length
                            ? "$select_all"
                            : "$deselect_all",
                },
            ].concat(array.sort((a, b) => (a.label > b.label ? 1 : -1)));
        },
    },
    methods: {
        ...mapActions([
            "setSolosReportFilter",
            "fetchSolosReport",
            "resetSolosReport",
            "setNavForcedLoading",
        ]),
        handleFetchSolosReport() {
            this.$message({
                message: this.$t("Message_analysing_period_data"),
                type: "info",
                showClose: true,
                offset: 60,
            });
            this.fetchSolosReport();
        },
        handleGenerateSolosReport() {
            this.handleShareLogServices(this.soloReportMissionsEventsDetails);
        },
        msToDDMMYYYY(ms) {
            return moment(ms).format("DD/MM/YYYY");
        },
        msToDDMMYYYYHHmmss(ms) {
            return moment(ms).format("DD/MM/YYYY HH:mm:ss");
        },
        msToLLL(ms) {
            return moment(ms).locale("pt-br").format("LLL");
        },
        handleShareLogServices(details) {
            let self = this;
            let promises = [];

            for (let value of this.soloReportMissionsEventsDetails) {
                promises.push(
                    new Promise(function (resolve, reject) {
                        let element =
                            self.solosElements[
                                self.solosElements
                                    .map((v) => v.vUID)
                                    .indexOf(value.vCHILD_UID)
                            ];

                        firebase
                            .database()
                            .ref(`aegisv2/tokens/`)
                            .push({
                                vCHILD_TYPE: element.internals.vCHILD_TYPE,
                                vCHILD_UID: element.vUID,
                                vCREATED_AT: Date.now(),
                                vEXPIRES_IN: "indefinite",
                                vLABEL_TEXT: "vLABEL_TEXT",
                                vLOG_SERVICE_KEY: value.vSERVICE_KEY,
                                vTEAM_UID: element.internals.vTEAM_UID,
                                vTOKEN_TYPE: "share-mission",
                            })
                            .then(function (data) {
                                firebase
                                    .database()
                                    .ref(`aegisv2/tokens/${data.key}`)
                                    .update({
                                        vTOKEN_KEY: data.key,
                                        payload: {
                                            userId:
                                                Date.now() +
                                                "-" +
                                                Math.floor(
                                                    Math.random() * 1000000
                                                ),
                                            additionalClaims: {
                                                vTOKEN_KEY: data.key,
                                                vCHILD_TYPE: "viewer",
                                                vCREATOR_CHILD_UID:
                                                    element.vUID,
                                                vCREATOR_TEAM_UID:
                                                    self.user.data.uid,
                                                vCREATOR_CHILD_TYPE:
                                                    element.internals
                                                        .vCHILD_TYPE,
                                                vCREATOR_CHILD_NAME:
                                                    element.internals
                                                        .vCHILD_NAME,
                                                vCREATOR_MISSION_NAME:
                                                    self.events[
                                                        self.events
                                                            .map((v) => v.value)
                                                            .indexOf(
                                                                value.event
                                                                    .vEVENT_TYPE
                                                            )
                                                    ].label,
                                                vEXPIRES_IN: "indefinite",
                                                vCREATOR_LOG_SERVICE_KEY:
                                                    value.vSERVICE_KEY,
                                                vCREATOR_LOG_SERVICE_TIMESTAMP:
                                                    value.vTIMESTAMP,
                                                vCREATOR_TIMEZONE:
                                                    momenttz.tz.guess(),
                                            },
                                        },
                                    })
                                    .then(function () {
                                        firebase
                                            .functions()
                                            .httpsCallable(
                                                `CLOUD_MANAGE_DYNAMIC_LINK_SHARE_${element.internals.vCHILD_TYPE.toUpperCase()}_MISSION`
                                            )({
                                                userId:
                                                    Date.now() +
                                                    "-" +
                                                    Math.floor(
                                                        Math.random() * 1000000
                                                    ),
                                                additionalClaims: {
                                                    vTOKEN_KEY: data.key,
                                                    vCHILD_TYPE: "viewer",
                                                    vCREATOR_CHILD_UID:
                                                        element.vUID,
                                                    vCREATOR_TEAM_UID:
                                                        self.user.data.uid,
                                                    vCREATOR_CHILD_TYPE:
                                                        element.internals
                                                            .vCHILD_TYPE,
                                                    vCREATOR_CHILD_NAME:
                                                        element.internals
                                                            .vCHILD_NAME,
                                                    vCREATOR_MISSION_NAME:
                                                        self.events[
                                                            self.events
                                                                .map(
                                                                    (v) =>
                                                                        v.value
                                                                )
                                                                .indexOf(
                                                                    value.event
                                                                        .vEVENT_TYPE
                                                                )
                                                        ].label,
                                                    vEXPIRES_IN: "indefinite",
                                                    vCREATOR_LOG_SERVICE_KEY:
                                                        value.vSERVICE_KEY,
                                                    vCREATOR_LOG_SERVICE_TIMESTAMP:
                                                        value.vTIMESTAMP,
                                                    vCREATOR_TIMEZONE:
                                                        momenttz.tz.guess(),
                                                },
                                            })
                                            .then(function (result) {
                                                if (!result.data.error) {
                                                    resolve({
                                                        vSERVICE_KEY:
                                                            value.vSERVICE_KEY,
                                                        vMISSION_URL:
                                                            result.data.res,
                                                    });
                                                } else {
                                                    reject(result.data.error);
                                                }
                                            })
                                            .catch((e) => {
                                                reject(e);
                                            });
                                    })
                                    .catch((e) => {
                                        reject(e);
                                    });
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    })
                );
            }
            Promise.all(promises)
                .then(function (values) {
                    self.missionsUrls = values;
                    self.$refs.contentSoloReport.generatePdf();
                })
                .catch(function (e) {
                    console.log(e);
                    self.$message({
                        message: `Não foi possível gerar o relatório`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                    self.setNavForcedLoading(false);
                });
        },
    },
    watch: {
        _reviewFilterEvent: function (newVal, oldVal) {
            if (newVal.includes("$select_all")) {
                this.setSolosReportFilter({
                    key: "event",
                    value: this.events
                        .filter((v) => v.consideredBySolo)
                        .map((v) => v.value),
                });
            } else if (newVal.includes("$deselect_all")) {
                this.setSolosReportFilter({ key: "event", value: [] });
            }
        },
        _reviewFilterDevice: function (newVal, oldVal) {
            if (newVal.includes("$select_all")) {
                this.setSolosReportFilter({
                    key: "device",
                    value: this.solosElements.map((v) => v.vUID),
                });
            } else if (newVal.includes("$deselect_all")) {
                this.setSolosReportFilter({ key: "device", value: [] });
            }
        },
    },
    components: {
        appSoloReportEvents,
        appSoloReportTaggedImportantByDevice,
        VueHtml2pdf,
    },
    mounted() {},
};
</script>


<style scoped>
.main-topbar {
    position: absolute;
    top: 50px;
    left: 0px;
    height: 64px;
    width: 100vw;
}
</style>