import { render, staticRenderFns } from "./SoloTopbar.vue?vue&type=template&id=445a0830&scoped=true&"
import script from "./SoloTopbar.vue?vue&type=script&lang=js&"
export * from "./SoloTopbar.vue?vue&type=script&lang=js&"
import style0 from "./SoloTopbar.vue?vue&type=style&index=0&id=445a0830&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445a0830",
  null
  
)

export default component.exports