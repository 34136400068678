<template>
    <div
        style="
            width: 100%;
            border-radius: 20px;
            padding: 0 20px 20px 20px;
            margin-bottom: 10px;
        "
        class="bg-dark-low-gray"
    >
        <div class="row">
            <div class="col-12 text-right">
                <button @click="handleHideAlert" class="btn btn-link">
                    <i
                        class="fas fa-times"
                        style="font-size: 1rem !important"
                    ></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <el-checkbox v-model="_checked"></el-checkbox>
            </div>
            <div class="col-10 text-left">
                <strong>{{
                    events.map((v) => v.value).indexOf(alert.vEVENT_TYPE) !== -1
                        ? events[
                              events
                                  .map((v) => v.value)
                                  .indexOf(alert.vEVENT_TYPE)
                          ].label
                        : $t("Unknown_event")
                }}</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-left">
                <span>{{ formatDate(alert.vTIMESTAMP) }}</span>
            </div>
            <div class="col-12 text-left">
                <span>Dispositivo: </span>
                <button
                    class="btn btn-link"
                    style="text-decoration: underline !important"
                    @click="handleAlertElementDeviceClick()"
                >
                    {{
                        smartElements[
                            smartElements
                                .map((v) => v.vUID)
                                .indexOf(alert.vCHILD_UID)
                        ].internals.vCHILD_NAME
                    }}
                </button>
            </div>
            <br />
            <div class="col-12 text-left">
                <span>Gravação: </span>
                <button
                    class="btn btn-link"
                    style="text-decoration: underline !important"
                    @click="handleSeeAlertClick()"
                >
                    Ver
                </button>
            </div>
            <br />
            <div
                class="col-12 text-left"
                v-show="
                    alert.vEVENT_LOCATION_LATITUDE &&
                    alert.vEVENT_LOCATION_LONGITUDE
                "
            >
                <span>Localização: </span>
                <button
                    class="btn btn-link"
                    style="text-decoration: underline !important"
                    @click="handleAlertElementLocationClick()"
                >
                    Ver no mapa
                </button>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase";
import moment from "moment";
import momenttz from "moment-timezone";
import LoginVue from "../../views/Login.vue";

export default {
    data() {
        return {};
    },
    props: ["alert"],
    computed: {
        ...mapGetters([
            "user",
            "smartElements",
            "events",
            "checkedEventsNotifications",
            "smartElements",
        ]),
        _checked: {
            get() {
                return this.checkedEventsNotifications.includes(
                    this.alert.vEVENT_KEY
                );
            },
            set(val) {
                this.setCheckedEventNotificationByKey(this.alert.vEVENT_KEY);
            },
        },
    },
    methods: {
        ...mapActions([
            "removeEventNotificationByKey",
            "setCheckedEventNotificationByKey",
            "setNavMainNav",
            "setNavTopbar",
            "setDroneActive",
            "setEventNotificationFocusedLocation",
            "setMapDragged",
            "setSoloActive",
            "setNavForcedLoading",
        ]),
        formatDate(ms) {
            return (
                moment(ms).format("DD/MM/YYYY") +
                " - " +
                moment(ms).format("HH:mm:ss")
            );
        },
        handleHideAlert() {
            this.removeEventNotificationByKey(this.alert);
        },
        handleAlertElementDeviceClick() {
            let type =
                this.smartElements[
                    this.smartElements
                        .map((v) => v.vUID)
                        .indexOf(this.alert.vCHILD_UID)
                ].internals.vCHILD_TYPE;

            this.setNavMainNav(type);
            this.setNavTopbar(1);

            if (type === "drone") {
                this.setSoloActive(null);
                this.setDroneActive(this.alert.vCHILD_UID);
            } else if (type === "solo") {
                this.setDroneActive(null);
                this.setSoloActive(this.alert.vCHILD_UID);
            }
            this.$emit("toggle-alert-drawer", false);
        },
        handleAlertElementLocationClick() {
            let type =
                this.smartElements[
                    this.smartElements
                        .map((v) => v.vUID)
                        .indexOf(this.alert.vCHILD_UID)
                ].internals.vCHILD_TYPE;

            this.setNavMainNav(type);
            this.setNavTopbar(1);
            this.setEventNotificationFocusedLocation(null);
            this.setEventNotificationFocusedLocation(this.alert);
            this.setMapDragged(true);
            this.$emit("toggle-alert-drawer", false);
        },
        handleSeeAlertClick() {
            let self = this;
            firebase
                .database()
                .ref(
                    `aegisv2/users/${this.alert.vCHILD_UID}/logServices/${this.alert.vLOG_SERVICE_KEY}/vVIDEO_URL`
                )
                .on("value", function (data) {
                    if (!data.exists() || data.val() === "none") {
                        self.$message({
                            message: `Nenhuma mídia associada à esse evento, tente novamente mais tarde.`,
                            type: "error",
                            showClose: true,
                            offset: 60,
                        });
                    } else {
                        self.handleShareLogService();
                    }
                });
        },
        handleShareLogService() {
            let self = this;
            let element =
                this.smartElements[
                    this.smartElements
                        .map((v) => v.vUID)
                        .indexOf(this.alert.vCHILD_UID)
                ];
            this.setNavForcedLoading(true);

            firebase
                .database()
                .ref(`aegisv2/tokens/`)
                .push({
                    vCHILD_TYPE: element.internals.vCHILD_TYPE,
                    vCHILD_UID: element.vUID,
                    vCREATED_AT: Date.now(),
                    vEXPIRES_IN: Date.now() + 60 * 60 * 1000,
                    vLABEL_TEXT: "vLABEL_TEXT",
                    vLOG_SERVICE_KEY: self.alert.vLOG_SERVICE_KEY,
                    vTEAM_UID: element.internals.vTEAM_UID,
                    vTOKEN_TYPE: "share-mission",
                })
                .then(function (data) {
                    firebase
                        .database()
                        .ref(`aegisv2/tokens/${data.key}`)
                        .update({
                            vTOKEN_KEY: data.key,
                            payload: {
                                userId:
                                    Date.now() +
                                    "-" +
                                    Math.floor(Math.random() * 1000000),
                                additionalClaims: {
                                    vTOKEN_KEY: data.key,
                                    vCHILD_TYPE: "viewer",
                                    vCREATOR_CHILD_UID: element.vUID,
                                    vCREATOR_TEAM_UID: self.user.data.uid,
                                    vCREATOR_CHILD_TYPE:
                                        element.internals.vCHILD_TYPE,
                                    vCREATOR_CHILD_NAME:
                                        element.internals.vCHILD_NAME,
                                    vCREATOR_MISSION_NAME: "-",
                                    vEXPIRES_IN: Date.now() + 60 * 60 * 1000,
                                    vCREATOR_LOG_SERVICE_KEY:
                                        self.alert.vLOG_SERVICE_KEY,
                                    vCREATOR_LOG_SERVICE_TIMESTAMP:
                                        self.alert.vTIMESTAMP,
                                    vCREATOR_TIMEZONE: momenttz.tz.guess(),
                                },
                            },
                        })
                        .then(function () {
                            firebase
                                .functions()
                                .httpsCallable(
                                    `CLOUD_MANAGE_DYNAMIC_LINK_SHARE_${element.internals.vCHILD_TYPE.toUpperCase()}_MISSION`
                                )({
                                    userId:
                                        Date.now() +
                                        "-" +
                                        Math.floor(Math.random() * 1000000),
                                    additionalClaims: {
                                        vTOKEN_KEY: data.key,
                                        vCHILD_TYPE: "viewer",
                                        vCREATOR_CHILD_UID: element.vUID,
                                        vCREATOR_TEAM_UID: self.user.data.uid,
                                        vCREATOR_CHILD_TYPE:
                                            element.internals.vCHILD_TYPE,
                                        vCREATOR_CHILD_NAME:
                                            element.internals.vCHILD_NAME,
                                        vCREATOR_MISSION_NAME: "-",
                                        vEXPIRES_IN:
                                            Date.now() + 60 * 60 * 1000,
                                        vCREATOR_LOG_SERVICE_KEY:
                                            self.alert.vLOG_SERVICE_KEY,
                                        vCREATOR_LOG_SERVICE_TIMESTAMP:
                                            self.alert.vTIMESTAMP,
                                        vCREATOR_TIMEZONE: momenttz.tz.guess(),
                                    },
                                })
                                .then(function (result) {
                                    if (!result.data.error) {
                                        window.open(result.data.res);
                                    } else {
                                        self.$message({
                                            message: `Não foi possível obter o link para visualizar o evento`,
                                            type: "error",
                                            showClose: true,
                                            offset: 60,
                                        });
                                    }
                                })
                                .catch((e) => {
                                    self.$message({
                                        message: `Não foi possível obter o link para visualizar o evento`,
                                        type: "error",
                                        showClose: true,
                                        offset: 60,
                                    });
                                })
                                .finally(() => {
                                    self.setNavForcedLoading(false);
                                });
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível obter o link para visualizar o evento`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                            self.setNavForcedLoading(false);
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível obter o link para visualizar o evento`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                    self.setNavForcedLoading(false);
                });
        },
    },
};
</script>