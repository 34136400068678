<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem"
                            >Drone - {{ $t("Settings") }}</span
                        >
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem"
                            >Use essa tela para configurar parâmetros de
                            operação de drones.</span
                        >
                    </div>
                </div>
                <br />
                <br />

                <!--  -->
                <div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <h5>Comandos enviados pela central</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <el-radio
                                v-model="controllableByDashboard"
                                :label="true"
                                @change="handleControllableByDashboardChange"
                                >Habilitado</el-radio
                            >
                            <el-radio
                                v-model="controllableByDashboard"
                                :label="false"
                                @change="handleControllableByDashboardChange"
                                >Desabilitado</el-radio
                            >
                        </div>
                    </div>
                    <br />
                </div>

                <!--  -->
                <div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <h5>Joystick Virtual</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <el-radio
                                v-model="virtuaJoystick"
                                :label="true"
                                @change="handleVirtuaJoystickChange"
                                >Habilitado</el-radio
                            >
                            <el-radio
                                v-model="virtuaJoystick"
                                :label="false"
                                @change="handleVirtuaJoystickChange"
                                >Desabilitado</el-radio
                            >
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>

        <br />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";

export default {
    data() {
        return {
            controllableByDashboard: false,
            virtuaJoystick: false,
        };
    },
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        refreshUserInternalsSettings() {
            if (this.user.data.internals.settings) {
                let settings = this.user.data.internals.settings;
                if (settings.drone) {
                    let settingsDrone = settings.drone;

                    if (settingsDrone.vIS_CONTROLLABLE_BY_DASHBOARD !== null) {
                        this.controllableByDashboard =
                            settingsDrone.vIS_CONTROLLABLE_BY_DASHBOARD;
                    } else {
                        this.controllableByDashboard = true;
                    }

                    if (settingsDrone.vIS_VIRTUAL_JOYSTICK_ENABLED !== null) {
                        this.virtuaJoystick =
                            settingsDrone.vIS_VIRTUAL_JOYSTICK_ENABLED;
                    } else {
                        this.virtuaJoystick = true;
                    }
                }
            }
        },
        handleControllableByDashboardChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/drone/vIS_CONTROLLABLE_BY_DASHBOARD/`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
        handleVirtuaJoystickChange(value) {
            let self = this;
            firebase
                .database()
                .ref(
                    `/aegisv2/users/${this.user.data.uid}/internals/settings/drone/vIS_VIRTUAL_JOYSTICK_ENABLED/`
                )
                .set(value)
                .finally(() => {
                    self.refreshUserInternalsSettings();
                });
        },
    },
    watch: {
        "user.data.internals.settings": function (newVal, oldVal) {
            this.refreshUserInternalsSettings();
        },
    },
    mounted() {
        this.refreshUserInternalsSettings();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>