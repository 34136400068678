var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-solo-report bg-dark-high-gray"},[_c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"row mx-0 px-0 py-0",staticStyle:{"height":"50%"}},[_c('div',{staticClass:"col-4 h-100 px-1 py-1"},[_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"chart-container bg-dark-medium-gray",staticStyle:{"height":"100%"}},[_c('app-solo-report-events',{attrs:{"datacollection":_vm.soloReportEventsCollection,"showAllTooltips":false}})],1)])]),_c('div',{staticClass:"col-8 h-100 px-1 py-1"},[_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"bg-dark-medium-gray",staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"row h-100",staticStyle:{"padding":"0","margin":"0"}},_vm._l((_vm.soloReportMissionsKpi),function(kpi,index){return _c('div',{key:index,staticClass:"col my-auto align-middle"},[_vm._v(" "+_vm._s(kpi.label)+": "),_c('h1',{class:{
                                        'color-light-red':
                                            kpi.color === '#EF9A9A',
                                        'color-light-green':
                                            kpi.color === '#A5D6A7',
                                    }},[_vm._v(" "+_vm._s(kpi.value)+" ")])])}),0)])])])]),_c('div',{staticClass:"row mx-0 px-0 py-0",staticStyle:{"height":"50%"}},[_c('div',{staticClass:"col-8 h-100 px-1 py-1"},[_c('el-carousel',{staticStyle:{"height":"100%","width":"100%"},attrs:{"height":"100%","direction":"horizontal","autoplay":false,"interval":null,"trigger":"click","loop":false}},[_c('el-carousel-item',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"chart-container bg-dark-medium-gray",staticStyle:{"height":"100%"}},[_c('app-solo-report-tagged-important-by-device',{staticStyle:{"height":"100%"},attrs:{"datacollection":_vm.soloReportTaggedImportantByDeviceCollection,"showAllTooltips":false}})],1)]),_vm._l((Object.values(
                            _vm.soloReportAdditionalGraphs
                        )),function(a,index){return _c('el-carousel-item',{key:index,staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"chart-container bg-dark-medium-gray",staticStyle:{"height":"100%"}},[_c('app-solo-report-events-by-device',{staticStyle:{"height":"100%"},attrs:{"datacollection":a,"showAllTooltips":false}})],1)])})],2)],1),_c('div',{staticClass:"col-4 h-100 px-1 py-1"},[_c('div',{staticStyle:{"height":"100%","width":"100%"}},[_c('div',{staticClass:"bg-dark-medium-gray",staticStyle:{"height":"100%","outline":"none"}},[_c('gmap-map',{ref:"mapRefSoloReportHeatmap",staticStyle:{"width":"100%","height":"100%","padding":"0","margin":"0"},attrs:{"options":{
                                tilt: 0,
                            },"center":{ lat: -23.508, lng: -46.587 },"zoom":4}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }