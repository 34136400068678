var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-drone-stream-telemetry",staticStyle:{"position":"absolute","bottom":"5px","left":"5px","width":"200px","background-color":"#2d2d2d","opacity":"0.85","padding-top":"5px","font-size":"0.8rem","border-radius":"10px"}},[_c('div',{staticClass:"row mx-0 px-0"},[_c('div',{staticClass:"col-2 mx-0 px-0",staticStyle:{"color":"#ffe082"}}),_c('div',{staticClass:"col-8 mx-0 px-0 text-center",staticStyle:{"color":"#ffe082"}},[_vm._v(" "+_vm._s(_vm.drone.telemetry.vMISSION_NAME || "-")+" ")]),_c('div',{staticClass:"col-2 mx-0 px-0",class:{
                'color-green':
                    _vm.isConnected &&
                    !_vm.drone.flight.state.vIS_EXECUTING_MISSION,
                'color-yellow':
                    _vm.isConnected && _vm.drone.flight.state.vIS_EXECUTING_MISSION,
                'color-red': !_vm.isConnected,
            }},[_c('i',{staticClass:"fas fa-circle",class:{
                    pulse: _vm.isConnected,
                }})])]),_c('div',{staticClass:"row mx-0 px-0"},[_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"#a7a7a7"}},[_vm._v("Altura")]),_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"#a7a7a7"}},[_vm._v("Velocidade")]),_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"#a7a7a7"}},[_vm._v("Satélites")]),_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.isConnected ? Math.floor(_vm.drone.telemetry.vALTITUDE) + " m" : "-")+" ")]),_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.isConnected ? Math.floor( Math.pow( Math.pow(_vm.drone.telemetry.vVELOCITY_X, 2) + Math.pow(_vm.drone.telemetry.vVELOCITY_Y, 2), 0.5 ) * 3.6 ) + " km/h" : "-")+" ")]),_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.isConnected ? _vm.drone.telemetry.vSATELLITES_COUNT : "-")+" ")])]),_c('div',{staticClass:"row mx-0 px-0"},[_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"#a7a7a7"}},[_vm._v("Bateria")]),_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"#a7a7a7"}},[_vm._v("Vento")]),_vm._m(0),_c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.isConnected ? Math.floor( _vm.drone.telemetry .vBATTERY_CHARGE_REMAINING_IN_PERCENT ) + " %" : "-")+" ")]),_c('div',{staticClass:"col-4 mx-0 px-0",class:{
                'color-green':
                    _vm.drone.telemetry.vFLIGHT_WIND_WARNING === 'LEVEL_0' &&
                    _vm.isConnected,
                'color-yellow':
                    _vm.drone.telemetry.vFLIGHT_WIND_WARNING === 'LEVEL_1' &&
                    _vm.isConnected,
                'color-red':
                    _vm.drone.telemetry.vFLIGHT_WIND_WARNING === 'LEVEL_2' &&
                    _vm.isConnected,
                'color-white': !_vm.isConnected,
            }},[_vm._v(" "+_vm._s(_vm.isConnected ? _vm.drone.telemetry.vFLIGHT_WIND_WARNING === "LEVEL_0" ? "Baixo" : _vm.drone.telemetry.vFLIGHT_WIND_WARNING === "LEVEL_1" ? "Médio" : _vm.drone.telemetry.vFLIGHT_WIND_WARNING === "LEVEL_2" ? "Ventania" : "-" : "-")+" ")]),_c('div',{staticClass:"col-4 mx-0 px-0",class:{
                'color-red':
                    _vm.drone.stream.vWEBRTC_BITRATE < 500 && _vm.isConnected,
                'color-yellow':
                    _vm.drone.stream.vWEBRTC_BITRATE >= 500 &&
                    _vm.drone.stream.vWEBRTC_BITRATE < 1300 &&
                    _vm.isConnected,
                'color-green':
                    _vm.drone.stream.vWEBRTC_BITRATE >= 1300 && _vm.isConnected,
                'color-white': !_vm.isConnected,
            }},[_vm._v(" "+_vm._s(_vm.isConnected ? (_vm.drone.stream.vWEBRTC_BITRATE / 1000).toFixed(2) + "Mbps" : "-")+" ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-4 mx-0 px-0",staticStyle:{"color":"#a7a7a7"}},[_c('i',{staticClass:"fas fa-cloud"})])}]

export { render, staticRenderFns }