<template>
    <div class="main-drone-report-battery-states">
        <app-single-bar-chart
            :chart-data="datacollection"
            :style="style"
            :title="'Baterias'"
            :tooltips="datacollection"
            :show-all-tooltips="showAllTooltips"
        ></app-single-bar-chart>
    </div>
</template>

<script>
import appSingleBarChart from "@/components/charts/SingleBarChartCustomTooltips";

export default {
    data() {
        return {
            style: { height: "100%" },
        };
    },
    components: {
        appSingleBarChart,
    },
    methods: {},
    props: ["datacollection", "showAllTooltips"],
    mounted() {},
};
</script>

<style>
.main-drone-report-battery-states {
    height: 100%;
    position: relative;
}
</style>