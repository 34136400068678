var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('gmap-map',{ref:"mapRefDroneSelectedMission",staticStyle:{"width":"100%","height":"100%","padding":"0","margin":"0"},attrs:{"zoom":4,"options":{
            disableDoubleClickZoom: true,
            disableDefaultUI: true,
            draggable: false,
            tilt: 0,
        },"center":_vm.center}},[_vm._l((_vm.mission.markers),function(dm,index){return _c('gmap-marker',{key:'drone-mission-selected-marker-' + index,attrs:{"position":dm.position,"icon":{
                    path: dm.icon.path,
                    rotation: dm.icon.rotation + 180,
                    fillColor: '#FFE082',
                    fillOpacity: dm.icon.fillOpacity,
                    anchor: dm.icon.anchor,
                    labelOrigin: dm.icon.labelOrigin,
                    strokeWeight: dm.icon.strokeWeight,
                    scale: dm.icon.scale,
                },"clickable":true,"label":{
                    color: '#000000',
                    fontSize: '16px',
                    text: (index + 1).toString(),
                }}})}),[_c('gmap-polyline',{attrs:{"path":_vm.polylines,"options":{
                    strokeColor: '#A1A0A0',
                }},on:{"update:path":function($event){_vm.polylines=$event}}})],_vm._l((_vm.altitudeInfoWindows),function(dm,index){return _c('gmap-info-window',{key:'drone-mission-selected-altitude-info-window-' + index,attrs:{"position":dm.position,"options":dm.infoOptions,"opened":true,"clickable":false}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }