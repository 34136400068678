<template>
    <div class="main-drone-report bg-dark-high-gray">
        <div style="height: 100%">
            <div class="row mx-0 px-0 py-0" style="height: 25%">
                <div class="col-3 h-100 px-1 py-1">
                    <div style="height: 100%; width: 100%">
                        <div
                            class="chart-container bg-dark-medium-gray"
                            style="height: 100%"
                        >
                            <app-drone-report-battery-states
                                :datacollection="
                                    droneReportBatteryStatesCollection
                                "
                                :show-all-tooltips="false"
                            ></app-drone-report-battery-states>
                        </div>
                    </div>
                </div>
                <div class="col-6 h-100 px-1 py-1">
                    <div style="height: 100%; width: 100%">
                        <div
                            class="chart-container bg-dark-medium-gray"
                            style="height: 100%"
                        >
                            <app-drone-report-missions-executed
                                :datacollection="
                                    droneReportMissionsExecutedCollection
                                "
                                :show-all-tooltips="false"
                            ></app-drone-report-missions-executed>
                        </div>
                    </div>
                </div>
                <div class="col-3 h-100 px-1 py-1">
                    <div style="height: 100%; width: 100%">
                        <div
                            style="height: 100%; outline: none"
                            class="bg-dark-medium-gray"
                        >
                            <gmap-map
                                :options="{
                                    tilt: 0,
                                }"
                                :center="{ lat: -23.508, lng: -46.587 }"
                                :zoom="4"
                                ref="mapRefDroneReportHeatmap"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    padding: 0;
                                    margin: 0;
                                "
                            >
                            </gmap-map>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 px-0 py-0" style="height: 75%">
                <div class="col-3 h-100 mx-0 px-0" style="height: 35%">
                    <div class="row mx-0 px-0 mx-0 py-0" style="height: 50%">
                        <div class="col-12 h-100 px-1 py-1">
                            <div
                                class="chart-container bg-dark-medium-gray"
                                style="height: 100%"
                            >
                                <app-drone-report-events
                                    :datacollection="
                                        droneReportEventsCollection
                                    "
                                ></app-drone-report-events>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 px-0 mx-0 py-0" style="height: 50%">
                        <div class="col-12 h-100 px-1 py-1">
                            <div
                                class="chart-container bg-dark-medium-gray"
                                style="height: 100%"
                            >
                                <app-drone-report-artificial-intelligence-events
                                    :datacollection="
                                        droneReportArtificialIntelligenceEventsCollection
                                    "
                                    :showAllTooltips="false"
                                ></app-drone-report-artificial-intelligence-events>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-7 h-100 px-0 py-0" style="height: 35%">
                    <div class="row mx-0 px-0 py-0" style="height: 35%">
                        <div class="col-12 h-100 px-0 py-0">
                            <div class="row mx-0 px-0 py-0 h-100">
                                <div class="col-4 px-1 py-1 h-100">
                                    <div style="height: 100%; width: 100%">
                                        <div
                                            class="
                                                chart-container
                                                bg-dark-medium-gray
                                            "
                                            style="height: 100%"
                                        >
                                            <app-drone-report-missions-by-operator
                                                :datacollection="
                                                    droneReportMissionsByOperatorCollection
                                                "
                                                :showAllTooltips="false"
                                            ></app-drone-report-missions-by-operator>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 px-1 py-1 h-100">
                                    <div style="height: 100%; width: 100%">
                                        <div
                                            class="
                                                chart-container
                                                bg-dark-medium-gray
                                            "
                                            style="height: 100%"
                                        >
                                            <app-drone-report-scheduled-missions
                                                :datacollection="
                                                    droneReportScheduledMissionsCollection
                                                "
                                                :showAllTooltips="false"
                                            ></app-drone-report-scheduled-missions>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 px-1 py-1 h-100">
                                    <div style="height: 100%; width: 100%">
                                        <div
                                            class="
                                                chart-container
                                                bg-dark-medium-gray
                                            "
                                            style="height: 100%"
                                        >
                                            <app-drone-report-missions-not-executed
                                                :datacollection="
                                                    droneReportMissionsNotExecutedCollection
                                                "
                                                :showAllTooltips="false"
                                            ></app-drone-report-missions-not-executed>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 px-0 py-0" style="height: 35%">
                        <div class="col-12 h-100 px-1 py-1">
                            <div style="height: 100%; width: 100%">
                                <div
                                    class="chart-container bg-dark-medium-gray"
                                    style="height: 100%"
                                >
                                    <app-drone-report-missions-executed-by-hour
                                        :datacollection="
                                            droneReportMissionsExecutedByHourCollection
                                        "
                                    ></app-drone-report-missions-executed-by-hour>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 px-0 py-0" style="height: 30%">
                        <div class="col-12 h-100 px-1 py-1">
                            <div style="height: 100%; width: 100%">
                                <div
                                    class="chart-container bg-dark-medium-gray"
                                    style="height: 100%"
                                >
                                    <app-drone-report-missions-executed-by-day
                                        :datacollection="
                                            droneReportMissionsExecutedByDayCollection
                                        "
                                    ></app-drone-report-missions-executed-by-day>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 h-100 px-1 py-1" style="height: 35%">
                    <div style="height: 100%; width: 100%">
                        <div
                            style="width: 100%; height: 100%"
                            class="bg-dark-medium-gray"
                        >
                            <div
                                v-for="(kpi, index) in droneReportMissionsKpi"
                                :key="index"
                                style="
                                    height: calc(10vh - 6px - 12px);
                                    line-height: calc(5vh - 6px - 12px);
                                    width: 100%;
                                    position: relative;
                                    padding: 0px;
                                    margin: 0px;
                                "
                            >
                                {{ kpi.label }}:
                                <h4
                                    :class="{
                                        'color-light-red':
                                            kpi.color === '#EF9A9A',
                                        'color-light-green':
                                            kpi.color === '#A5D6A7',
                                    }"
                                >
                                    {{ kpi.value }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import appDroneReportMissionsExecuted from "@/components/drone/containers/report/DroneReportMissionsExecuted";
import appDroneReportMissionsByOperator from "@/components/drone/containers/report/DroneReportMissionsByOperator";
import appDroneReportScheduledMissions from "@/components/drone/containers/report/DroneReportScheduledMissions";
import appDroneReportMissionsNotExecuted from "@/components/drone/containers/report/DroneReportMissionsNotExecuted";
import appDroneReportMissionsExecutedByHour from "@/components/drone/containers/report/DroneReportMissionsExecutedByHour";
import appDroneReportMissionsExecutedByDay from "@/components/drone/containers/report/DroneReportMissionsExecutedByDay";
import appDroneReportEvents from "@/components/drone/containers/report/DroneReportEvents";
import appDroneReportArtificialIntelligenceEvents from "@/components/drone/containers/report/DroneReportArtificialIntelligenceEvents";
import appDroneReportBatteryStates from "@/components/drone/containers/report/DroneReportBatteryStates";

import { mapGetters } from "vuex";

export default {
    data() {
        return {
            droneMissionsDetectedEvents: [
                {
                    value: Math.floor(10 * Math.random()),
                    label: "Perda de sinal",
                },
                {
                    value: Math.floor(10 * Math.random()),
                    label: "Intervenção do Operador",
                },
                {
                    value: Math.floor(10 * Math.random()),
                    label: "Detecção de veículos",
                },
                {
                    value: Math.floor(10 * Math.random()),
                    label: "Detecção de pessoas",
                },
                { value: Math.floor(10 * Math.random()), label: "Vento forte" },
            ],
            heatmap: { map: null, currentZoom: 0, desiredRadiusInMeters: 35 },
        };
    },
    computed: {
        ...mapGetters([
            "droneReportMissionsExecutedCollection",
            "droneReportMissionsByOperatorCollection",
            "droneReportScheduledMissionsCollection",
            "droneReportMissionsNotExecutedCollection",
            "droneReportMissionsExecutedByHourCollection",
            "droneReportMissionsExecutedByDayCollection",
            "droneReportEventsCollection",
            "droneReportArtificialIntelligenceEventsCollection",
            "droneReportBatteryStatesCollection",
            "droneReportMissionsKpi",
            "droneReportMissionsEvents",
            "droneReportMissionsLocations",
            "dronesReportStatus",
        ]),
        sortedDroneReportMissionsEvents() {
            return this.droneReportMissionsEvents
                .sort((a, b) =>
                    a.count > b.count ? -1 : b.count > a.count ? 1 : 0
                )
                .filter((v) => v.count !== 0);
        },
    },
    components: {
        appDroneReportMissionsExecuted,
        appDroneReportMissionsByOperator,
        appDroneReportScheduledMissions,
        appDroneReportMissionsNotExecuted,
        appDroneReportMissionsExecutedByHour,
        appDroneReportMissionsExecutedByDay,
        appDroneReportEvents,
        appDroneReportArtificialIntelligenceEvents,
        appDroneReportBatteryStates,
    },
    methods: {
        getHeatmapRadius(desiredRadiusInMeters, zoomLevel, latitudeLevel) {
            let metersPerPx =
                (156543.03392 * Math.cos((latitudeLevel * Math.PI) / 180)) /
                Math.pow(2, zoomLevel);
            return desiredRadiusInMeters / metersPerPx;
        },
    },
    watch: {
        dronesReportStatus: function (newVal, oldVal) {
            if (newVal) {
                if (newVal === "success") {
                    this.$message({
                        message: this.$t("Messsage_data_obtained"),
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                } else if (newVal === "error") {
                    this.$message({
                        message: `Não foi possível carregar os dados`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            }
        },
        droneReportMissionsLocations: function (newVal, oldVal) {
            this.heatmap.map.setMap(null);
            let self = this;
            this.$refs.mapRefDroneReportHeatmap.$mapPromise.then((map) => {
                self.heatmap.map = new google.maps.visualization.HeatmapLayer({
                    data: newVal.map(
                        (v) => new google.maps.LatLng(v.lat, v.lng)
                    ),
                });

                const bounds = new google.maps.LatLngBounds();
                if (newVal.length > 0) {
                    for (let p of newVal) {
                        bounds.extend(p);
                    }
                } else {
                    bounds.extend({ lat: -23, lng: -46 });
                }

                map.fitBounds(bounds);
                let val = self.getHeatmapRadius(
                    self.heatmap.desiredRadiusInMeters,
                    map.getZoom(),
                    map.getCenter().lat()
                );
                self.heatmap.map.setOptions({
                    radius: Math.max(val * 1, 20),
                });
                self.heatmap.map.setMap(map);
            });
        },
        "heatmap.currentZoom": function (newVal, oldVal) {
            let self = this;
            this.$refs.mapRefDroneReportHeatmap.$mapPromise.then((map) => {
                let val = self.getHeatmapRadius(
                    self.heatmap.desiredRadiusInMeters,
                    map.getZoom(),
                    map.getCenter().lat()
                );
                self.heatmap.map.setOptions({
                    radius: Math.max(val * 1, 10),
                });
            });
        },
    },
    mounted() {
        let self = this;
        if (!this.heatmap.map) {
            this.$refs.mapRefDroneReportHeatmap.$mapPromise.then((map) => {
                if (self.droneReportMissionsLocations.length) {
                    self.heatmap.map =
                        new google.maps.visualization.HeatmapLayer({
                            data: self.droneReportMissionsLocations.map(
                                (v) => new google.maps.LatLng(v.lat, v.lng)
                            ),
                        });
                    const bounds = new google.maps.LatLngBounds();
                    for (let l of self.droneReportMissionsLocations.map(
                        (v) => new google.maps.LatLng(v.lat, v.lng)
                    )) {
                        bounds.extend(l);
                    }

                    map.fitBounds(bounds);

                    let val = self.getHeatmapRadius(
                        self.heatmap.desiredRadiusInMeters,
                        map.getZoom(),
                        map.getCenter().lat()
                    );
                    self.heatmap.map.setOptions({
                        radius: Math.max(val * 1, 20),
                    });
                    self.heatmap.map.setMap(map);
                } else {
                    self.heatmap.map =
                        new google.maps.visualization.HeatmapLayer({
                            data: [],
                        });
                    self.heatmap.map.setMap(map);
                }

                self.heatmap.currentZoom = map.getZoom();

                google.maps.event.addListener(map, "zoom_changed", function () {
                    self.heatmap.currentZoom = map.getZoom();
                });
            });
        }
    },
};
</script>

<style scoped>
.main-drone-report {
    position: absolute;
    left: 0px;
    top: 100px;
    width: calc(100vw);
    height: calc(100vh - 100px);
    padding: 10px;

    -webkit-touch-callout: text !important; /* iOS Safari */
    -webkit-user-select: text !important; /* Safari */
    -khtml-user-select: text !important; /* Konqueror HTML */
    -moz-user-select: text !important; /* Firefox */
    -ms-user-select: text !important; /* Internet Explorer/Edge */
    user-select: text !important; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.no-padding-no-margin {
    padding: 0;
    margin: 0;
}

.no-padding {
    padding: 0;
}
.no-margin {
    margin: 0;
}

.chart-container {
    padding: 1rem;
    flex-grow: 1;
    min-height: 0;
}

.color-light-red {
    color: #ef9a9a;
}

.color-light-green {
    color: #a5d6a7;
}
</style>