<template>
    <div
        class="main-drone-mission-commands"
        v-if="
            droneActive &&
            droneActiveElement.vIS_CONNECTED &&
            user.data.internals.settings.drone.vIS_CONTROLLABLE_BY_DASHBOARD !==
                false
        "
    >
        <div
            class="row px-0 mx-0"
            style="
                background-color: rgba(112, 112, 112, 0.8);
                height: 50px;
                width: 250px;
                border-radius: 25px 0px 0px 25px;
            "
        >
            <div
                class="col-3 align-self-center mx-0 px-0"
                style="height: 50px; position: relative; top: -2.5px"
            >
                <el-tooltip
                    :content="$t('Cancel_all_actions')"
                    placement="top"
                    :open-delay="1000"
                >
                    <button
                        @click="handleStopAllActions()"
                        class="btn px-0 mx-0"
                        style="box-shadow: none"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <g
                                id="Grupo_1102"
                                data-name="Grupo 1102"
                                transform="translate(-1597 -969)"
                            >
                                <circle
                                    id="Elipse_132"
                                    data-name="Elipse 132"
                                    cx="20"
                                    cy="20"
                                    r="20"
                                    transform="translate(1597 969)"
                                    fill="#ef5350"
                                />
                                <g
                                    id="Grupo_1068"
                                    data-name="Grupo 1068"
                                    transform="translate(1607.865 979.762)"
                                >
                                    <path
                                        id="Caminho_677"
                                        data-name="Caminho 677"
                                        d="M0,0H27.239"
                                        transform="translate(0 0) rotate(45)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-width="5"
                                    />
                                    <path
                                        id="Caminho_678"
                                        data-name="Caminho 678"
                                        d="M0,0H27.239"
                                        transform="translate(0 19.261) rotate(-45)"
                                        fill="none"
                                        stroke="#fff"
                                        stroke-width="5"
                                    />
                                </g>
                            </g>
                        </svg></button
                ></el-tooltip>
            </div>
            <div
                class="col-3 align-self-center mx-0 px-0"
                style="height: 50px; position: relative; top: -2.5px"
            >
                <el-tooltip
                    :content="$t('Center_map')"
                    placement="top"
                    :open-delay="1000"
                >
                    <button
                        @click="setMapDragged(false)"
                        class="btn"
                        style="box-shadow: none"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <g
                                id="Grupo_1071"
                                data-name="Grupo 1071"
                                transform="translate(0.453 0.147)"
                            >
                                <circle
                                    id="Elipse_133"
                                    data-name="Elipse 133"
                                    cx="20"
                                    cy="20"
                                    r="20"
                                    transform="translate(-0.453 -0.147)"
                                    fill="#4e4e4e"
                                />
                                <g
                                    id="gps_fixed-24px"
                                    transform="translate(7.264 7.264)"
                                >
                                    <path
                                        id="Caminho_682"
                                        data-name="Caminho 682"
                                        d="M0,0H24.608V24.608H0Z"
                                        fill="none"
                                    />
                                    <path
                                        id="Caminho_683"
                                        data-name="Caminho 683"
                                        d="M12.279,8.177a4.1,4.1,0,1,0,4.1,4.1A4.1,4.1,0,0,0,12.279,8.177Zm9.167,3.076A9.222,9.222,0,0,0,13.3,3.112V1H11.253V3.112a9.222,9.222,0,0,0-8.141,8.141H1V13.3H3.112a9.222,9.222,0,0,0,8.141,8.141v2.112H13.3V21.445A9.222,9.222,0,0,0,21.445,13.3h2.112V11.253H21.445Zm-9.167,8.2a7.177,7.177,0,1,1,7.177-7.177A7.172,7.172,0,0,1,12.279,19.456Z"
                                        transform="translate(0.025 0.025)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg></button
                ></el-tooltip>
            </div>
            <div
                class="col-3 align-self-center mx-0 px-0"
                style="height: 50px; position: relative; top: -2.5px"
            >
                <el-tooltip
                    v-if="!droneActiveElement.flight.state.vIS_GOING_HOME"
                    :content="$t('Return_to_home')"
                    placement="top"
                    :open-delay="1000"
                >
                    <button
                        class="btn"
                        style="box-shadow: none"
                        @click="handleGoHome()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <g
                                id="Grupo_1097"
                                data-name="Grupo 1097"
                                transform="translate(-1718 -969)"
                            >
                                <circle
                                    id="Elipse_132"
                                    data-name="Elipse 132"
                                    cx="20"
                                    cy="20"
                                    r="20"
                                    transform="translate(1718 969)"
                                    fill="#4e4e4e"
                                />
                                <path
                                    id="Caminho_679"
                                    data-name="Caminho 679"
                                    d="M75.389,338.167c-.311.23-.628.452-.932.691a2.04,2.04,0,0,0-.82,1.138,1.323,1.323,0,0,0,.4,1.27A6.867,6.867,0,0,0,76.45,342.8a16.491,16.491,0,0,0,3.718.983c.948.149,1.9.276,2.855.373a29.787,29.787,0,0,0,4.031.122c1.066-.036,2.134-.094,3.2-.194a20.968,20.968,0,0,0,5.736-1.238,7.99,7.99,0,0,0,2.266-1.285,1.649,1.649,0,0,0,.715-1.326,1.5,1.5,0,0,0-.526-1.091c-.383-.341-.807-.637-1.214-.952a.138.138,0,0,1-.059-.086c.324.1.649.19.972.29a11.583,11.583,0,0,1,3.289,1.46,5.587,5.587,0,0,1,.978.847,1.255,1.255,0,0,1,0,1.775,5.037,5.037,0,0,1-1.519,1.19,16.51,16.51,0,0,1-3.472,1.324,31.888,31.888,0,0,1-4.481.877c-1.458.192-2.92.33-4.389.382-.892.031-1.785.075-2.676.049-1.393-.041-2.785-.121-4.176-.211a32.724,32.724,0,0,1-4.454-.6,21.79,21.79,0,0,1-5.255-1.663,5.165,5.165,0,0,1-1.745-1.26,2.21,2.21,0,0,1-.4-.734,1.144,1.144,0,0,1,.228-1.011,3.825,3.825,0,0,1,1.037-.95,11.051,11.051,0,0,1,2.843-1.315c.434-.143.875-.265,1.314-.4a1.014,1.014,0,0,1,.12-.021Z"
                                    transform="translate(1651.672 652.311)"
                                    fill="#fff"
                                />
                                <g
                                    id="Grupo_1070"
                                    data-name="Grupo 1070"
                                    transform="translate(1730.654 986.692)"
                                >
                                    <path
                                        id="Caminho_680"
                                        data-name="Caminho 680"
                                        d="M177.35,296.136l-.512,2.431H184.3l-.508-2.431h1.738l2.4,7.453h-2.582l-.885-4.229h-7.79l-.89,4.229h-2.552l2.4-7.453Z"
                                        transform="translate(-173.229 -296.136)"
                                        fill="#fff"
                                    />
                                </g>
                                <path
                                    id="Caminho_681"
                                    data-name="Caminho 681"
                                    d="M209.119,217.166l8.689-8.227H200.43Z"
                                    transform="translate(1528.764 766.457)"
                                    fill="#fff"
                                />
                            </g>
                        </svg></button
                ></el-tooltip>
                <el-tooltip
                    v-else
                    :content="$t('Cancel_return_to_home')"
                    placement="top"
                    :open-delay="1000"
                >
                    <button
                        class="btn"
                        style="box-shadow: none"
                        @click="handleCancelGoHome()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                        >
                            <g
                                id="Grupo_1187"
                                data-name="Grupo 1187"
                                transform="translate(16948 15205)"
                            >
                                <circle
                                    id="Elipse_132"
                                    data-name="Elipse 132"
                                    cx="20"
                                    cy="20"
                                    r="20"
                                    transform="translate(-16948 -15205)"
                                    fill="#4e4e4e"
                                />
                                <path
                                    id="Caminho_679"
                                    data-name="Caminho 679"
                                    d="M75.389,338.167c-.311.23-.628.452-.932.691a2.04,2.04,0,0,0-.82,1.138,1.323,1.323,0,0,0,.4,1.27A6.867,6.867,0,0,0,76.45,342.8a16.491,16.491,0,0,0,3.718.983c.948.149,1.9.276,2.855.373a29.787,29.787,0,0,0,4.031.122c1.066-.036,2.134-.094,3.2-.194a20.968,20.968,0,0,0,5.736-1.238,7.99,7.99,0,0,0,2.266-1.285,1.649,1.649,0,0,0,.715-1.326,1.5,1.5,0,0,0-.526-1.091c-.383-.341-.807-.637-1.214-.952a.138.138,0,0,1-.059-.086c.324.1.649.19.972.29a11.583,11.583,0,0,1,3.289,1.46,5.587,5.587,0,0,1,.978.847,1.255,1.255,0,0,1,0,1.775,5.037,5.037,0,0,1-1.519,1.19,16.51,16.51,0,0,1-3.472,1.324,31.888,31.888,0,0,1-4.481.877c-1.458.192-2.92.33-4.389.382-.892.031-1.785.075-2.676.049-1.393-.041-2.785-.121-4.176-.211a32.724,32.724,0,0,1-4.454-.6,21.79,21.79,0,0,1-5.255-1.663,5.165,5.165,0,0,1-1.745-1.26,2.21,2.21,0,0,1-.4-.734,1.144,1.144,0,0,1,.228-1.011,3.825,3.825,0,0,1,1.037-.95,11.051,11.051,0,0,1,2.843-1.315c.434-.143.875-.265,1.314-.4a1.014,1.014,0,0,1,.12-.021Z"
                                    transform="translate(-17014.328 -15521.689)"
                                    fill="#ef5350"
                                />
                                <g
                                    id="Grupo_1070"
                                    data-name="Grupo 1070"
                                    transform="translate(-16935.346 -15187.308)"
                                >
                                    <path
                                        id="Caminho_680"
                                        data-name="Caminho 680"
                                        d="M177.35,296.136l-.512,2.431H184.3l-.508-2.431h1.738l2.4,7.453h-2.582l-.885-4.229h-7.79l-.89,4.229h-2.552l2.4-7.453Z"
                                        transform="translate(-173.229 -296.136)"
                                        fill="#ef5350"
                                    />
                                </g>
                                <path
                                    id="Caminho_681"
                                    data-name="Caminho 681"
                                    d="M209.119,217.166l8.689-8.227H200.43Z"
                                    transform="translate(-17137.236 -15407.543)"
                                    fill="#ef5350"
                                />
                            </g>
                        </svg></button
                ></el-tooltip>
            </div>
            <div
                class="col-3 align-self-center mx-0 px-0"
                style="height: 50px; position: relative; top: -30px"
            >
                <el-tooltip
                    v-if="!droneActiveElement.flight.state.vIS_FLYING"
                    :content="$t('Start_flight')"
                    placement="top"
                    :open-delay="1000"
                >
                    <button
                        class="btn"
                        style="box-shadow: none"
                        @click="
                            droneActiveElement.vIS_CONNECTED
                                ? (dialogFlightModeVisible = true)
                                : false
                        "
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="96"
                            height="96"
                            viewBox="0 0 96 96"
                        >
                            <g
                                id="Grupo_1184"
                                data-name="Grupo 1184"
                                transform="translate(-1770 -939)"
                            >
                                <g
                                    id="Elipse_131"
                                    data-name="Elipse 131"
                                    transform="translate(1770 939)"
                                    fill="#1e88e5"
                                    stroke="#fff"
                                    stroke-width="5"
                                >
                                    <circle
                                        cx="48"
                                        cy="48"
                                        r="48"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="48"
                                        cy="48"
                                        r="45.5"
                                        fill="none"
                                    />
                                </g>
                                <g
                                    id="Camada_2"
                                    data-name="Camada 2"
                                    transform="translate(1799.17 968.576)"
                                >
                                    <g
                                        id="Camada_1"
                                        data-name="Camada 1"
                                        transform="translate(0)"
                                    >
                                        <path
                                            id="Caminho_477"
                                            data-name="Caminho 477"
                                            d="M25.779,33.74A9.7,9.7,0,0,0,23.8,27.361a4.907,4.907,0,0,0-2.968-2.008,3.347,3.347,0,0,1-2.476-3.512A3.5,3.5,0,0,1,21.49,18.65a3.28,3.28,0,0,1,3.549,2.274c.647,2.915,2.882,3.936,5.324,4.589a16.415,16.415,0,0,0,4.237.293,8.335,8.335,0,0,0,5.757-2.393,4.781,4.781,0,0,0,1.2-2.027,3.6,3.6,0,0,1,3.621-2.756,3.514,3.514,0,0,1,3.226,3.2,3.4,3.4,0,0,1-2.737,3.605,4.377,4.377,0,0,0-2.323,1.473,10.032,10.032,0,0,0,.377,13.423A4.665,4.665,0,0,0,45.6,41.4a3.583,3.583,0,0,1,2.812,3.673,3.522,3.522,0,0,1-3.252,3.188,3.587,3.587,0,0,1-3.605-2.779A4.989,4.989,0,0,0,40.279,43.4a8.933,8.933,0,0,0-5.809-2.332c-3.121-.167-6.025.319-8.315,2.643a3.778,3.778,0,0,0-.912,1.633,3.472,3.472,0,1,1-4.035-3.973,4.07,4.07,0,0,0,1.846-1.083A10.02,10.02,0,0,0,25.779,33.74Zm10.612-.278a3,3,0,1,0-6.01-.058c-.019,1.4,1.5,3.205,2.709,3.222A3.394,3.394,0,0,0,36.391,33.462Z"
                                            transform="translate(-14.777 -15)"
                                            fill="#fff"
                                        />
                                        <path
                                            id="Caminho_478"
                                            data-name="Caminho 478"
                                            d="M126.4,13.461a4.4,4.4,0,0,1,2.527-1.926,4.551,4.551,0,0,0,3.411-4.9,4.706,4.706,0,0,0-4.276-4.2,4.571,4.571,0,0,0-4.824,3.51,3.694,3.694,0,0,1-1.955,2.371A6.64,6.64,0,0,1,124.413.948,6.861,6.861,0,0,1,133.7,10.5,6.652,6.652,0,0,1,126.4,13.461Z"
                                            transform="translate(-97.528 -0.009)"
                                            fill="#fff"
                                        />
                                        <path
                                            id="Caminho_479"
                                            data-name="Caminho 479"
                                            d="M13.482,124.779a6.613,6.613,0,0,1-2.956,7.271,6.9,6.9,0,0,1-8.418-.882,6.916,6.916,0,0,1-1.24-8.216,6.732,6.732,0,0,1,7.362-3.366,3.4,3.4,0,0,1-2.208,1.944,4.571,4.571,0,0,0-3.586,4.95,4.727,4.727,0,0,0,4.29,4.187,4.567,4.567,0,0,0,4.842-3.607A3.706,3.706,0,0,1,13.482,124.779Z"
                                            transform="translate(-0.035 -96.198)"
                                            fill="#fff"
                                        />
                                        <path
                                            id="Caminho_480"
                                            data-name="Caminho 480"
                                            d="M8.2,13.568c-2.36.8-5.543-.5-7.1-2.913a6.894,6.894,0,0,1,.777-8.427,6.922,6.922,0,0,1,8.457-1.211A6.672,6.672,0,0,1,13.5,8.373a3.5,3.5,0,0,1-1.926-2.251A4.577,4.577,0,0,0,7.007,2.511a4.657,4.657,0,0,0-.937,9.207A2.915,2.915,0,0,1,8.2,13.568Z"
                                            transform="translate(-0.013 -0.077)"
                                            fill="#fff"
                                        />
                                        <path
                                            id="Caminho_481"
                                            data-name="Caminho 481"
                                            d="M126.5,119.514c2.437-.869,5.625.437,7.2,2.867a6.863,6.863,0,0,1-9.135,9.7,6.688,6.688,0,0,1-3.25-7.4,3.453,3.453,0,0,1,1.93,2.243,4.577,4.577,0,0,0,4.56,3.611,4.646,4.646,0,0,0,.933-9.187A3.86,3.86,0,0,1,126.5,119.514Z"
                                            transform="translate(-97.572 -96.069)"
                                            fill="#fff"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg></button
                ></el-tooltip>
                <el-tooltip
                    v-else-if="
                        droneActiveElement.flight.state.vIS_FLYING &&
                        droneActiveElement.flight.state.vIS_MISSION_PAUSED
                    "
                    :content="$t('Resume_mission')"
                    placement="top"
                    :open-delay="1000"
                >
                    <button
                        class="btn"
                        style="box-shadow: none"
                        @click="handleResumeMission()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="96"
                            height="96"
                            viewBox="0 0 96 96"
                        >
                            <g
                                id="Grupo_1186"
                                data-name="Grupo 1186"
                                transform="translate(14647 15092)"
                            >
                                <g
                                    id="Elipse_131"
                                    data-name="Elipse 131"
                                    transform="translate(-14647 -15092)"
                                    fill="#1e88e5"
                                    stroke="#fff"
                                    stroke-width="5"
                                >
                                    <circle
                                        cx="48"
                                        cy="48"
                                        r="48"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="48"
                                        cy="48"
                                        r="45.5"
                                        fill="none"
                                    />
                                </g>
                                <g
                                    id="play_arrow-24px"
                                    transform="translate(-14637 -15082)"
                                >
                                    <path
                                        id="Caminho_712"
                                        data-name="Caminho 712"
                                        d="M0,0H75.6V75.6H0Z"
                                        fill="none"
                                    />
                                    <path
                                        id="Caminho_713"
                                        data-name="Caminho 713"
                                        d="M8,5V49.1L42.651,27.05Z"
                                        transform="translate(17.201 10.75)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg></button
                ></el-tooltip>
                <el-tooltip
                    v-else
                    :content="$t('Pause_mission')"
                    placement="top"
                    :open-delay="1000"
                >
                    <button
                        class="btn"
                        style="box-shadow: none"
                        @click="handlePauseMission()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="96"
                            height="96"
                            viewBox="0 0 96 96"
                        >
                            <g
                                id="Grupo_1088"
                                data-name="Grupo 1088"
                                transform="translate(-0.406)"
                            >
                                <g
                                    id="Elipse_131"
                                    data-name="Elipse 131"
                                    transform="translate(0.406)"
                                    fill="#1e88e5"
                                    stroke="#fff"
                                    stroke-width="5"
                                >
                                    <circle
                                        cx="48"
                                        cy="48"
                                        r="48"
                                        stroke="none"
                                    />
                                    <circle
                                        cx="48"
                                        cy="48"
                                        r="45.5"
                                        fill="none"
                                    />
                                </g>
                                <g
                                    id="pause-24px"
                                    transform="translate(17.406 17)"
                                >
                                    <path
                                        id="Caminho_710"
                                        data-name="Caminho 710"
                                        d="M0,0H63V63H0Z"
                                        fill="none"
                                    />
                                    <path
                                        id="Caminho_711"
                                        data-name="Caminho 711"
                                        d="M6,41.75H16.5V5H6ZM27,5V41.75H37.5V5Z"
                                        transform="translate(9.75 8.125)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg></button
                ></el-tooltip>
            </div>
        </div>
        <!--  -->
        <el-dialog
            title="Modo de Voo"
            :visible.sync="dialogFlightModeVisible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <span style="color: white">Selecione um modo de voo</span>
            <br />
            <br />
            <div class="row">
                <div class="col-12">
                    <el-radio v-model="flightMode" label="mission"
                        >Missão</el-radio
                    >
                    <el-radio v-model="flightMode" label="manual"
                        >Manual</el-radio
                    >
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                            @click="handleCancelDialogFlightMode"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                            @click="handleConfirmDialogFlightMode"
                        >
                            Iniciar
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!--  -->
        <el-dialog
            title="Confirmação"
            :visible.sync="dialogTakeOffVisible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <span style="color: white"
                >O drone irá decolar e pairar em 1,2m de altura.
            </span>
            <br />
            <span style="color: white">Tem certeza que deseja decolar? </span>

            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                            @click="handleCancelDialogTakeOff"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                            @click="handleConfirmDialogTakeOff"
                        >
                            {{ $t("Yes") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
        <!--  -->
        <el-dialog
            title="Selecione um Missão Automática"
            :visible.sync="dialogMissionSelectionVisible"
            style=""
            width="750px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div
                v-if="
                    dronesMissions.filter(
                        (v) => v.vCHILD_TO_EXECUTE_MISSION === droneActive
                    ).length > 0
                "
            >
                <div class="row" style="height: auto">
                    <div class="col-3">
                        <div
                            style="
                                overflow-y: auto;
                                overflow-x: hidden;
                                height: 40vh;
                                padding-left: 20px;
                            "
                        >
                            <div
                                v-for="(m, index) in dronesMissions.filter(
                                    (v) =>
                                        v.vCHILD_TO_EXECUTE_MISSION ===
                                        droneActive
                                )"
                                :key="'drone-mission-selection-' + index"
                            >
                                <div class="row">
                                    <div class="col text-left">
                                        <el-radio
                                            v-model="selectedDroneMission"
                                            :label="m.vMISSION_KEY"
                                            >{{ m.vMISSION_NAME }}</el-radio
                                        >
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <div class="col-9" v-if="selectedDroneMission">
                        <el-carousel
                            arrow="never"
                            style="height: 100%"
                            height="100%"
                            direction="horizontal"
                            trigger="click"
                            :autoplay="false"
                        >
                            <el-carousel-item>
                                <div>
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>Pontos de rota</strong>
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMission
                                                    ? selectedDroneMissionElement
                                                          .waypoints.length
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>Distância</strong>
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMission
                                                    ? distanceTotal.toFixed(2) +
                                                      " km"
                                                    : "-"
                                            }}
                                            <!-- | Máx.:
                                {{
                                    selectedDroneMission
                                        ? distanceMax.toFixed(2) + " km"
                                        : "-"
                                }} -->
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>Tempo estimado</strong>
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{ timeEstimated }}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>Altitude mínima</strong>
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMission
                                                    ? selectedDroneMissionElement.waypoints
                                                          .map(
                                                              (v) => v.vALTITUDE
                                                          )
                                                          .reduce(
                                                              (a, b) =>
                                                                  a > b ? b : a,
                                                              Infinity
                                                          ) + " m"
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>Altidude máxima</strong>
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMission
                                                    ? selectedDroneMissionElement.waypoints
                                                          .map(
                                                              (v) => v.vALTITUDE
                                                          )
                                                          .reduce(
                                                              (a, b) =>
                                                                  a > b ? a : b,
                                                              -Infinity
                                                          ) + " m"
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>Velocidade</strong>
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMission
                                                    ? selectedDroneMissionElement.vSPEED +
                                                      " m/s (" +
                                                      (
                                                          3.6 *
                                                          selectedDroneMissionElement.vSPEED
                                                      ).toFixed(0) +
                                                      " km/h)"
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong
                                                >Direcionamento da
                                                câmera</strong
                                            >
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMissionElement
                                                    ? selectedDroneMissionElement.vGIMBAL_MANUAL_ROTATION
                                                        ? "Manual"
                                                        : "Auto"
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>
                                                Direcionamento do drone</strong
                                            >
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMissionElement
                                                    ? selectedDroneMissionElement.vHEADING_MODE ===
                                                      "auto"
                                                        ? "Automático"
                                                        : "Configurado em cada ponto"
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="col-6 text-left">
                                            <strong>
                                                Ação ao finalizar a
                                                missão</strong
                                            >
                                        </div>
                                        <div
                                            class="col-6"
                                            style="color: #ffe082"
                                        >
                                            {{
                                                selectedDroneMissionElement
                                                    ? selectedDroneMissionElement.vEND_ACTION ===
                                                      "go_home"
                                                        ? "Voltar para a base"
                                                        : "Não fazer nada"
                                                    : "-"
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                            </el-carousel-item>
                            <el-carousel-item>
                                <app-drone-selected-mission-map
                                    :mission="selectedDroneMissionElement"
                                    :beginWaypointIndex="beginWaypointIndex"
                                    style="height: calc(100% - 100px)"
                                ></app-drone-selected-mission-map>

                                <div class="row" style="margin-top: 20px">
                                    <div class="col-6 my-auto">
                                        Ponto inicial
                                    </div>
                                    <div class="col-6">
                                        <el-input-number
                                            v-model="beginWaypointIndex"
                                            controls-position="right"
                                            :min="1"
                                            :max="
                                                dronesMissions.filter(
                                                    (v) =>
                                                        v.vMISSION_KEY ===
                                                        this
                                                            .selectedDroneMission
                                                )[0].waypoints.length - 1
                                            "
                                            :step="1"
                                        ></el-input-number>
                                    </div>
                                </div>
                                <br />
                                <br />
                            </el-carousel-item>
                            <el-carousel-item>
                                <app-drone-selected-mission-card-elevation
                                    :mission="selectedDroneMissionElement"
                                    :beginWaypointIndex="beginWaypointIndex"
                                    style="height: 100%"
                                ></app-drone-selected-mission-card-elevation>
                                <br />
                                <br />
                            </el-carousel-item>
                            <br />
                            <br />
                        </el-carousel>
                    </div>
                    <div class="col-8" v-else>
                        Selecione uma Missão Automática
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="row">
                    <div class="col-12">
                        Nenhuma Missão Automática disponível
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                            @click="handleCancelDialogMissionSelection"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                            @click="handleConfirmDialogMissionSelection"
                            :disabled="
                                !dronesMissions.filter(
                                    (v) =>
                                        v.vCHILD_TO_EXECUTE_MISSION ===
                                        droneActive
                                ).length > 0 || !selectedDroneMission
                            "
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase";
import appDroneSelectedMissionCardElevation from "@/components/drone/containers/DroneSelectedMissionCardElevation";
import appDroneSelectedMissionMap from "@/components/drone/containers/DroneSelectedMissionMap";

export default {
    data() {
        return {
            dialogFlightModeVisible: false,
            dialogTakeOffVisible: false,
            dialogMissionSelectionVisible: false,
            flightMode: "mission",
            selectedDroneMission: null,
            beginWaypointIndex: 1,
        };
    },
    components: {
        appDroneSelectedMissionMap,
        appDroneSelectedMissionCardElevation,
    },
    computed: {
        ...mapGetters([
            "droneActive",
            "droneActiveElement",
            "dronesMissions",
            "user",
        ]),
        selectedDroneMissionElement() {
            if (this.selectedDroneMission) {
                let m = JSON.parse(
                    JSON.stringify(
                        this.dronesMissions.filter(
                            (v) => v.vMISSION_KEY === this.selectedDroneMission
                        )[0]
                    )
                );
                m.waypoints = m.waypoints.slice(
                    this.beginWaypointIndex,
                    m.waypoints.length
                );
                m.markers = m.markers.slice(
                    this.beginWaypointIndex,
                    m.markers.length
                );
                return m;
            } else {
                return null;
            }
        },
        distanceTotal() {
            return this.selectedDroneMissionElement
                ? this.distanceInKm(
                      this.selectedDroneMissionElement.vEND_ACTION ===
                          "no_action"
                          ? this.selectedDroneMissionElement.waypoints.map(
                                function (v) {
                                    return {
                                        lat: v.vLATITUDE,
                                        lng: v.vLONGITUDE,
                                    };
                                }
                            )
                          : this.selectedDroneMissionElement.waypoints
                                .map(function (v) {
                                    return {
                                        lat: v.vLATITUDE,
                                        lng: v.vLONGITUDE,
                                    };
                                })
                                .concat(
                                    this.selectedDroneMissionElement.waypoints.map(
                                        function (v) {
                                            return {
                                                lat: v.vLATITUDE,
                                                lng: v.vLONGITUDE,
                                            };
                                        }
                                    )[0]
                                ) || 0
                  )
                : 0;
        },
        distanceMax() {
            if (this.selectedDroneMissionElement) {
                if (this.selectedDroneMissionElement.waypoints.length > 1) {
                    let dist = -Infinity;
                    let w0 = this.selectedDroneMissionElement.waypoints[0];
                    for (let w of this.selectedDroneMissionElement.waypoints) {
                        let value = this.distanceInKm([
                            {
                                lat: w.vLATITUDE,
                                lng: w.vLONGITUDE,
                            },
                            {
                                lat: w0.vLATITUDE,
                                lng: w0.vLONGITUDE,
                            },
                        ]);
                        if (value > dist) {
                            dist = value;
                        }
                    }
                    return dist;
                } else {
                    return 0.0;
                }
            } else {
                return 0.0;
            }
        },
        timeEstimated() {
            let duration = this.selectedDroneMissionElement
                ? Math.round(
                      (this.distanceTotal * 1000) /
                          this.selectedDroneMissionElement.vSPEED
                  )
                : 0;

            let h = Math.floor(duration / 3600);
            let m = Math.floor((duration - h * 3600) / 60);
            let s = Math.floor(duration - h * 3600 - m * 60);
            return (
                (h < 10 ? ("0" + h).slice(-2) : h) +
                ":" +
                ("0" + m).slice(-2) +
                ":" +
                ("0" + s).slice(-2)
            );
        },
    },
    methods: {
        ...mapActions(["setMapDragged"]),
        distanceInKm(locations) {
            var earthRadiusKm = 6371;
            var distanceInKm = 0.0;
            for (let i = 0; i < locations.length - 1; i++) {
                let lat1 = locations[i].lat;
                let lat2 = locations[i + 1].lat;
                let lng1 = locations[i].lng;
                let lng2 = locations[i + 1].lng;

                let dLat = ((lat2 - lat1) * Math.PI) / 180;
                let dLon = ((lng2 - lng1) * Math.PI) / 180;

                lat1 = (lat1 * Math.PI) / 180;
                lat2 = (lat2 * Math.PI) / 180;

                let a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) *
                        Math.sin(dLon / 2) *
                        Math.cos(lat1) *
                        Math.cos(lat2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                distanceInKm += earthRadiusKm * c;
            }
            return distanceInKm;
        },
        handleConfirmDialogFlightMode() {
            console.log("handleConfirmDialogFlightMode");
            this.dialogFlightModeVisible = false;
            let self = this;
            setTimeout(() => {
                if (self.flightMode === "manual") {
                    self.dialogTakeOffVisible = true;
                } else if (self.flightMode === "mission") {
                    self.selectedDroneMission =
                        self.dronesMissions[0].vMISSION_KEY;
                    self.beginWaypointIndex = 1;
                    self.selectedDroneMission = null;
                    self.dialogMissionSelectionVisible = true;
                }
            }, 500);
        },
        handleCancelDialogFlightMode() {
            console.log("handleCancelDialogFlightModeV");
            this.dialogFlightModeVisible = false;
        },
        handleConfirmDialogTakeOff() {
            console.log("handleConfirmDialogTakeOff");
            this.dialogTakeOffVisible = false;
            if (this.droneActiveElement.vIS_CONNECTED) {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.droneActive +
                            "/flight/actions/aSTART_TAKE_OFF"
                    )
                    .set(true);
            } else {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleCancelDialogTakeOff() {
            console.log("handleCancelDialogTakeOff");
            this.dialogTakeOffVisible = false;
        },
        handleConfirmDialogMissionSelection() {
            console.log("handleConfirmDialogMissionSelection");
            let m = JSON.parse(
                JSON.stringify(this.selectedDroneMissionElement)
            );
            delete m["markers"];
            this.dialogMissionSelectionVisible = false;
            if (this.droneActiveElement.vIS_CONNECTED) {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.droneActive +
                            "/flight/control/currentMission"
                    )
                    .set(m);
            } else {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleCancelDialogMissionSelection() {
            console.log("handleCancelDialogMissionSelection");
            this.dialogMissionSelectionVisible = false;
            if (this.droneActiveElement.vIS_CONNECTED) {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.droneActive +
                            "/flight/actions/aSTART_TAKE_OFF"
                    )
                    .set(true);
            } else {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleStopAllActions() {
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.droneActive +
                        "/flight/actions/aSTOP_ALL_ACTIONS"
                )
                .set(true);

            if (!this.droneActiveElement.vIS_CONNECTED) {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleGoHome() {
            console.log("handleGoHome");
            if (this.droneActiveElement.vIS_CONNECTED) {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.droneActive +
                            "/flight/actions/aSTART_GO_HOME"
                    )
                    .set(true);
            } else {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleCancelGoHome() {
            console.log("handleCancelGoHome");
            if (this.droneActiveElement.vIS_CONNECTED) {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.droneActive +
                            "/flight/actions/aSTOP_GO_HOME"
                    )
                    .set(true);
            } else {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handleResumeMission() {
            console.log("handleResumeMission");
            if (this.droneActiveElement.vIS_CONNECTED) {
                firebase
                    .database()
                    .ref(
                        "aegisv2/users/" +
                            this.droneActive +
                            "/flight/actions/aRESUME_MISSION"
                    )
                    .set(true);
            } else {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
        handlePauseMission() {
            console.log("handlePauseMission");
            if (this.droneActiveElement.flight.state.vIS_CONNECTED) {
                if (
                    this.droneActiveElement.flight.state.vIS_EXECUTING_MISSION
                ) {
                    firebase
                        .database()
                        .ref(
                            "aegisv2/users/" +
                                this.droneActive +
                                "/flight/actions/aPAUSE_MISSION"
                        )
                        .set(true);
                } else {
                    this.$message({
                        message: `Nenhuma missão está sendo executada`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            } else {
                this.$message({
                    message: `Drone desconectado`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
            }
        },
    },
    watch: {
        selectedDroneMission: function (newVal) {
            this.beginWaypointIndex = 1;
        },
    },
};
</script>

<style scoped>
.main-drone-mission-commands {
}
</style>