<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div>
            <!--  -->
            <div class="row text-left">
                <div class="col-12 my-auto align-middle" style="color: #bebebe">
                    <span style="font-size: 2rem">{{
                        $t("APIS_and_Integration")
                    }}</span>
                </div>
            </div>
            <div class="row text-left">
                <div class="col-12 my-auto align-middle" style="color: #bebebe">
                    <span style="font-size: 1rem">{{
                        $t("APIS_and_Integration_desc")
                    }}</span>
                </div>
            </div>
            <br />
            <br />
            <!--  -->
            <div class="row">
                <div class="col-12 text-left">
                    <h6 class="title">{{ $t("Integration") }}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col- text-left">
                    <img
                        style="padding: 20px; height: 120px"
                        :src="require('@/assets/img/digifort.png')"
                        alt
                    />
                </div>
                <div class="col- text-left">
                    <img
                        style="padding: 20px; height: 120px"
                        :src="require('@/assets/img/segware.png')"
                        alt
                    />
                </div>
            </div>
            <br />
            <!--  -->
            <div class="row">
                <div class="col-12 text-left">
                    <h6 class="title">{{ $t("Integration_token") }}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-6 text-left">
                    <el-input
                        :placeholder="$t('Integration_token')"
                        readonly
                        v-model="user.data.refreshToken"
                    ></el-input>
                </div>
                <div class="col-6 text-left">
                    <button
                        @click="handleCopyUserRefreshToken"
                        class="btn btn-primary"
                    >
                        {{ $t("Copy") }}
                    </button>
                </div>
            </div>
            <br />
            <!--  -->
            <div
                class="row"
                v-if="user.data.realInternals.vCHILD_TYPE === 'main'"
            >
                <div class="col-12 text-left">
                    <h6 class="title">
                        {{ $t("Team_logo") }}
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-6 text-left">
                    <input
                        ref="fileupload"
                        type="file"
                        accept="image/png, image/jpeg"
                        @change="onFileChange"
                    />
                </div>
                <div class="col-6 text-left">
                    <button
                        :disabled="fileList.length === 0"
                        class="btn btn-primary mx-2"
                        @click="handleUploadTeamLogo()"
                    >
                        {{ $t("Save") }}
                    </button>
                    <button
                        class="btn btn-primary mx-2"
                        v-if="user.data.internals.vTEAM_LOGO != null"
                        @click="handleRemoveTeamLogo()"
                    >
                        <i class="fas fa-times"></i>
                        {{ $t("Remove_team_logo") }}
                    </button>
                </div>
            </div>
            <br />
            <!--  -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase";

export default {
    data() {
        return {
            googleMapsApiKey: "",
            fileList: [],
        };
    },
    computed: {
        ...mapGetters({
            user: "user",
        }),
    },
    methods: {
        ...mapActions(["setNavForcedLoading"]),
        handleValidateGoogleMapsApiKey() {
            let self = this;
            firebase
                .database()
                .ref(
                    "aegisv2/users/" +
                        this.user.data.uid +
                        "/internals/vGOOGLE_MAPS_API_KEY"
                )
                .set(this.googleMapsApiKey || "")
                .then(() => {
                    self.$message({
                        message: `Modificações realizadas com sucesso`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        handleCopyUserRefreshToken() {
            let self = this;
            this.$copyText(this.user.data.refreshToken).then(
                () => {
                    self.$message({
                        message: `Token copiado para a área de transferência`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                },
                () => {
                    self.$message({
                        message: `Não foi possível copiar o token para a área de transferência`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                }
            );
        },
        onFileChange(e) {
            this.fileList = e.target.files || e.dataTransfer.files;
            if (!this.fileList.length) return;
        },
        handleUploadTeamLogo() {
            let self = this;
            let file = this.fileList[0];
            if (["image/png", "image/jpeg"].indexOf(file.type) === -1) {
                this.$message({
                    message: `Formato inválido, o arquivo deve ser uma imagem png ou jpeg`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
                this.$refs.fileupload.value = null;

                return;
            }
            if (file.size > 500000) {
                this.$message({
                    message: `Tamanho inválido, o tamanho do arquivo deve ser inferior à 500kb`,
                    type: "error",
                    showClose: true,
                    offset: 60,
                });
                this.$refs.fileupload.value = null;

                return;
            }

            this.setNavForcedLoading(true);
            const refStorage = firebase.storage().ref();
            refStorage
                .child(`teamLogos/${self.user.data.uid}`)
                .put(file)
                .then(async function (snapshot) {
                    const url = await snapshot.ref.getDownloadURL();
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${self.user.data.uid}/internals/vTEAM_LOGO`
                        )
                        .set(url);
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Modificações realizadas com sucesso`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch(function (e) {
                    self.setNavForcedLoading(false);
                    console.log(e);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                })
                .finally(function () {
                    self.$refs.fileupload.value = null;
                    self.fileList = [];
                });
        },
        handleRemoveTeamLogo() {
            let self = this;
            self.setNavForcedLoading(true);
            firebase
                .database()
                .ref(`aegisv2/users/${self.user.data.uid}/internals/vTEAM_LOGO`)
                .set(null)
                .then(() => {
                    self.$message({
                        message: `Modificações realizadas com sucesso`,
                        type: "success",
                        showClose: true,
                        offset: 60,
                    });
                })
                .catch(() => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                })
                .finally(() => {
                    self.setNavForcedLoading(false);
                });
        },
    },
    mounted() {
        this.googleMapsApiKey =
            this.user.data.internals.vGOOGLE_MAPS_API_KEY || "";
    },
};
</script>

<style scoped>
h6 {
    color: #a1a0a0;
}
</style>