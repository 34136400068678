<template>
    <div style="width: 100%; height: 100%; padding: 20px">
        <div class="h-100 d-flex flex-column">
            <div class="flex-column justify-content-center">
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 2rem">{{
                            $t("Licensing")
                        }}</span>
                    </div>
                </div>
                <div class="row text-left">
                    <div
                        class="col-12 my-auto align-middle"
                        style="color: #bebebe"
                    >
                        <span style="font-size: 1rem">{{
                            $t("Licensing_desc")
                        }}</span>
                    </div>
                </div>
                <br />
                <br />
            </div>

            <button
                class="btn btn-link"
                style="
                    display: absolute;
                    left: 0px;
                    top: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: #ffe082;
                    border-radius: 20px;
                    z-index: 1;
                "
                @click="dialog.visible = true"
            >
                <i
                    class="fas fa-plus"
                    style="font-size: 1rem; color: white"
                ></i>
            </button>

            <div
                class="
                    flex-column
                    justify-content-center
                    flex-grow-1
                    overflow-auto
                "
                style="margin-top: -20px; margin-left: 20px"
            >
                <el-table
                    :data="treatedLicensesKeys"
                    style="width: 100%"
                    height="100%"
                    highlight-current-row
                    :row-class-name="tableRowClassName"
                    header-cell-class-name="header-row-class-name-custom"
                >
                    <el-table-column
                        sortable
                        prop="vLICENSE_KEY"
                        :label="$t('License')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vLICENSE_TYPE"
                        :label="$t('Type')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vNUMBER_OF_CHILDREN"
                        :label="$t('Elements')"
                    >
                    </el-table-column>
                    <el-table-column
                        sortable
                        prop="vEXPIRES_IN"
                        :label="$t('Expires_at')"
                    >
                    </el-table-column>
                    <el-table-column align="right">
                        <template slot-scope="scope">
                            <el-popconfirm
                                :confirm-button-text="$t('Confirm')"
                                :cancel-button-text="$t('Cancel')"
                                icon="el-icon-info"
                                icon-color="red"
                                title="Você tem certeza disso?"
                                @confirm="
                                    confirmUnassignTeamToLicenseKey(
                                        scope.$index,
                                        scope.row
                                    )
                                "
                            >
                                <button class="btn btn-link" slot="reference">
                                    <i
                                        class="fas fa-trash"
                                        style="font-size: 1rem !important"
                                    ></i>
                                </button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="flex-column justify-content-center">
                <div class="p-0" style="height: 50px"></div>
            </div>
        </div>

        <el-dialog
            :title="$t('Add_license')"
            :visible.sync="dialog.visible"
            style=""
            width="570px"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
        >
            <div>
                <!--  -->
                <el-form
                    ref="form-add-license-key"
                    :model="dialog.formLicenseKey"
                    :rules="dialog.rulesLicenseKey"
                >
                    <!--  -->
                    <el-form-item
                        :label="$t('License_key')"
                        prop="vLICENSE_KEY"
                    >
                        <el-input
                            :maxlength="13"
                            v-model="dialog.formLicenseKey.vLICENSE_KEY"
                        ></el-input>
                    </el-form-item>
                    <!--  -->
                </el-form>
                <br />
            </div>

            <!--  -->
            <span slot="footer" class="dialog-footer">
                <div class="row">
                    <div class="col-6 text-right">
                        <button
                            @click="dialog.visible = false"
                            class="btn btn-secondary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Cancel") }}
                        </button>
                    </div>
                    <div class="col-6 text-left">
                        <button
                            @click="submitForm('form-add-license-key')"
                            class="btn btn-primary btn-bold"
                            style="width: 100px"
                        >
                            {{ $t("Confirm") }}
                        </button>
                    </div>
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";

export default {
    data() {
        return {
            treatedLicensesKeys: [],
            childTypesLabels: {
                main: "Administrador",
                drone: "Drone",
                solo: "SmartCam",
                subscriber: "Inscrito",
            },
            dialog: {
                visible: false,
                //
                formLicenseKey: {
                    vLICENSE_KEY: "",
                },
                rulesLicenseKey: {
                    vLICENSE_KEY: [
                        {
                            required: true,
                            message: "Insira uma chave de licença",
                            trigger: "change",
                        },
                        {
                            min: 13,
                            max: 13,
                            message:
                                "A chave de licença deve ter 13 letras/números",
                            trigger: "change",
                        },
                    ],
                },
                //
            },
        };
    },
    computed: {
        ...mapGetters(["user", "licensesKeysElements"]),
        tableRowClassName({ row, rowIndex }) {
            return "row-class-name-custom";
        },
    },
    methods: {
        ...mapActions(["initializeLicensesKeys", "setNavForcedLoading"]),
        submitForm(formName) {
            let self = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    self.confirmAssignTeamToLicenseKey();
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        getLicenseKeysElements() {
            let self = this;

            this.treatedLicensesKeys = this.licensesKeysElements.map(function (
                v
            ) {
                return {
                    vLICENSE_KEY: v.data.vLICENSE_KEY,
                    vLICENSE_TYPE: self.childTypesLabels[v.data.vLICENSE_TYPE],
                    vEXPIRES_IN: moment(v.data.vEXPIRES_IN).format(
                        "DD/MM/YYYY HH:mm:ss"
                    ),
                    vNUMBER_OF_CHILDREN: v.data.vNUMBER_OF_CHILDREN || 0,
                };
            });
        },
        confirmAssignTeamToLicenseKey() {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    self.setNavForcedLoading(true);
                    self.dialog.visible = false;
                    firebase
                        .functions()
                        .httpsCallable(
                            "CLOUD_DASHBOARD_ASSIGN_TEAM_TO_LICENSE_KEY"
                        )({
                            vTEAM_UID: self.user.data.uid,
                            payload: {
                                vLICENSE_KEY:
                                    self.dialog.formLicenseKey.vLICENSE_KEY,
                            },
                            idToken: idToken,
                            command: "assign-team-to-license-key",
                        })
                        .then(function (result) {
                            self.setNavForcedLoading(false);
                            self.resetForm("form-add-license-key");
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                setTimeout(() => {
                                    location.reload();
                                }, 1000);
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.setNavForcedLoading(false);
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.setNavForcedLoading(false);
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
        confirmUnassignTeamToLicenseKey(i, r) {
            let self = this;
            firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(function (idToken) {
                    firebase
                        .functions()
                        .httpsCallable(
                            "CLOUD_DASHBOARD_UNASSIGN_TEAM_TO_LICENSE_KEY"
                        )({
                            vTEAM_UID: self.user.data.uid,
                            payload: {
                                vLICENSE_KEY: r.vLICENSE_KEY,
                            },
                            idToken: idToken,
                            command: "unassign-team-to-license-key",
                        })
                        .then(function (result) {
                            if (!result.data.error) {
                                self.$message({
                                    message: `Modificações realizadas com sucesso`,
                                    type: "success",
                                    showClose: true,
                                    offset: 60,
                                });
                                setTimeout(() => {
                                    location.reload();
                                }, 1000);
                            } else {
                                self.$message({
                                    message: `Não foi possível efetuar as modificações`,
                                    type: "error",
                                    showClose: true,
                                    offset: 60,
                                });
                            }
                        })
                        .catch((e) => {
                            self.$message({
                                message: `Não foi possível efetuar as modificações`,
                                type: "error",
                                showClose: true,
                                offset: 60,
                            });
                        });
                })
                .catch((e) => {
                    self.$message({
                        message: `Não foi possível efetuar as modificações`,
                        type: "error",
                        showClose: true,
                        offset: 60,
                    });
                });
        },
    },
    mounted() {
        this.getLicenseKeysElements();
    },
};
</script>


<style scoped>
h5 {
    color: #a1a0a0;
}
</style>