<template>
    <div
        class="main-dashboard"
        v-loading="
            !dronesInitialized ||
            !solosInitialized ||
            smartElements.map((v) => v.internals).filter((v) => !v).length >
                0 ||
            logReviewsStatus === 'waiting' ||
            dronesReportStatus === 'waiting' ||
            solosReportStatus === 'waiting' ||
            navState.forcedLoading ||
            !user.loggedIn
        "
        :element-loading-text="$t('Message_loading') + '...'"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="height: 100vh"
        customClass="loading-custom-class"
    >
        <app-navbar v-show="!navState.streamFocused"></app-navbar>
        <!--  -->
        <app-drone-left-container
            v-show="
                !navState.streamFocused &&
                navState.mainNav === 'drone' &&
                navState.topBar !== 3
            "
        ></app-drone-left-container>
        <app-drone-topbar
            v-if="navState.mainNav === 'drone' && navState.topBar === 3"
        ></app-drone-topbar>
        <app-drone-report
            v-if="navState.mainNav === 'drone' && navState.topBar === 3"
        >
        </app-drone-report>
        <!--  -->
        <app-solo-left-container
            v-show="!navState.streamFocused && navState.mainNav === 'solo'"
        ></app-solo-left-container>
        <app-solo-topbar
            v-if="navState.mainNav === 'solo' && navState.topBar === 2"
        ></app-solo-topbar>
        <app-solo-report
            v-if="navState.mainNav === 'solo' && navState.topBar === 2"
        >
        </app-solo-report>
        <!--  -->
        <app-review-container
            v-if="navState.mainNav === 'review'"
        ></app-review-container>
        <!--  -->

        <app-settings-container
            v-if="navState.mainNav === 'setup'"
        ></app-settings-container>

        <div
            v-if="
                ((navState.mainNav === 'drone' && navState.topBar !== 3) ||
                    (navState.mainNav === 'solo' && navState.topBar !== 2)) &&
                user.loggedIn
            "
            :class="{
                'main-gmaps': !navState.streamFocused,
                'main-gmaps-when-stream-focused': navState.streamFocused,
            }"
        >
            <app-gmaps></app-gmaps>
        </div>
        <div
            class="main-drone-mission-card-root"
            v-show="
                navState.mainNav === 'drone' &&
                navState.topBar === 2 &&
                droneActiveMission
            "
        >
            <app-drone-mission-card></app-drone-mission-card>
        </div>
        <div
            class="main-drone-mission-commands-root"
            v-show="
                navState.mainNav === 'drone' &&
                navState.topBar === 1 &&
                droneActive
            "
            style="z-index: 200"
        >
            <app-drone-mission-commands></app-drone-mission-commands>
        </div>

        <div
            class="main-solo-commands-root"
            v-show="
                navState.mainNav === 'solo' &&
                navState.topBar === 1 &&
                soloActive
            "
            style="z-index: 200"
        >
            <app-solo-commands></app-solo-commands>
        </div>

        <div
            :key="'drone-stream-card-' + index"
            v-for="(drone, index) in dronesElements"
        >
            <!--  -->
            <div
                v-if="
                    navState.mainNav === 'drone' &&
                    navState.topBar === 1 &&
                    droneActive === drone.vUID &&
                    drone.stream.vIS_STREAM_RESTARTED !== false &&
                    drone.stream.vIS_RTMP_STREAM !== true
                "
                :class="{
                    'main-dashboard-stream-card': !navState.streamFocused,
                    'main-dashboard-stream-card-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <app-drone-stream-card :drone="drone"></app-drone-stream-card>
            </div>
            <div
                v-if="
                    navState.mainNav === 'drone' &&
                    navState.topBar === 1 &&
                    droneActive === drone.vUID &&
                    drone.stream.vIS_STREAM_RESTARTED !== false &&
                    drone.stream.vIS_RTMP_STREAM === true
                "
                :class="{
                    'main-dashboard-stream-card': !navState.streamFocused,
                    'main-dashboard-stream-card-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <app-solo-stream-card-rtmp
                    :drone="drone"
                ></app-solo-stream-card-rtmp>
            </div>
            <!--  -->
            <div
                v-if="
                    navState.mainNav === 'drone' &&
                    navState.topBar === 1 &&
                    droneActive === drone.vUID
                "
                style="z-index: 200"
                :class="{
                    'main-drone-pilot-controls-root': !navState.streamFocused,
                    'main-drone-pilot-controls-root-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <app-drone-pilot-controls
                    :drone="drone"
                ></app-drone-pilot-controls>
            </div>
            <div
                v-if="
                    navState.mainNav === 'drone' &&
                    navState.topBar === 1 &&
                    droneActive === drone.vUID
                "
                style="z-index: 201"
                :class="{
                    'main-drone-stream-telemetry-root': !navState.streamFocused,
                    'main-drone-stream-telemetry-root-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <app-drone-stream-telemetry
                    :drone="drone"
                ></app-drone-stream-telemetry>
            </div>

            <div
                v-if="
                    navState.mainNav === 'drone' &&
                    navState.topBar === 1 &&
                    droneActive === drone.vUID &&
                    drone.vIS_CONNECTED
                "
                style="z-index: 201"
                :class="{
                    'main-drone-moisaic-button-root': !navState.streamFocused,
                    'main-drone-moisaic-button-root-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <button class="btn btn-link" @click="handleDialogMosaic()">
                    <i class="fa fa-th-large"></i>
                </button>
            </div>
        </div>

        <div
            :key="'solo-stream-card-' + index"
            v-for="(solo, index) in solosElements"
        >
            <!--  -->
            <div
                v-if="
                    navState.mainNav === 'solo' &&
                    navState.topBar === 1 &&
                    soloActive === solo.vUID &&
                    solo.stream.vIS_STREAM_RESTARTED !== false
                "
                :class="{
                    'main-dashboard-stream-card': !navState.streamFocused,
                    'main-dashboard-stream-card-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <app-solo-stream-card :solo="solo"></app-solo-stream-card>
            </div>
            <!--  -->
            <!-- <div
                v-if="
                    navState.mainNav === 'drone' &&
                    navState.topBar === 1 &&
                    droneActive === drone.vUID
                "
                style="z-index: 201"
                :class="{
                    'main-drone-stream-telemetry-root': !navState.streamFocused,
                    'main-drone-stream-telemetry-root-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <app-drone-stream-telemetry
                    :drone="drone"
                ></app-drone-stream-telemetry>
            </div> -->

            <!-- <div
                v-if="
                    navState.mainNav === 'drone' &&
                    navState.topBar === 1 &&
                    droneActive === drone.vUID &&
                    drone.vIS_CONNECTED
                "
                style="z-index: 201"
                :class="{
                    'main-drone-moisaic-button-root': !navState.streamFocused,
                    'main-drone-moisaic-button-root-when-stream-focused':
                        navState.streamFocused,
                }"
            >
                <button class="btn btn-link" @click="handleDialogMosaic()">
                    <i class="fa fa-th-large"></i>
                </button>
            </div> -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import appNavbar from "@/components/Navbar";
import appDroneTopbar from "@/components/drone/DroneTopbar";
import appDroneLeftContainer from "@/components/drone/DroneLeftContainer";
import appDroneReport from "@/components/drone/DroneReport";
import appSoloTopbar from "@/components/solo/SoloTopbar";
import appSoloReport from "@/components/solo/SoloReport";
import appSoloLeftContainer from "@/components/solo/SoloLeftContainer";
import appReviewContainer from "@/components/review/ReviewContainer";
import appSettingsContainer from "@/components/settings/SettingsContainer";
import appDroneMissionCard from "@/components/drone/DroneMissionCard";
import appDroneMissionCommands from "@/components/drone/DroneMissionCommands";
import appDroneStreamCard from "@/components/drone/DroneStreamCard";
import appDroneStreamCardRtmp from "@/components/drone/DroneStreamCardRtmp";
import appSoloStreamCard from "@/components/solo/SoloStreamCard";
import appDronePilotControls from "@/components/drone/containers/DronePilotControls";
import appDroneStreamTelemetry from "@/components/drone/containers/DroneStreamTelemetry";
import appSoloCommands from "@/components/solo/SoloCommands";
import appGmaps from "@/components/Gmaps";
import firebase from "firebase";
import moment from "moment";

// import appRootDiv from "./../components/RootDiv";
export default {
    data() {
        return {
            lastLogin: null,
            intervalMonitored: null,
            intervalWatched: null,
            lastTimeUpdated: {
                eventsNotifications: Date.now(),
            },
        };
    },
    components: {
        appNavbar,
        appDroneTopbar,
        appSoloTopbar,
        appDroneLeftContainer,
        appSoloLeftContainer,
        appDroneReport,
        appSoloReport,
        appReviewContainer,
        appGmaps,
        appDroneMissionCard,
        appDroneMissionCommands,
        appSoloCommands,
        appDroneStreamCard,
        appDroneStreamCardRtmp,
        appDronePilotControls,
        appSettingsContainer,
        appDroneStreamTelemetry,
        appSoloStreamCard,
        // appRootDiv,
    },
    computed: {
        ...mapGetters([
            "user",
            "navState",
            "dronesElements",
            "droneActive",
            "droneActiveMission",
            "dronesInitialized",
            "dronesReportStatus",
            "solosReportStatus",
            "logReviewsStatus",
            "dronesReportStatus",
            "soloActive",
            "solosElements",
            "smartElements",
            "solosInitialized",
            "events",
            "eventsNotifications",
        ]),
    },
    methods: {
        ...mapActions([
            "fetchLicenses",
            "setNavStreamFocused",
            "setDroneActive",
            "setNavStreamDialogDrawerVisible",
        ]),

        msToDDMMYYYYHHmmss(ms) {
            return moment(ms).format("DD/MM/YYYY HH:mm:ss");
        },
        handleDialogMosaic() {
            this.setNavStreamFocused(false);
            this.setDroneActive(null);
            this.setNavStreamDialogDrawerVisible(true);
        },
    },
    watch: {
        "user.toast": function (newVal, oldVal) {
            if (
                newVal.message !== null &&
                newVal.message !== "none" &&
                ["success", "warning", "error", "info"].indexOf(
                    newVal.color
                ) !== -1
            ) {
                this.$message({
                    message: newVal.message,
                    type: newVal.color,
                    duration: 5000,
                    showClose: true,
                    offset: 60,
                });
            }
        },
        "user.data.lastLogin": function (newVal, oldVal) {
            this.lastLogin = newVal;
            if (typeof oldVal !== "undefined") {
                this.$message({
                    message: `A sua conta está sendo usada em outro dispositivo. Você será desconectado(a).`,
                    type: "warning",
                    duration: 5000,
                    showClose: true,
                    offset: 60,
                });
                setTimeout(() => {
                    firebase
                        .auth()
                        .signOut()
                        .then(() => {
                            location.reload(true);
                        });
                }, 5000);
            }
        },
        eventsNotifications: function (newVal) {
            let self = this;
            for (let n of newVal.filter(
                (v) =>
                    v.vTIMESTAMP > this.lastTimeUpdated["eventsNotifications"]
            )) {
                let message = `${
                    self.smartElements[
                        self.smartElements
                            .map((v) => v.vUID)
                            .indexOf(n.vCHILD_UID)
                    ].internals.vCHILD_NAME
                }: Novo evento ${
                    self.events.map((v) => v.value).indexOf(n.vEVENT_TYPE) !==
                    -1
                        ? self.events[
                              self.events
                                  .map((v) => v.value)
                                  .indexOf(n.vEVENT_TYPE)
                          ].label
                        : "desconhecido"
                } detectado - ${self.msToDDMMYYYYHHmmss(n.vTIMESTAMP)}`;
                self.$message({
                    message: message,
                    type: "warning",
                    duration: 5000,
                    showClose: true,
                    offset: 60,
                });
            }
            this.lastTimeUpdated["eventsNotifications"] = Date.now();
        },
    },
    mounted() {
        console.log("mounted");
        // this.fetchLicenses();
        let self = this;
        if (this.intervalMonitored) clearInterval(this.intervalMonitored);
        if (this.intervalWatched) clearInterval(this.intervalWatched);

        this.intervalMonitored = setInterval(() => {
            if (self.navState.mainNav === "drone") {
                for (let d of self.dronesElements) {
                    let uid = d.vUID;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vIS_DEVICE_BEING_MONITORED`
                        )
                        .set(true);
                }
            } else if (self.navState.mainNav === "solo") {
                for (let s of self.solosElements) {
                    let uid = s.vUID;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vIS_DEVICE_BEING_MONITORED`
                        )
                        .set(true);
                }
            }
        }, 60 * 1000);

        this.intervalMonitored = setInterval(() => {
            if (self.navState.streamDialogDrawerVisible) {
                for (let d of self.dronesElements) {
                    let uid = d.vUID;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vSTREAMING_BEING_WATCHED_AT`
                        )
                        .set(Date.now());
                }
                for (let s of self.solosElements) {
                    let uid = s.vUID;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vSTREAMING_BEING_WATCHED_AT`
                        )
                        .set(Date.now());
                }
            } else {
                if (self.droneActive) {
                    let uid = self.droneActive;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vSTREAMING_BEING_WATCHED_AT`
                        )
                        .set(Date.now());
                } else if (self.soloActive) {
                    let uid = self.soloActive;
                    firebase
                        .database()
                        .ref(
                            `aegisv2/users/${uid}/telemetry/vSTREAMING_BEING_WATCHED_AT`
                        )
                        .set(Date.now());
                }
            }
        }, 15 * 1000);
    },
};
</script>

<style scoped>
.main-dashboard {
    overflow: hidden !important;
}
.main-gmaps {
    position: absolute;
    left: 350px;
    top: 50px;
    height: calc(100vh - 50px);
    width: calc(100vw - 350px);
    z-index: 100;
}

.main-gmaps-when-stream-focused {
    position: absolute;
    right: 50px;
    top: 100px;
    width: 480px;
    height: 270px;
    z-index: 200;
}

.main-drone-mission-card-root {
    position: relative;
    left: calc(100vw - 370px);
    top: 74px;
    width: 350px;
    height: calc(100vh - 64px);
    z-index: 300;
}

.main-drone-mission-commands-root {
    position: absolute;
    right: 50px;
    bottom: 50px;
    width: 350px;
    height: 50px;
}

.main-solo-commands-root {
    position: absolute;
    left: calc(350px + (100% - 600px) / 2);
    bottom: 50px;
    width: 340px;
    height: 80px;
}

.main-drone-pilot-controls-root {
    position: absolute;
    right: calc(50vw - 436px / 2 - 330px / 2);
    bottom: 50px;
}
.main-drone-pilot-controls-root-when-stream-focused {
    position: absolute;
    right: calc(50vw - 436px / 2);
    bottom: 50px;
}

.main-drone-stream-telemetry-root {
    display: none;
}

.main-drone-stream-telemetry-root-when-stream-focused {
    position: absolute;
    left: 50px;
    bottom: 50px;
}

.main-dashboard-stream-card {
    position: absolute;
    right: 20px;
    top: 100px;
    width: 540px;
    height: 270px;
    z-index: 200;
}

.main-dashboard-stream-card-when-stream-focused {
    position: absolute;
    left: 0px;
    top: 0;
    height: calc(100vh);
    width: 100vw;
    background-color: #000000;
    z-index: 100;
}

.main-drone-moisaic-button-root {
    display: none;
}

.main-drone-moisaic-button-root-when-stream-focused {
    position: absolute;
    right: 20px;
    top: 20px;
}

.loading-custom-class {
    z-index: 9999 !important;
}
</style>