// 
import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import {
    routes
} from "./routes";

import store from './store'

import { i18n } from '@/plugins/i18n';
import FlagIcon from 'vue-flag-icon';

Vue.use(FlagIcon);


import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/pt-br'
import Masked from 'vue-the-mask';

import 'element-ui/lib/theme-chalk/index.css';

// import firebase from "firebase"
import firebase from 'firebase';
// import * as VueGoogleMaps from "vue2-google-maps";
import {
    BootstrapVue,
    IconsPlugin
}
from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;

import * as VueGoogleMaps from "vue2-google-maps";





Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBUWkIG-fRHIXYAe_tcVSHMd9fzNeb2JRg",
        libraries: ['visualization', 'places']
    },
});

Vue.use(VueClipboard)


Vue.use(ElementUI, {
    locale
});
Vue.use(Masked)
Vue.use(IconsPlugin);
Vue.use(require('vue-moment'));

const router = new VueRouter({
    routes,
    mode: "history",
});

Vue.config.productionTip = false

const config = {
    apiKey: "AIzaSyB_YCF1IEEvUwsBAZu_MMRwVOehWDRQ0EQ",
    authDomain: "aeroguard-b83bc.firebaseapp.com",
    databaseURL: "https://aeroguard-b83bc.firebaseio.com",
    projectId: "aeroguard-b83bc",
    storageBucket: "aeroguard-b83bc.appspot.com",
    messagingSenderId: "475163584777",
    appId: "1:475163584777:web:91075805c6a39ff538cbd8"
};

firebase.initializeApp(config);

if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    messaging
        .requestPermission()
        .then(function() {
            console.log("Messaging: Permission granted");
            return messaging.getToken();
        })
        .then(function(token) {})
        .catch(function(error) {
            console.log(error);
            console.log("Messaging: Error");
        });


    messaging.onMessage(function(payload) {
        const notificationOption = {
            body: payload.notification.body,
            icon: payload.notification.icon,
            click_action: "https://aeroguard-dashboard.web.app/"
        }

        if (Notification.permission === 'granted') {
            let notification = new Notification(payload.notification.title, notificationOption);

            notification.onclick = function(e) {
                e.preventDefault();
                window.open(payload.notification.click_action, '_blank');
                notification.close()
            }
        }
    });


} else {
    console.log('Messaging not supported.');
}

firebase.auth().onAuthStateChanged((user) => {
    firebase
        .database()
        .ref(`aegisv2/users/${user.uid}/internals`)
        .once("value", function(snapshot) {
            if (snapshot.child('vCHILD_TYPE').val() === 'main' || snapshot.child('vCHILD_TYPE').val() === 'supervisor') {
                store.dispatch("fetchUser", {
                    user: user,
                    internals: snapshot.val()
                });
            } else {
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        console.log("Going to login");
                        location.reload(true);
                    });
            }
        });
});

router.beforeEach((to, from, next) => {
    let currentUser = firebase.auth().currentUser;
    let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth && !currentUser) next("/login");
    else if (!requiresAuth && currentUser) next("/dasboard");
    else next();
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')