<template>
    <div>
        <div class="div-main-nav bg-dark-medium-gray">
            <div style="height: 100%; width: 350px">
                <div class="row mx-0" style="height: 100%">
                    <div
                        class="col-12 align-self-center"
                        v-if="user.data.internals.vTEAM_LOGO != null"
                    >
                        <img
                            :src="user.data.internals.vTEAM_LOGO"
                            alt=""
                            style="height: 30px"
                        />
                    </div>
                    <div class="col-12 align-self-center" v-else>
                        <img
                            v-if="dronesElements.length > 0"
                            style="height: 25px"
                            :src="require('@/assets/logoAeroguard.png')"
                        />
                        <img
                            v-else
                            style="height: 30px"
                            :src="require('@/assets/logoSolo.png')"
                        />
                    </div>
                </div>
            </div>
            <div
                style="
                    height: 50px;
                    width: calc(100vw - 400px);
                    position: absolute;
                    left: 350px;
                    top: 0px;
                "
                v-if="true"
            >
                <div
                    v-if="true"
                    class="row mx-0"
                    style="height: 100%; width: calc(100vw - 400px)"
                >
                    <div
                        class="calign-self-center"
                        v-if="dronesElements.length > 0"
                        :class="{
                            'col-4': solosElements.length === 0,
                            'col-2': solosElements.length > 0,
                        }"
                    >
                        <b-dropdown
                            block
                            class="m-2"
                            menu-class="w-100 "
                            style="border: none"
                            :toggle-class="{
                                'btn-active-color':
                                    navState.mainNav === 'drone',
                            }"
                        >
                            <template #button-content>
                                <button
                                    class="btn btn-link px-0 mx-0 py-0 my-0"
                                    @click="
                                        setNavMainNav('drone');
                                        setDroneActiveMission(null);
                                        navState.mainNav !== 'drone'
                                            ? setNavTopbar(1)
                                            : false;
                                    "
                                    :class="{
                                        active: navState.mainNav === 'drone',
                                    }"
                                >
                                    <span
                                        style="
                                            font-weight: 600;
                                            font-size: 1rem !important;
                                        "
                                        >{{ $t("DRONES") }}</span
                                    >
                                </button>
                            </template>
                            <b-dropdown-item
                                href="#"
                                @click="
                                    setNavMainNav('drone');
                                    setNavTopbar(1);
                                    setDroneActiveMission(null);
                                    handleCollapseDronesFocusOut();
                                "
                                ><span
                                    :class="{
                                        active:
                                            navState.mainNav === 'drone' &&
                                            navState.topBar === 1,
                                    }"
                                    >{{ $t("List_of_Drones") }}</span
                                >
                            </b-dropdown-item>
                            <b-dropdown-item
                                href="#"
                                @click="
                                    setNavMainNav('drone');
                                    setNavTopbar(2);
                                    setDroneActive(null);
                                    handleCollapseDronesFocusOut();
                                "
                                ><span
                                    :class="{
                                        active:
                                            navState.mainNav === 'drone' &&
                                            navState.topBar === 2,
                                    }"
                                    >{{ $t("Missions") }}</span
                                ></b-dropdown-item
                            >
                            <b-dropdown-item
                                href="#"
                                @click="
                                    setNavMainNav('drone');
                                    setNavTopbar(3);
                                    setDroneActiveMission(null);
                                    setDroneActive(null);
                                    handleCollapseDronesFocusOut();
                                "
                                ><span
                                    :class="{
                                        active:
                                            navState.mainNav === 'drone' &&
                                            navState.topBar === 3,
                                    }"
                                    >{{ $t("Reports") }}</span
                                ></b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                    <div
                        v-if="solosElements.length > 0"
                        class="align-self-center"
                        :class="{
                            'col-4': dronesElements.length === 0,
                            'col-2': dronesElements.length > 0,
                        }"
                    >
                        <b-dropdown
                            block
                            class="m-2"
                            menu-class="w-100 "
                            style="border: none"
                            :toggle-class="{
                                'btn-active-color': navState.mainNav === 'solo',
                            }"
                        >
                            <template #button-content>
                                <button
                                    class="btn btn-link px-0 mx-0 py-0 my-0"
                                    @click="
                                        setSoloActive(null);
                                        setDroneActive(null);
                                        setDroneActiveMission(null);
                                        setNavMainNav('solo');
                                        setNavTopbar(1); //TODO: remove it if there are more than one sub menu
                                        navState.mainNav !== 'solo'
                                            ? setNavTopbar(1)
                                            : false;
                                    "
                                    :class="{
                                        active: navState.mainNav === 'solo',
                                    }"
                                >
                                    <span
                                        style="
                                            font-weight: 600;
                                            font-size: 1rem !important;
                                        "
                                        >{{ $t("SMARTCAMS") }}</span
                                    >
                                </button>
                            </template>
                            <b-dropdown-item
                                href="#"
                                @click="
                                    setNavMainNav('solo');
                                    setNavTopbar(1);
                                    setDroneActiveMission(null);
                                    handleCollapseSolosFocusOut();
                                "
                                ><span
                                    :class="{
                                        active:
                                            navState.mainNav === 'solo' &&
                                            navState.topBar === 1,
                                    }"
                                >
                                    {{ $t("List_of_SmartCams") }}</span
                                >
                            </b-dropdown-item>
                            <b-dropdown-item
                                href="#"
                                @click="
                                    setNavMainNav('solo');
                                    setNavTopbar(2);
                                    setDroneActiveMission(null);
                                    handleCollapseSolosFocusOut();
                                "
                                ><span
                                    :class="{
                                        active:
                                            navState.mainNav === 'solo' &&
                                            navState.topBar === 2,
                                    }"
                                >
                                    {{ $t("Reports") }}</span
                                >
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="col-2 align-self-center">
                        <button
                            class="btn btn-link px-0 mx-0 py-0 my-0"
                            style="
                                border: none;
                                padding: 0;
                                margin: 0;
                                font-size: 1rem !important;
                            "
                            :class="{
                                active: navState.mainNav === 'review',
                            }"
                            @click="
                                setNavMainNav('review');
                                setNavTopbar(1);
                                setDroneActive(null);
                                setDroneActiveMission(null);
                            "
                        >
                            <span style="font-weight: 600">{{
                                $t("REVIEW")
                            }}</span>
                        </button>
                    </div>
                    <div class="col-2 align-self-center">
                        <button
                            class="btn btn-link px-0 mx-0 py-0 my-0"
                            style="
                                border: none;
                                padding: 0;
                                margin: 0;
                                font-size: 1rem !important;
                            "
                            :class="{
                                active: navState.mainNav === 'setup',
                            }"
                            @click="
                                setNavMainNav('setup');
                                setNavTopbar(1);
                                setDroneActive(null);
                                setDroneActiveMission(null);
                            "
                            v-if="
                                user.data
                                    ? user.data.realInternals.vCHILD_TYPE ===
                                      'main'
                                    : false
                            "
                        >
                            <span style="font-weight: 600">{{
                                $t("SETTINGS").toUpperCase()
                            }}</span>
                        </button>
                    </div>
                    <div class="col-1 align-self-center">
                        <button
                            class="btn btn-link"
                            @click="alertDrawer = true"
                            :class="{ pulsed: eventsNotifications.length > 0 }"
                        >
                            <el-badge
                                :value="eventsNotifications.length"
                                :max="9"
                                class="badge-custom mx-0"
                            >
                                <i class="far fa-bell"></i>
                            </el-badge>
                        </button>
                        <button
                            class="btn btn-link"
                            @click="handleDialogMosaic()"
                            :disabled="
                                dronesElements.length === 0 &&
                                solosElements.length === 0
                            "
                        >
                            <i class="fa fa-th-large"></i>
                        </button>
                    </div>
                    <div class="col-1 mx-0 px-0 align-self-center text-right">
                        <b-dropdown
                            block
                            class="m-2"
                            menu-class="w-100"
                            style="border: none; display: inline-block"
                        >
                            <template #button-content>
                                <flag
                                    :iso="
                                        languages[
                                            languages
                                                .map((v) => v.language)
                                                .indexOf($i18n.locale)
                                        ].flag
                                    "
                                    v-bind:squared="false"
                                />
                            </template>
                            <b-dropdown-item
                                href="#"
                                @click="handleLocaleChange(l)"
                                v-for="l in languages.filter(
                                    (v) => v.language !== $i18n.locale
                                )"
                                :key="l.flag"
                            >
                                <flag :iso="l.flag" v-bind:squared="false" />
                                {{ l.title }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="col-2 align-self-center text-right mx-0 px-0">
                        <b-dropdown
                            block
                            class="m-2"
                            menu-class="w-100"
                            style="border: none; display: inline-block"
                        >
                            <template #button-content>
                                <i
                                    class="fas mx-2"
                                    style="display: inline-block"
                                    :class="{
                                        'fa-user-shield': user.data
                                            ? user.data.realInternals
                                                  .vCHILD_TYPE === 'main'
                                            : false,
                                        'fa-user': user.data
                                            ? user.data.realInternals
                                                  .vCHILD_TYPE === 'supervisor'
                                            : false,
                                    }"
                                ></i
                                >{{
                                    user.data
                                        ? user.data.internals.vTEAM_NAME
                                        : ""
                                }}
                            </template>
                            <b-dropdown-item
                                href="#"
                                @click="
                                    handleCollapseUserFocusOut();
                                    signOut();
                                "
                            >
                                <span>Sair</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="div-main-nav" v-if="false">
            <ul class="nav">
                <li class="nav-item-brand">
                    <img
                        class="img-brand"
                        style="padding: 20px"
                        :src="require('@/assets/logo-aeroguard.svg')"
                        alt
                    />
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        href="#"
                        @click="
                            setSoloActive(null);
                            setDroneActive(null);
                            setDroneActiveMission(null);
                            setNavMainNav('drone');
                            setNavTopbar(1);
                        "
                    >
                        <icon-drone
                            :active="navState.mainNav == 'drone'"
                        ></icon-drone>
                        <span
                            :class="{
                                'active-label': navState.mainNav == 'drone',
                                label: navState.mainNav !== 'drone',
                            }"
                            >Drones</span
                        >
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        href="#"
                        @click="
                            setNavMainNav('review');
                            setNavTopbar(1);
                            setDroneActive(null);
                            setDroneActiveMission(null);
                        "
                    >
                        <i
                            class="fas fa-chart-bar"
                            :class="{
                                'active-label': navState.mainNav == 'review',
                                label: navState.mainNav !== 'review',
                            }"
                        ></i>
                        <span
                            :class="{
                                'active-label': navState.mainNav == 'review',
                                label: navState.mainNav !== 'review',
                            }"
                            >Revisão</span
                        >
                    </a>
                </li>
                <li class="nav-item" style="position: absolute; bottom: 80px">
                    <a
                        class="nav-link"
                        href="#"
                        @click="
                            setNavMainNav('setup');
                            setNavTopbar(1);
                            setDroneActive(null);
                            setDroneActiveMission(null);
                        "
                    >
                        <i
                            class="fa fa-gear"
                            :class="{
                                'active-label': navState.mainNav == 'setup',
                                label: navState.mainNav !== 'setup',
                            }"
                        ></i>
                        <span
                            :class="{
                                'active-label': navState.mainNav == 'setup',
                                label: navState.mainNav !== 'setup',
                            }"
                            >Config.</span
                        >
                    </a>
                </li>
                <li class="nav-item" style="position: absolute; bottom: 0px">
                    <a class="nav-link" href="#" @click="signOut">
                        <i class="fas fa-arrow-alt-circle-left"></i>
                    </a>
                    <span class="label">Sair</span>
                </li>
            </ul>
        </div>
        <el-drawer
            :visible.sync="alertDrawer"
            :with-header="false"
            custom-class="drawer-custom"
            size="20%"
        >
            <div
                class="row bg-dark-medium-gray"
                style="line-height: 2rem; padding: 1rem"
            >
                <div class="col-12 text-center" style="color: #ffe082">
                    <div :class="{ pulsed: eventsNotifications.length > 0 }">
                        <el-badge
                            :value="eventsNotifications.length"
                            :max="9"
                            class="badge-custom mx-2"
                        >
                            <i
                                class="fas fa-bell"
                                style="font-size: 1.4rem"
                            ></i>
                        </el-badge>
                    </div>
                    <span class="mx-2">{{ $t("Events") }}</span>
                </div>
                <br />
                <div class="col-6">
                    <el-radio
                        v-model="_showAllEventsNotifications"
                        :label="true"
                        >Mostrar todos</el-radio
                    >
                </div>
                <div class="col-6">
                    <el-radio
                        v-model="_showAllEventsNotifications"
                        :label="false"
                        >Apenas selecionado</el-radio
                    >
                </div>
            </div>
            <br />
            <div
                style="
                    padding: 0px 5px 0px 5px;
                    overflow-y: auto;
                    height: calc(100vh - 8rem - 100px);
                "
            >
                <div
                    v-for="(e, index) in eventsNotifications"
                    :key="'events-notification-' + index"
                >
                    <app-alert-element
                        :alert="e"
                        @toggle-alert-drawer="handleTogleAlertDrawer"
                    ></app-alert-element>
                </div>
            </div>
            <div
                class="row"
                style="
                    height: 70px;
                    position: absolute;
                    width: 100%;
                    left: 0px;
                    bottom: 0px;
                    padding: 0px;
                    margin: 0px;
                "
            >
                <div class="col-12 text-center my-auto">
                    <button
                        class="btn btn-primary"
                        @click="clearAllEventsNotifications"
                        :disabled="eventsNotifications.length === 0"
                    >
                        <i class="fas fa-trash"></i> Excluir todos
                    </button>
                </div>
            </div>
        </el-drawer>

        <el-drawer
            title="I am the title"
            :visible.sync="navState.streamDialogDrawerVisible"
            direction="rtl"
            :with-header="false"
            size="100%"
        >
            <div class="row" style="height: 30px">
                <div class="col-12 h-100 text-right">
                    <button
                        class="btn btn-link"
                        @click="setNavStreamDialogDrawerVisible(false)"
                    >
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div
                class="row"
                style="height: calc(100vh - 30px); margin: 0; overflow-y: auto"
            >
                <div
                    v-for="(e, index) in (visibleStreamItems.drones
                        ? dronesElements
                        : []
                    ).concat(visibleStreamItems.solo ? solosElements : [])"
                    :key="'mosaic-element-' + index"
                    class="col-6 h-50"
                    v-show="
                        e.vIS_CONNECTED &&
                        e.stream.vIS_STREAMING_TO_CLOUD &&
                        navState.streamDialogDrawerVisible
                    "
                >
                    <div
                        v-if="
                            e.vIS_CONNECTED &&
                            e.stream.vIS_STREAMING_TO_CLOUD &&
                            navState.streamDialogDrawerVisible
                        "
                        style="padding: 10px; height: 100%"
                    >
                        <app-stream-element
                            :element="e"
                            style="width: 100%; height: 100%"
                        ></app-stream-element>
                    </div>
                </div>

                <div
                    v-for="(e, index) in (visibleStreamItems.drones
                        ? dronesElements
                        : []
                    ).concat(visibleStreamItems.solo ? solosElements : [])"
                    :key="'mosaic-fake-element-' + index"
                    class="col-6 h-50"
                    v-show="!e.vIS_CONNECTED"
                >
                    <div
                        v-if="!e.vIS_CONNECTED"
                        style="padding: 10px; height: 100%"
                    >
                        <app-fake-stream-element
                            :element="e"
                            style="width: 100%; height: 100%"
                        ></app-fake-stream-element>
                    </div>
                </div>
            </div>

            <div
                v-if="dronesElements.length > 0 && solosElements.length > 0"
                style="
                    position: absolute;
                    right: 30px;
                    bottom: 30px;
                    padding: 10px;
                    background-color: rgba(73, 73, 73, 0.7);
                    border-top-left-radius: 55px;
                    border-top-right-radius: 55px;
                    border-bottom-left-radius: 55px;
                    border-bottom-right-radius: 55px;
                "
            >
                <div class="row my-0">
                    <div class="col-12 text-left">
                        <button
                            class="btn btn-link mx-2"
                            style="
                                border-radius: 50% !important;
                                height: 55px;
                                width: 55px;
                            "
                            :class="{
                                'active-color': visibleStreamItems.solo,
                                'btn-primary': visibleStreamItems.solo,
                                'btn-secondary': !visibleStreamItems.solo,
                            }"
                            @click="
                                visibleStreamItems.solo =
                                    !visibleStreamItems.solo
                            "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26.809"
                                height="33.689"
                                viewBox="0 0 26.809 33.689"
                            >
                                <g
                                    id="Grupo_1536"
                                    data-name="Grupo 1536"
                                    transform="translate(0 -320.62)"
                                >
                                    <path
                                        id="Caminho_1"
                                        data-name="Caminho 1"
                                        d="M608.756,182.384q0,1.311,0,2.623c0,.311,0,.307-.334.15-.693-.327-1.379-.67-2.087-.962-.162-.067-.207-.105-.206-.261,0-.828-.015-1.657.013-2.485.008-.243-.1-.287-.27-.367-.71-.342-1.4-.721-2.123-1.042-.954-.427-1.86-.961-2.817-1.365-1.1-.465-2.143-1.045-3.227-1.541-.29-.133-.586-.254-.873-.393-.479-.232-.954-.475-1.431-.713-.48.23-.951.481-1.442.686-.867.363-1.672.861-2.535,1.214-1.247.51-2.4,1.206-3.635,1.726-1.024.43-2,.954-2.986,1.46-.12.062-.23.053-.228.246.011,1.4.006,2.807.006,4.211a7.811,7.811,0,0,1,.159,1.526,7.9,7.9,0,0,1,.123,1.189.258.258,0,0,0,.14.169c1,.466,1.956,1.034,2.994,1.439.85.331,1.646.8,2.47,1.2.976.476,1.953.95,2.935,1.414.577.272,1.184.487,1.736.8.21.119.281.073.452-.012,1.506-.751,3.063-1.394,4.542-2.2.365-.2.792-.3,1.171-.5.03-.016.118.009.123.028a.9.9,0,0,1,.006.206c0,.736-.02,1.473.009,2.209.013.331-.127.461-.4.59-1.349.635-2.684,1.3-4.029,1.942a5.662,5.662,0,0,0-1.163.509c-.49.4-.813.072-1.2-.107s-.792-.312-1.175-.495c-.731-.349-1.449-.723-2.177-1.077-1.072-.522-2.138-1.059-3.225-1.548-1.109-.5-2.177-1.079-3.272-1.6-.594-.284-1.206-.532-1.8-.83a.728.728,0,0,1-.5-.763,3.328,3.328,0,0,0-.127-.7c-.042-.235.108-.507-.137-.7-.011-.009-.005-.045,0-.067a9.057,9.057,0,0,0-.149-1.67c0-.694-.1-1.388-.123-2.083-.023-.713-.005-1.426-.005-2.14v-2.14c0-.161.007-.323,0-.483s.1-.14.195-.2c1.182-.69,2.464-1.169,3.682-1.781,1.5-.755,3.005-1.519,4.542-2.2,1.073-.476,2.1-1.048,3.166-1.522.565-.25,1.135-.485,1.678-.78.122-.067.179-.039.291.016,1.32.644,2.646,1.276,3.972,1.909.961.458,1.924.911,2.885,1.367.942.448,1.886.892,2.825,1.347,1.112.538,2.215,1.095,3.333,1.62.165.078.237.125.234.315C608.749,180.635,608.756,181.509,608.756,182.384Z"
                                        transform="translate(-581.952 147.472)"
                                        :fill="
                                            visibleStreamItems.solo
                                                ? '#404040'
                                                : '#FFFFFF'
                                        "
                                        style="isolation: isolate"
                                    />
                                    <path
                                        id="Caminho_2"
                                        data-name="Caminho 2"
                                        d="M598.782,240.581c0-.391.006-.783,0-1.174-.005-.25.091-.359.332-.49.92-.5,1.877-.917,2.819-1.366.6-.284,1.189-.573,1.785-.858.346-.165.691-.332,1.043-.483a.33.33,0,0,1,.232.027c.68.3,1.361.606,2.033.926q2.134,1.016,4.26,2.05c.92.447,1.807.976,2.755,1.348,1.156.453,2.213,1.1,3.351,1.579.078.033.152.079.232.107.346.118.49.285.341.582a2.007,2.007,0,0,0-.123.711c-.03.213-.1.421-.134.634-.134.765-.315,1.518-.516,2.27-.183.684-.346,1.371-.591,2.036a17.7,17.7,0,0,1-1.606,3.481c-.3.469-.537.979-.876,1.43a16.2,16.2,0,0,1-1.235,1.629c-.391.4-.74.849-1.129,1.255a25.148,25.148,0,0,1-3.125,2.606,32.621,32.621,0,0,1-2.9,1.863c-.268.16-.491.429-.857.433s-.584-.269-.862-.425a30.558,30.558,0,0,1-3.428-2.239,22.491,22.491,0,0,1-2.985-2.608c-.588-.631-1.108-1.32-1.632-2.009a21.137,21.137,0,0,1-1.976-3.262,27.052,27.052,0,0,1-1.011-2.549c-.014-.035,0-.109.022-.123.053-.031.138-.068.182-.047,1.013.5,2.026,1,3.029,1.522a.884.884,0,0,1,.35.4,26.415,26.415,0,0,0,1.534,2.539,7.384,7.384,0,0,0,.76.929,12.34,12.34,0,0,0,1.08,1.205,28.738,28.738,0,0,0,3.15,2.54,12.142,12.142,0,0,1,1.463.982.443.443,0,0,0,.61.008c1.052-.735,2.131-1.435,3.154-2.209a11.9,11.9,0,0,0,1.746-1.61,19.58,19.58,0,0,0,2.548-3.425,10.865,10.865,0,0,0,.8-1.535A7.439,7.439,0,0,0,614,247.7c.038-.3.329-.569.373-.871s.171-.579.241-.872a3.5,3.5,0,0,1,.213-.945c.18-.3-.012-.678.25-.939a.4.4,0,0,0-.134-.167c-1.379-.673-2.742-1.38-4.147-1.992-.979-.426-1.912-.937-2.878-1.384-.986-.457-1.961-.935-2.934-1.418-.136-.068-.187-.005-.29.047-.625.321-1.258.627-1.892.933q-1.726.833-3.456,1.658c-.134.064-.294.078-.418.154s-.141.012-.142-.084C598.78,241.41,598.782,241,598.782,240.581Z"
                                        transform="translate(-591.454 93.13)"
                                        :fill="
                                            visibleStreamItems.solo
                                                ? '#404040'
                                                : '#FFFFFF'
                                        "
                                    />
                                </g>
                            </svg>
                        </button>
                        <button
                            class="btn mx-2"
                            style="
                                border-radius: 50% !important;
                                height: 55px;
                                width: 55px;
                            "
                            :class="{
                                'active-color': visibleStreamItems.drones,
                                'btn-primary': visibleStreamItems.drones,
                                'btn-secondary': !visibleStreamItems.drones,
                            }"
                            @click="
                                visibleStreamItems.drones =
                                    !visibleStreamItems.drones
                            "
                        >
                            <svg
                                id="Camada_2"
                                data-name="Camada 2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="29.02"
                                height="28.762"
                                viewBox="0 0 29.02 28.762"
                            >
                                <g
                                    id="Camada_1"
                                    data-name="Camada 1"
                                    transform="translate(0)"
                                >
                                    <path
                                        id="Caminho_477"
                                        data-name="Caminho 477"
                                        d="M24.14,30.407A7.56,7.56,0,0,0,22.6,25.434a3.826,3.826,0,0,0-2.314-1.565,2.609,2.609,0,0,1-1.93-2.738A2.727,2.727,0,0,1,20.8,18.643a2.557,2.557,0,0,1,2.767,1.773c.5,2.273,2.247,3.068,4.15,3.578a12.8,12.8,0,0,0,3.3.229A6.5,6.5,0,0,0,35.5,22.356a3.728,3.728,0,0,0,.932-1.58,2.8,2.8,0,0,1,2.823-2.149,2.74,2.74,0,0,1,2.515,2.5,2.652,2.652,0,0,1-2.133,2.811,3.413,3.413,0,0,0-1.811,1.149,7.821,7.821,0,0,0,.294,10.464,3.637,3.637,0,0,0,1.471.833,2.794,2.794,0,0,1,2.193,2.864,2.746,2.746,0,0,1-2.535,2.485,2.8,2.8,0,0,1-2.811-2.167,3.89,3.89,0,0,0-1-1.624,6.964,6.964,0,0,0-4.529-1.818c-2.434-.13-4.7.249-6.482,2.061a2.946,2.946,0,0,0-.711,1.273,2.707,2.707,0,1,1-3.146-3.1,3.173,3.173,0,0,0,1.44-.844A7.812,7.812,0,0,0,24.14,30.407Zm8.273-.217a2.343,2.343,0,1,0-4.685-.045,2.779,2.779,0,0,0,2.112,2.512A2.646,2.646,0,0,0,32.413,30.19Z"
                                        transform="translate(-15.562 -15.797)"
                                        :fill="
                                            visibleStreamItems.drones
                                                ? '#404040'
                                                : '#FFFFFF'
                                        "
                                    />
                                    <path
                                        id="Caminho_478"
                                        data-name="Caminho 478"
                                        d="M125.219,10.5a3.433,3.433,0,0,1,1.97-1.5,3.548,3.548,0,0,0,2.659-3.818A3.668,3.668,0,0,0,126.514,1.9a3.564,3.564,0,0,0-3.761,2.737,2.88,2.88,0,0,1-1.524,1.849A5.177,5.177,0,0,1,123.673.741a5.349,5.349,0,0,1,7.238,7.449A5.186,5.186,0,0,1,125.219,10.5Z"
                                        transform="translate(-102.713 -0.009)"
                                        :fill="
                                            visibleStreamItems.drones
                                                ? '#404040'
                                                : '#FFFFFF'
                                        "
                                    />
                                    <path
                                        id="Caminho_479"
                                        data-name="Caminho 479"
                                        d="M10.52,123.594a5.156,5.156,0,0,1-2.3,5.669,5.381,5.381,0,0,1-6.563-.688,5.392,5.392,0,0,1-.967-6.405,5.249,5.249,0,0,1,5.74-2.624A2.652,2.652,0,0,1,4.7,121.061a3.564,3.564,0,0,0-2.8,3.859,3.685,3.685,0,0,0,3.344,3.264,3.561,3.561,0,0,0,3.775-2.812A2.889,2.889,0,0,1,10.52,123.594Z"
                                        transform="translate(-0.036 -101.312)"
                                        :fill="
                                            visibleStreamItems.drones
                                                ? '#404040'
                                                : '#FFFFFF'
                                        "
                                    />
                                    <path
                                        id="Caminho_480"
                                        data-name="Caminho 480"
                                        d="M6.4,10.6A5.135,5.135,0,0,1,.86,8.327a5.375,5.375,0,0,1,.606-6.57A5.4,5.4,0,0,1,8.059.813a5.2,5.2,0,0,1,2.465,5.735,2.724,2.724,0,0,1-1.5-1.755A3.569,3.569,0,0,0,5.466,1.978a3.631,3.631,0,0,0-.73,7.178A2.273,2.273,0,0,1,6.4,10.6Z"
                                        transform="translate(-0.013 -0.081)"
                                        :fill="
                                            visibleStreamItems.drones
                                                ? '#404040'
                                                : '#FFFFFF'
                                        "
                                    />
                                    <path
                                        id="Caminho_481"
                                        data-name="Caminho 481"
                                        d="M125.31,119.454a5.106,5.106,0,0,1,5.616,2.235,5.35,5.35,0,0,1-7.122,7.561,5.214,5.214,0,0,1-2.534-5.77,2.692,2.692,0,0,1,1.5,1.749,3.568,3.568,0,0,0,3.555,2.815,3.622,3.622,0,0,0,.727-7.163A3.009,3.009,0,0,1,125.31,119.454Z"
                                        transform="translate(-102.759 -101.177)"
                                        :fill="
                                            visibleStreamItems.drones
                                                ? '#404040'
                                                : '#FFFFFF'
                                        "
                                    />
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
    <!--  -->
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase";
import iconDrone from "@/components/icons/IconDrone";
import appStreamElement from "@/components/stream/StreamElement";
import appFakeStreamElement from "@/components/stream/FakeStreamElement";
import appAlertElement from "@/components/alert/AlertElement";
import moment from "moment";

export default {
    data() {
        return {
            alertDrawer: false,
            visibleStreamItems: {
                drones: true,
                solo: true,
            },
            languages: [
                { flag: "br", language: "pt", title: "Português" },
                { flag: "us", language: "en", title: "English" },
                // { flag: "fr", language: "fr", title: "Français" },
            ],
        };
    },
    components: {
        iconDrone,
        appStreamElement,
        appAlertElement,
        appFakeStreamElement,
    },
    computed: {
        ...mapGetters([
            "user",
            "navState",
            "dronesElements",
            "solosElements",
            "eventsNotifications",
            "smartElements",
            "checkedEventsNotifications",
            "showAllEventsNotifications",
        ]),
        _showAllEventsNotifications: {
            get() {
                return this.showAllEventsNotifications;
            },
            set(val) {
                this.setShowAllEventsNotifications(val);
            },
        },
    },
    methods: {
        ...mapActions([
            "setNavMainNav",
            "setNavTopbar",
            "setSoloActive",
            "setDroneActive",
            "setDroneActiveMission",
            "setEventsNotifications",
            "setNavStreamDialogDrawerVisible",
            "clearAllEventsNotifications",
            "setShowAllEventsNotifications",
        ]),
        handleLocaleChange(l) {
            this.$i18n.locale = l.language;
        },
        handleCollapseDronesFocusOut(e) {
            this.$root.$emit("bv::toggle::collapse", "collapse-drones");
        },
        handleCollapseSolosFocusOut(e) {
            this.$root.$emit("bv::toggle::collapse", "collapse-solos");
        },
        handleCollapseUserFocusOut(e) {
            this.$root.$emit("bv::toggle::collapse", "collapse-user");
        },
        handleDialogMosaic() {
            this.dronesElements.length > 0 || this.solosElements.length === 0
                ? this.setNavMainNav("drone")
                : this.setNavMainNav("solo");
            this.setNavTopbar(1);
            this.setDroneActive(null);
            this.setDroneActiveMission(null);
            this.setSoloActive(null);
            let self = this;
            setTimeout(() => {
                self.setNavStreamDialogDrawerVisible(true);
            }, 250);
        },
        signOut() {
            let self = this;
            firebase
                .auth()
                .signOut()
                .then(() => {
                    console.log("Going to login");
                    location.reload(true);
                    // self.$router.push({ path: "/login" });
                });
        },
        handleTogleAlertDrawer(e) {
            this.alertDrawer = e;
        },
    },
};
</script>


<style scoped>
.div-main-nav {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
}

.nav-item {
    height: 80px;
    width: 70px;
    text-align: center;
}

.nav-bolder {
    font-weight: 1000 !important;
}

.nav-item-brand {
    height: 80px;
    width: 70px;
    text-align: center;
}

.nav-link {
    padding: 0px;
}

li {
    padding: 0px !important;
    margin: 0px !important;
}

.active {
    color: #ffe082 !important;
}

i {
    font-size: 1rem;
}

@keyframes pulse {
    0%,
    100% {
        animation-timing-function: ease-in;
    }
    50% {
        transform: scale(1.2);
    }
}

.pulsed {
    animation: pulse 1s infinite;
}
</style>